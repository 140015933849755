import { Grid } from "@mui/material";
import React from "react";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import AdMenu from "./AdMenu";
import { baseUrl } from "../constants";
import { useNavigate } from "react-router-dom";

const AdCard = ({
  ad,
  anchorEl,
  open,
  handleCloseMenu,
  handleOpenMenu,
  draft,
  handleDeleteAdModalOpen,
  setSelectedAd,
  allAds,
  allDraftAds,
  selectedAd,
  handleViewsModalOpen,
  handlePricesModalOpen,
}) => {
  console.log({
    ad,
    anchorEl,
    open,
    handleCloseMenu,
    handleOpenMenu,
    draft,
    handleDeleteAdModalOpen,
    setSelectedAd,
    allAds,
    allDraftAds,
    selectedAd,
    handleViewsModalOpen,
    handlePricesModalOpen,
  })
  const navigate = useNavigate();
  const handleDuplicate = () => {
    navigate("/ads/createAd", { state: { allAds, allDraftAds, selectedAd } });
  };
  const handleGoToDetails = () => {
    navigate("/ads/details", { state: { card: ad } });
    handleCloseMenu();
  };

  return (
    <Grid
      container
      direction="column"
      style={{ width: 272, height: 335, marginRight: 40, marginBottom: 40 }}
    >
      <Grid
        container
        style={{
          width: 272,
          height: 272,
          borderRadius: 16,
          // backgroundImage: `${baseUrl + ad.post?.media[0]?.media}`,
          backgroundImage: `url(${baseUrl + ad?.post?.media[0]?.media})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingTop: 30,
          paddingRight: 20,
        }}
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <MoreHorizOutlinedIcon
          fontSize="large"
          style={{
            color: "#fff",
            borderRadius: "50%",
            background: "gray",
            cursor: "pointer",
          }}
          id="action-button"
          aria-controls={open ? "action-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            setSelectedAd(ad);
            handleOpenMenu(e);
          }}
        />
      </Grid>
      <span
        style={{ paddingTop: 10, fontSize: 18, fontWeight: 600, color: "#000" }}
      >
        {ad.post ? ad.name && ad.name : ad.adName}
      </span>
      {ad.clickRate && (
        <span
          style={{
            paddingTop: 4,
            fontSize: 14,
            fontWeight: 500,
            color: "#000",
          }}
        >
          <span style={{ color: ad.clickRate > 0 ? "#24CB99" : "#FF5655" }}>
            {ad.clickRate}%
          </span>{" "}
          clickrate vs last week
        </span>
      )}
      <AdMenu
        anchorEl={anchorEl}
        open={open}
        handleCloseMenu={handleCloseMenu}
        draft={draft}
        handleDeleteAdModalOpen={handleDeleteAdModalOpen}
        handleDuplicate={handleDuplicate}
        handleViewsModalOpen={handleViewsModalOpen}
        handlePricesModalOpen={handlePricesModalOpen}
        handleGoToDetails={handleGoToDetails}
      />
    </Grid>
  );
};

export default AdCard;
