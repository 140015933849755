import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";

import Header from "../../components/dashboard/Header";
import UserSection from "../../components/dashboard/UserSection";
import ActivitySection from "../../components/dashboard/ActivitySection";
import MostPopularWineSection from "../../components/dashboard/MostPopularWineSection";
import MostPopularHashtagsSection from "../../components/dashboard/MostPopularHashtagsSection";

import { useDashboardContext } from "../../context/DashboardContext";
import { getDashboardCountsByDates } from "../../apis/dashboard";

const Dashboard = () => {
  const { dashboardData, setDashboardData, handleGetDashboardData } =
    useDashboardContext();

  // console.log("date:", moment(new Date()).format("YYYY-MM-DD"));
  // console.log("dashboard data:", dashboardData);

  const [counts, setCounts] = useState(dashboardData);
  // const [counts, setCounts] = useState({
  //   registeredUser: users && users,
  //   personalUsers: personalUsers && personalUsers,
  //   businessUsers: businessUsers && businessUsers,
  //   suspendedUsers: deactivatedUsersData && deactivatedUsersData,
  // });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dropdownButtonText, setDropdownButtonText] = useState("All");

  useEffect(() => {
    setCounts(dashboardData);
  }, [dashboardData]);

  const handleFilter = async (filterOption) => {
    setDropdownButtonText(filterOption);
    // console.log("filter option:", filterOption);
    // let regUsersTemp = [];
    // let VeriUsersTemp = [];
    // let suspUsersTemp = [];

    if (filterOption == "All") {
      setCounts(dashboardData);
    }

    if (filterOption == "Today") {
      let dates = {
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: moment(new Date()).format("YYYY-MM-DD"),
      };
      let temp = await getDashboardCountsByDates(dates);
      // console.log("Today's data: ", temp);
      setCounts(temp);
    }

    if (filterOption == "Last Week") {
      let beforeOneWeek = new Date(
        new Date().getTime() - 60 * 60 * 24 * 7 * 1000
      );
      let beforeOneWeek2 = new Date(beforeOneWeek);
      let day = beforeOneWeek.getDay();
      let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
      let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
      let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

      // console.log("first day", lastMonday);
      // console.log("last day", lastSunday);

      let temp = await getDashboardCountsByDates({
        start_date: lastMonday,
        end_date: lastSunday,
      });
      // console.log("Last Week's data: ", temp);
      setCounts(temp);
    }

    if (filterOption == "Last Month") {
      const date = new Date();
      const firstDayPrevMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1
      );
      const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);

      let temp = await getDashboardCountsByDates({
        start_date: firstDayPrevMonth,
        end_date: lastDayPrevMonth,
      });
      // console.log("Last Month's data: ", temp);
      setCounts(temp);
    }
  };

  const handleDateRangeData = async (sDate, eDate) => {
    if (sDate || eDate) {
      // console.log("start date", sDate);
      // console.log("end date", eDate);
      let temp = await getDashboardCountsByDates({
        start_date: sDate,
        end_date: eDate,
      });
      // console.log("date range's data: ", temp);
      setCounts(temp);
    }
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <Header
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        dropdownButtonText={dropdownButtonText}
        setDropdownButtonText={setDropdownButtonText}
        handleFilter={handleFilter}
        handleDateRangeData={handleDateRangeData}
      />
      

      {/*User Analytics Section*/}
      <UserSection
        users={counts && counts.reg_users && counts.reg_users}
        personalUsers={
          counts && counts.personal_accounts && counts.personal_accounts
        }
        businessUsers={
          counts && counts.business_accounts && counts.business_accounts
        }
        suspendedUsers={
          counts && counts.deactivated_accounts && counts.deactivated_accounts
        }
      />

      {/*Activity Analytics Section*/}
      <ActivitySection
        uploadedImages={
          counts && counts.uploaded_images && counts.uploaded_images
        }
        uploadedVideos={
          counts && counts.uploaded_videos && counts.uploaded_videos
        }
        quizzesTaken={counts && counts.quiz_attempts && counts.quiz_attempts}
        ads={counts && counts.ads && counts.ads}
      />

      {/*Most Popular Experience Type Section*/}
      <MostPopularWineSection
        popularWines={counts && counts.pouplar_wines && counts.pouplar_wines}
      />

      {/*Most Active Cities (USA)*/}
      <MostPopularHashtagsSection
        popularHashtags={
          counts && counts.popularHashtags && counts.popularHashtags
        }
      />
    </Fragment>
  );
};

export default Dashboard;
