import { API } from "../constants";
import { request } from "../constants/request";

export const getAllUserGames = () => {
    return request({
      url: API.baseUrl + API.getGameUser,
      method: "GET",
    });
  };

export const getWordDetail =(word_id) =>{
  return request({
    url: API.baseUrl + API.getWordAnalytics + `/${word_id}`,
    method:"GET",
  })
}  