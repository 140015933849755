import Table from "@mui/material/Table";
import { winesColumns } from "../constants";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import React, { Fragment, useState } from "react";
import { useWinesContext } from "../../context/WinesContext";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Grid, TablePagination } from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

export default function WinesTable({
  allWines,
  setSelectedWine,
  handleClickAdd,
}) {
  const navigate = useNavigate();
  const {
    winesTableDetail,
    currentPage,
    setCurrentPage,
    setLoading,
    loading,
    handleGetAllWines,
    rowsPage,
    setRowPage,
  } = useWinesContext();
  // const [rowsPage, setRowPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = async (event) => {
    // console.log(event.target.value);
    setRowPage(event.target.value);
    await handleGetAllWines(event.target.value);
  };

  return (
    <Fragment>
      {loading ? (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <CircularProgress
            color="secondary"
            style={{
              color: "rgb(200, 75, 150)",
              width: "150px",
              height: "150px",
            }}
          />
        </Box>
      ) : (
        <Table style={{ border: "none", marginTop: 20 }}>
          <TableHead style={{ border: "none" }}>
            <TableRow>
              {winesColumns &&
                winesColumns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      border: "none",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#8086A2",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allWines &&
              allWines.map((row, index) => {
                return (
                  <Fragment>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      style={{ borderRadius: 20 }}
                    >
                      {winesColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              border: "none",
                              background: "#F6F6F6",
                              color: "#C84B96",
                              fontSize: 14,
                              fontWeight: column.id === "name" ? 600 : 500,
                              borderRadius:
                                column.id === "name"
                                  ? "8px 0px 0px 8px"
                                  : undefined,
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        key="forward"
                        align="right"
                        style={{
                          border: "none",
                          background: "#F6F6F6",
                          color: "#000",
                          fontSize: 14,
                          fontWeight: 500,
                          borderRadius: "0px 8px 8px 0px",
                          paddingRight: 30,
                        }}
                      >
                        <ArrowForwardIosOutlinedIcon
                          onClick={() => {
                            setSelectedWine(row);
                            navigate("/wines/wineDetails", {
                              state: { allWines, wineData: row },
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <div style={{ margin: 8 }} />
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      )}
      <Grid container style={{ marginTop: 15 }}>
        <Grid xs={6} item container>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={winesTableDetail.total}
            rowsPerPage={rowsPage}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid xs={6} item container>
          <Button
            variant="outlined"
            onClick={handleClickAdd}
            style={addWIneButtonStyles}
          >
            Add New Wine
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

const addWIneButtonStyles = {
  marginLeft: "auto",
  background: "#C84B96",
  borderRadius: 12,
  width: 160,
  height: 50,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
  color: "#fff",
};
