import { API } from "../constants";
import { request } from "../constants/request";

export const getAllAdmins = () => {
  return request({
    url: API.baseUrl + API.getAdmins,
    method: "GET",
  });
};

export const addAdmin = (adminData) => {
  return request({
    url: API.baseUrl + API.getAdmins,
    method: "POST",
    data: adminData,
  });
};

export const updateAdmin = (adminData, adminId) => {
  return request({
    url: API.baseUrl + API.getAdmins + `/${adminId}`,
    method: "PUT",
    data: adminData,
  });
};

export const deleteAdmin = (adminId) => {
  return request({
    url: API.baseUrl + API.getAdmins + `/${adminId}`,
    method: "DELETE",
  });
};

export const getAllGameWord = () => {
  return request({
    url: API.baseUrl + API.getAllWord,
    method: "GET",
  });
};

export const getCurrentActiveWordCall = () => {
  return request({
    url: API.baseUrl + API.gameWords,
    method:"GET",
  })
}

export const updateCurrentActiveWordCall = (word_id, word_data) => {
  return request({
    url: API.baseUrl + API.gameWords + `/${word_id}`,
    method:"POST",
    data: word_data,
  })
}
export const deleteDraftedWord = (word_id) => {
  return request({
    url: API.baseUrl + API.gameWords + `/${word_id}`,
    method:"DELETE",
  })
}

export const addGameWord = (data) => {
  return request({
    url: API.baseUrl + API.gameWords,
    method: "POST",
    data,
  });
};
