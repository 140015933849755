import React from "react";
import { Grid, TextField } from "@mui/material";

const ReviewTextField = ({ value, title }) => {
  return (
    <Grid container alignItems={"center"} style={{ marginBottom: 15 }}>
      <span
        style={{ fontSize: 16, fontWeight: 600, color: "#000", width: 120 }}
      >
        {title}
      </span>
      <TextField
        variant="outlined"
        value={value}
        style={textFieldStyles}
        InputProps={{
          style: textFieldPropsStyles,
        }}
        inputProps={{ sx: { color: "#808080" } }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
    </Grid>
  );
};

export default ReviewTextField;

const textFieldPropsStyles = {
  fontSize: 16,
  fontWeight: 600,
};
const textFieldStyles = {
  width: 320,
  borderRadius: 8,
  background: "#F6F6F6",
  color: "#808080",
};  
