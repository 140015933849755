import { Button, Grid, TextField } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CSVLink } from "react-csv";

import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";

import wineImage from "../../../images/wines/wine1.png";
import WineDetailTextField from "./WineDetailTextField";
import SelectedOptions from "../../ads/createAd/SelectedOptions";
import SelectOptionsComponent from "../../ads/createAd/SelectOptionsComponent";
import UploadImageModal from "./UploadImageModal";
import { Toast } from "../../alert";
import WineUpdatedModal from "./WineUpdatedModal";
import { updateWine } from "../../../apis/wines";
import { useWinesContext } from "../../../context/WinesContext";
import ButtonLoader from "../../ButtonLoader";
import { WinesHeader, baseUrl } from "../../constants";

const DetailsTab = ({ wineData, setWineData, allWines, setAllWines }) => {
  const { wines, setWines, handleGetAllWines } = useWinesContext();
  const [showVintage, setShowVintage] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [wineUpdatedModal, setWineUpdatedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [vintageOptions, setVintageOptions] = useState([]);
  const [varietalOptions, setVarietalOptions] = useState([
    "Cabernet Sauvignon",
    "Merlot",
    "Pinot noir",
    "Chardonnay",
    "White Zinfandel",
    "Riesling",
    "Blanc de Noirs",
    "Cabernet Franc",
    "Châteauneuf-du-Pape",
  ]);

  useEffect(() => {
    handleGetVintages();
  }, []);

  const handleGetVintages = () => {
    const currentYear = new Date().getFullYear();
    const years = ["NV"];

    for (let i = currentYear - 1; i >= currentYear - 100; i--) {
      years.push(i);
    }
    // console.log(years);
    setVintageOptions(years);
  };

  const [vintages, setVintages] = useState([]);

  // console.log(vintages);
  useEffect(() => {
    if (wineData.vintage) {
      const vintageArray = wineData.vintage.split(",").map(Number);
      setVintages(vintageArray);
      // if (typeof wineData.vintage === "string") {
      //   // if (typeof JSON.parse(wineData.vintage) === "object") {
      //   if (typeof wineData.vintage === "object") {
      //     const temp = JSON.parse(wineData.vintage).map((item) => Number(item));
      //     setVintages(temp);
      //   } else {
      //     if (wineData.vintage === "nv") {
      //       setVintages([]);
      //     } else {
      //       setVintages([JSON.parse(wineData.vintage)]);
      //     }
      //   }
      // } else {
      //   const temp = wineData.vintage.map((item) => Number(item));
      //   setVintages(temp);
      // }
    }
  }, [wineData]);

  const handleUploadImageModalOpen = () => {
    setUploadImageModal(true);
  };
  const handleUploadImageModalClose = () => {
    setWineData({
      ...wineData,
      wineImage: wineData.wineImage && wineData.wineImage,
    });
    setUploadImageModal(false);
  };

  const handleWineUpdatedModalOpen = () => {
    setWineUpdatedModal(true);
  };
  const handleWineUpdatedModalClose = () => {
    setWineUpdatedModal(false);
  };

  const handleSelectVintage = (vintage, index) => {
    if (vintages.includes(vintage)) {
      setVintages(vintages.filter((item) => item !== vintage));
    } else {
      setVintages([...vintages, vintage]);
    }
    setShowVintage(false);
  };

  const handlePhotoChange = (e) => {
    if (e.target.files.length) {
      setWineData({
        ...wineData,
        wineImage: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        },
      });
    }
  };

  const handleSelectAllVintages = () => {
    setWineData({ ...wineData, vintage: vintageOptions });
    setVintages(vintageOptions);
    // setVintageOptions(temp);
    setShowVintage(false);
  };

  // function arrayRemove(arr, value) {
  //   return arr.filter(function (ele) {
  //     return ele != value;
  //   });
  // }

  const handleUpdateWine = async () => {
    try {
      setLoading(true);

      let temp = {
        ...wineData,
        wineImage:
          wineData.wineImage &&
          wineData.wineImage.preview &&
          wineData.wineImage.preview
            ? wineData.wineImage.preview
            : wineData.wineImage,
      };

      let docTemp = {
        name: temp.name,
        price: temp.price,
        varietal: temp.varietal,
        region: temp.region,
        vintage: vintages,
        image: wineData.wineImage ? wineData.wineImage.raw : "",
      };

      // console.log(docTemp);

      const Data = new FormData();
      if (docTemp.image) {
        Data.append("image", docTemp.image);
      }
      Data.append("name", docTemp.name);
      Data.append("varietal", docTemp.varietal);
      if (docTemp.price) {
        Data.append("price", docTemp.price);
      }
      Data.append("region", docTemp.region);
      Data.append("_method", "PUT");
      docTemp.vintage.map((item, i) => {
        Data.append(`vintage[${i}]`, item.toString());
      });

      await updateWine(Data, wineData.id);

      let docsTemp = [...wines];
      let index;
      docsTemp.forEach((el) => {
        if (el.id == wineData.id) {
          index = docsTemp.indexOf(el);
        }
      });
      if (index > -1) {
        docsTemp[index] = { ...temp };
      }
      // console.log("wines :", docsTemp);
      setWines(docsTemp);
      setAllWines(docsTemp);

      Toast("success", "wine Updated successfully.");
      if (wineData.wineImage && wineData.wineImage.preview) {
        handleWineUpdatedModalOpen();
      } else {
        navigate("/wines");
        await handleGetAllWines();
      }
    } catch (error) {
      console.log("error updating wine profile:", error.message);
      Toast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Grid container>
        <Grid xs={5} container style={{ padding: 40 }}>
          <Grid
            container
            style={{
              width: 400,
              height: 400,
              borderRadius: 16,
              backgroundImage: `url(${
                wineData.wineImage
                  ? wineData.wineImage.preview
                  : wineData.image
                  ? baseUrl + wineData.image
                  : wineImage
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            //   alignItems="flex-start"
            //   justifyContent="flex-end"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{
                width: 40,
                height: 40,
                background: "#3E173A",
                border: "1px solid #fff",
                borderRadius: 50,
                marginTop: -4,
                marginLeft: -4,
              }}
            >
              <DriveFileRenameOutlineOutlinedIcon
                style={{ color: "#fff", cursor: "pointer" }}
                onClick={handleUploadImageModalOpen}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={7} style={{ paddingTop: 40 }}>
          <WineDetailTextField
            title={"Price"}
            value={wineData?.price && wineData?.price}
            onChange={(e) =>
              setWineData({ ...wineData, price: e.target.value })
            }
            type={"number"}
          />
          <WineDetailTextField
            title={"Alcohol"}
            value={wineData?.name && wineData?.name}
            onChange={(e) => setWineData({ ...wineData, name: e.target.value })}
          />

          <WineDetailTextField
            title={"Vintage"}
            //   value={wineData.name && wineData.name}
            onChange={(e) => setShowVintage(true)}
            icon={
              <ExpandMoreOutlinedIcon
                onClick={() => setShowVintage(!showVintage)}
                style={{ cursor: "pointer" }}
              />
            }
          />
          {/* show selected age ranges options */}
          {vintages.length > 0 && (
            <Grid container style={{ paddingLeft: 120, marginTop: -15 }}>
              <SelectedOptions data={vintages ? vintages : []} />
            </Grid>
          )}
          {/* Show age range suggestions section */}
          {showVintage && (
            <Grid container style={{ paddingLeft: 120 }}>
              <SelectOptionsComponent
                title={"Vintage"}
                data={vintageOptions}
                handleSelectOption={handleSelectVintage}
                handleSelectAll={handleSelectAllVintages}
                selectedGender={vintages}
                interestsDropdown={false}
              />
            </Grid>
          )}
          {/*DIning out / cooking at home
          <Grid container style={{ paddingLeft: 120 }}>
            <SelectOptionsComponent
              title={"Plan"}
              data={planOptions}
              handleSelectOption={handleSelectVintage}
              handleSelectAll={handleSelectAllVintages}
            />
          </Grid>
        
        
        */}
          <WineDetailTextField
            title={"Location"}
            value={wineData.region && wineData.region}
            onChange={(e) =>
              setWineData({ ...wineData, region: e.target.value })
            }
          />
          <Grid container alignItems={"center"} style={{ marginBottom: 15 }}>
            <span
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: "#000",
                width: 120,
              }}
            >
              Varietal
            </span>
            <Autocomplete
              // defaultValue={wineData.varietal ? wineData.varietal : ""}
              value={wineData.varietal ? wineData.varietal : ""}
              onChange={(event, newValue) => {
                setWineData({ ...wineData, varietal: newValue });
              }}
              inputValue={wineData.varietal ? wineData.varietal : ""}
              onInputChange={(event, newInputValue) => {
                setWineData({ ...wineData, varietal: newInputValue });
              }}
              options={varietalOptions}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              style={textFieldStyles}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          {/*
          <WineDetailTextField
            title={"Varietal"}
            value={wineData.varietal && wineData.varietal}
            onChange={(e) =>
              setWineData({ ...wineData, varietal: e.target.value })
            }
          />
        */}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={"flex-end"}
        justifyContent="flex-end"
        style={{ marginTop: 150, width: "95%" }}
      >
        <Button
          variant="contained"
          style={exportButtonStyles}
          //   onClick={handlePublishModalOpen}
        >
          <CSVLink
            data={wineData ? [wineData] : []}
            headers={WinesHeader}
            filename={`${wineData.name} Wine's Record.csv`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export
          </CSVLink>
        </Button>
        {loading ? (
          <Grid
            container
            style={{ width: 100 }}
            alignItems="center"
            justifyContent="center"
          >
            <ButtonLoader />
          </Grid>
        ) : (
          <Button
            variant="outlined"
            style={updateButtonStyles}
            onClick={handleUpdateWine}
          >
            Update
          </Button>
        )}
      </Grid>
      {/*Upload Image Modal Section*/}
      <UploadImageModal
        modalOpen={uploadImageModal}
        handleClose={handleUploadImageModalClose}
        // handleConfirm={handleUpdateWine}
        handlePhotoChange={handlePhotoChange}
        wineData={wineData}
        setWineData={setWineData}
      />
      <WineUpdatedModal
        modalOpen={wineUpdatedModal}
        handleClose={() => {
          handleWineUpdatedModalClose();
          handleGetAllWines();
          navigate("/wines");
        }}
      />
    </Fragment>
  );
};

export default DetailsTab;

const textFieldPropsStyles = {
  fontSize: 16,
  fontWeight: 600,
};
const textFieldStyles = {
  width: 320,
  borderRadius: 8,
  background: "#F6F6F6",
  color: "#808080",
};

const exportButtonStyles = {
  color: "#FFFFFF",
  background: "#C84B96",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const updateButtonStyles = {
  color: "#C84B96",
  borderColor: "#C84B96",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
