import React, { Fragment, useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import moment from "moment";
import mime from "mime";

import axios from "axios";

import CreateAdHeader from "../../components/ads/createAd/CreateAdHeader";
import AdDetailsSection from "../../components/ads/createAd/AdDetailsSection";
import AdImageSection from "../../components/ads/createAd/AdImageSection";
import { Toast } from "../../components/alert";
import ReviewAdDetailsSection from "../../components/ads/createAd/ReviewAdDetailsSection";
import ReviewImageSection from "../../components/ads/createAd/ReviewImageSection";
import PublishQuizModal from "../../components/quizzes/addQuiz/PublishQuizModal";
import QuizPublishedModal from "../../components/quizzes/addQuiz/QuizPublishedModal";
import SaveDraftModal from "../../components/quizzes/addQuiz/SaveDraftModal";
import { addInterest, addNewAd } from "../../apis/ads";
import { useAdsContext } from "../../context/AdsContext";
import { add } from "date-fns";
import { baseUrl } from "../../components/constants";

const CreateAd = () => {
  const { handleGetAllAds, interests } = useAdsContext();
  const navigate = useNavigate();
  const location = useLocation();
  const imageRef = useRef();

  const [allAds, setAllAds] = useState(location.state.allAds);
  const [allDraftAds, setAllDraftAds] = useState(location.state.allDraftAds);
  const [selectedAd, setSelectedAd] = useState(location.state.ad);

  const [showAgeRange, setShowAgeRange] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showInterest, setShowInterest] = useState(false);
  const [reviewAd, setReviewAd] = useState(false);
  const [publishAdModal, setPublishAdModal] = useState(false);
  const [adPublishedModal, setAdPublishedModal] = useState(false);
  const [saveAdDraftModal, setSaveAdDraftModal] = useState(false);
  const [adDraftSavedModal, setAdDraftSavedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);

  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [ageRange, setAgeRange] = useState([
    "21-27yrs",
    "28-35yrs",
    "36-43yrs",
    "44-51yrs",
    "52 and over",
  ]);
  const [genders, setGenders] = useState(["Male", "Female", "Non Binary"]);
  // const [interests, setInterests] = useState([
  //   "Rose Wine",
  //   "Grapes Wine",
  //   "Wood Wine",
  // ]);

  const [adData, setAdData] = useState({
    image: "",
    adName: "",
    goal: "",
    website: "",
    location: "",
    ageRange: [],
    gender: [],
    interests: [],
    description: "",
    startDate: null,
    endDate: null,
  });

  const downloadImage = async (image) => {
    const response = await fetch(image, {
      mode: "no-cors",
    });
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "share.jpg", { type: blob.type });
    // console.log(file);
    setImageFile(file);
    setImage({
      preview: image,
      raw: file,
    });
  };

  useEffect(() => {
    if (location.state.selectedAd) {
      // console.log(location.state.selectedAd.promotion_interests);
      setAdData({
        ...adData,
        image: "",
        adName: location.state.selectedAd.name,
        goal: location.state.selectedAd.goal,
        website: location.state.selectedAd.website,
        location: location.state.selectedAd.location,
        age: location.state.selectedAd.age,
        gender: ["Male", "Female", "Non Binary"],
        // gender: location.state.selectedAd.promotion_genders.map((item) => {
        //   return item.gender;
        // }),
        interests: location.state.selectedAd.promotion_interests.map((item) => {
          return item.hashtag_id;
        }),
        description: "",
      });
      downloadImage(
        `${baseUrl + location.state.selectedAd?.post?.media[0]?.media}`
      );
    }
  }, [location.state.selectedAd]);

  const [openStartDateModal, setOpenStartDateModal] = useState(false);
  const [openEndDateModal, setOpenEndDateModal] = useState(false);

  const goback = () => {
    navigate("/ads");
  };

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const handleResetStates = () => {
    setAdData({
      image: "",
      adName: "",
      goal: "",
      website: "",
      location: "",
      ageRange: [],
      gender: [],
      interests: [],
      description: "",
      startDate: null,
      endDate: null,
    });
    setImage({ preview: "", raw: "" });
  };

  const handlePhotoChange = (e) => {
    if (e.target.files.length) {
      // console.log(e.target.files[0]);
      setImageFile(e.target.files[0]);
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      // console.log(e.target.files[0]);
    }
  };

  const handleOpenStartDateModal = () => setOpenStartDateModal(true);
  const handleCloseStartDateModal = () => setOpenStartDateModal(false);
  const handleOpenEndDateModal = () => setOpenEndDateModal(true);
  const handleCloseEndDateModal = () => setOpenEndDateModal(false);

  const handleSelectAgeRange = (age, index) => {
    // let temp = [...ageRange];
    // let adDocTemp = [...adData.ageRange];
    // let removeItem;
    // temp[index] = { ...age, selected: !age.selected };
    // const found = adDocTemp.find((element) => element == age.value);
    // if (found) {
    //   removeItem = arrayRemove(adDocTemp, age.value);
    //   adDocTemp = [...removeItem];
    // } else {
    //   adDocTemp.push(age.value);
    // }
    // setAgeRange(temp);
    setAdData({ ...adData, ageRange: age });
    setShowAgeRange(false);
  };

  const handleSelectAllAgeRange = () => {
    let temp = [...ageRange];
    let docTemp = [];
    temp.forEach((doc, index) => {
      docTemp.push(doc.value);
      temp[index] = { ...doc, selected: true };
    });
    setAdData({ ...adData, ageRange: docTemp });
    setAgeRange(temp);
    setShowAgeRange(false);
  };

  const handleSelectGender = (gender, index) => {
    // let temp = [...genders];
    // let adDocTemp = [...adData.gender];
    // let removeItem;
    // temp[index] = { ...gender, selected: !gender.selected };
    // const found = adDocTemp.find((element) => element == gender.value);
    // if (found) {
    //   removeItem = arrayRemove(adDocTemp, gender.value);
    //   console.log(removeItem);
    //   adDocTemp = [...removeItem];
    // } else {
    //   adDocTemp.push(gender.value);
    // }
    // setGenders(temp);
    setAdData({ ...adData, gender: gender });
    setShowGender(false);
  };

  const handleSelectAllGender = () => {
    // let temp = [...genders];
    // let docTemp = [];
    // temp.forEach((doc, index) => {
    //   docTemp.push(doc.value);
    //   temp[index] = { ...doc, selected: true };
    // });
    // setAdData({ ...adData, gender: docTemp });
    setAdData({ ...adData, gender: genders });
    setShowGender(false);
  };

  const handleSelectInterests = (interest, index) => {
    // let temp = [...interests];
    // let adDocTemp = [...adData.interests];
    // let removeItem;
    // temp[index] = { ...interest, selected: !interest.selected };
    // const found = adDocTemp.find((element) => element == interest.value);
    // if (found) {
    //   removeItem = arrayRemove(adDocTemp, interest.value);
    //   console.log(removeItem);
    //   adDocTemp = [...removeItem];
    // } else {
    //   adDocTemp.push(interest.value);
    // }
    // setInterests(temp);
    setAdData({ ...adData, interests: interest });
    setShowInterest(false);
  };

  const handleSelectAllInterests = () => {
    let temp = [...interests];
    let docTemp = [];
    temp.map((item) => docTemp.push(item.id));
    setAdData({ ...adData, interests: docTemp });
    // setInterests(temp);
    setShowInterest(false);
  };

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  const handleReviewAd = () => {
    if (
      adData.adName == "" ||
      adData.website == "" ||
      adData.location == "" ||
      adData.age == "" ||
      // adData.gender.length == 0 ||
      // adData.interests.length == 0 ||
      adData.description == "" ||
      adData.startDate == null ||
      adData.endDate == null
      // image.preview == ""
    ) {
      Toast("error", "Please complete the Ad first.");
    } else {
      // Toast("success", "Review clicked");
      setReviewAd(true);
    }
  };

  const handlePublishAdModalOpen = () => {
    setPublishAdModal(true);
  };
  const handlePublishAdModalClose = () => {
    setPublishAdModal(false);
  };

  // console.log(adData);

  const handlePublishAd = async () => {
    try {
      setLoading(true);
      // let interestIdsTemp = [];
      // adData.interests.forEach(async (doc) => {
      //   let temp = { name: doc };
      //   let tempDoc = await addInterest(temp);
      //   interestIdsTemp.push(tempDoc.id);
      // });

      const Data = new FormData();
      if (imageFile) {
        Data.append("image", imageFile);
      }
      Data.append("name", adData.adName);
      Data.append("description", adData.description);
      Data.append("goal", adData.goal);
      Data.append(
        "website",
        adData.website.includes("https://")
          ? adData.website
          : `https://${adData.website}`
      );

      // Data.append(`promotion_interests`, adData.interests);
      adData.interests.map((item, i) => {
        Data.append(`promotion_interests[${i}]`, item);
      });
      genders.map((item, i) => {
        Data.append(`promotion_genders[${i}]`, item);
      });
      Data.append("location", adData.location);
      Data.append("start_date", moment(adData.startDate).format("YYYY-MM-DD"));
      Data.append("end_date", moment(adData.endDate).format("YYYY-MM-DD"));
      Data.append("status", "Publish");
      Data.append("age", Number(adData.age));

      // console.log(Data);

      await addNewAd(Data);

      await handleGetAllAds();
      handleAdPublishedModalOpen();
      handlePublishAdModalClose();
      Toast("success", "Ad published successfully.");
      handleResetStates();
      setReviewAd(false);
      navigate("/ads");
    } catch (error) {
      console.log("error publishing quiz:", error.response.data.message);
      Toast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAdPublishedModalOpen = () => {
    setAdPublishedModal(true);
  };
  const handleAdPublishedModalClose = () => {
    setAdPublishedModal(false);
  };

  const handleSaveDraftModalOpen = () => {
    setSaveAdDraftModal(true);
  };
  const handleSaveDraftModalClose = () => {
    setSaveAdDraftModal(false);
  };
  const handleDraftSavedModalOpen = () => {
    setAdDraftSavedModal(true);
  };
  const handleDraftSavedModalClose = () => {
    setAdDraftSavedModal(false);
  };

  const handleSaveDraft = async () => {
    try {
      setDraftLoading(true);

      // let id = new Date().getUTCMilliseconds();
      // let docsTemp = [...allDraftAds];
      // docsTemp.push({ ...adData, id });
      // setAllDraftAds(docsTemp);
      // let temp = {
      //   name: adData.adName,
      //   goal: adData.goal,
      //   website: "https://www.youtube.com",
      //   promotion_interests: [],
      //   // age: adData.ageRange[0],
      //   age: 35,
      //   promotion_genders: [],
      //   location: adData.location,
      //   description: adData.description,
      //   image: image.raw,
      //   start_date: moment(adData.startDate).format("YYYY-MM-DD"),
      //   end_date: moment(adData.endDate).format("YYYY-MM-DD"),
      //   status: "Draft",
      // };

      const Data = new FormData();
      if (imageFile) {
        Data.append("image", imageFile);
      }
      Data.append("name", adData.adName);
      Data.append("description", adData.description);
      Data.append("goal", adData.goal);
      Data.append(
        "website",
        adData.website.includes("https://")
          ? adData.website
          : `https://${adData.website}`
      );
      // Data.append(`promotion_interests`, adData.interests);
      adData.interests.map((item, i) => {
        Data.append(`promotion_interests[${i}]`, item);
      });
      adData.gender.map((item, i) => {
        Data.append(`promotion_genders[${i}]`, adData.gender[i]);
      });
      Data.append("location", adData.location);
      Data.append("start_date", moment(adData.startDate).format("YYYY-MM-DD"));
      Data.append("end_date", moment(adData.endDate).format("YYYY-MM-DD"));
      Data.append("status", "Draft");
      Data.append("age", Number(adData.age));

      await addNewAd(Data);

      await handleGetAllAds();
      handleDraftSavedModalOpen();
      handleSaveDraftModalClose();
      Toast("success", "Draft saved successfully.");
      handleResetStates();
      setReviewAd(false);
      navigate("/ads");
    } catch (error) {
      console.log("error saving draft:", error.message);
      Toast("error", error.response.data.message);
    } finally {
      setDraftLoading(false);
    }
  };

  return (
    <Fragment>
      {/* Header section */}
      <CreateAdHeader
        goback={goback}
        reviewAd={reviewAd}
        handleReviewAd={handleReviewAd}
        handlePublishModalOpen={handlePublishAdModalOpen}
        handleSaveDraftModalOpen={handleSaveDraftModalOpen}
      />
      <Grid container>
        {/*ad's textfields section */}
        {reviewAd ? (
          <ReviewAdDetailsSection adData={adData} />
        ) : (
          <AdDetailsSection
            adData={adData}
            setAdData={setAdData}
            showAgeRange={showAgeRange}
            setShowAgeRange={setShowAgeRange}
            showGender={showGender}
            setShowGender={setShowGender}
            showInterest={showInterest}
            setShowInterest={setShowInterest}
            ageRange={ageRange}
            setAgeRange={setAgeRange}
            genders={genders}
            setGenders={setGenders}
            interests={interests}
            // setInterests={setInterests}
            openStartDateModal={openStartDateModal}
            openEndDateModal={openEndDateModal}
            handleOpenStartDateModal={handleOpenStartDateModal}
            handleCloseStartDateModal={handleCloseStartDateModal}
            handleOpenEndDateModal={handleOpenEndDateModal}
            handleCloseEndDateModal={handleCloseEndDateModal}
            handleSelectAgeRange={handleSelectAgeRange}
            handleSelectAllAgeRange={handleSelectAllAgeRange}
            handleSelectGender={handleSelectGender}
            handleSelectAllGender={handleSelectAllGender}
            handleSelectInterests={handleSelectInterests}
            handleSelectAllInterests={handleSelectAllInterests}
          />
        )}
        {/* Ad Image Section */}
        {reviewAd ? (
          <ReviewImageSection adData={adData} image={image} />
        ) : (
          <AdImageSection
            image={image}
            imageRef={imageRef}
            showOpenFileDialog={showOpenFileDialog}
            handlePhotoChange={handlePhotoChange}
          />
        )}
      </Grid>

      {/* Publish Ad Modal section */}
      <PublishQuizModal
        modalOpen={publishAdModal}
        handleClose={handlePublishAdModalClose}
        handleConfirm={handlePublishAd}
        loading={loading}
        createAdTitle={"Yes, Publish Ad"}
        title="Are you sure you want to publish this ad? Action is not reversable."
      />
      {/* Quiz published Modal section */}
      <QuizPublishedModal
        modalOpen={adPublishedModal}
        handleClose={handleAdPublishedModalClose}
        title="Published Successfully"
      />
      {/* Save Draft Quiz Modal section */}
      <SaveDraftModal
        modalOpen={saveAdDraftModal}
        handleClose={handleSaveDraftModalClose}
        handleConfirm={handleSaveDraft}
        loading={draftLoading}
        title="You have unsaved changes. Would you like to save your Ad?"
      />
      {/* Draft Quiz saved Modal section */}
      <QuizPublishedModal
        modalOpen={adDraftSavedModal}
        handleClose={handleDraftSavedModalClose}
        title="Successfully saved draft"
      />
    </Fragment>
  );
};

export default CreateAd;
