import { Button, Grid, TextField } from "@mui/material";
import React, { Fragment } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import AddAnswerTextField from "./AddAnswerTextField";

const CreateQuestionCard = ({
  quizData,
  singleQuestion,
  setSingleQuestion,
  handleChangeAnswer,
  handleSelectCorrectAnswer,
  questionCompleted,
  handleAddAnotherQuestion,
  addAnswerToArray,
  editQuestion,
  handleUpdateQuestion,
  handleDeleteQuestionModalOpen,
  removeAnswerFromArray,
  handleUploadFile,
}) => {
  return (
    <Grid container direction={"column"} style={{ width: "600px", marginTop: 40 }}>
      <TextField
        variant="outlined"
        fullWidth
        value={singleQuestion?.question}
        onChange={(e) => setSingleQuestion({ ...singleQuestion, question: e.target.value })}
        placeholder="Type your questions here..."
        inputProps={{ sx: { color: "#000" } }}
        InputProps={{
          style: questionPropsStyles,
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
      <div>
        <label htmlFor="questionImage" style={uploadBtnStyle}>
          {singleQuestion?.image ? singleQuestion?.image?.name : "Upload Image"}
        </label>
        <input
          type="file"
          id="questionImage"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleUploadFile}
        />
      </div>
      <AddAnswerTextField
        label={singleQuestion.answers[0].label}
        value={singleQuestion.answers[0].answer}
        correct={singleQuestion.answers[0].correct}
        onChange={(e) => handleChangeAnswer(e, 0)}
        handleCorrectAnswerClick={() => handleSelectCorrectAnswer(0)}
        key={"A"}
      />
      <AddAnswerTextField
        label={singleQuestion.answers[1].label}
        value={singleQuestion.answers[1].answer}
        correct={singleQuestion.answers[1].correct}
        onChange={(e) => handleChangeAnswer(e, 1)}
        handleCorrectAnswerClick={() => handleSelectCorrectAnswer(1)}
        key={"B"}
      />
      {singleQuestion.answers[2] && (
        <AddAnswerTextField
          label={singleQuestion.answers[2].label}
          value={singleQuestion.answers[2].answer}
          correct={singleQuestion.answers[2].correct}
          onChange={(e) => handleChangeAnswer(e, 2)}
          handleCorrectAnswerClick={() => handleSelectCorrectAnswer(2)}
          key={"C"}
          handleRemove={() => removeAnswerFromArray(2)}
        />
      )}
      {singleQuestion.answers[3] && (
        <AddAnswerTextField
          label={singleQuestion.answers[3].label}
          value={singleQuestion.answers[3].answer}
          correct={singleQuestion.answers[3].correct}
          onChange={(e) => handleChangeAnswer(e, 3)}
          handleCorrectAnswerClick={() => handleSelectCorrectAnswer(3)}
          key={"D"}
          handleRemove={() => removeAnswerFromArray(3)}
        />
      )}
      {singleQuestion.answers.length !== 4 && (
        <Grid container justifyContent={"flex-end"}>
          <Grid
            container
            alignItems={"center"}
            justifyContent="center"
            style={addAnswerButtonStyles}
            onClick={addAnswerToArray}
          >
            <AddOutlinedIcon fontSize={"medium"} style={{ color: "#fff" }} />
          </Grid>
        </Grid>
      )}

      <Grid container alignItems={"center"} justifyContent="center">
        {editQuestion ? (
          <Grid container>
            <Button
              variant="contained"
              style={{
                ...editQuestionButtonStyles,
                background: "#C84B96",
                color: "#fff",
              }}
              onClick={handleUpdateQuestion}
            >
              Save Changes
            </Button>
            <Button
              variant="outlined"
              style={deleteQuestionButtonStyles}
              onClick={handleDeleteQuestionModalOpen}
            >
              Delete
            </Button>
          </Grid>
        ) : (
          <Button
            variant="contained"
            style={{
              ...addQuestionButtonStyles,
              background: !questionCompleted ? "#F6F6F6" : "#C84B96",
              color: !questionCompleted ? "#9D9DAA" : "#fff",
            }}
            disabled={!questionCompleted}
            onClick={handleAddAnotherQuestion}
          >
            {/*Add another question*/}
            Complete Question
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateQuestionCard;

const addAnswerButtonStyles = {
  width: 50,
  height: 50,
  background: "#4E1B5C",
  borderRadius: 50,
  marginTop: 15,
  cursor: "pointer",
};

const questionPropsStyles = {
  fontSize: 16,
  fontWeight: 600,
  marginLeft: -10,
};

const addQuestionButtonStyles = {
  marginTop: 80,
  width: 350,
  height: 60,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
  borderRadius: 8,
};

const editQuestionButtonStyles = {
  marginTop: 80,
  width: "60%",
  height: 60,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
  borderRadius: 8,
};

const deleteQuestionButtonStyles = {
  marginTop: 150,
  width: "35%",
  height: 60,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
  borderRadius: 8,
  border: "1px solid #C84B96",
  color: "#C84B96",
  marginLeft: "auto",
};

const uploadBtnStyle = {
  padding: "1rem",
  background: "#f6f6f6",
  color: "gray",
  display: "block",
  textAlign: "center",
  borderRadius: ".3rem",
};
