import { Button, Grid } from "@mui/material";
import React from "react";

import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

const QuestionPreviewCard = ({ question, handleEditQuestionClick }) => {
  return (
    <Grid
      item
      container
      direction={"column"}
      style={questionContainerStyles}
      //   alignItems="center"
      //   justifyContent={"center"}
    >
      <span style={questionStyles}>
        {question.question && question.question}
      </span>
      {/*
       */}
      {question.answers &&
        question.answers.map((answer, index) => (
          <Grid
            item
            container
            style={answerContainerStyles}
            key={index}
            alignItems="center"
          >
            {answer.correct ? (
              <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                style={correctAnswerStyles}
              >
                <DoneOutlinedIcon style={{ padding: 2, color: "#fff" }} />
              </Grid>
            ) : (
              <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                style={labelContainerStyles}
              >
                <span style={answerLabelStyles}>{answer.label}</span>
              </Grid>
            )}
            <span style={answerStyles}>{answer.answer}</span>
          </Grid>
        ))}
      <Grid container alignItems={"flex-end"} style={{ marginTop: "auto" }}>
        <Button
          fullWidth
          variant="container"
          style={editQuestionButtonStyles}
          onClick={handleEditQuestionClick}
        >
          Edit Question
        </Button>
      </Grid>
    </Grid>
  );
};

export default QuestionPreviewCard;

const editQuestionButtonStyles = {
  color: "#fff",
  background: "#4E1B5C",
  borderRadius: 8,
  textTransform: "none",
  fontSize: 14,
  fontWeight: 600,
};
const answerStyles = {
  fontWeight: 600,
  fontSize: 14,
  color: "#313137",
};
const answerLabelStyles = {
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: 14,
  color: "#9D9DAA",
};
const labelContainerStyles = {
  margin: 12,
  border: "2px solid #9D9DAA",
  borderRadius: 50,
  width: 24,
  height: 24,
};
const correctAnswerStyles = {
  margin: 12,
  borderRadius: 50,
  width: 24,
  height: 24,
  background: "#17DDA2",
};
const answerContainerStyles = {
  height: 40,
  marginBottom: 15,
  borderRadius: 8,
  background: "#fff",
};
const questionStyles = {
  fontWeight: 600,
  fontSize: 16,
  color: "#000",
  height: 70,
  overflow: "hidden",
  marginBottom: 15,
};
const questionContainerStyles = {
  width: 300,
  // height: 390,
  padding: 30,
  borderRadius: 20,
  background: "#F9F9FA",
  marginRight: 15,
  marginBottom: 15,
};
