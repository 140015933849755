import React, { useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";

const VerifyUserModal = ({
  modalOpen,
  handleClose,
  modalStyle,
  handleConfirm,
  userDetails,
  setUserDetails,
  handleConfirmVerification,
  handleDenyVerification,
}) => {
  const [denyMessage, setDenyMessage] = useState("");
  const [denyReason, setDenyReason] = useState("");

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Verify User
        </span>
        <Grid container alignItems="center" justifyContent="center">
          <span
            style={{
              color: "#000000",
              fontSize: 15,
              fontWeight: 500,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            Verified users should be vetted by WineLikes admin to confirm their
            identity and need for verification.
          </span>
          <Button
            fullWidth
            variant="contained"
            onClick={handleConfirmVerification}
            style={{
              textTransform: "none",
              height: 55,
              fontSize: 15,
              fontWeight: 600,
              background: "#C84B96",
              color: "#FFFFFF",
            }}
          >
            Confirm User Verification
          </Button>
          <Typography
            style={{ padding: 6, fontSize: 14, fontWeight: 600, color: "#000" }}
          >
            or
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={denyMessage}
            onChange={(e) => setDenyMessage(e.target.value)}
            placeholder="Deny Due To (Select Reason)"
            style={{ margin: "20px 0px" }}
            InputProps={{
              style: {
                color: "#B1B1BD",
                fontSize: 16,
                fontWeight: 600,
              },
            }}
          />
          <TextField
            fullWidth
            select
            variant="outlined"
            value={denyReason}
            onChange={(e) => setDenyReason(e.target.value)}
            label="Reason"
            InputProps={{
              style: {
                color: "#B1B1BD",
                fontSize: 16,
                fontWeight: 600,
              },
            }}
          >
            <MenuItem value="False Identity">False Identity</MenuItem>
            <MenuItem value="Identity already claimed">
              Identity already claimed
            </MenuItem>
          </TextField>
          <Button
            fullWidth
            variant="contained"
            onClick={() => handleDenyVerification(denyMessage, denyReason)}
            style={{
              height: 55,
              fontSize: 15,
              fontWeight: 600,
              color: "#FFF",
              background: "#440356",
              marginTop: 20,
              textTransform: "none",
            }}
          >
            Submit
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default VerifyUserModal;
