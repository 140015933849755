import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./context/AuthContext";
import { WinesProvider } from "./context/WinesContext";
import { UsersProvider } from "./context/UsersContext";
import { QuizProvider } from "./context/QuizContext";
import { AdminsProvider } from "./context/AdminsContext";
import { DashboardProvider } from "./context/DashboardContext";
import { ReportsProvider } from "./context/ReportsContext";
import { AdsProvider } from "./context/AdsContext";
import { MenusProvider } from "./context/MenusContext";
import {RecipesProvider} from "./context/RecipesContext"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <DashboardProvider>
      <AdminsProvider>
        <UsersProvider>
          <ReportsProvider>
            <MenusProvider>
              <WinesProvider>
                <QuizProvider>
                  <AdsProvider>
                  <RecipesProvider>
                    <App />
                  </RecipesProvider>
                  </AdsProvider>
                </QuizProvider>
              </WinesProvider>
            </MenusProvider>
          </ReportsProvider>
        </UsersProvider>
      </AdminsProvider>
    </DashboardProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
