import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const AdTextFieldComponent = ({
  defaultValue,
  onChange,
  placeholder,
  onClick,
  icon,
  multiline,
  rows,
  value,
  onBlur
}) => {
  return (
    <TextField
      variant="outlined"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      style={textFieldStyles}
      multiline={multiline}
      onBlur={onBlur}
      rows={rows}
      InputProps={{
        style: textFieldPropsStyles,
        endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
      }}
    />
  );
};

export default AdTextFieldComponent;

const textFieldPropsStyles = {
  fontSize: 16,
  fontWeight: 600,
  //   marginLeft: -10,
};
const textFieldStyles = { width: 366, borderRadius: 8, marginBottom: 8 };
