import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const AdImageSection = ({
  image,
  imageRef,
  showOpenFileDialog,
  handlePhotoChange,
}) => {
  return (
    <Grid
      item
      container
      direction={"column"}
      style={{ width: "55%", padding: 60 }}
    >
      <Grid item style={{ paddingTop: 20 }}>
        {!image.preview ? (
          <Box width={3 / 4} style={uploadMediaBoxStyles}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Button style={uploadButtonStyles} onClick={showOpenFileDialog}>
                Upload Media
              </Button>
              <Typography style={uploadMediaTextStyles}>
                - We accept both image / video
              </Typography>
              <Typography style={uploadMediaTextStyles}>
                - Image size: min. 500x500 px
              </Typography>
              <Typography style={uploadMediaTextStyles}>
                - Video length: less than 30 seconds
              </Typography>
              <input
                ref={imageRef}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handlePhotoChange}
              />
            </Grid>
          </Box>
        ) : (
          <img
            src={image.preview}
            alt=""
            style={{ width: 500, height: 500, borderRadius: 12 }}
          />
        )}
        {image.preview ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="flex-start"
          >
            <Button style={reUploadButtonStyles} onClick={showOpenFileDialog}>
              Reupload Media
            </Button>
            <Typography style={uploadMediaTextStyles}>
              - We accept both image / video
            </Typography>
            <Typography style={uploadMediaTextStyles}>
              - Image size: min. 500x500 px
            </Typography>
            <Typography style={uploadMediaTextStyles}>
              - Video length: less than 30 seconds
            </Typography>
            <input
              ref={imageRef}
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handlePhotoChange}
            />
          </Grid>
        ) : undefined}
      </Grid>
    </Grid>
  );
};

export default AdImageSection;

const uploadMediaBoxStyles = {
  height: 320,
  width: 570,
  borderRadius: 12,
  border: "2px dotted #85848B ",
};
const uploadButtonStyles = {
  background: "#4E1B5C",
  borderRadius: 8,
  width: 155,
  height: 52,
  color: "#fff",
  marginTop: "7em",
  marginBottom: "1em",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
};
const reUploadButtonStyles = {
  background: "#4E1B5C",
  borderRadius: 16,
  width: 170,
  height: 52,
  color: "#fff",
  marginTop: 36,
  marginBottom: "1em",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
};
const uploadMediaTextStyles = {
  width: 263,
  fontSize: 14,
  fontWeight: 600,
  color: "#85848B",
};
