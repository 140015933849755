import React, { Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { adminsColumns } from "../constants";
import { useAuth } from "../../context/AuthContext";

export default function AdminsTable({
  allAdmins,
  setAllAdmins,
  setSelectedAdmin,
  handleClickEdit,
  handleClickDelete,
}) {
  const { currentUser } = useAuth();
  console.log("admin:", currentUser, allAdmins);
  return (
    <Table style={{ border: "none", marginTop: 40 }}>
      <TableHead style={{ border: "none" }}>
        <TableRow>
          {adminsColumns &&
            adminsColumns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  border: "none",
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#8086A2",
                }}
              >
                {column.label}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {allAdmins.length > 0 &&
          allAdmins.map((row, index) => {
            return (
              <Fragment>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  style={{ borderRadius: 20 }}
                >
                  {adminsColumns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          border: "none",
                          background: "#F6F6F6",
                          color: "#C84B96",
                          fontSize: 14,
                          fontWeight: column.id == "name" ? 600 : 500,
                          borderRadius: column.id == "name" ? "8px 0px 0px 8px" : undefined,
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    key="forward"
                    align="right"
                    style={{
                      border: "none",
                      background: "#F6F6F6",
                      color: "#C84B96",
                      fontSize: 14,
                      fontWeight: 500,
                      borderRadius: "0px 8px 8px 0px",
                    }}
                  >
                    {currentUser && currentUser.role === "Super Admin" && (
                      <EditIcon
                        onClick={() => {
                          // console.log(row);
                          setSelectedAdmin(row);
                          handleClickEdit();
                        }}
                      />
                    )}
                    {currentUser && currentUser.role === "Super Admin" && (
                      <DeleteOutlineIcon
                        onClick={() => {
                          // console.log(row);
                          setSelectedAdmin(row);
                          handleClickDelete();
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <div style={{ margin: 8 }} />
              </Fragment>
            );
          })}
      </TableBody>
    </Table>
  );
}
