import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import SideDrawer from "./Drawer";

export default function Layout({ children }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <SideDrawer />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        {children}
      </Box>
    </Box>
  );
}
