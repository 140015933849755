import React, { useState } from "react";
import { Button, Grid, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortMenu from "./SortMenu";
import FilterMenu from "./FilterMenu";

const CustomerSupportHeader = ({
  anchorEl,
  setAnchorEl,
  filterAnchorEl,
  setFilterAnchorEl,
  dropdownButtonText,
  setDropdownButtonText,
  sortOption,
  setSortOption,
  filterData,
  setFilterData,
  open,
  openFilter,
  handleSortOptionChange,
  handleSortUser,
  handleOpenSortMenu,
  handleCloseSortMenu,
  handleOpenFilterMenu,
  handleCloseFilterMenu,
  handleFilter,
  handleFilterOrderChange,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs></Grid>
        <Grid item xs>
          <span className={classes.mainTitle}>Customer Support</span>
        </Grid>

        <Grid item xs style={{ textAlign: "end" }}>
          {/*
      
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          style={buttonStyle}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
        >
          Sort
        </Button>
        <Button
          variant="outlined"
          endIcon={<FilterListIcon />}
          id="filter-button"
          aria-controls={openFilter ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilter ? "true" : undefined}
          onClick={handleOpenFilterMenu}
          style={buttonStyle}
        >
          Filter
        </Button>
        <SortMenu
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          classes={classes}
          setDropdownButtonText={setDropdownButtonText}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          handleSortUser={handleSortUser}
          dropdownButtonText={dropdownButtonText}
        />
        <FilterMenu
          anchorEl={filterAnchorEl}
          open={openFilter}
          handleCloseMenu={handleCloseFilterMenu}
          classes={classes}
          sortOption={sortOption}
          handleFilterOrderChange={handleFilterOrderChange}
          filterData={filterData}
          setFilterData={setFilterData}
          handleFilter={handleFilter}
        />
      */}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default CustomerSupportHeader;

const useStyles = makeStyles({
  mainTitle: {
    color: "#313137",
    fontSize: 36,
    fontWeight: 700,
    marginLeft: 10,
  },
  customWidth: {
    "& div": {
      width: "290px",
      borderRadius: 20,
    },
  },
  filterCustomWidth: {
    "& div": {
      width: "330px",
      borderRadius: 20,
      padding: "0px 7px",
    },
  },
  sortTitle: {
    fontWeight: 600,
    fontSize: 16,
    //   fontFamily: "Poppins",
    color: "#000",
    padding: "0px 17px",
  },
  filterTitle: {
    fontWeight: 600,
    fontSize: 16,
    //   fontFamily: "Poppins",
    color: "#000",
  },
  sortItems: {
    fontWeight: 500,
    fontSize: 16,
    //   fontFamily: "Poppins",
    color: "#000",
    paddingTop: 10,
    paddingBottom: 10,
    width: "100%",
  },
  dateTextfield: {
    width: 253,
  },
  selectedFilterButton: {
    color: "#FFFFFF",
    background: "#C84B96",
    borderRadius: 21,
    marginRight: 20,
  },
  nonSelectedFilterButton: {
    color: "#000000",
    borderRadius: 21,
    marginRight: 20,
    borderColor: "#000000",
  },
});

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
