import { API } from "../constants";
import { request } from "../constants/request";

export const getAllUsers = (currentPage, limit) => {
  return request({
    url:
      API.baseUrl +
      API.getUsers +
      `?is_paginate=1&page=${currentPage}&limit=${limit}`,
    method: "GET",
  });
};
export const getPersonalUsers = (currentPage, limit) => {
  // /api/users?filter[account_type][is]=Personal
  return request({
    url:
      API.baseUrl +
      API.getUsers +
      "?filter[account_type][is]=Personal" +
      `&is_paginate=1&page=${currentPage}&limit=${limit}`,
    method: "GET",
  });
};
export const getBusinessUsers = (currentPage, limit) => {
  // /api/users?filter[account_type][is]=Personal
  return request({
    url:
      API.baseUrl +
      API.getUsers +
      "?filter[account_type][is]=Business" +
      `&is_paginate=1&page=${currentPage}&limit=${limit}`,
    method: "GET",
  });
};
export const getUserCertificates = () => {
  return request({
    url: API.baseUrl + API.getUsers + `?filter[certificate_doc][isnt]=null`,
    method: "GET",
  });
};

export const getUserSearch = (value) => {
  return request({
    url: API.baseUrl + API.getUsers + `/${value}/search`,
    method: "GET",
  });
};
export const getUserInfo = (userId) => {
  return request({
    url: API.baseUrl + API.getUsers + `/${userId}`,
    method: "GET",
  });
};
export const getUserAds = (userId) => {
  return request({
    url: API.baseUrl + API.getUserAds + `/${userId}`,
    method: "GET",
  });
};

export const deleteUser = (userId) => {
  return request({
    url: API.baseUrl + API.getUsers + `/${userId}`,
    method: "DELETE",
  });
};

export const sendPasswordResetEmail = (data) => {
  return request({
    url: API.baseUrl + API.passwordResetEmail,
    method: "POST",
    data: data,
  });
};
export const suspendUser = (userData) => {
  return request({
    url: API.baseUrl + API.getSuspendedUsers,
    method: "POST",
    data: userData,
  });
};

export const unsuspendUser = (userId) => {
  return request({
    url: API.baseUrl + API.getSuspendedUsers + `/${userId}`,
    method: "DELETE",
  });
};

export const getSuspendUsers = () => {
  return request({
    url: API.baseUrl + API.getSuspendedUsers,
    method: "GET",
  });
};
