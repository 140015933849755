import React, { useState } from "react";
import { Card, CardContent, CardHeader, CardMedia, Grid, Menu, MenuItem, Typography } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { API } from "../../apis/constants";
import ButtonLoader from "../ButtonLoader";
  
const RecipesCard = ({
  recipesData,
  setSelectedRecipe,
  handleEditRecipesModalOpen,
  handleDeleteRecipesModalOpen,
  feartureLoading,
  handleFeatureRecipe,
  handleUnFeatureRecipe,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const EditDeleteMenu = () => (
    <Menu
      id="edit-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "editMenuButton",
      }}
    >
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          setSelectedRecipe(recipesData);
          handleEditRecipesModalOpen();
          handleClose();
        }}
      >
        Edit
      </MenuItem>
      {recipesData.is_featured && recipesData.is_featured === 1 ? (
        <MenuItem
          style={menuItemStyles}
          onClick={() => {
            // console.log(recipesData);
            setSelectedRecipe(recipesData);
            handleUnFeatureRecipe();
            handleClose();
          }}
        >
          {feartureLoading ? <ButtonLoader /> : "Un-feature"}
        </MenuItem>
      ) : (
        <MenuItem
          style={menuItemStyles}
          onClick={() => {
            setSelectedRecipe(recipesData);
            handleFeatureRecipe();
            handleClose();
          }}
        >
          {feartureLoading ? <ButtonLoader /> : "Feature"}
        </MenuItem>
      )}
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          setSelectedRecipe(recipesData);
          handleDeleteRecipesModalOpen();
          handleClose();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <Grid item style={recipesData.is_featured ? {border:'3px solid #D675AC', borderRadius:'4px'} : {}}>
    <Card sx={{ minWidth: 345, height:'100%' }}>
    <CardHeader
      style={{fontSize: 18, fontWeight: 700,}}
      action={
        <>
          <MoreVertIcon 
          id="editMenuButton"
          aria-controls={open ? "edit-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            setSelectedRecipe(recipesData);
            handleClick(e);
          }}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        />
        <EditDeleteMenu />
        </>
      }
      title={recipesData.title}
    />
    <CardMedia
      component="img"
      height="250"
      image={`${API.baseUrl}${recipesData.image?recipesData.image:`https://static.vecteezy.com/system/resources/previews/037/136/253/original/recipe-card-template-for-notes-making-about-food-preparation-hand-drawn-recipe-card-vector.jpg`}`}
      alt={recipesData.title}
      style={{
        objectFit:'contain'
      }}
    />  
    <div style={{display: "flex", justifyContent:'center' }}>
    <div style={{ color: "#D675AC", fontWeight: 700, display:'block' }}>
          {recipesData.is_featured && recipesData.is_featured === 1 ? "Featured" : ""}
    </div>
    </div>
    <div style={{display:'flex' }}>
   <CardContent style={{display:'flex', flexDirection:'row' ,alignItems:'center',gap:'1rem'}}>
    <Typography variant="h6">Time:</Typography>
      <Typography variant="body2" color="text.secondary" style={{fontSize:'1.25rem'}}>{
        recipesData?.duration
      }mins
      </Typography>
    </CardContent>
    <CardContent style={{display:'flex', flexDirection:'row' ,alignItems:'center',gap:'1rem', padding:'16px'}}>
    <Typography variant="h6">Portion:</Typography>
      <Typography variant="body2" color="text.secondary" style={{fontSize:'1.25rem'}}>{
        recipesData?.portion
      }
      </Typography>
    </CardContent>  
    </div>  
    <CardContent>
    <Typography variant="h6">Instruction:</Typography>
      <Typography variant="body2" color="text.secondary">{
        recipesData?.instructions
      }
      </Typography>
    </CardContent>
      <CardContent>
        <Typography variant="h6">Ingredients:</Typography>
        {
          recipesData?.ingredients?.map((ele)=>{
            return (
              <Typography variant="body2" color="text.secondary">{ele}</Typography>
            )
          })
        }
      </CardContent>
  </Card>
    </Grid>
  );
};

export default RecipesCard;

const cardContainerStyles = {
  width: 215,
  height: 235,
  borderRadius: 12,
  border: "1px solid #E2EBEF",
  marginRight: 25,
  marginBottom: 25,
};

const cardTilteStyles = { fontSize: 18, fontWeight: 700, paddingTop: 10 };

const menuItemStyles = { width: 150, fontSize: 14, fontWeight: 600 };

const cardHeadingStyles ={};
