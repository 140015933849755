import React, { Fragment, useEffect, useState } from "react";
import {
  deactivatedUsersData,
  reportsData,
  resolvedReportsData,
  certificationData,
} from "../../components/constants";
import CustomerSupportHeader from "../../components/support/CustomerSupportHeader";
import CustomerSupportTabs from "../../components/support/CustomerSupportTabs";
import { useReportsContext } from "../../context/ReportsContext";
import { useUsersContext } from "../../context/UsersContext";

const CutomerSupport = () => {
  const { reports, setReports, resolvedReports } = useReportsContext();
  const { deactivatedUsers, certificates, handleGetCertificates } =
    useUsersContext();
  const [allReports, setAllReports] = useState(reports);
  const [allSuspendedUsers, setAllSuspendedUsers] = useState(deactivatedUsers);
  const [allResolvedReports, setAllResolvedReports] = useState(resolvedReports);
  const [certifications, setCertifications] = useState(certificates);

  // console.log("certificates:", certificates);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [dropdownButtonText, setDropdownButtonText] = useState("Sort");
  const [sortOption, setSortOption] = useState("Ascending");
  const [filterData, setFilterData] = useState({
    experience: "All",
    age: "All",
    gender: "All",
    accountType: "Personal",
  });

  useEffect(() => {
    setAllReports(reports);
  }, [reports]);
  useEffect(() => {
    setAllResolvedReports(resolvedReports);
  }, [resolvedReports]);
  useEffect(() => {
    setAllSuspendedUsers(deactivatedUsers);
  }, [deactivatedUsers]);
  useEffect(() => {
    setCertifications(certificates);
  }, [certificates]);

  const open = Boolean(anchorEl);
  const openFilter = Boolean(filterAnchorEl);

  const handleSortOptionChange = (e) => {
    // console.log(e.target.value);
    setSortOption(e.target.value);
    let sorted = [...allReports];
    let resolvedSorted = [...resolvedReports];
    sorted.sort((a, b) =>
      dropdownButtonText === "Requester Name"
        ? a.user.first_name > b.user.first_name
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Requested"
        ? a.created_at > b.created_at
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : a.user.first_name > b.user.first_name
        ? e.target.value === "Ascending"
          ? 1
          : -1
        : e.target.value === "Ascending"
        ? -1
        : 1
    );
    resolvedSorted.sort((a, b) =>
      dropdownButtonText === "Requester Name"
        ? a.user.first_name > b.user.first_name
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Requested"
        ? a.created_at > b.created_at
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : a.user.first_name > b.user.first_name
        ? e.target.value === "Ascending"
          ? 1
          : -1
        : e.target.value === "Ascending"
        ? -1
        : 1
    );
    setAllReports(sorted);
    setAllResolvedReports(resolvedSorted);
    // console.log(sorted);
    handleCloseSortMenu();
  };

  const handleFilterOrderChange = (e) => {
    // console.log(e.target.value);
    setSortOption(e.target.value);
    let sorted = [...allReports];
    sorted.sort((a, b) =>
      a.requesterName > b.requesterName
        ? e.target.value === "Ascending"
          ? 1
          : -1
        : e.target.value === "Ascending"
        ? -1
        : 1
    );
    setAllReports(sorted);
    // console.log(sorted);
    handleCloseSortMenu();
  };

  const handleSortReports = (field) => {
    setDropdownButtonText(field);
    let sorted = [...reports];
    let resolvedSorted = [...resolvedReports];
    sorted.sort((a, b) =>
      field === "Requester Name"
        ? a.user.first_name > b.user.first_name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Date Requested"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.user.first_name > b.user.first_name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    resolvedSorted.sort((a, b) =>
      field === "Requester Name"
        ? a.user.first_name > b.user.first_name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Date Requested"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.user.first_name > b.user.first_name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    setAllReports(sorted);
    setAllResolvedReports(resolvedSorted);
    // console.log(sorted);
    if (field === "All") {
      setAllReports(reports);
      setAllResolvedReports(resolvedReports);
    }
    handleCloseSortMenu();
  };

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  // console.log("certificates:", certificates);
  // console.log("suspended:", deactivatedUsers);

  const handleFilter = async (filterOption) => {
    setFilterData(filterOption);
    // console.log("option :", filterOption);
    let usersTemp = [];
    let suspendDocsTemp = [];
    let certificatesTemp = [...certificates];
    let suspendedTemp = [...deactivatedUsers];

    certificatesTemp.forEach((el) => {
      if (
        filterOption.accountType === el.account_type &&
        filterOption.experience === el.knowledge_level &&
        filterOption.gender === el.gender
      ) {
        usersTemp.push(el);
      } else if (
        filterOption.accountType === el.account_type &&
        filterOption.gender === el.gender &&
        filterOption.experience === "All"
      ) {
        usersTemp.push(el);
      } else if (
        filterOption.accountType === el.account_type &&
        filterOption.gender === "All" &&
        filterOption.experience === el.knowledge_level
      ) {
        usersTemp.push(el);
      } else if (
        filterOption.accountType === el.account_type &&
        filterOption.gender === "All" &&
        filterOption.experience === "All"
      ) {
        usersTemp.push(el);
      }
    });
    suspendedTemp.forEach((el) => {
      if (
        filterOption.accountType === el.user.account_type &&
        filterOption.experience === el.user.knowledge_level &&
        filterOption.gender === el.user.gender
      ) {
        suspendDocsTemp.push(el);
      } else if (
        filterOption.accountType === el.user.account_type &&
        filterOption.gender === el.user.gender &&
        filterOption.experience === "All"
      ) {
        suspendDocsTemp.push(el);
      } else if (
        filterOption.accountType === el.user.account_type &&
        filterOption.gender === "All" &&
        filterOption.experience === el.user.knowledge_level
      ) {
        suspendDocsTemp.push(el);
      } else if (
        filterOption.accountType === el.user.account_type &&
        filterOption.gender === "All" &&
        filterOption.experience === "All"
      ) {
        suspendDocsTemp.push(el);
      }
    });

    // console.log("certificates:", usersTemp);
    setCertifications(usersTemp);
    setAllSuspendedUsers(suspendDocsTemp);
    handleCloseFilterMenu();
  };

  return (
    <Fragment>
      {/*header section*/}
      <CustomerSupportHeader
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        filterAnchorEl={filterAnchorEl}
        setFilterAnchorEl={setFilterAnchorEl}
        dropdownButtonText={dropdownButtonText}
        setDropdownButtonText={setDropdownButtonText}
        sortOption={sortOption}
        setSortOption={setSortOption}
        filterData={filterData}
        setFilterData={setFilterData}
        open={open}
        openFilter={openFilter}
        handleSortOptionChange={handleSortOptionChange}
        handleSortUser={handleSortReports}
        handleOpenSortMenu={handleOpenSortMenu}
        handleCloseSortMenu={handleCloseSortMenu}
        handleOpenFilterMenu={handleOpenFilterMenu}
        handleCloseFilterMenu={handleCloseFilterMenu}
        handleFilter={handleFilter}
        handleFilterOrderChange={handleFilterOrderChange}
      />

      {/* Support Tabs section*/}
      <CustomerSupportTabs
        reports={allReports}
        setReports={setAllReports}
        resolvedReports={allResolvedReports}
        setResolvedReports={setAllResolvedReports}
        certifications={certifications}
        setCertifications={setCertifications}
        allSuspendedUsers={allSuspendedUsers}
      />
    </Fragment>
  );
};

export default CutomerSupport;
