import React, { Fragment } from "react";

import ReportsTable from "./reportsTab/ReportsTable";

const ResolvedReportsTab = ({ resolvedReports, setResolvedReports }) => {
  return (
    <Fragment>
      {/* reports table section*/}
      <ReportsTable reports={resolvedReports} setReports={setResolvedReports} />
    </Fragment>
  );
};

export default ResolvedReportsTab;
