import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterListIcon from "@mui/icons-material/FilterList";

import RegisteredUsersHeader from "../../components/dashboard/registeredUsers/RegisteredUsersHeader";
import UsersTable from "../../components/dashboard/registeredUsers/UsersTable";
import SortUsersMenu from "../../components/dashboard/registeredUsers/SortUsersMenu";
import FilterUsersMenu from "../../components/dashboard/registeredUsers/FilterUsersMenu";
import { suspendedUsersSearch, usersSearch } from "../../common";
import BusinessTable from "../../components/users/BusinessTable";
import { useUsersContext } from "../../context/UsersContext";
import { Toast } from "../../components/alert";
import { getUserSearch } from "../../apis/users";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-tabpanel-${index}`}
      aria-labelledby={`user-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const UsersMainPage = ({
  allUsers,
  setAllUsers,
  allPersonalUsers,
  setAllPersonalUsers,
  allBusinessUsers,
  setAllBusinessUsers,
  allDeactivatedUsers,
  setAllDeactivatedUsers,
  headerTitle,
  mainTitle,
}) => {
  console.log({ allPersonalUsers });
  const { personalUsers, businessUsers, users, deactivatedUsers } = useUsersContext();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [dropdownButtonText, setDropdownButtonText] = useState("Sort");
  const [sortOption, setSortOption] = React.useState("Ascending");
  const [filterData, setFilterData] = useState({
    experience: "All",
    age: "All",
    gender: "All",
    accountType: "Personal",
    business_type: "All",
  });

  const open = Boolean(anchorEl);
  const openFilter = Boolean(filterAnchorEl);

  const [tabValue, setTabValue] = useState(0);

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSortUser = (field) => {
    setDropdownButtonText(field);
    let sorted = [...personalUsers];
    let regUsersSorted = [...allUsers];
    let businessUsersSort = [...businessUsers];
    sorted.sort((a, b) =>
      field === "Name"
        ? a.first_name > b.first_name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "State"
        ? a.state > b.state
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Date Joined"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.first_name > b.first_name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    regUsersSorted.sort((a, b) =>
      dropdownButtonText === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "State"
        ? a.state > b.state
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Joined"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.first_name > b.first_name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    businessUsersSort.sort((a, b) =>
      field === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "State"
        ? a.state > b.state
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Date Joined"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    setAllPersonalUsers(sorted);
    setAllBusinessUsers(businessUsersSort);
    setAllUsers(regUsersSorted);
    // console.log(sorted);
    handleCloseSortMenu();
  };

  const handleSortOptionChange = (e) => {
    // console.log(e.target.value);
    setSortOption(e.target.value);
    let sorted = [...allPersonalUsers];
    let regUsersSorted = [...allUsers];
    let businessUsersSort = [...allBusinessUsers];
    sorted.sort((a, b) =>
      dropdownButtonText === "Name"
        ? a.first_name > b.first_name
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "State"
        ? a.state > b.state
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Joined"
        ? a.created_at > b.created_at
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : a.first_name > b.first_name
        ? e.target.value === "Ascending"
          ? 1
          : -1
        : e.target.value === "Ascending"
        ? -1
        : 1
    );
    regUsersSorted.sort((a, b) =>
      dropdownButtonText === "Name"
        ? a.name > b.name
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "State"
        ? a.state > b.state
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Joined"
        ? a.created_at > b.created_at
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : a.first_name > b.first_name
        ? e.target.value === "Ascending"
          ? 1
          : -1
        : e.target.value === "Ascending"
        ? -1
        : 1
    );
    businessUsersSort.sort((a, b) =>
      dropdownButtonText === "Name"
        ? a.name > b.name
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "State"
        ? a.state > b.state
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Joined"
        ? a.created_at > b.created_at
          ? e.target.value === "Ascending"
            ? 1
            : -1
          : e.target.value === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? e.target.value === "Ascending"
          ? 1
          : -1
        : e.target.value === "Ascending"
        ? -1
        : 1
    );
    setAllPersonalUsers(sorted);
    setAllBusinessUsers(businessUsersSort);
    setAllUsers(regUsersSorted);
    // console.log(sorted);
    handleCloseSortMenu();
  };

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  const handleBusinessUsersFilter = async (filterOption) => {
    setFilterData(filterOption);
    // console.log("option :", filterOption);
    let itemsTemp = [];
    let temp = [...businessUsers];
    temp.forEach((el) => {
      if (filterOption.business_type === el.business_type) {
        itemsTemp.push(el);
      } else if (filterOption.business_type === "All") {
        itemsTemp.push(el);
      }
    });
    // console.log(itemsTemp);
    setAllBusinessUsers(itemsTemp);
  };

  const handleFilter = async (filterOption) => {
    setFilterData(filterOption);
    // console.log("option :", filterOption);
    // console.log("option :", personalUsers);
    let itemsTemp = [];
    let temp = [...personalUsers];
    let regUsersTemp = [...users];
    const experienceFilter =
      filterOption.experience !== "All"
        ? temp.filter((el) => el.knowledge_level === filterOption.experience)
        : temp;
    const genderFilter =
      filterOption.gender !== "All"
        ? experienceFilter.filter((el) => el.gender === filterOption.gender)
        : experienceFilter;
    const regExperienceFilter =
      filterOption.experience !== "All"
        ? regUsersTemp.filter((el) => el.knowledge_level === filterOption.experience)
        : regUsersTemp;
    const regGenderFilter =
      filterOption.gender !== "All"
        ? regExperienceFilter.filter((el) => el.gender === filterOption.gender)
        : regExperienceFilter;
    // temp.forEach((el) => {
    //   if (
    //     filterOption.experience === el.knowledge_level &&
    //     filterOption.gender === el.gender
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.gender === el.gender &&
    //     filterOption.experience === "All"
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.gender === "All" &&
    //     filterOption.experience === el.knowledge_level
    //   ) {
    //     itemsTemp.push(el);
    //   }

    // else if (
    //   filterOption.experience === el.knowledge_level &&
    //   filterOption.gender === el.gender
    //   // &&
    //   // filterOption.age === el.ageRange
    // ) {
    //   itemsTemp.push(el);
    // }

    // if (filterOption.accountType === el.account_type) {
    //   itemsTemp.push(el);
    // }

    // ////////////////////////////////////////
    // else if (filterOption.experience === "All") {
    //   if (
    //     filterOption.gender === el.gender
    //     //  &&
    //     // filterOption.age === el.ageRange
    //   ) {
    //     console.log("all expr. and gender");
    //     itemsTemp.push(el);
    //   } else if (filterOption.gender === "All") {
    //     itemsTemp.push(el);
    //   }
    // } else if (filterOption.gender === "All") {
    //   if (filterOption.experience === el.knowledge_level) {
    //     itemsTemp.push(el);
    //     console.log("expr. and all gender");
    //   } else if (filterOption.experience === "All") {
    //     itemsTemp.push(el);
    //   }
    //   // if (filterOption.age === el.ageRange) {
    //   //   itemsTemp.push(el);
    //   // }
    // }
    ///////////////////////////////////////////
    // if (filterOption.age === "All") {
    //   if (
    //     filterOption.experience === el.knowledge_level &&
    //     filterOption.gender === el.gender
    //   ) {
    //     itemsTemp.push(el);
    //   }
    //   if (filterOption.gender === el.gender) {
    //     itemsTemp.push(el);
    //   }
    // }

    // else if (
    //   filterOption.gender === "All" &&
    //   // filterOption.age === "All" &&
    //   filterOption.experience === "All"
    // ) {
    //   console.log("all expr. and all gender");
    //   itemsTemp.push(el);
    // }
    // });
    // console.log(itemsTemp);
    setAllPersonalUsers(genderFilter);
    setAllUsers(regGenderFilter);
  };

  const handleSearchChange = async (e) => {
    let value = e.target.value;
    if (e.keyCode === 13) {
      if (value.length >= 1) {
        let docsTemp = await getUserSearch(value);
        console.log("searched result:", docsTemp);
        // if (headerTitle === "Registered Users") {
        //   setAllUsers(docsTemp);
        // } else if (headerTitle === "Business Accounts") {
        //   setAllBusinessUsers(docsTemp);
        // } else if (headerTitle === "Personal Accounts") {
        //   setAllPersonalUsers(docsTemp);
        // } else if (headerTitle === "Deactivated Accounts") {
        //   setAllDeactivatedUsers(docsTemp);
        // } else {
        //   setAllPersonalUsers(docsTemp);
        // }

        allUsers && setAllUsers(docsTemp);
        allPersonalUsers && setAllPersonalUsers(docsTemp);
        allBusinessUsers && setAllBusinessUsers(docsTemp);
        allDeactivatedUsers && setAllDeactivatedUsers(docsTemp);

        //   let personalUsersSearch = await usersSearch(personalUsers, value);
        //   let businessUsersSearch = await usersSearch(businessUsers, value);
        //   let deactivatedUsersSearch = await suspendedUsersSearch(
        //     deactivatedUsers,
        //     value
        //   );
        //   setAllPersonalUsers(personalUsersSearch);
        //   setAllBusinessUsers(businessUsersSearch);
        //   setAllDeactivatedUsers(deactivatedUsersSearch);
        //   if (allUsers.length > 0) {
        //     let search = await usersSearch(users, value);
        //     setAllUsers(search);
        //   }
        // console.log("searched personal users:", personalUsersSearch);
        // console.log("searched business users:", businessUsersSearch);
        // console.log("searched deactivated users:", deactivatedUsersSearch);
      } else {
        allPersonalUsers && setAllPersonalUsers(personalUsers);
        allBusinessUsers && setAllBusinessUsers(businessUsers);
        allUsers && setAllUsers(users);
        allDeactivatedUsers && setAllDeactivatedUsers(deactivatedUsers);
      }
    }
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <RegisteredUsersHeader
        oncChange={handleSearchChange}
        headerTitle={headerTitle}
        mainTitle={mainTitle}
        tabValue={tabValue}
        handleTabValueChange={handleTabValueChange}
      />

      {/* Sort and Filter Section*/}
      <Grid container style={{ padding: 20 }} alignItems="flex-end" justifyContent="flex-end">
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
          style={buttonStyle}
        >
          {dropdownButtonText}
        </Button>
        <Button
          variant="outlined"
          endIcon={<FilterListIcon />}
          id="filter-button"
          aria-controls={openFilter ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilter ? "true" : undefined}
          onClick={handleOpenFilterMenu}
          style={buttonStyle}
        >
          Filter
        </Button>
        <SortUsersMenu
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          classes={classes}
          setDropdownButtonText={setDropdownButtonText}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          handleSortUser={handleSortUser}
        />
        <FilterUsersMenu
          anchorEl={filterAnchorEl}
          open={openFilter}
          handleCloseMenu={handleCloseFilterMenu}
          classes={classes}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          filterData={filterData}
          setFilterData={setFilterData}
          handleFilter={handleFilter}
          tabValue={tabValue}
          handleBusinessUsersFilter={handleBusinessUsersFilter}
        />
      </Grid>
      <TabPanel value={tabValue} index={0}>
        <UsersTable
          allUsers={
            headerTitle === "Registered Users"
              ? allUsers
              : headerTitle === "Business Accounts"
              ? allBusinessUsers
              : headerTitle === "Personal Accounts"
              ? allPersonalUsers
              : headerTitle === "Deactivated Accounts"
              ? allDeactivatedUsers
              : allPersonalUsers
          }
          headerTitle={headerTitle}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <BusinessTable allUsers={allBusinessUsers} />
      </TabPanel>
    </Fragment>
  );
};

export default UsersMainPage;

const useStyles = makeStyles(() => {
  return {
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
    customWidth: {
      "& div": {
        width: "290px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    filterCustomWidth: {
      "& div": {
        width: "330px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
    selectedFilterButton: {
      color: "#FFFFFF",
      background: "#C84B96",
      borderRadius: 21,
      marginRight: 20,
    },
    nonSelectedFilterButton: {
      color: "#000000",
      borderRadius: 21,
      marginRight: 20,
      borderColor: "#000000",
    },
  };
});

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
