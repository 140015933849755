import { Menu, MenuItem } from "@mui/material";
import React from "react";

const AdMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  draft,
  handleDeleteAdModalOpen,
  handleDuplicate,
  handleViewsModalOpen,
  handlePricesModalOpen,
  handleGoToDetails,
}) => {
  return (
    <Menu
      id="action-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "action-button",
      }}
      // sx={{
      //   "& .MuiPaper-root": {
      //     backgroundColor: "#C84B96",
      //   },
      // }}
    >
      {draft && (
        <MenuItem
          onClick={() => {
            handleCloseMenu();
          }}
          style={{ color: "#000", width: 172 }}
        >
          Edit
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleGoToDetails();
        }}
        style={{ color: "#000", width: 172 }}
      >
        Ad Details
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDuplicate();
          handleCloseMenu();
        }}
        style={{ color: "#000", width: 172 }}
      >
        Duplicate
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDeleteAdModalOpen();
          handleCloseMenu();
        }}
        style={{ color: "#000", width: 172 }}
      >
        Delete
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleViewsModalOpen();
          handleCloseMenu();
        }}
        style={{ color: "#000", width: 172 }}
      >
        Views Details
      </MenuItem>
    </Menu>
  );
};

export default AdMenu;
