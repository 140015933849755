import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import LogoutModal from "../../components/settings/LogoutModal";
import SettingsHeader from "../../components/settings/SettingsHeader";
import SettingsTabs from "../../components/settings/SettingsTabs";
import { Toast } from "../../components/alert";
// import { adminEmailTemp, adminPasswordTemp } from "../../components/constants";
import { useAuth } from "../../context/AuthContext";
import { getCurrentUserData, handleLogoutAdmin } from "../../apis/auth";

const Settings = () => {
  const { currentUser, setCurrentUser, userToken, setUserToken } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: currentUser && currentUser.email,
    password: "",
  });
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      let temp = await getCurrentUserData(localStorage.getItem("userId"));
      // console.log("User details:", temp);
      setUserDetails(temp);
      setUser({ ...user, email: temp.email });
    } catch (error) {
      console.log(
        "error getting current user details:",
        error.response.data.message
      );
    }
  };

  console.log("current user:", currentUser);
  const [logoutModal, setLogoutModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log("token:", localStorage.getItem("token"))

  const handleOpenLogoutModal = () => {
    setLogoutModal(true);
  };
  const handleCloseLogoutModal = () => {
    setLogoutModal(false);
  };

  const handleUpdateEmail = async (email) => {
    try {
      setLoading(true);

      let data = {
        email: user.email,
        _method: "PUT",
      };

      // console.log("usersss", user)
      await axios
        .post(
          `https://winelikes.alphaprecision.co/api/users/${localStorage.getItem(
            "userId"
          )}`,
          data,
          {
            headers: {
              Accept: "application/json",
              Authorization: localStorage.getItem("token"),
              // "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async () => {
          await getUserDetails();
          Toast("success", "Admin Email updated successfully.");
        })
        .catch((error) => {
          console.log("error:", error.response.data.message);
        });
      // await updateAdminEmail(localStorage.getItem("userId"), data);
    } catch (error) {
      console.log("error updating Admin Email.", error.message);
      Toast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await handleLogoutAdmin();
      handleCloseLogoutModal();
      navigate("/");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      setCurrentUser(null);
      setUserToken(null);
    } catch (error) {
      console.log("error loging out Admin:", error.message);
    }
  };

  return (
    <Fragment>
      {/* settings Header section */}
      <SettingsHeader handleOpenLogoutModal={handleOpenLogoutModal} />

      {/* settings Tabs section */}
      <SettingsTabs
        user={user}
        setUser={setUser}
        handleUpdateEmail={handleUpdateEmail}
        loading={loading}
      />

      {/*Logout Modal section*/}
      <LogoutModal
        modalOpen={logoutModal}
        modalStyle={modalStyle}
        handleClose={handleCloseLogoutModal}
        handleConfirm={handleLogout}
      />
    </Fragment>
  );
};

export default Settings;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};
