import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField, Typography } from "@mui/material";
import ButtonLoader from "../ButtonLoader";

const EmailTab = ({
  enableEdit,
  handleChangeEmail,
  user,
  emailError,
  handleUpdateEmail,
}) => {
  const classes = useStyles();

  // console.log("Current user:", user);

  const [deleteLoading, setDeleteLoading] = useState(false);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography style={messageTextStyle}>
        * If you require a new login to this Admin panel, please update the
        email address below.
      </Typography>
      <Grid style={{ width: 400, padding: 20 }}>
        <Typography style={emailLabelStyle}>Email Address</Typography>
        <TextField
          fullWidth
          placeholder="Email Address"
          value={user && user.email && user.email}
          onChange={handleChangeEmail}
          className={classes.textfieldStyle}
          InputProps={{
            style: {
              ...textFieldPropsStyle,
              color: enableEdit ? "#000" : "#808080",
            },
          }}
        />
      </Grid>
      {emailError && (
        <Typography style={emailErrorTextStyle}>
          Please enter a valid email address
        </Typography>
      )}
      {deleteLoading ? (
        <ButtonLoader />
      ) : (
        <Button
          variant="contained"
          disabled={!enableEdit}
          style={{
            ...updateButtonStyle,
            background: enableEdit ? "#C84B96" : "#F0F0F0",
            color: enableEdit ? "#fff" : "#808080",
          }}
          // onClick={handleUpdateEmail}
          onClick={async () => {
            setDeleteLoading(true);
            await handleUpdateEmail();
            setDeleteLoading(false);
          }}
        >
          Update
        </Button>
      )}
    </Grid>
  );
};

export default EmailTab;

const useStyles = makeStyles({
  textfieldStyle: {
    "& fieldset": {
      border: "none",
    },
  },
});

const emailErrorTextStyle = {
  color: "#FF5655",
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 20,
};

const updateButtonStyle = {
  borderRadius: 38,
  textTransform: "none",
  width: 110,
  height: 50,
  fontSize: 16,
  fontWeight: 600,
};

const messageTextStyle = {
  width: 380,
  color: "#85848B",
  fontWeight: 500,
  fontSize: 16,
  marginTop: 40,
  marginBottom: 20,
};

const emailLabelStyle = {
  color: "#000",
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 20,
};

const textFieldPropsStyle = {
  background: "#F6F6F6",
  fontSize: 16,
  fontWeight: 600,
  borderRadius: 8,
};
