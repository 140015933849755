import React, { Fragment, useEffect, useState } from "react";
import { addAdmin, deleteAdmin, updateAdmin } from "../../apis/admins";
import AdminAddedModal from "../../components/admins/AdminAddedModal";
import AdminDeletedModal from "../../components/admins/AdminDeletedModal";
import AdminsTable from "../../components/admins/AdminsTable";
import AdminUpdatedModal from "../../components/admins/AdminUpdatedModal";
import DeleteAdminModal from "../../components/admins/DeleteAdminModal";
import Header from "../../components/admins/Header";
import ManageAdminModal from "../../components/admins/ManageAdminModal";
import { Toast } from "../../components/alert";
import { useAdminsContext } from "../../context/AdminsContext";

// import { adminsData } from "../../components/constants";

const Admins = () => {
  const { admins, setAdmins, handleGetAllAdmins } = useAdminsContext();

  const [allAdmins, setAllAdmins] = useState(admins);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [adminAddedModal, setAdminAddedModal] = useState(false);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [adminUpdatedModal, setAdminUpdatedModal] = useState(false);
  const [deleteAdminModal, setDeleteAdminModal] = useState(false);
  const [adminDeletedModal, setAdminDeletedModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log("admins:", admins);

  const [newAdmin, setNewAdmin] = useState({
    role: "",
    name: "",
    email: "",
    password: "",
  });
  const [selectedAdmin, setSelectedAdmin] = useState({});

  useEffect(() => {
    setAllAdmins(admins);
  }, [admins]);

  const handleAddAdminModalOpen = () => {
    setAddAdminModal(true);
  };
  const handleAddAdminModalClose = () => {
    setAddAdminModal(false);
  };

  const handleAdminAddedModalOpen = () => {
    setAdminAddedModal(true);
  };
  const handleAdminAddedModalClose = () => {
    setAdminAddedModal(false);
    setNewAdmin({ role: "", name: "", email: "", password: "" });
  };

  const handleEditAdminModalOpen = () => {
    setEditAdminModal(true);
  };
  const handleEditAdminModalClose = () => {
    setEditAdminModal(false);
  };

  const handleAdminUpdatedModalOpen = () => {
    setAdminUpdatedModal(true);
  };
  const handleAdminUpdatedModalClose = () => {
    setAdminUpdatedModal(false);
    setSelectedAdmin({});
  };

  const handleDeleteAdminModalOpen = () => {
    setDeleteAdminModal(true);
  };
  const handleDeleteAdminModalClose = () => {
    setDeleteAdminModal(false);
  };

  const handleAdminDeleteModalOpen = () => {
    setAdminDeletedModal(true);
  };
  const handleAdminDeleteModalClose = () => {
    setAdminDeletedModal(false);
  };

  const handleAddNewAdmin = async () => {
    try {
      setLoading(true);
      // console.log("new admin:", newAdmin);

      let adminTemp = await addAdmin(newAdmin);
      // let temp = { ...newAdmin };
      // delete temp.password;

      // let id = new Date().getUTCMilliseconds();
      if (adminTemp) {
        let docsTemp = [...allAdmins];
        docsTemp.push({ ...newAdmin, id: adminTemp.id });
        setAllAdmins(docsTemp);
        setAdmins(docsTemp);

        // console.log("new Added admin:", newAdmin);
        // console.log("all admins:", docsTemp);
        handleAddAdminModalClose();
        handleAdminAddedModalOpen();
        Toast("success", "New Admin Added Successfully.");
      }
    } catch (error) {
      console.log("error adding new admin:", error.message);
      handleAddAdminModalClose();
      Toast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAdmin = async () => {
    try {
      setLoading(true);

      let adminTemp = await updateAdmin(selectedAdmin, selectedAdmin.id);
      if (adminTemp) {
        // let docsTemp = [...allAdmins];
        // let index;
        // docsTemp.forEach((doc) => {
        //   if (doc.id == adminTemp.id) {
        //     index = docsTemp.indexOf(doc);
        //   }
        // });
        // if (index) {
        //   docsTemp[index] = { ...adminTemp };
        // }

        // setAllAdmins(docsTemp);
        // setAdmins(docsTemp);
        // // setAdmins(docsTemp);
        // console.log("updated admin:", selectedAdmin);
        // console.log("all admins:", docsTemp);
        await handleGetAllAdmins();

        handleEditAdminModalClose();
        handleAdminUpdatedModalOpen();
        Toast("success", "Admin Updated Successfully.");
      }
    } catch (error) {
      console.log("error updating admin:", error.message);
      Toast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      setLoading(true);

      let adminTemp = await deleteAdmin(selectedAdmin.id);

      if (adminTemp) {
        let docsTemp = [...allAdmins];
        let index = docsTemp.indexOf(selectedAdmin);
        if (index > -1) {
          docsTemp.splice(index, 1); // 2nd parameter means remove one item only
        }

        setAllAdmins(docsTemp);
        // setAdmins(docsTemp);
        // console.log("all admins:", docsTemp);
        Toast("success", "Admin Deleted Successfully.");
        handleDeleteAdminModalClose();
        handleAdminDeleteModalOpen();
      }
    } catch (error) {
      console.log("error deleting admin:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <Header handleModalOpen={handleAddAdminModalOpen} />

      {/*Table Section*/}
      <AdminsTable
        allAdmins={allAdmins}
        setAllAdmins={setAllAdmins}
        setSelectedAdmin={setSelectedAdmin}
        handleClickEdit={handleEditAdminModalOpen}
        handleClickDelete={handleDeleteAdminModalOpen}
      />

      {/*Add Admin Modal Section*/}
      <ManageAdminModal
        adminModal={addAdminModal}
        handleClose={handleAddAdminModalClose}
        title="Add New Admin"
        adminData={newAdmin}
        setAdminData={setNewAdmin}
        handleConfirm={handleAddNewAdmin}
        newAdmin={true}
        loading={loading}
      />
      {/*Admin added Modal Section*/}
      <AdminAddedModal
        modalOpen={adminAddedModal}
        handleClose={handleAdminAddedModalClose}
        title="New Admin Added"
        adminData={newAdmin}
      />

      {/*Edit Admin Modal Section*/}
      <ManageAdminModal
        adminModal={editAdminModal}
        handleClose={handleEditAdminModalClose}
        title="Update Admin"
        adminData={selectedAdmin}
        setAdminData={setSelectedAdmin}
        handleConfirm={handleUpdateAdmin}
        loading={loading}
      />

      {/*Admin updated Modal Section*/}
      <AdminUpdatedModal
        modalOpen={adminUpdatedModal}
        handleClose={handleAdminUpdatedModalClose}
        title="Admin Updated"
        adminData={selectedAdmin}
      />

      {/*Delete Admin Modal Section*/}
      <DeleteAdminModal
        modalOpen={deleteAdminModal}
        handleClose={handleDeleteAdminModalClose}
        title="Delete Admin"
        adminData={selectedAdmin}
        handleConfirm={handleDeleteAdmin}
        loading={loading}
      />

      {/*Delete Admin Modal Section*/}
      <AdminDeletedModal
        modalOpen={adminDeletedModal}
        handleClose={handleAdminDeleteModalClose}
        title="Admin Deleted"
        adminData={selectedAdmin}
      />
    </Fragment>
  );
};

export default Admins;
