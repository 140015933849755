import React, { useState } from "react";
import { Avatar, Button, Grid } from "@mui/material";
import { CSVLink } from "react-csv";

import TextFieldComponent from "./Profile-Info-Tab/TextFieldComponent";
import { baseUrl, usersHeader } from "../../constants";
import { useEffect } from "react";

const ProfileInfoTab = ({ userDetails, userInfo }) => {
  const [userData, setUserData] = useState(userDetails);

  const [age, setAge] = useState(null);

  useEffect(() => {
    getAge();
  }, []);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(userDetails.dob && userDetails.dob);
    var ageTemp = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageTemp--;
    }
    setAge(ageTemp);
  };

  // console.log("image path:", baseUrl + userDetails.dp);

  return (
    <Grid container style={{ marginTop: 50 }}>
      <Grid
        item
        container
        xs={4.5}
        style={{ padding: 10 }}
        alignItems="start"
        justifyContent="end"
      >
        <Avatar
          style={{ width: 200, height: 200 }}
          // src={
          //   "https://winelikes.alphaprecision.co/storage/post/media/1667245366-8d29ebc8da31c0f08cb4ddf8d0bbfd88fc0c8dee.jpg"
          // }
          src={`${baseUrl + userDetails.dp}`}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        xs={7.5}
        style={{ paddingLeft: 60 }}
      >
        <TextFieldComponent
          label="Name"
          value={`${
            userData.account_type === "Business"
              ? userData.name && userData.name
              : userData.first_name &&
                userData.first_name + " " + userData.last_name &&
                userData.last_name
          }`}
          placeholder="User Name"
          //   handleChange={(e) =>
          //     setUserData({ ...userData, name: e.target.value })
          //   }
        />
        {userDetails.account_type === "Business" && (
          <TextFieldComponent
            label="Type"
            value={userData && userData.business_type && userData.business_type}
            placeholder="Type"
          />
        )}
        <TextFieldComponent
          label="Username"
          value={userData && userData.username && userData.username}
          placeholder="User Name"
        />
        {userDetails.account_type !== "Business" && (
          <TextFieldComponent
            label="Age"
            value={age && age}
            placeholder="Age"
          />
        )}
        {/*
        {userDetails.account_type !== "Business" && (
          <TextFieldComponent
            label="Gender"
            value={userData && userData.gender && userData.gender}
            placeholder="Gender"
          />
        )}
      */}
        <TextFieldComponent
          label="City"
          value={userData && userData.city && userData.city}
          placeholder="City"
        />
        <TextFieldComponent
          label="State"
          value={userData && userData.state && userData.state}
          placeholder="State"
        />
        <TextFieldComponent
          label="Zip Code"
          value={userData && userData.zipcode && userData.zipcode}
          placeholder="Zip Code"
        />
        {userDetails.account_type === "Business" && (
          <TextFieldComponent
            label="About"
            value={userData && userData.bio && userData.bio}
            placeholder="About"
            rows={4}
            multiline={true}
          />
        )}
      </Grid>
      <Grid
        container
        style={{ paddingRight: 30, paddingTop: 50, marginTop: 30 }}
        justifyContent="flex-end"
      >
        <Button variant="contained" style={exportButtonStyle}>
          <CSVLink
            data={userDetails ? [userDetails] : []}
            headers={usersHeader}
            filename={`${userDetails.name}-Record.csv`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export CSV
          </CSVLink>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProfileInfoTab;

const exportButtonStyle = {
  background: "#C84B96",
  borderRadius: 12,
  height: 48,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
};
