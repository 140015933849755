import { API } from "../constants";
import { request } from "../constants/request";

export const updateCertificate = (data, userId) => {
  return request({
    url: API.baseUrl + API.updateCertificate + `/${userId}/status`,
    method: "POST",
    data: data,
  });
};

export const sendReplyReport = (data, reportId) => {
  return request({
    url: API.baseUrl + API.sendReplyReport + `/${reportId}`,
    method: "POST",
    data: data,
  });
};
