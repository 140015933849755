import React, { useState } from "react";
import {
  Button,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Menu,
  Radio,
  RadioGroup,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const FilterWinesMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  classes,
  sortOption,
  handleSortOptionChange,
  filterData,
  handleFilter,
}) => {
  return (
    <Menu
      id="filter-menu"
      MenuListProps={{
        "aria-labelledby": "filter-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      TransitionComponent={Fade}
      className={classes.filterCustomWidth}
    >
      <Grid container alignItems="center">
        <Grid item xs={8} style={{ textAlign: "start" }}>
          <span className={classes.sortTitle}>Filter</span>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <CloseIcon
            onClick={handleCloseMenu}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Divider />

      {/* Filter With Verified Section  */}
      <Grid container style={{ padding: 12 }}>
        <span style={{ fontSize: 15, fontWeight: 600 }}>Varietal</span>
        <Grid>
          <Button
            variant={
              filterData.varietal == "Cabernet Sauvignon"
                ? "contained"
                : "outlined"
            }
            style={
              filterData.varietal == "Cabernet Sauvignon"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, varietal: "Cabernet Sauvignon" });
            }}
          >
            Cabernet Sauvignon
          </Button>
          <Button
            variant={filterData.varietal == "Merlot" ? "contained" : "outlined"}
            style={
              filterData.varietal == "Merlot"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, varietal: "Merlot" });
            }}
          >
            Merlot
          </Button>
          <Button
            variant={
              filterData.varietal == "Pinot noir" ? "contained" : "outlined"
            }
            style={
              filterData.varietal == "Pinot noir"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, varietal: "Pinot noir" });
            }}
          >
            Pinot noir
          </Button>
          <Button
            variant={
              filterData.varietal == "Chardonnay" ? "contained" : "outlined"
            }
            style={
              filterData.varietal == "Chardonnay"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, varietal: "Chardonnay" });
            }}
          >
            Chardonnay
          </Button>
          <Button
            variant={
              filterData.varietal == "White Zinfandel"
                ? "contained"
                : "outlined"
            }
            style={
              filterData.varietal == "White Zinfandel"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, varietal: "White Zinfandel" });
            }}
          >
            White Zinfandel
          </Button>
          <Button
            variant={
              filterData.varietal == "Riesling" ? "contained" : "outlined"
            }
            style={
              filterData.varietal == "Riesling"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, varietal: "Riesling" });
            }}
          >
            Riesling
          </Button>
          <Button
            variant={filterData.varietal == "All" ? "contained" : "outlined"}
            style={
              filterData.varietal == "All"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, varietal: "All" });
            }}
          >
            All
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ width: "70%" }} />
      {/* FIlter with Age Range Section */}
      <Grid
        container
        style={{ marginTop: 12, paddingLeft: 12, marginBottom: 12 }}
      >
        <span style={{ fontSize: 15, fontWeight: 600 }}>Location</span>
        <Grid>
          <Button
            variant={
              filterData.region == "California" ? "contained" : "outlined"
            }
            style={
              filterData.region == "California"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() =>
              handleFilter({ ...filterData, region: "California" })
            }
          >
            California
          </Button>
          <Button
            variant={filterData.region == "Italy" ? "contained" : "outlined"}
            style={
              filterData.region == "Italy"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, region: "Italy" })}
          >
            Italy
          </Button>
          <Button
            variant={filterData.region == "Spain" ? "contained" : "outlined"}
            style={
              filterData.region == "Spain"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, region: "Spain" })}
          >
            Spain
          </Button>
          <Button
            variant={filterData.region == "France" ? "contained" : "outlined"}
            style={
              filterData.region == "France"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, region: "France" })}
          >
            France
          </Button>
          <Button
            variant={
              filterData.region == "Washington" ? "contained" : "outlined"
            }
            style={
              filterData.region == "Washington"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() =>
              handleFilter({ ...filterData, region: "Washington" })
            }
          >
            Washington
          </Button>
          <Button
            variant={filterData.region == "All" ? "contained" : "outlined"}
            style={
              filterData.region == "All"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, region: "All" })}
          >
            All
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ width: "70%" }} />

      {/* Filter with Gender Section */}
      <Grid
        container
        style={{ marginTop: 12, paddingLeft: 12, marginBottom: 12 }}
      >
        <span style={{ fontSize: 15, fontWeight: 600 }}>Vintage</span>
        <Grid>
          <Button
            variant={filterData.vintage == "1961" ? "contained" : "outlined"}
            style={
              filterData.vintage == "1961"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, vintage: "1961" })}
          >
            1961
          </Button>
          <Button
            variant={filterData.vintage == "1959" ? "contained" : "outlined"}
            style={
              filterData.vintage == "1959"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, vintage: "1959" })}
          >
            1959
          </Button>
          <Button
            variant={filterData.vintage == "1955" ? "contained" : "outlined"}
            style={
              filterData.vintage == "1955"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, vintage: "1955" })}
          >
            1955
          </Button>
          <Button
            variant={filterData.vintage == "1960" ? "contained" : "outlined"}
            style={
              filterData.vintage == "1960"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, vintage: "1960" })}
          >
            1960
          </Button>
          <Button
            variant={filterData.vintage == "1945" ? "contained" : "outlined"}
            style={
              filterData.vintage == "1945"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, vintage: "1945" })}
          >
            1945
          </Button>
          <Button
            variant={filterData.vintage == "1939" ? "contained" : "outlined"}
            style={
              filterData.vintage == "1939"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, vintage: "1939" })}
          >
            1939
          </Button>

          <Button
            variant={filterData.vintage == "All" ? "contained" : "outlined"}
            style={
              filterData.vintage == "All"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter({ ...filterData, vintage: "All" })}
          >
            All
          </Button>
        </Grid>
      </Grid>

      <Divider />
      <RadioGroup value={sortOption} onChange={handleSortOptionChange}>
        <FormControlLabel
          value="Ascending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="Ascending"
        />
        <FormControlLabel
          value="Descending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="Descending"
        />
      </RadioGroup>
    </Menu>
  );
};

export default FilterWinesMenu;

const selectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  color: "#FFF",
  background: "#C84B96",
};

const nonSelectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  borderColor: "#000",
  color: "#000",
};
