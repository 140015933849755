import { Grid, TextField } from "@mui/material";
import React, { Fragment } from "react";

import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveCircleOutline";

const AddAnswerTextField = ({
  label,
  value,
  correct,
  onChange,
  handleCorrectAnswerClick,
  handleRemove,
}) => {
  // console.log(correct, "this si");
  return (
    <Fragment>
      <Grid item container style={maintextFieldContainer} alignItems="center">
        {correct ? (
          <Grid
            container
            alignItems={"center"}
            justifyContent="center"
            style={{
              margin: 12,
              borderRadius: 50,
              width: 24,
              height: 24,
              background: "#17DDA2",
            }}
          >
            <DoneOutlinedIcon style={{ padding: 2, color: "#fff" }} />
          </Grid>
        ) : (
          <Grid
            container
            alignItems={"center"}
            justifyContent="center"
            style={labelContainer}
            onClick={handleCorrectAnswerClick}
          >
            <span style={labelStyles}>{label}</span>
          </Grid>
        )}
        <TextField
          variant="outlined"
          value={value}
          defaultValue={null}
          onChange={onChange}
          placeholder="Type your questions here..."
          sx={{
            width: "400px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
      </Grid>
      {handleRemove && (
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          style={{
            marginTop: -35,
            paddingRight: 10,
            width: "50px",
            marginLeft: "auto",
          }}
        >
          <RemoveOutlinedIcon
            style={{ cursor: "pointer" }}
            onClick={handleRemove}
          />
        </Grid>
      )}
    </Fragment>
  );
};

export default AddAnswerTextField;

const maintextFieldContainer = {
  height: 50,
  marginTop: 15,
  borderRadius: 8,
  background: "#F6F6F6",
  width: "100%",
};

const labelContainer = {
  margin: 12,
  border: "2px solid #9D9DAA",
  borderRadius: 50,
  width: 24,
  height: 24,
  cursor: "pointer",
};

const labelStyles = {
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: 14,
  color: "#9D9DAA",
};

const textFieldPropsStyles = {
  fontSize: 16,
  fontWeight: 600,
  marginLeft: -10,
  background: "red",
  width: "",
};

<Grid
  container
  alignItems={"center"}
  justifyContent="center"
  style={{
    margin: 12,
    //   border: "2px solid #9D9DAA",
    borderRadius: 50,
    width: 24,
    height: 24,
    background: "#17DDA2",
  }}
>
  <DoneOutlinedIcon style={{ padding: 2, color: "#fff" }} />
</Grid>;
