import React from "react";
import { Grid, Toolbar } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AdDetailsHeader = ({ navigate, adData }) => {
  return (
    <Toolbar>
      <Grid item xs justifyContent="center" alignItems={"center"}>
        <ArrowBackIosIcon
          style={{
            marginTop: 15,
            marginBottom: -3,
            cursor: "pointer",
            color: "#C84B96",
          }}
          onClick={() => navigate(-1)}
          // onClick={() => navigate("/dashboard/ads")}
        />
        <span
          style={{
            color: "#000",
            fontSize: 25,
            fontWeight: 600,
            marginLeft: 10,
          }}
        >
          {adData.name && adData.name}
        </span>
      </Grid>
    </Toolbar>
  );
};

export default AdDetailsHeader;
