import { Grid } from "@mui/material";
import React from "react";
import AdCard from "./AdCard";

const DraftAdsTab = ({
  anchorEl,
  open,
  handleCloseMenu,
  handleOpenMenu,
  allDraftAds,
  setSelectedAd,
  handleDeleteAdModalOpen,
  selectedAd,
  handleViewsModalOpen,
  handlePricesModalOpen,
}) => {
  return (
    <Grid container>
      {allDraftAds.length > 0 ? (
        allDraftAds.map((ad, index) => (
          <AdCard
            anchorEl={anchorEl}
            open={open}
            handleCloseMenu={handleCloseMenu}
            handleOpenMenu={handleOpenMenu}
            ad={ad}
            key={index}
            draft={true}
            handleDeleteAdModalOpen={handleDeleteAdModalOpen}
            setSelectedAd={setSelectedAd}
            selectedAd={selectedAd}
            handleViewsModalOpen={handleViewsModalOpen}
            handlePricesModalOpen={handlePricesModalOpen}
          />
        ))
      ) : (
        <Grid container alignItems={"center"} justifyContent="center">
          <span>No Draft Ads available.</span>
        </Grid>
      )}
    </Grid>
  );
};

export default DraftAdsTab;
