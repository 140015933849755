import React from "react";
import {
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const SortReviews = ({
  anchorEl,
  open,
  handleCloseMenu,
  classes,
  sortOption,
  handleSortOptionChange,
  handleSortWines,
}) => {
  return (
    <Menu
      id="sort-menu"
      MenuListProps={{
        "aria-labelledby": "sort-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      TransitionComponent={Fade}
      className={classes.customWidth}
    >
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <span className={classes.sortTitle}>Sort By</span>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <CloseIcon
            onClick={handleCloseMenu}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      {/*

      
      <Divider />
      <MenuItem onClick={() => handleSortWines("Name")}>
        <span className={classes.sortItems}>Name</span>
      </MenuItem>
      <MenuItem onClick={() => handleSortWines("Varietal")}>
        <span className={classes.sortItems}>Varietal</span>
      </MenuItem>
      <MenuItem onClick={() => handleSortWines("Location")}>
        <span className={classes.sortItems}>Location</span>
      </MenuItem>
      <MenuItem onClick={() => handleSortWines("Vintage")}>
        <span className={classes.sortItems}>Vintage</span>
      </MenuItem>
      <MenuItem
        onClick={() => handleSortWines("All")}
        style={{ marginBottom: 10 }}
      >
        <span className={classes.sortItems}>All</span>
      </MenuItem>
      <Divider />
      */}
      <RadioGroup value={sortOption} onChange={handleSortOptionChange}>
        <FormControlLabel
          value="Ascending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="A-Z"
        />
        <FormControlLabel
          value="Descending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="Z-A"
        />
      </RadioGroup>
    </Menu>
  );
};

export default SortReviews;
