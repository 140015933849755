import { API } from "../constants";
import { request } from "../constants/request";

export const getAllWines = (currentPage, limit) => {
  return request({
    url: `${API.baseUrl}${API.getWines}?is_paginate=1&page=${currentPage}&limit=${limit}`,
    method: "GET",
  });
};
export const getWines = () => {
  return request({
    url: `${API.baseUrl}${API.getWines}`,
    method: "GET",
  });
};

export const getSingleWine = (wineId) => {
  return request({
    url: API.baseUrl + API.getWines + `/${wineId}`,
    method: "GET",
  });
};

export const getSearchWine = (name) => {
  return request({
    url: `${API.baseUrl + API.getWines}/${name}/search`,
    method: "GET",
  });
};

export const getFoodMenus = () => {
  return request({
    url: API.baseUrl + API.getFoodMenus,
    method: "GET",
  });
};
export const getFoods = () => {
  return request({
    url: API.baseUrl + API.getMenuItems,
    method: "GET",
  });
};

export const getFoodItem = (value) => {
  return request({
    url: API.baseUrl + API.searchFoods + `/${value}`,
    method: "GET",
  });
};

export const getWinePairings = (wineId) => {
  return request({
    // url: API.baseUrl + API.getWinePairings + `/${wineId}` + "/pairings",
    url: API.baseUrl + API.addWinePairing + `?filter[wine_id][is]=${wineId}`,
    method: "GET",
  });
};
export const getWineReviews = (wineId) => {
  return request({
    url: API.baseUrl + API.getWineReview + `?filter[wine_id][is]=${wineId}`,
    method: "GET",
  });
};

export const deleteWinePairing = (pairingId) => {
  let temp = { _method: "DELETE" };
  return request({
    url: API.baseUrl + API.addWinePairing + `/${pairingId}`,
    method: "POST",
    data: temp,
  });
};

export const addWineFoodPairing = (data) => {
  return request({
    url: API.baseUrl + API.addWinePairing,
    method: "POST",
    data: data,
  });
};
export const addMenuItem = (data) => {
  return request({
    url: API.baseUrl + API.getMenuItem,
    method: "POST",
    data: data,
  });
};
export const addWine = (wineData) => {
  return request({
    url: API.baseUrl + API.getWines,
    method: "POST",
    data: wineData,
  });
};

export const updateWine = (wineData, wineId) => {
  return request({
    url: API.baseUrl + API.getWines + `/${wineId}`,
    method: "POST",
    data: wineData,
  });
};

export const deleteWine = (wineId) => {
  return request({
    url: API.baseUrl + API.getWines + `/${wineId}`,
    method: "DELETE",
  });
};
