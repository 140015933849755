import React, { useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import ButtonLoader from "../../ButtonLoader";
      
const SuspendUserModal = ({
  modalOpen,
  handleClose,
  modalStyle,
  handleConfirm,
  loading,
}) => {
  const [suspensionDays, setSuspensionDays] = useState("");
  const [suspensionReason, setSuspensionReason] = useState("");

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Suspend User
        </span>
        <Grid container alignItems="center" justifyContent="center">
          <span style={suspendUserTextStyle}>
            Please select a time range for suspending the selected user.
          </span>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setSuspensionDays(Number(7))}
            style={suspend7DaysButtonStyle}
          >
            Suspend 7 days
          </Button>
          <Typography
            style={{ padding: 6, fontSize: 14, fontWeight: 600, color: "#000" }}
          >
            or
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter suspend days (up to 365 days)"
            value={suspensionDays}
            onChange={(e) => setSuspensionDays(Number(e.target.value))}
            type="number"
            style={{ margin: "20px 0px" }}
            InputProps={{
              style: {
                color: "#B1B1BD",
                fontSize: 16,
                fontWeight: 600,
              },
            }}
          />
          <TextField
            fullWidth
            select
            variant="outlined"
            label="Reason"
            value={suspensionReason}
            onChange={(e) => setSuspensionReason(e.target.value)}
            InputProps={{
              style: {
                color: "#B1B1BD",
                fontSize: 16,
                fontWeight: 600,
              },
            }}
          >
            <MenuItem value="Inappropriate Posts">Inappropriate Posts</MenuItem>
            <MenuItem value="Harrasment">Harrasment</MenuItem>
          </TextField>
          {loading ? (
            <Grid style={{marginTop: 20}}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleConfirm(suspensionDays, suspensionReason)}
              style={submitButtonStyle}
            >
              Submit
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default SuspendUserModal;

const suspend7DaysButtonStyle = {
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
};

const suspendUserTextStyle = {
  color: "#000000",
  fontSize: 15,
  fontWeight: 500,
  marginTop: 15,
  marginBottom: 15,
};

const submitButtonStyle = {
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#FFF",
  background: "#440356",
  marginTop: 20,
  textTransform: "none",
};
