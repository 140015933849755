import React, { useState, Fragment } from "react";
import {
  Button,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const FilterUsersMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  classes,
  sortOption,
  handleSortOptionChange,
  filterData,
  setFilterData,
  handleFilter,
  tabValue,
  handleBusinessUsersFilter,
}) => {
  return (
    <Menu
      id="filter-menu"
      MenuListProps={{
        "aria-labelledby": "filter-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      TransitionComponent={Fade}
      className={classes.filterCustomWidth}
    >
      <Grid container alignItems="center">
        <Grid item xs={8} style={{ textAlign: "start" }}>
          <span className={classes.sortTitle}>Filter</span>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <CloseIcon
            onClick={handleCloseMenu}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Divider />

      {/* Filter With account Type Section  */}
      {/*
      <Grid container style={{ padding: 12 }}>
        <span style={{ fontSize: 15, fontWeight: 600 }}>Account Type</span>
        <Grid>
          <Button
            variant={
              filterData.accountType == "Personal" ? "contained" : "outlined"
            }
            style={
              filterData.accountType == "Personal"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, accountType: "Personal" });
            }}
          >
            Personal
          </Button>
          <Button
            variant={
              filterData.accountType == "Business" ? "contained" : "outlined"
            }
            style={
              filterData.accountType == "Business"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => {
              handleFilter({ ...filterData, accountType: "Business" });
            }}
          >
            Business
          </Button>
        </Grid>
      </Grid>
    */}
      <Divider style={{ width: "70%" }} />
      {tabValue === 1 ? (
        <Grid container style={{ padding: 12 }}>
          <span style={{ fontSize: 15, fontWeight: 600 }}>Business Type</span>
          <Grid>
            <Button
              variant={
                filterData.business_type == "Winery/Vineyard"
                  ? "contained"
                  : "outlined"
              }
              style={
                filterData.business_type == "Winery/Vineyard"
                  ? selectedButtonStyle
                  : nonSelectedButtonStyle
              }
              onClick={() => {
                // setFilterData({ ...filterData, experience: "Yes" });
                handleBusinessUsersFilter({
                  ...filterData,
                  business_type: "Winery/Vineyard",
                });
              }}
            >
              Winery/Vineyard
            </Button>
            <Button
              variant={
                filterData.business_type == "Restaurant"
                  ? "contained"
                  : "outlined"
              }
              style={
                filterData.business_type == "Restaurant"
                  ? selectedButtonStyle
                  : nonSelectedButtonStyle
              }
              onClick={() => {
                // setFilterData({ ...filterData, experience: "Yes" });
                handleBusinessUsersFilter({
                  ...filterData,
                  business_type: "Restaurant",
                });
              }}
            >
              Restaurant
            </Button>
            <Button
              variant={
                filterData.business_type == "Shop/Outlet"
                  ? "contained"
                  : "outlined"
              }
              style={
                filterData.business_type == "Shop/Outlet"
                  ? selectedButtonStyle
                  : nonSelectedButtonStyle
              }
              onClick={() => {
                // setFilterData({ ...filterData, experience: "Yes" });
                handleBusinessUsersFilter({
                  ...filterData,
                  business_type: "Shop/Outlet",
                });
              }}
            >
              Shop/Outlet
            </Button>

            <Button
              variant={
                filterData.business_type == "All" ? "contained" : "outlined"
              }
              style={
                filterData.business_type == "All"
                  ? selectedButtonStyle
                  : nonSelectedButtonStyle
              }
              onClick={() => {
                // setFilterData({ ...filterData, experience: "Yes" });
                handleBusinessUsersFilter({ ...filterData, business_type: "All" });
              }}
            >
              All
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          {/* Filter With experience Section  */}

          <Grid container style={{ padding: 12 }}>
            <span style={{ fontSize: 15, fontWeight: 600 }}>Experience</span>
            <Grid>
              <Button
                variant={
                  filterData.experience == "Novice" ? "contained" : "outlined"
                }
                style={
                  filterData.experience == "Novice"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => {
                  // setFilterData({ ...filterData, experience: "Yes" });
                  handleFilter({ ...filterData, experience: "Novice" });
                }}
              >
                Novice
              </Button>
              <Button
                variant={
                  filterData.experience == "Intermediate"
                    ? "contained"
                    : "outlined"
                }
                style={
                  filterData.experience == "Intermediate"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => {
                  // setFilterData({ ...filterData, experience: "Yes" });
                  handleFilter({ ...filterData, experience: "Intermediate" });
                }}
              >
                Intermediate
              </Button>
              <Button
                variant={
                  filterData.experience == "Experienced"
                    ? "contained"
                    : "outlined"
                }
                style={
                  filterData.experience == "Experienced"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => {
                  // setFilterData({ ...filterData, experience: "Yes" });
                  handleFilter({ ...filterData, experience: "Experienced" });
                }}
              >
                Experienced
              </Button>
              <Button
                variant={
                  filterData.experience == "Sommelier"
                    ? "contained"
                    : "outlined"
                }
                style={
                  filterData.experience == "Sommelier"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => {
                  // setFilterData({ ...filterData, experience: "Yes" });
                  handleFilter({ ...filterData, experience: "Sommelier" });
                }}
              >
                Sommelier
              </Button>
              <Button
                variant={
                  filterData.experience == "All" ? "contained" : "outlined"
                }
                style={
                  filterData.experience == "All"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => {
                  // setFilterData({ ...filterData, experience: "Yes" });
                  handleFilter({ ...filterData, experience: "All" });
                }}
              >
                All
              </Button>
            </Grid>
          </Grid>
          <Divider style={{ width: "70%" }} />
          {/* FIlter with Age Range Section */}
          <Grid
            container
            style={{ marginTop: 12, paddingLeft: 12, marginBottom: 12 }}
          >
            <span style={{ fontSize: 15, fontWeight: 600 }}>Age Range</span>
            <Grid>
              <Button
                variant={filterData.age == "13-20" ? "contained" : "outlined"}
                style={
                  filterData.age == "13-20"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, age: "13-20" })}
              >
                13-20yrs
              </Button>
              <Button
                variant={filterData.age == "21-26" ? "contained" : "outlined"}
                style={
                  filterData.age == "21-26"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, age: "21-26" })}
              >
                21-26yrs
              </Button>
              <Button
                variant={filterData.age == "27-36" ? "contained" : "outlined"}
                style={
                  filterData.age == "27-36"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, age: "27-36" })}
              >
                27-36yrs
              </Button>
              <Button
                variant={filterData.age == "37-44" ? "contained" : "outlined"}
                style={
                  filterData.age == "37-44"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, age: "37-44" })}
              >
                37-44yrs
              </Button>
              <Button
                variant={filterData.age == "45-51" ? "contained" : "outlined"}
                style={
                  filterData.age == "45-51"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, age: "45-51" })}
              >
                45-51 years
              </Button>
              <Button
                variant={filterData.age == "All" ? "contained" : "outlined"}
                style={
                  filterData.age == "All"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, age: "All" })}
              >
                All
              </Button>
            </Grid>
          </Grid>
          <Divider style={{ width: "70%" }} />

          {/* Filter with Gender Section */}
          <Grid
            container
            style={{ marginTop: 12, paddingLeft: 12, marginBottom: 12 }}
          >
            <span style={{ fontSize: 15, fontWeight: 600 }}>Gender</span>
            <Grid>
              <Button
                variant={filterData.gender == "Male" ? "contained" : "outlined"}
                style={
                  filterData.gender == "Male"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, gender: "Male" })}
              >
                Male
              </Button>
              <Button
                variant={
                  filterData.gender == "Female" ? "contained" : "outlined"
                }
                style={
                  filterData.gender == "Female"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() =>
                  handleFilter({ ...filterData, gender: "Female" })
                }
              >
                Female
              </Button>
              <Button
                variant={
                  filterData.gender == "Non-Binary" ? "contained" : "outlined"
                }
                style={
                  filterData.gender == "Non-Binary"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() =>
                  handleFilter({ ...filterData, gender: "Non-Binary" })
                }
              >
                Non-Binary
              </Button>

              <Button
                variant={filterData.gender == "All" ? "contained" : "outlined"}
                style={
                  filterData.gender == "All"
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                }
                onClick={() => handleFilter({ ...filterData, gender: "All" })}
              >
                All
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      )}

      <Divider />
      <RadioGroup value={sortOption} onChange={handleSortOptionChange}>
        <FormControlLabel
          value="Ascending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="Ascending"
        />
        <FormControlLabel
          value="Descending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="Descending"
        />
      </RadioGroup>
    </Menu>
  );
};

export default FilterUsersMenu;

const selectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  color: "#FFF",
  background: "#C84B96",
};

const nonSelectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  borderColor: "#000",
  color: "#000",
};
