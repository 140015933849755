import React, { Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { CSVLink } from "react-csv";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Grid, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate } from "react-router-dom";
import { businessHeader, businessColumns } from "../constants";
import { useUsersContext } from "../../context/UsersContext";

export default function BusinessTable({ allUsers }) {
  const {
    handleGetBusinessUsers,
    businessUsersCurrentPage,
    setBusinessUsersCurrentPage,
    businessUsersRowsPage,
    setBusinessUsersRowPage,
    loading,
    setLoading,
    businessUsersTableDetails,
  } = useUsersContext();
  const navigate = useNavigate();

  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setBusinessUsersCurrentPage(newPage + 1);
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setBusinessUsersRowPage(+event.target.value);
    handleGetBusinessUsers(+event.target.value);
    // setRowsPerPage(+event.target.value);
    // setPage(0);
  };

  return (
    <Fragment>
      {loading ? (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <CircularProgress
            color="secondary"
            style={{
              color: "rgb(200, 75, 150)",
              width: "150px",
              height: "150px",
            }}
          />
        </Box>
      ) : (
        <Table style={{ border: "none" }}>
          <TableHead style={{ border: "none" }}>
            <TableRow>
              {businessColumns &&
                businessColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      border: "none",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#8086A2",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers &&
              allUsers.length > 1 &&
              allUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <Fragment>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        style={{ borderRadius: 20 }}
                      >
                        {businessColumns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                border: "none",
                                background: "#F6F6F6",
                                color: "#C84B96",
                                fontSize: 14,
                                fontWeight: column.id == "name" ? 600 : 500,
                                borderRadius:
                                  column.id == "name"
                                    ? "8px 0px 0px 8px"
                                    : undefined,
                              }}
                            >
                              {column.id == "name"
                                ? row.account_type === "Business"
                                  ? `${row.name && row.name}`
                                  : `${row.first_name && row.first_name}  ${
                                      row.last_name && row.last_name
                                    }`
                                : column.id == "state"
                                ? `${value},  ${
                                    row.zipcode === "undefined"
                                      ? ""
                                      : row.zipcode
                                  }`
                                : column.id == "verified" && row.verified
                                ? row.verified
                                : column.id == "verified" && !row.verified
                                ? "Nil"
                                : column.id == "last_login"
                                ? `${value ? value : ""}`
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          key="forward"
                          align="right"
                          style={tableCellForward}
                        >
                          <ArrowForwardIosIcon
                            onClick={() =>
                              navigate("/users/details", {
                                state: { user: row, allUsers },
                              })
                            }
                            style={{
                              color: "#C84B96",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <div style={{ margin: 8 }} />
                    </Fragment>
                  );
                })}
          </TableBody>
        </Table>
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: 10 }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={businessUsersTableDetails.total}
          rowsPerPage={businessUsersRowsPage}
          page={businessUsersCurrentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button variant="outlined" style={exportButton}>
          <CSVLink
            data={allUsers && allUsers.length ? allUsers : []}
            headers={businessHeader}
            filename={`Business Records.csv`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export CSV
          </CSVLink>
        </Button>
      </Grid>
    </Fragment>
  );
}

const exportButton = {
  marginLeft: "auto",
  background: "#C84B96",
  borderRadius: 12,
  width: 130,
  height: 50,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
};

const tableCellForward = {
  border: "none",
  background: "#F6F6F6",
  color: "#C84B96",
  fontSize: 14,
  fontWeight: 500,
  borderRadius: "0px 8px 8px 0px",
};
