import ButtonLoader from "../../components/ButtonLoader";
import { useLocation, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { deleteQuizQuestion, updateQuestion } from "../../apis/quizzes";
import AddAnswerTextField from "../../components/quizzes/addQuiz/AddAnswerTextField";
import DeleteQuestionModal from "../../components/quizzes/addQuiz/DeleteQuestionModal";

const EditQuiz = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [question, setQuestion] = useState(location.state.question);
  const [quizDetails, setQuizDetails] = useState(location.state?.quizDetails);

  // console.log("quiz Details>>>", quizDetails);

  // Handle Change
  const handleComingOption = () => {
    let temp = [];
    location.state.question.options.map((el, index) =>
      temp.push({
        answer: el.option,
        label: String.fromCharCode(65 + index),
        correct: el.is_correct === 1 ? 1 : 0,
        created_at: el.created_at,
        is_correct: el.is_correct === 1 ? 1 : 0,
        option: el.option,
        quiz_question_id: el.quiz_question_id,
        id: el.id,
        updated_at: el.updated_at,
        user_response: el.user_response,
      })
    );
    return temp;
  };

  useEffect(() => {
    setQuestion(location.state.question);
    setQuizDetails(location.state?.quizDetails);
  }, [location.state]);

  const [singleQuestion, setSingleQuestion] = useState({
    question: question?.question || " ",
    answers: handleComingOption(),
    options: handleComingOption(),
  });
  const [correcAnswerIndex, setCorrecAnswerIndex] = useState(null);

  const [questionCompleted, setQuestionCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteQuestionModal, setDeleteQuestionModal] = useState(false);

  //Handle Add Correct Answer to Question Options
  const addAnswerToArray = () => {
    let temp = [...singleQuestion.answers];
    if (singleQuestion.answers.length == 2) {
      temp.push({ label: "C", answer: "" });
    }
    if (singleQuestion.answers.length == 3) {
      temp.push({ label: "D", answer: "" });
    }
    setSingleQuestion({
      ...singleQuestion,
      answers: temp,
    });
  };

  // Handle Change True Answer
  const handleChangeAnswer = (e, index) => {
    let value = e.target.value;
    let temp = [...singleQuestion.answers];
    temp[index].answer = value;
    temp[index].option = value;
    setSingleQuestion({
      ...singleQuestion,
      answers: temp,
      options: temp,
    });
  };

  //Handle Select Correct Option
  const handleSelectCorrectAnswer = (index) => {
    try {
      let temp = [...singleQuestion.answers];
      temp.forEach((item, itemIndex) => {
        delete temp[itemIndex].correct;
        temp[itemIndex].is_correct = 0;
      });
      temp[index] = { ...temp[index], correct: true, is_correct: 1 };
      setCorrecAnswerIndex(index);
      setSingleQuestion({
        ...singleQuestion,
        answers: temp,
        options: temp,
      });
      setQuestionCompleted(true);
    } catch (error) {
      console.log("error selecting answer:", error.message);
    }
  };

  // Question Delete Modals
  const handleDeleteQuestionModalOpen = () => {
    setDeleteQuestionModal(true);
  };
  const handleDeleteQuestionModalClose = () => {
    setDeleteQuestionModal(false);
  };

  //Handle Delete Question
  const handleDeleteQuestion = async () => {
    try {
      setDeleteLoading(true);

      await deleteQuizQuestion(question.id);
      handleDeleteQuestionModalClose();
      navigate(-1);
    } catch (error) {
      console.log("error deleting question:", error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  //Handle Update Quiz Question
  const handleUpdateQuizQuestion = async () => {
    try {
      setLoading(true);
      let answersTemp = [];
      let correctAnswerIndex;

      singleQuestion.options.forEach((ans, index) => {
        answersTemp.push(ans.answer);
        if (ans.correct) {
          correctAnswerIndex = index;
        }
      });
      let updateQuestionTemp = {
        quiz_id: quizDetails.id,
        question: singleQuestion.question,
        order: question.order,
        option: answersTemp,
        correct_option_index: correctAnswerIndex,
        _method: "PUT",
      };
      // console.log("Test Update Quiz Question>>>>", updateQuestionTemp);
      await updateQuestion(updateQuestionTemp, question.id);
      navigate(-1);
    } catch (error) {
      console.log("error updating Question:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {/* Header section */}
      <ArrowBackIosIcon style={arrowBackStyles} onClick={() => navigate(-1)} />

      <Grid
        container
        direction={"column"}
        alignItems="center"
        justifyContent="center"
      >
        <Typography style={{ fontSize: "36px", fontWeight: 700 }}>
          {quizDetails?.name}
        </Typography>
        <Grid style={{ width: "40%", marginTop: 40 }}>
          <span style={{ fontSize: 16, fontWeight: 300 }}>
            <i>
              Type your question then add answers. Once you’re done adding
              answers, select the correct option.{" "}
            </i>
          </span>
        </Grid>
        <Grid
          container
          direction={"column"}
          style={{ width: "40%", marginTop: 40 }}
        >
          <TextField
            variant="outlined"
            fullWidth
            value={singleQuestion?.question}
            onChange={(e) =>
              setSingleQuestion({ ...singleQuestion, question: e.target.value })
            }
            placeholder="Type your questions here..."
            inputProps={{ sx: { color: "#000" } }}
            InputProps={{
              style: questionPropsStyles,
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          <AddAnswerTextField
            label={singleQuestion.answers[0].label}
            value={singleQuestion.answers[0].answer}
            correct={singleQuestion.answers[0].correct}
            onChange={(e) => handleChangeAnswer(e, 0)}
            handleCorrectAnswerClick={() => handleSelectCorrectAnswer(0)}
            key={"A"}
          />
          <AddAnswerTextField
            label={singleQuestion.answers[1].label}
            value={singleQuestion.answers[1].answer}
            correct={singleQuestion.answers[1].correct}
            onChange={(e) => handleChangeAnswer(e, 1)}
            handleCorrectAnswerClick={() => handleSelectCorrectAnswer(1)}
            key={"B"}
          />
          {singleQuestion.answers[2] && (
            <AddAnswerTextField
              label={singleQuestion.answers[2].label}
              value={singleQuestion.answers[2].answer}
              correct={singleQuestion.answers[2].correct}
              onChange={(e) => handleChangeAnswer(e, 2)}
              handleCorrectAnswerClick={() => handleSelectCorrectAnswer(2)}
              key={"C"}
            />
          )}
          {singleQuestion.answers[3] && (
            <AddAnswerTextField
              label={singleQuestion.answers[3].label}
              value={singleQuestion.answers[3].answer}
              correct={singleQuestion.answers[3].correct}
              onChange={(e) => handleChangeAnswer(e, 3)}
              handleCorrectAnswerClick={() => handleSelectCorrectAnswer(3)}
              key={"D"}
            />
          )}
          {singleQuestion.answers.length !== 4 && (
            <Grid container justifyContent={"flex-end"}>
              <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                style={addAnswerButtonStyles}
                onClick={addAnswerToArray}
              >
                <AddOutlinedIcon
                  fontSize={"medium"}
                  style={{ color: "#fff" }}
                />
              </Grid>
            </Grid>
          )}

          <Grid container alignItems={"center"} justifyContent="center">
            <Grid container>
              {loading ? (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  justifyContent="center"
                  style={{ marginTop: 150, width: "60%" }}
                >
                  <ButtonLoader />
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    ...editQuestionButtonStyles,
                    background: "#C84B96",
                    color: "#fff",
                  }}
                  onClick={handleUpdateQuizQuestion}
                >
                  Save Changes
                </Button>
              )}
              <Button
                variant="outlined"
                style={deleteQuestionButtonStyles}
                onClick={handleDeleteQuestionModalOpen}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Delete Quiz Modal section */}
      <DeleteQuestionModal
        modalOpen={deleteQuestionModal}
        handleClose={handleDeleteQuestionModalClose}
        handleConfirm={handleDeleteQuestion}
        loading={deleteLoading}
      />
    </Fragment>
  );
};

export default EditQuiz;

const arrowBackStyles = {
  marginBottom: -3,
  cursor: "pointer",
  color: "#C84B96",
  alignSelf: "flex-start",
};

const addAnswerButtonStyles = {
  width: 50,
  height: 50,
  background: "#4E1B5C",
  borderRadius: 50,
  marginTop: 15,
  cursor: "pointer",
};

const questionPropsStyles = {
  fontSize: 16,
  fontWeight: 600,
  marginLeft: -10,
};

const editQuestionButtonStyles = {
  marginTop: 150,
  width: "60%",
  height: 60,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
  borderRadius: 8,
};

const deleteQuestionButtonStyles = {
  marginTop: 150,
  width: "35%",
  height: 60,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
  borderRadius: 8,
  border: "1px solid #C84B96",
  color: "#C84B96",
  marginLeft: "auto",
};
