import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import ButtonLoader from "../ButtonLoader";
import Autocomplete from "@mui/material/Autocomplete";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useEffect } from "react";

const ManageWineModal = ({
  wineModal,
  handleClose,
  modalStyle,
  title,
  wineData,
  setWineData,
  handleConfirm,
  loading,
}) => {
  const [vintages, setVintages] = useState([]);
  const [varietalOptions, setVarietalOptions] = useState([
    "Cabernet Sauvignon",
    "Merlot",
    "Pinot noir",
    "Chardonnay",
    "White Zinfandel",
    "Riesling",
    "Blanc de Noirs",
    "Cabernet Franc",
    "Châteauneuf-du-Pape",
  ]);

  useEffect(() => {
    handleGetVintages();
  }, []);

  const handleGetVintages = () => {
    const currentYear = new Date().getFullYear();
    const years = ["NV"];

    for (let i = currentYear - 1; i >= currentYear - 100; i--) {
      years.push(i);
    }
    // console.log(years);
    setVintages(years);
  };

  return (
    <Modal open={wineModal} onClose={handleClose}>
      <Box sx={modalStyle}>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          {title}
        </span>

        <TextField
          fullWidth
          label="Name"
          placeholder="Name"
          variant="outlined"
          value={wineData.name}
          onChange={(e) => setWineData({ ...wineData, name: e.target.value })}
          style={{ marginTop: 40 }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          //   inputProps={{ sx: { color: "#808080" } }}
          inputProps={{
            sx: { color: "#808080" },
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <TextField
          fullWidth
          label="Price"
          placeholder="Price"
          variant="outlined"
          value={wineData.price}
          type="number"
          onChange={(e) => setWineData({ ...wineData, price: e.target.value })}
          style={{ marginTop: 15 }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          //   inputProps={{ sx: { color: "#808080" } }}
          inputProps={{
            sx: { color: "#808080" },
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <Autocomplete
          value={wineData.varietal ? wineData.varietal : ""}
          onChange={(event, newValue) => {
            setWineData({ ...wineData, varietal: newValue });
          }}
          inputValue={wineData.varietal ? wineData.varietal : ""}
          onInputChange={(event, newInputValue) => {
            setWineData({ ...wineData, varietal: newInputValue });
          }}
          options={varietalOptions}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          renderInput={(params) => <TextField {...params} label="Varietal" />}
          fullWidth
          style={{ textAlign: "start", marginTop: 30, marginTop: 15 }}
        />
        {/*
        <FormControl
          fullWidth
          style={{ textAlign: "start", marginTop: 30, marginTop: 15 }}
        >
          <InputLabel id="varietal-select">Varietal</InputLabel>
          <Select
            labelId="varietal-select-label"
            id="varietal-select"
            value={wineData.varietal}
            label="Varietal"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #C84B96",
              },
            }}
            onChange={(e) =>
              setWineData({ ...wineData, varietal: e.target.value })
            }
          >
            <MenuItem value={"Cabernet Sauvignon"}>Cabernet Sauvignon</MenuItem>
            <MenuItem value={"Merlot"}>Merlot</MenuItem>
            <MenuItem value={"Pinot noir"}>Pinot noir</MenuItem>
            <MenuItem value={"Chardonnay"}>Chardonnay</MenuItem>
            <MenuItem value={"White Zinfandel"}>White Zinfandel</MenuItem>
            <MenuItem value={"Riesling"}>Riesling</MenuItem>
            <MenuItem value={"Blanc de Noirs"}>Blanc de Noirs</MenuItem>
            <MenuItem value={"Cabernet Franc"}>Cabernet Franc</MenuItem>
            <MenuItem value={"Châteauneuf-du-Pape"}>
              Châteauneuf-du-Pape
            </MenuItem>
          </Select>
        </FormControl>
      */}
        <TextField
          fullWidth
          label="Location"
          placeholder="Location"
          variant="outlined"
          value={wineData.region}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          onChange={(e) => setWineData({ ...wineData, region: e.target.value })}
          style={{ marginTop: 15 }}
          inputProps={{
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl
          fullWidth
          style={{ textAlign: "start", marginTop: 15, marginBottom: 30 }}
        >
          <InputLabel id="vintage-select">Vintage</InputLabel>
          <Select
            labelId="vintage-select-label"
            id="vintage-select"
            value={wineData.vintage}
            label="Vintage"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #C84B96",
              },
            }}
            onChange={(e) =>
              setWineData({ ...wineData, vintage: e.target.value })
            }
          >
            {vintages.map((year, index) => (
              <MenuItem value={year} key={index}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container>
          <Button
            fullWidth
            variant="container"
            onClick={handleClose}
            style={cancelButtonStyles}
          >
            Cancel
          </Button>
          {loading ? (
            <Grid
              container
              style={{ width: 200 }}
              alignItems="center"
              justifyContent="center"
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              disabled={
                wineData.name
                  ? // wineData.varietal &&
                    // wineData.region &&
                    // wineData.vintage
                    false
                  : true
              }
              onClick={handleConfirm}
              style={{
                ...confirmButton,
                background: wineData.name
                  ? // wineData.varietal &&
                    // wineData.region &&
                    // wineData.vintage
                    "#C84B96"
                  : "#F6F6F6",
                color: wineData.name
                  ? // wineData.varietal &&
                    // wineData.region &&
                    // wineData.vintage
                    "#FFFFFF"
                  : "#9D9DAA",
              }}
            >
              {title}
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default ManageWineModal;

const textFieldStyles = {
  //   width: 320,
  //   borderRadius: 8,
  background: "#F6F6F6",
  color: "#C84B96",
};

const confirmButton = {
  width: 170,
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  marginLeft: "auto",
};

const cancelButtonStyles = {
  width: "130px",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#9D9DAA",
  background: "#F6F6F6",
  borderRadius: 8,
  textTransform: "none",
};
