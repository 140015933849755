import { Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import image1 from "../../../images/mediaImages/MediaCard_New1.png";
import { baseUrl } from "../../constants";
import ShowImageModal from "./ShowImageModal";

const CertificationDetailsForm = ({ supportDetails }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  // console.log(supportDetails);
  return (
    <Grid
      item
      xs
      container
      alignItems={"center"}
      justifyContent="center"
      style={{}}
    >
      {supportDetails.certificate_doc === null ? (
        <span>No Certification image is provided</span>
      ) : (
        <img
          src={`${
            supportDetails.certificate_doc &&
            baseUrl + supportDetails.certificate_doc
          }`}
          alt="certification image"
          style={{ width: 250, height: 250, cursor: "pointer" }}
          onClick={() => setShowImageModal(true)}
        />
      )}
      <ShowImageModal
        modalOpen={showImageModal}
        handleClose={() => setShowImageModal(false)}
        image={`${
          supportDetails.certificate_doc &&
          baseUrl + supportDetails.certificate_doc
        }`}
      />
    </Grid>
  );
};

export default CertificationDetailsForm;
