import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import ReportsTab from "./ReportsTab";
import SuspendedUsersTab from "./SuspendedUsersTab";
import ResolvedReportsTab from "./ResolvedReportsTab";
import CertificationsTab from "./CertificationsTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`support-tabpanel-${index}`}
      aria-labelledby={`support-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `support-tab-${index}`,
    "aria-controls": `support-tabpanel-${index}`,
  };
}

export default function CustomerSupportTabs({
  reports,
  setReports,
  resolvedReports,
  setResolvedReports,
  certifications,
  setCertifications,
  allSuspendedUsers,
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: 2 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
          centered
          style={tabsStyle}
        >
          <Tab
            label="Reports"
            {...a11yProps(0)}
            style={value == 0 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Certifications"
            {...a11yProps(1)}
            style={value == 1 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Suspended Users"
            {...a11yProps(2)}
            style={value == 2 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Resolved"
            {...a11yProps(3)}
            style={value == 3 ? selectedTab : nonSelectedTab}
          />
        </Tabs>
      </Grid>

      <TabPanel value={value} index={0}>
        <ReportsTab reports={reports} setReports={setReports} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CertificationsTab
          certifications={certifications}
          setCertifications={setCertifications}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SuspendedUsersTab suspendedUsers={allSuspendedUsers} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ResolvedReportsTab
          resolvedReports={resolvedReports}
          setResolvedReports={setResolvedReports}
        />
      </TabPanel>
    </Box>
  );
}

const selectedTab = {
  background: "#C84B96",
  borderRadius: 100,
  textTransform: "none",
  color: "#FFF",
  height: 40,
  padding: "0px 43px",
  fontSize: 16,
  fontWeight: 600,
};

const nonSelectedTab = {
  background: "#F6F6F6",
  textTransform: "none",
  color: "#000000",
  height: 40,
  fontSize: 16,
  fontWeight: 600,
  padding: "0px 43px",
};

const tabsStyle = {
  background: "#F6F6F6",
  borderRadius: 53,
  padding: 5,
};
