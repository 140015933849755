import { Divider, Grid } from "@mui/material";
import React from "react";

import UserIcon from "../../../images/icons/userIcon.png";
import AverageIcon from "../../../images/icons/Statistics.png";
import HighestIcon from "../../../images/icons/highIcon.png";
import LowestIcon from "../../../images/icons/lowIcon.png";

const WordAnalytics = ({ wordDetails }) => {
  return (
    <Grid
      container
      style={{
        height: 150,
        borderRadius: 20,
        background: "#F9F9FA",
        marginTop: 30,
      }}
    >
      <Grid
        xs
        item
        container
        direction={"column"}
        alignItems={"center"}
        justifyContent="center"
        style={{ padding: "40px 50px 30px 50px" }}
      >
        <span style={{ fontSize: 16, fontWeight: 600, color: "#000" }}>
          Total Participants{" "}
          <img
            src={UserIcon}
            alt="user icon"
            style={{ width: 24, height: 24, marginBottom: -5 }}
          />
        </span>
        <span style={{ fontSize: 36, fontWeight: 700, color: "#C84B96" }}>
          {wordDetails &&
            wordDetails.word_game_attempt_count &&
            wordDetails.word_game_attempt_count}
        </span>
      </Grid>
      <Divider orientation="vertical" style={{ height: 60, marginTop: 50 }} />
      <Grid
        xs
        item
        container
        direction={"column"}
        alignItems={"center"}
        justifyContent="center"
        style={{ padding: "40px 50px 30px 50px" }}
      >
        <span style={{ fontSize: 16, fontWeight: 600, color: "#000" }}>
          Average Score{" "}
          <img
            src={AverageIcon}
            alt="Average icon"
            style={{ width: 24, height: 24, marginBottom: -5 }}
          />
        </span>
        <span style={{ fontSize: 36, fontWeight: 700, color: "#C84B96" }}>
          {wordDetails?.stats?.avg_score}
        </span>
      </Grid>
      <Divider orientation="vertical" style={{ height: 60, marginTop: 50 }} />
      <Grid
        xs
        item
        container
        direction={"column"}
        alignItems={"center"}
        justifyContent="center"
        style={{ padding: "40px 50px 30px 50px" }}
      >
        <span style={{ fontSize: 16, fontWeight: 600, color: "#000" }}>
          Highest Score{" "}
          <img
            src={HighestIcon}
            alt="Highest icon"
            style={{ width: 24, height: 24, marginBottom: -5 }}
          />
        </span>
        <span style={{ fontSize: 36, fontWeight: 700, color: "#C84B96" }}>
          {wordDetails &&
            wordDetails.stats &&
            wordDetails.stats.highest_score &&
            wordDetails.stats.highest_score}
        </span>
      </Grid>
      <Divider orientation="vertical" style={{ height: 60, marginTop: 50 }} />
      <Grid
        xs
        item
        container
        direction={"column"}
        alignItems={"center"}
        justifyContent="center"
        style={{ padding: "40px 50px 30px 50px" }}
      >
        <span style={{ fontSize: 16, fontWeight: 600, color: "#000" }}>
          Lowest Score{" "}
          <img
            src={LowestIcon}
            alt="lowest icon"
            style={{ width: 24, height: 24, marginBottom: -5 }}
          />
        </span>
        <span style={{ fontSize: 36, fontWeight: 700, color: "#C84B96" }}>
          {wordDetails &&
            wordDetails.stats &&
            wordDetails.stats.lowest_score &&
            wordDetails.stats.lowest_score}
        </span>
      </Grid>
    </Grid>
  );
};

export default WordAnalytics;
