import axios from "axios";
import { API } from "../constants";
import { request } from "../constants/request";

export const adminLogin = (email, password) => {
  return axios({
    url: API.baseUrl + API.getAdminLogin,
    method: "POST",
    data: { email, password },
    headers: { "content-type": "application/json" },
  });
};
export const handleLogoutAdmin = () => {
  return request({
    url: API.baseUrl + API.logoutAdmin,
    method: "GET",
  });
};

export const getCurrentUserData = (adminId) => {
  return request({
    url: API.baseUrl + API.getAdmins + `/${adminId}`,
    method: "GET",
  });
};

export const updateAdminEmail = (adminId, data) => {
  return request({
    url: API.baseUrl + API.getAdmins + `/${adminId}`,
    method: "POST",
    data: data,
  });
};

export const updateAdminPassword = (data) => {
  return request({
    url: API.baseUrl + API.resetPassword,
    method: "POST",
    data: data,
  });
};
