import { Grid } from "@mui/material";
import React from "react";
import QuizCard from "./QuizCard";

const PendingQuizzesSection = ({ allPendingQuizzes, setAllPendingQuizzes }) => {
  return (
    <Grid container direction={"column"} style={{ marginTop: 20 }}>
      {/*  Header/Top section */}
      <Grid container item>
        <Grid>
          <span style={{ fontSize: 20, fontWeight: 600, color: "#000" }}>
            Pending
          </span>
        </Grid>
        {/* <Grid style={{ marginLeft: "auto" }}>
          <span
            style={{
              marginRight: 35,
              textDecorationLine: "underline",
              fontSize: 16,
              fontWeight: 500,
              color: "#000",
            }}
          >
            View All
          </span>
        </Grid> */}
      </Grid>
      {/* Drafts Quizzes Cards section */}
      <Grid container>
        {allPendingQuizzes.map((quiz, index) => (
          <QuizCard quiz={quiz} key={index} status="Pending" />
        ))}
      </Grid>
    </Grid>
  );
};

export default PendingQuizzesSection;
