import { Grid, Menu, MenuItem } from "@mui/material";
import React from "react";
import ButtonLoader from "../../ButtonLoader";

const DeleteReviewMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  loading,
  handleDeleteReview,
}) => {
  return (
    <Menu
      id="action-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "action-button",
      }}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#C84B96",
        },
      }}
    >
      {loading ? (
        <Grid style={{ paddingRight: 30, paddingLeft: 30 }}>
          <ButtonLoader />
        </Grid>
      ) : (
        <MenuItem
          onClick={() => {
            handleDeleteReview();
          }}
          style={{ color: "#fff", width: 172 }}
        >
          Delete
        </MenuItem>
      )}
    </Menu>
  );
};

export default DeleteReviewMenu;
