import { Grid } from "@mui/material";
import React from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const ReviewImageSection = ({ adData, image }) => {
  return (
    <Grid
      item
      container
      direction={"column"}
      style={{ width: "48%", padding: 60 }}
    >
      <img
        src={image.preview && image.preview}
        alt=""
        style={{
          width: 500,
          height: 500,
          borderRadius: "12px 0px",
          marginTop: 20,
        }}
      />
      <Grid
        container
        alignItems={"center"}
        style={{
          borderRadius: "0px 0px 12px 12px",
          height: 50,
          cursor: "pointer",
          paddingLeft: 30,
          background: "#C84B96",
          marginTop: -30,
          width: 500,
        }}
      >
        <span style={{ fontSize: 14, fontWeight: 600, color: "#fff" }}>
          Shop Now
        </span>
        <ArrowForwardIosOutlinedIcon
          fontSize="small"
          style={{ color: "#fff", marginLeft: 10 }}
        />
      </Grid>
      <span
        style={{ fontSize: 16, fontWeight: 500, color: "#000", marginTop: 25 }}
      >
        {adData.description && adData.description}
      </span>
    </Grid>
  );
};

export default ReviewImageSection;
