import React from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
} from "@mui/material";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

import AdTextFieldComponent from "./AdTextFieldComponent";
import SelectedOptions from "./SelectedOptions";
import SelectOptionsComponent from "./SelectOptionsComponent";
import OutlinedInput from "@mui/material/OutlinedInput";

const AdDetailsSection = ({
  adData,
  setAdData,
  showAgeRange,
  setShowAgeRange,
  showGender,
  setShowGender,
  showInterest,
  setShowInterest,
  ageRange,
  setAgeRange,
  genders,
  setGenders,
  interests,
  setInterests,
  openStartDateModal,
  openEndDateModal,
  handleOpenStartDateModal,
  handleCloseStartDateModal,
  handleOpenEndDateModal,
  handleCloseEndDateModal,
  handleSelectAgeRange,
  handleSelectAllAgeRange,
  handleSelectGender,
  handleSelectAllGender,
  handleSelectInterests,
  handleSelectAllInterests,
}) => {
  const [personName, setPersonName] = React.useState([]);
  const [interestSearch, setInterestSearch] = React.useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    handleSelectAgeRange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeGender = (event) => {
    if (adData.gender.includes(event)) {
      handleSelectGender(adData.gender.filter((item) => item !== event));
    } else {
      handleSelectGender([...adData.gender, event]);
    }
    // const {
    //   target: { value },
    // } = event;
    // handleSelectGender(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };
  const handleChangeInterest = (event) => {
    if (adData.interests.includes(event)) {
      handleSelectInterests(adData.interests.filter((item) => item !== event));
    } else {
      handleSelectInterests([...adData.interests, event]);
    }
    // const {
    //   target: { value },
    // } = event;
    // handleSelectInterests(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let tempInterests = [...interests]
  tempInterests = tempInterests.filter(item=>{
    return item.name.toLowerCase().includes(interestSearch.toLowerCase())
  })

  return (
    <Grid
      item
      container
      direction={"column"}
      style={{ width: "43%", padding: 60 }}
    >
      <span style={adInfoTitleStyles}>Ad Information</span>
      {/*Ad's Name text field */}
      <AdTextFieldComponent
        defaultValue={adData.adName && adData.adName}
        value={adData.adName}
        onChange={(e) => setAdData({ ...adData, adName: e.target.value })}
        placeholder="Ad Name (For recognition)"
      />
      {/* Ad's Goal select text field */}
      <TextField
        variant="outlined"
        defaultValue={"Goal"}
        value={adData.goal}
        onChange={(e) => setAdData({ ...adData, goal: e.target.value })}
        placeholder="Goal"
        style={textFieldStyles}
        select
        InputProps={{
          style: textFieldPropsStyles,
        }}
      >
        <MenuItem value="More Profile Visits" key={0}>
          More Profile Visits
        </MenuItem>
        <MenuItem value="More Website Visits" key={1}>
          More Website Visits
        </MenuItem>
      </TextField>
      {/* Ad's Website text field */}
      <AdTextFieldComponent
        defaultValue={adData.website && adData.website}
        value={adData.website}
        onChange={(e) => setAdData({ ...adData, website: e.target.value })}
        placeholder="Website"
      />
      {/* Ad's Location text field */}
      <AdTextFieldComponent
        defaultValue={adData.location && adData.location}
        value={adData.location}
        onChange={(e) => setAdData({ ...adData, location: e.target.value })}
        placeholder="Location"
        icon={<SearchOutlinedIcon />}
      />
      <AdTextFieldComponent
        defaultValue={adData.location && adData.location}
        value={adData.age}
        onChange={(e) => setAdData({ ...adData, age: e.target.value })}
        placeholder="Age"
      />
      {/* Ad's Age Range text field */}
      {/* <Select
        style={textFieldStyles}
        multiple
        displayEmpty
        value={adData.ageRange}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Age Range</em>;
          }
          return selected.join(", ");
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Age Range</em>
        </MenuItem>
        {ageRange.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select> */}

      {/* <AdTextFieldComponent
        placeholder="Select Age Range"
        value={adData.addRange}
        icon={
          <ExpandMoreOutlinedIcon
            onClick={() => setShowAgeRange(!showAgeRange)}
            style={{ cursor: "pointer" }}
          />
        }
        onClick={() => setShowAgeRange(true)}
      /> */}
      {/* show selected age ranges options */}

      {/* {adData.ageRange.length > 0 && <SelectedOptions data={adData.ageRange} />} */}
      {/* Show age range suggestions section */}
      {/* {showAgeRange && (
        <SelectOptionsComponent
          title={"Age Range"}
          data={ageRange}
          handleSelectOption={handleSelectAgeRange}
          handleSelectAll={handleSelectAllAgeRange}
        />
      )} */}

      {/* Ad's Gender text field */}
      {/* <Select
        style={textFieldStyles}
        multiple
        displayEmpty
        value={adData.gender}
        onChange={handleChangeGender}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Gender</em>;
          }
          return selected.join(", ");
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Gender</em>
        </MenuItem>
        {genders.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select> */}
      {/* <AdTextFieldComponent
        // defaultValue={adData.gender && adData.gender}
        placeholder="Select Gender"
        icon={
          <ExpandMoreOutlinedIcon
            onClick={() => setShowGender(!showGender)}
            style={{ cursor: "pointer" }}
          />
        }
        onClick={() => setShowGender(true)}
      /> */}
      {/* show selected gender options */}
      {/* {adData.gender.length > 0 && <SelectedOptions data={adData.gender} />} */}
      {/* Show genders suggestions section */}
      {/* {showGender && (
        <SelectOptionsComponent
          title={"Gender"}
          data={genders}
          selectedGender={adData.gender}
          handleSelectOption={handleChangeGender}
          handleSelectAll={handleSelectAllGender}
        />
      )} */}

      {/* Ad's interests text field */}
      {/* <Select
        style={textFieldStyles}
        multiple
        displayEmpty
        value={adData.interests}
        onChange={handleChangeInterest}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Interests</em>;
          }
          return selected.join(", ");
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Interests</em>
        </MenuItem>
        {interests.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select> */}
      <AdTextFieldComponent
        placeholder="Select Interests"
        value={interestSearch}
        onChange={(e) => setInterestSearch(e.target.value)}
        onBlur={()=>setShowInterest(false)}
        icon={
          <SearchOutlinedIcon
            onClick={() => setShowInterest(!showInterest)}
            style={{ cursor: "pointer" }}
          />
        }
        onClick={() => setShowInterest(true)}
      />
      {/* show selected gender options */}
      {adData.interests.length > 0 && (
        <SelectedOptions
          interests={interests}
          data={adData.interests}
          interestsDropdown={true}
        />
      )}
      {/* Show genders suggestions section */}
      {showInterest && (
        <SelectOptionsComponent
          title={"Interests"}
          data={tempInterests}
          selectedGender={adData.interests}
          handleSelectOption={handleChangeInterest}
          handleSelectAll={handleSelectAllInterests}
          interestsDropdown={true}
        />
      )}

      {/*Ad's Name text field */}
      <AdTextFieldComponent
        defaultValue={adData.description && adData.description}
        value={adData.description}
        onChange={(e) => setAdData({ ...adData, description: e.target.value })}
        placeholder="Type description"
        multiline={true}
        rows={4}
      />
      {/* Ad's Start and End dates text fields */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          inputFormat="MM/dd/yyyy"
          toolbarPlaceholder={"Start Date"}
          open={openStartDateModal}
          onOpen={handleOpenStartDateModal}
          onClose={handleCloseStartDateModal}
          InputProps={{
            style: { width: 366, borderRadius: 8, marginBottom: 8 },
            endAdornment: (
              <InputAdornment position="end" style={{ justifyContent: " end" }}>
                <IconButton edge="end" onClick={handleOpenStartDateModal}>
                  <InsertInvitationOutlinedIcon fontSize="medium" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={adData.startDate}
          onChange={(newValue) => {
            setAdData({ ...adData, startDate: newValue });
            handleCloseStartDateModal();
          }}
          renderInput={(params) => <TextField {...params} />}
        />

        <MobileDatePicker
          inputFormat="MM/dd/yyyy"
          toolbarPlaceholder={"End Date"}
          open={openEndDateModal}
          onOpen={handleOpenEndDateModal}
          onClose={handleCloseEndDateModal}
          InputProps={{
            style: { width: 366, borderRadius: 8 },
            endAdornment: (
              <InputAdornment position="end" style={{ justifyContent: " end" }}>
                <IconButton edge="end" onClick={handleOpenEndDateModal}>
                  <InsertInvitationOutlinedIcon fontSize="medium" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={adData.endDate}
          onChange={(newValue) => {
            setAdData({ ...adData, endDate: newValue });
            handleCloseEndDateModal();
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      {/**/}
    </Grid>
  );
};

export default AdDetailsSection;

const adInfoTitleStyles = {
  color: "#C84B96",
  fontWeight: 600,
  fontSize: 18,
  marginBottom: 10,
};

const textFieldPropsStyles = {
  fontSize: 16,
  fontWeight: 600,
  //   marginLeft: -10,
};
const textFieldStyles = { width: 366, borderRadius: 8, marginBottom: 8 };
