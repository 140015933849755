import React, { useState } from "react";
import {
  Button,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const AdsFilterMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  classes,
  sortOption,
  handleSortOptionChange,
  filterData,
  setFilterData,
  handleFilter,
}) => {
  return (
    <Menu
      id="filter-menu"
      MenuListProps={{
        "aria-labelledby": "filter-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      TransitionComponent={Fade}
      className={classes.filterCustomWidth}
    >
      <Grid container alignItems="center">
        <Grid item xs={8} style={{ textAlign: "start" }}>
          <span className={classes.sortTitle}>Filter</span>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <CloseIcon
            onClick={handleCloseMenu}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Divider />

      {/* Filter with Business Type Section */}
      <Grid
        container
        style={{ marginTop: 12, paddingLeft: 12, marginBottom: 12 }}
      >
        <span style={{ fontSize: 15, fontWeight: 600 }}>Gender</span>
        <Grid>
          <Button
            variant={filterData == "Winery/Vineyard" ? "contained" : "outlined"}
            style={
              filterData == "Winery/Vineyard"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter("Winery/Vineyard")}
          >
            Winery/Vineyard
          </Button>
          <Button
            variant={filterData == "Restaurant" ? "contained" : "outlined"}
            style={
              filterData == "Restaurant"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter("Restaurant")}
          >
            Restaurant
          </Button>
          <Button
            variant={filterData == "Shop/Outlet" ? "contained" : "outlined"}
            style={
              filterData == "Shop/Outlet"
                ? selectedButtonStyle
                : nonSelectedButtonStyle
            }
            onClick={() => handleFilter("Shop/Outlet")}
          >
            Shop/Outlet
          </Button>

          <Button
            variant={filterData == "All" ? "contained" : "outlined"}
            style={
              filterData == "All" ? selectedButtonStyle : nonSelectedButtonStyle
            }
            onClick={() => handleFilter("All")}
          >
            All
          </Button>
        </Grid>
      </Grid>

      <Divider />
      <RadioGroup value={sortOption} onChange={handleSortOptionChange}>
        <FormControlLabel
          value="Ascending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="Ascending"
        />
        <FormControlLabel
          value="Descending"
          control={<Radio style={{ color: "#C84B96" }} />}
          label="Descending"
        />
      </RadioGroup>
    </Menu>
  );
};

export default AdsFilterMenu;

const selectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  color: "#FFF",
  background: "#C84B96",
};

const nonSelectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  borderColor: "#000",
  color: "#000",
};
