import React from "react";
import { Grid, Avatar, Button } from "@mui/material";
import AdDetailsTextField from "../../../dashboard/ads/AdDetailsTextField";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";

import moment from "moment";
import { baseUrl } from "../../../constants";

const AdsDetails = ({ adData, handleCloseDetails }) => {
  return (
    <Grid container spacing={2} style={{ padding: "0px 55px" }}>
      <Grid item container direction="column" xs={5}>
        <BackspaceOutlinedIcon
          fontSize="medium"
          style={{ margin: 10, cursor: "pointer" }}
          onClick={handleCloseDetails}
        />
        <img
          // src={adData.mediaImage}
          src={`${
            adData.post &&
            adData.post.media &&
            adData.post.media[0] &&
            baseUrl + adData.post.media[0].media
          }`}
          alt="ads image"
          style={{
            width: 400,
            height: 400,
            marginBottom: 20,
            borderRadius: 10,
          }}
        />
        {adData &&
        Math.ceil(new Date(adData.end_date).getTime() - new Date().getTime()) /
          (1000 * 3600 * 24) <
          0 ? (
          <Button
            variant="contained"
            style={{
              width: 260,
              background: "#FFEEEE",
              color: "#FF5655",
              fontSize: 16,
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            Expired
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{
              width: 260,
              background: "#EEFCF8",
              color: "#17DDA2",
              fontSize: 16,
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            {adData &&
              `Active: Day ${Math.ceil(
                (new Date(adData.end_date).getTime() - new Date().getTime()) /
                  (1000 * 3600 * 24)
              )} of ${
                (new Date(adData.end_date).getTime() -
                  new Date(adData.start_date).getTime()) /
                (1000 * 3600 * 24)
              }`}
          </Button>
        )}
      </Grid>
      <Grid item container direction="column" xs={7} style={{ marginTop: 30 }}>
        <AdDetailsTextField
          title={"Budget"}
          value={adData && adData.budget ? adData.budget : "$00"}
        />
        <AdDetailsTextField
          title={"Start Date"}
          value={
            adData &&
            adData.start_date &&
            moment(adData.start_date).format("MMMM DD, YYYY")
          }
        />
        <AdDetailsTextField
          title={"End Date"}
          value={
            adData &&
            adData.end_date &&
            moment(adData.end_date).format("MMMM DD, YYYY")
          }
        />
        <AdDetailsTextField
          title={"Description"}
          value={adData && adData.post && adData.post.caption}
          multiline={true}
          rows={4}
        />
        <AdDetailsTextField
          title={"Website"}
          value={adData && adData.website && adData.website}
        />
      </Grid>
    </Grid>
  );
};

export default AdsDetails;
