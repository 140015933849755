import moment from "moment";

import cardImage1 from "../../images/mediaImages/MediaCard_New1.png";
import cardImage2 from "../../images/mediaImages/MediaCard_New2.png";
import cardImage3 from "../../images/mediaImages/MediaCard_New3.png";
import cardImage4 from "../../images/mediaImages/MediaCard_New4.png";
import cardImage5 from "../../images/mediaImages/MediaCard_New5.png";
import cardImage6 from "../../images/mediaImages/MediaCard_New6.png";
import cardImage7 from "../../images/mediaImages/MediaCard_Old1.png";
import cardImage8 from "../../images/mediaImages/MediaCard_Old2.png";
import cardImage9 from "../../images/mediaImages/MediaCard_Old3.png";
import cardImage10 from "../../images/mediaImages/MediaCard_Old4.png";
import cardImage11 from "../../images/mediaImages/MediaCard_Old5.png";
import cardImage12 from "../../images/mediaImages/MediaCard_Old6.png";
import cardImage13 from "../../images/mediaImages/MediaCard_Old7.png";
import cardImage14 from "../../images/mediaImages/MediaCard_Old8.png";
import cardImage15 from "../../images/mediaImages/MediaCard.png";

import userImage1 from "../../images/users/User_New1.png";
import userImage2 from "../../images/users/User_New2.png";
import userImage3 from "../../images/users/User_New3.png";
import userImage4 from "../../images/users/User_New4.png";
import userImage5 from "../../images/users/User.png";

import quizImage1 from "../../images/quizImages/img1.png";
import quizImage2 from "../../images/quizImages/img2.png";
import quizImage3 from "../../images/quizImages/img3.png";
import quizImage4 from "../../images/quizImages/img4.png";
import quizImage5 from "../../images/quizImages/img5.png";

import wineImage1 from "../../images/wines/wine1.png";
import wineImage2 from "../../images/wines/wine2.png";
import wineImage3 from "../../images/wines/wine3.png";

export const adminEmailTemp = "admin@winelikes.com";
export const adminPasswordTemp = "Abcd@1234";

// export const baseUrl = "https://staging.api.winelikes.com";
export const baseUrl = "https://staging.api.winelikes.com";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};

export const lastWeekFirstDay = moment()
  .subtract(1, "weeks")
  .startOf("isoWeek")
  .format("MMMM D");

export const lastWeekLastDay = moment()
  .subtract(1, "weeks")
  .endOf("isoWeek")
  .format("D, yyyy");

export const lastMonthFirstDay = moment()
  .subtract(1, "months")
  .startOf("month")
  .format("MMMM D");

export const prevSixMonthFirstDay = moment()
  .subtract(6, "months")
  .format("D,MMM, yyyy");

export const today = moment().format("MMMM D, yyyy");

export const lastMonthLastDay = moment()
  .subtract(1, "months")
  .endOf("month")
  .format("D, yyyy");

export const adsData = [
  {
    image: wineImage1,
    adName: "Taquila At",
    website: "TaquilaAt.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date("10-15-2022"),
    endDate: new Date("10-20-2022"),
    clickRate: 2.5,
    id: 0,
    userImage: userImage1,
  },
  {
    image: wineImage2,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date("06-15-2022"),
    endDate: new Date("07-10-2022"),
    clickRate: 3.5,
    id: 1,
    userImage: userImage2,
  },
  {
    image: wineImage3,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date("07-10-2022"),
    endDate: new Date("07-15-2022"),
    clickRate: 1.9,
    id: 2,
    userImage: userImage3,
  },
  {
    image: wineImage1,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date("08-15-2022"),
    endDate: new Date("08-22-2022"),
    clickRate: -3.5,
    id: 3,
    userImage: userImage4,
  },
  {
    image: wineImage2,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date("09-15-2022"),
    endDate: new Date("09-25-2022"),
    clickRate: 2.5,
    id: 4,
    userImage: userImage5,
  },
];

export const expiredAdsData = [
  {
    image: wineImage2,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date(),
    endDate: new Date(),
    clickRate: 3.5,
    id: 0,
  },
  {
    image: wineImage3,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date(),
    endDate: new Date(),
    clickRate: 1.9,
    id: 1,
  },
  {
    image: wineImage2,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date(),
    endDate: new Date(),
    clickRate: 2.5,
    id: 2,
  },
];

export const draftAdsData = [
  {
    image: wineImage2,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date(),
    endDate: new Date(),
    // clickRate: 3.5,
    id: 0,
  },
  {
    image: wineImage2,
    adName: "AESOP",
    website: "aesop.com",
    location: "New Port, CA",
    ageRange: "21-27",
    gender: "male",
    interest: "Rose Wine",
    description: "Description of the ad of the wine.",
    startDate: new Date(),
    endDate: new Date(),
    // clickRate: 2.5,
    id: 1,
  },
];

export const quizzesData = [
  {
    id: 0,
    image: quizImage1,
    title: "Wine Knowledge Quiz",
    participants: 700,
    averageScore: "75%",
    highestScore: "90%",
    lowestScore: "20%",
    questions: [
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter" },
          { label: "B", answer: "Tank method" },
          { label: "C", answer: "Corbonation", correct: true },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1", correct: true },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3", correct: true },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
    ],
    scores: [
      {
        userName: "Jake Stroll",
        email: "jake.stroll@gmail.com",
        score: "95%",
        id: 0,
      },
      {
        userName: "Stroll Addi",
        email: "stroll.addi@gmail.com",
        score: "80%",
        id: 1,
      },
      {
        userName: "Lance sean",
        email: "lance.sean@gmail.com",
        score: "78%",
        id: 2,
      },
      {
        userName: "Jake Wood",
        email: "jake.wood@gmail.com",
        score: "100%",
        id: 3,
      },
      {
        userName: "Woody Stroll",
        email: "woody.stroll@gmail.com",
        score: "89%",
        id: 4,
      },
      {
        userName: "Lance josh",
        email: "lance.josh@gmail.com",
        score: "98%",
        id: 5,
      },
      {
        userName: "Josh Stroll",
        email: "jpsh.stroll@gmail.com",
        score: "100%",
        id: 6,
      },
    ],
  },
  {
    id: 1,
    image: quizImage2,
    title: "Which Wine Are You?",
    participants: 700,
    averageScore: "60%",
    highestScore: "87%",
    lowestScore: "40%",
    questions: [
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter" },
          { label: "B", answer: "Tank method", correct: true },
          { label: "C", answer: "Corbonation" },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1" },
          { label: "C", answer: "Corbonation 2", correct: true },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3", correct: true },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes", correct: true },
          { label: "B", answer: "Tank method 1" },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3" },
          { label: "B", answer: "Tank method 2", correct: true },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
    ],
    scores: [
      {
        userName: "Jake Stroll",
        email: "jake.stroll@gmail.com",
        score: "95%",
        id: 0,
      },
      {
        userName: "Stroll Addi",
        email: "stroll.addi@gmail.com",
        score: "80%",
        id: 1,
      },
      {
        userName: "Lance sean",
        email: "lance.sean@gmail.com",
        score: "78%",
        id: 2,
      },
      {
        userName: "Jake Wood",
        email: "jake.wood@gmail.com",
        score: "100%",
        id: 3,
      },
      {
        userName: "Woody Stroll",
        email: "woody.stroll@gmail.com",
        score: "89%",
        id: 4,
      },
      {
        userName: "Lance josh",
        email: "lance.josh@gmail.com",
        score: "98%",
        id: 5,
      },
      {
        userName: "Josh Stroll",
        email: "jpsh.stroll@gmail.com",
        score: "100%",
        id: 6,
      },
    ],
  },
  {
    id: 2,
    image: quizImage3,
    title: "The Red Wine Quiz",
    participants: 700,
    averageScore: "75%",
    highestScore: "970%",
    lowestScore: "30%",
    questions: [
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter" },
          { label: "B", answer: "Tank method", correct: true },
          { label: "C", answer: "Corbonation" },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1" },
          { label: "C", answer: "Corbonation 2", correct: true },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3", correct: true },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter" },
          { label: "B", answer: "Tank method" },
          { label: "C", answer: "Corbonation", correct: true },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1", correct: true },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3", correct: true },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
    ],
    scores: [
      {
        userName: "Jake Stroll",
        email: "jake.stroll@gmail.com",
        score: "95%",
        id: 0,
      },
      {
        userName: "Stroll Addi",
        email: "stroll.addi@gmail.com",
        score: "80%",
        id: 1,
      },
      {
        userName: "Lance sean",
        email: "lance.sean@gmail.com",
        score: "78%",
        id: 2,
      },
      {
        userName: "Jake Wood",
        email: "jake.wood@gmail.com",
        score: "100%",
        id: 3,
      },
      {
        userName: "Woody Stroll",
        email: "woody.stroll@gmail.com",
        score: "89%",
        id: 4,
      },
      {
        userName: "Lance josh",
        email: "lance.josh@gmail.com",
        score: "98%",
        id: 5,
      },
      {
        userName: "Josh Stroll",
        email: "jpsh.stroll@gmail.com",
        score: "100%",
        id: 6,
      },
    ],
  },
  {
    id: 3,
    image: quizImage4,
    title: "The White Wine Quiz",
    participants: 700,
    averageScore: "67%",
    highestScore: "90%",
    lowestScore: "35%",
    questions: [
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter", correct: true },
          { label: "B", answer: "Tank method" },
          { label: "C", answer: "Corbonation" },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1", correct: true },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3", correct: true },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
    ],
    scores: [
      {
        userName: "Jake Stroll",
        email: "jake.stroll@gmail.com",
        score: "95%",
        id: 0,
      },
      {
        userName: "Stroll Addi",
        email: "stroll.addi@gmail.com",
        score: "80%",
        id: 1,
      },
      {
        userName: "Lance sean",
        email: "lance.sean@gmail.com",
        score: "78%",
        id: 2,
      },
      {
        userName: "Jake Wood",
        email: "jake.wood@gmail.com",
        score: "100%",
        id: 3,
      },
      {
        userName: "Woody Stroll",
        email: "woody.stroll@gmail.com",
        score: "89%",
        id: 4,
      },
      {
        userName: "Lance josh",
        email: "lance.josh@gmail.com",
        score: "98%",
        id: 5,
      },
      {
        userName: "Josh Stroll",
        email: "jpsh.stroll@gmail.com",
        score: "100%",
        id: 6,
      },
    ],
  },
  {
    id: 4,
    image: quizImage5,
    title: "Wine True or False Quiz",
    participants: 700,
    averageScore: "75%",
    highestScore: "99%",
    lowestScore: "40%",
    questions: [
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter" },
          { label: "B", answer: "Tank method" },
          { label: "C", answer: "Corbonation", correct: true },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1", correct: true },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3", correct: true },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3", correct: true },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
    ],
    scores: [
      {
        userName: "Jake Stroll",
        email: "jake.stroll@gmail.com",
        score: "95%",
        id: 0,
      },
      {
        userName: "Stroll Addi",
        email: "stroll.addi@gmail.com",
        score: "80%",
        id: 1,
      },
      {
        userName: "Lance sean",
        email: "lance.sean@gmail.com",
        score: "78%",
        id: 2,
      },
      {
        userName: "Jake Wood",
        email: "jake.wood@gmail.com",
        score: "100%",
        id: 3,
      },
      {
        userName: "Woody Stroll",
        email: "woody.stroll@gmail.com",
        score: "89%",
        id: 4,
      },
      {
        userName: "Lance josh",
        email: "lance.josh@gmail.com",
        score: "98%",
        id: 5,
      },
      {
        userName: "Josh Stroll",
        email: "jpsh.stroll@gmail.com",
        score: "100%",
        id: 6,
      },
    ],
  },
];

export const draftQuizzesData = [
  {
    id: 0,
    image: quizImage1,
    title: "Wine Knowledge Quiz",
    participants: 700,
    averageScore: "70%",
    highestScore: "80%",
    lowestScore: "40%",
    questions: [
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter" },
          { label: "B", answer: "Tank method" },
          { label: "C", answer: "Corbonation" },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1" },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3" },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
    ],
    scores: [
      {
        userName: "Jake Stroll",
        email: "jake.stroll@gmail.com",
        score: "95%",
        id: 0,
      },
      {
        userName: "Stroll Addi",
        email: "stroll.addi@gmail.com",
        score: "80%",
        id: 1,
      },
      {
        userName: "Lance sean",
        email: "lance.sean@gmail.com",
        score: "78%",
        id: 2,
      },
      {
        userName: "Jake Wood",
        email: "jake.wood@gmail.com",
        score: "100%",
        id: 3,
      },
      {
        userName: "Woody Stroll",
        email: "woody.stroll@gmail.com",
        score: "89%",
        id: 4,
      },
      {
        userName: "Lance josh",
        email: "lance.josh@gmail.com",
        score: "98%",
        id: 5,
      },
      {
        userName: "Josh Stroll",
        email: "jpsh.stroll@gmail.com",
        score: "100%",
        id: 6,
      },
    ],
  },
  {
    id: 1,
    image: quizImage2,
    title: "Which Wine Are You?",
    participants: 700,
    averageScore: "75%",
    highestScore: "90%",
    lowestScore: "30%",
    questions: [
      {
        question:
          "1. Which of these is generally the cheapest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter" },
          { label: "B", answer: "Tank method" },
          { label: "C", answer: "Corbonation" },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1" },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3" },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
      {
        question:
          "2. Which of these is generally the best method of making grape wine?",
        answers: [
          { label: "A", answer: "Adding glitter grapes" },
          { label: "B", answer: "Tank method 1" },
          { label: "C", answer: "Corbonation 2" },
        ],
      },
      {
        question:
          "3. Which of these is generally the oldest method of making sparkling wine?",
        answers: [
          { label: "A", answer: "Adding glitter 3" },
          { label: "B", answer: "Tank method 2" },
          { label: "C", answer: "Corbonation 3" },
        ],
      },
    ],
    scores: [
      {
        userName: "Jake Stroll",
        email: "jake.stroll@gmail.com",
        score: "95%",
        id: 0,
      },
      {
        userName: "Stroll Addi",
        email: "stroll.addi@gmail.com",
        score: "80%",
        id: 1,
      },
      {
        userName: "Lance sean",
        email: "lance.sean@gmail.com",
        score: "78%",
        id: 2,
      },
      {
        userName: "Jake Wood",
        email: "jake.wood@gmail.com",
        score: "100%",
        id: 3,
      },
      {
        userName: "Woody Stroll",
        email: "woody.stroll@gmail.com",
        score: "89%",
        id: 4,
      },
      {
        userName: "Lance josh",
        email: "lance.josh@gmail.com",
        score: "98%",
        id: 5,
      },
      {
        userName: "Josh Stroll",
        email: "jpsh.stroll@gmail.com",
        score: "100%",
        id: 6,
      },
    ],
  },
];

export const quizScoresColumns = [
  { id: "name", label: "USERNAME", minWidth: 200 },
  { id: "email", label: "EMAIL", minWidth: 200 },
  {
    id: "score",
    label: "SCORE",
    minWidth: 100,
  },
];
export const wordPointsColumns = [
  { id: "name", label: "USERNAME", minWidth: 200 },
  { id: "email", label: "EMAIL", minWidth: 200 },
  {
    id: "points",
    label: "SCORE",
    minWidth: 100,
  },
];

export const usersAnalyticsArray = [
  {
    title: "Newly Registered Users",
    count: 259,
    percent: 2.5,
    expandable: true,
    link: "/dashboard/registered-users",
  },
  {
    title: "Personal Accounts",
    count: 8,
    percent: 4.5,
    expandable: true,
    link: "/dashboard/users/personal-accounts",
  },
  { title: "Locations Created", count: 15, percent: -1.8, expandable: true },
  {
    title: "Deactivated Users",
    count: 19,
    percent: -1.5,
    expandable: true,
    link: "/dashboard/deactivated-users",
  },
];

export const activityAnalyticsArray = [
  {
    title: "Uploaded Images",
    count: 339,
    percent: 2.5,
  },
  { title: "Uploaded Videos", count: 176, percent: 1.4 },
  { title: "Quizzes Taken", count: 109, percent: 1.4 },
  {
    title: "Ads",
    count: 150,
    percent: -3.5,
    expandable: true,
    link: "/dashboard/ads",
  },
];

export const mostPopularWineArray = [
  { title: "Cabernet Sauvignon", count: 240 },
  { title: "Merlot", count: 220 },
  { title: "Airen", count: 180 },
  { title: "Tempranillo", count: 150 },
];

export const mostPopularBrandsArray = [
  { name: "Penfolds", total_reviews: 240 },
  { name: "Marchesi Antinori", total_reviews: 220 },
  { name: "Louis Roederer", total_reviews: 180 },
  { name: "Harlan Estate", total_reviews: 150 },
];

export const verifiedUsersData = [
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@lance.s",
    true,
    7,
    "Inappropriate Posts"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@lewis.h"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@valtteri.b",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Sergio Perez",
    "sergio.p@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Pierre Gasly",
    "pierre.g@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@pierre.g",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@lewis.h"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@valtteri.b",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Sergio Perez",
    "sergio.p@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Pierre Gasly",
    "pierre.g@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@pierre.g",
    true,
    8,
    "Inappropriate Posts"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@lewis.h",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@valtteri.b"
  ),
  createData(
    "Sergio Perez",
    "sergio.p@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Pierre Gasly",
    "pierre.g@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@pierre.g"
  ),
];

export const deactivatedUsersData = [
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@lewis.h",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@valtteri.b",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Sergio Perez",
    "sergio.p@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Pierre Gasly",
    "pierre.g@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@pierre.g"
  ),
  createData(
    "Max Verstappen",
    "max.v@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lance.s",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Novice",
    "Costa Mesa",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lewis.h",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@valtteri.b"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Max Verstappen",
    "max.v@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@pierre.g"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Novice",
    "Costa Mesa",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@lewis.h"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@valtteri.b"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@pierre.g"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lance.s"
  ),
];

export const registeredUsersData = [
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@lance.s",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@lewis.h",
    true,
    8,
    "Inappropriate Posts"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@valtteri.b"
  ),
  createData(
    "Sergio Perez",
    "sergio.p@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@sergia.p",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Pierre Gasly",
    "pierre.g@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@pierre.g"
  ),
  createData(
    "Max Verstappen",
    "max.v@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Novice",
    "Costa Mesa",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lewis.h"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@valtteri.b",
    true,
    8,
    "Harrasment"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Max Verstappen",
    "max.v@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@pierre.g"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Novice",
    "Costa Mesa",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Intermediate",
    "Newport",
    "California",
    "92627",
    "@lewis.h"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@valtteri.b"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@pierre.g"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lance.s"
  ),
  createData(
    "Lance Stroll",
    "lance.s@gmail.com",
    "Novice",
    "Newport",
    "California",
    "92627",
    "@sergia.p"
  ),
  createData(
    "Lewis Hamilton",
    "lewis.h@gmail.com",
    "Intermediate",
    "Costa Mesa",
    "California",
    "92627",
    "@pierre.g"
  ),
  createData(
    "Valtteri Bottas",
    "valtteri.b@gmail.com",
    "Intermediate",
    "Irvine",
    "California",
    "92627",
    "@lance.s"
  ),
];

function createData(
  name,
  email,
  knowledge_level,
  city,
  state,
  zipcode,
  username,
  suspended,
  suspendedDays,
  suspensionReason
) {
  return {
    name,
    email,
    knowledge_level,
    city,
    state,
    zipcode,
    username,
    suspended,
    suspendedDays,
    suspensionReason,
  };
}

export const registeredUsersColumns = [
  { id: "name", label: "NAME", minWidth: 170 },
  { id: "email", label: "EMAIL", minWidth: 170 },
  {
    id: "knowledge_level",
    label: "EXPERIENCE",
    minWidth: 100,
  },
  {
    id: "city",
    label: "CITY",
    minWidth: 170,
  },
  {
    id: "state",
    label: "STATE, ZIP CODE",
    minWidth: 170,
  },
  {
    id: "last_login",
    label: "LAST LOGIN",
    minWidth: 170,
  },
];

export const usersHeader = [
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Profile Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Username", key: "username" },
  { label: "Suspended", key: "suspended" },
  { label: "Suspended Days", key: "suspendedDays" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip Code", key: "zipcode" },
  { label: "Last Login", key: "last_login" },
];

export const businessData = [
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Costa Mesa",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Irvine",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Shop/Outlet",
    "Irvine",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Costa Mesa",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Irvine",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Restaurant",
    "Newport",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Restaurant",
    "Newport",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Costa Mesa",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Shop/Outlet",
    "Irvine",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Costa Mesa",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Irvine",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Restaurant",
    "Newport",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Restaurant",
    "Newport",
    "California",
    "92627"
  ),
  createBusinessData(
    "Semi Tropic Wines",
    "semitropic@gmail.com",
    "Winery/Vineyard",
    "Costa Mesa",
    "California",
    "92627"
  ),
];

function createBusinessData(name, email, type, city, state, zipcode) {
  return {
    name,
    email,
    type,
    city,
    state,
    zipcode,
  };
}

export const businessColumns = [
  { id: "name", label: "BUSINESS NAME", minWidth: 170 },
  { id: "email", label: "EMAIL", minWidth: 100 },
  {
    id: "business_type",
    label: "TYPE",
    minWidth: 170,
  },
  {
    id: "city",
    label: "CITY",
    minWidth: 170,
  },
  {
    id: "state",
    label: "STATE, ZIP CODE",
    minWidth: 170,
  },
  {
    id: "last_login",
    label: "LAST LOGIN",
    minWidth: 170,
  },
];

export const businessHeader = [
  { label: "Business Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Type", key: "type" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip Code", key: "zipCode" },
  { label: "Last Login", key: "last_login" },
];

export const winesData = [
  createWineData("Naccams Dulce", "Cabernet Sauvignon", "California", 1961, 0),
  createWineData("Giner Rosado", "Merlot", "Italy", 1959, 1),
  createWineData("Jalavel Classic", "Pinot noir", "Spain", 1955, 2),
  createWineData("Cheval Adega", "Chardonnay", "France", 1960, 3),
  createWineData("Greille Secco", "White Zinfandel", "California", 1961, 4),
  createWineData("Ival Piquant", "Riesling", "Washington", 1945, 5),
  createWineData("Jenots Blanc", "Blanc de Noirs", "Oregon", 1939, 6),
  createWineData("Mullo Sec", "Cabernet Franc", "Italy", 1953, 7),
  createWineData("Tocano Classic", "Châteauneuf-du-Pape", "France", 1950, 8),
  createWineData("Giner Dulce", "Merlot 2nd", "Germany", 1965, 9),
  createWineData("Mullo Giner", "Chardonnay 2nd", "England", 1976, 10),
  createWineData("Jenots Giner", "Riesling 2nd", "Fineland", 1930, 11),
];

function createWineData(name, varietal, location, vintage, id) {
  return { name, varietal, location, vintage, id };
}

export const winesColumns = [
  { id: "name", label: "NAME", minWidth: 200 },
  { id: "varietal", label: "VARIETAL", minWidth: 200 },
  {
    id: "region",
    label: "LOCATION",
    minWidth: 170,
  },
  {
    id: "vintage",
    label: "VINTAGE",
    minWidth: 100,
  },
];

export const WinesHeader = [
  { label: "Wine Name", key: "name" },
  { label: "Varietal", key: "varietal" },
  { label: "Location", key: "location" },
  { label: "Vintage", key: "vintage" },
];

export const usersReviewsData = [
  {
    userName: "David Belly",
    username: "@davidbelly12",
    reviewTitle: "Wine Review of David",
    reviewDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna tristique integer aliquam sed in. Pharetra vel amet ultrices nulla libero, massa. Sodales odio eget a viverra ut imperdiet quisque eget. ",
    date: new Date("06-17-2022"),
    rating: 5,
    id: 0,
  },
  {
    userName: "David Ahmad",
    username: "@ahmad824",
    reviewTitle: "Wine Review of Ahmad",
    reviewDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna tristique integer aliquam sed in.",
    date: new Date("06-17-2022"),
    rating: 4,
    id: 1,
  },
  {
    userName: "David Belly",
    username: "@davidbelly12",
    reviewTitle: "Wine Review of David",
    // reviewDescription: "",
    date: new Date("06-17-2022"),
    rating: 5,
    id: 2,
  },
];

export const foodPairingsData = [
  {
    food: "Burrata",
    type: "Cheese",
    pairings: 5,
    id: 0,
  },
  {
    food: "Pizza",
    type: "Bread",
    pairings: 4,
    id: 1,
  },
  {
    food: "Burrata Milo",
    type: "Cheese",
    pairings: 4,
    id: 2,
  },
  {
    food: "Large Burger",
    type: "Burger",
    pairings: 5,
    id: 3,
  },
  {
    food: "Burrata 2",
    type: "Cheese",
    pairings: 5,
    id: 4,
  },
  {
    food: "Burrata 4",
    type: "Cheese",
    pairings: 3,
    id: 5,
  },
  {
    food: "Burrata 6",
    type: "Cheese",
    pairings: 5,
    id: 6,
  },
  {
    food: "Burrata",
    type: "Cheese",
    pairings: 2,
    id: 7,
  },
  {
    food: "Burrata",
    type: "Cheese",
    pairings: 5,
    id: 8,
  },
  {
    food: "Burrata",
    type: "Cheese",
    pairings: 5,
    id: 9,
  },
];

export const foodPairingsColumns = [
  { id: "food", label: "FOOD", minWidth: 170 },
  { id: "type", label: "TYPE", minWidth: 170 },
  {
    id: "pairings",
    label: "PAIRINGS",
    minWidth: 170,
  },
];

export const adminsData = [
  createAdminData(
    "Heather Freedman",
    "heather.f@winelikes.com",
    "Super Admin",
    1
  ),
  createAdminData("Jeff Gillis", "jeff.g@winelikes.com", "Super Admin", 2),
  createAdminData("Jack Sparrow", "jack.s@winelikes.com", "Admin", 3),
];

function createAdminData(name, email, role, id) {
  return { name, email, role, id };
}

export const adminsColumns = [
  { id: "name", label: "NAME", minWidth: 170 },
  { id: "email", label: "EMAIL ADDRESS", minWidth: 170 },
  {
    id: "role",
    label: "ROLE",
    minWidth: 100,
  },
  {
    id: "last_login",
    label: "LAST LOGIN",
    minWidth: 170,
  },
];

export const businessUsersAdsData = [
  {
    id: 1,
    mediaImage: cardImage1,
    budget: 200,
    start_date: new Date("10-15-2022"),
    end_date: new Date("11-15-2022"),
    website: "winelikes.com",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 2,
    mediaImage: cardImage2,
    budget: 100,
    start_date: new Date("10-15-2022"),
    end_date: new Date("11-05-2022"),
    website: "alinktotheexperienceinapp.com",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 3,
    mediaImage: cardImage3,
    budget: 170,
    start_date: new Date("10-15-2022"),
    end_date: new Date("11-20-2022"),
    website: "wine.com",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
];

export const uploadedMediaData = [
  {
    id: 1,
    mediaImage: cardImage1,
    userImage: userImage1,
    postedBy: "Elliot Smith",
    uploadedOn: new Date(),
    taggedUsers: ["@emmag", "@teely12", "@chopdawg"],
    experience: "Intermediate",
    userName: "@elliomit",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 2,
    mediaImage: cardImage2,
    userImage: userImage1,
    postedBy: "Jason William",
    uploadedOn: new Date(),
    taggedUsers: ["@smith23", "@teely12", "@chopdawg456"],
    experience: "Intermediate",
    userName: "@jasonwill",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 3,
    mediaImage: cardImage3,
    userImage: userImage1,
    postedBy: "Sean William",
    uploadedOn: new Date(),
    taggedUsers: ["@smith567", "@teely1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@seanp",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 4,
    mediaImage: cardImage4,
    userImage: userImage2,
    postedBy: "Brandon Jack",
    uploadedOn: new Date(),
    taggedUsers: ["@jack7", "@bandon1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@brandon",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 5,
    mediaImage: cardImage5,
    userImage: userImage2,
    postedBy: "Madaline Obe",
    uploadedOn: new Date(),
    taggedUsers: ["@obe567", "@madely1452", "@chopdawg645"],
    experience: "Intermediate",
    userName: "@madaline12",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 6,
    mediaImage: cardImage6,
    userImage: userImage2,
    postedBy: "kaitlyn Eaton",
    uploadedOn: new Date(),
    taggedUsers: ["@eaton567", "@kaitlyn452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@keaton",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 7,
    mediaImage: cardImage7,
    userImage: userImage3,
    postedBy: "Caroline Thomas",
    uploadedOn: new Date(),
    taggedUsers: ["@thomas567", "@teely1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@carolineT",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 8,
    mediaImage: cardImage8,
    userImage: userImage3,
    postedBy: "Sean William",
    uploadedOn: new Date(),
    taggedUsers: ["@smith567", "@teely1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@seanp",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 9,
    mediaImage: cardImage9,
    userImage: userImage3,
    postedBy: "Caroline Thomason",
    uploadedOn: new Date(),
    taggedUsers: ["@thomas567", "@teely1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@carolineTson",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 10,
    mediaImage: cardImage10,
    userImage: userImage4,
    postedBy: "Sean Paul",
    uploadedOn: new Date(),
    taggedUsers: ["@smith567", "@teely1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@seanpaul",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 11,
    mediaImage: cardImage11,
    userImage: userImage4,
    postedBy: "Jack William",
    uploadedOn: new Date(),
    taggedUsers: ["@jack234", "@lily1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@jack1",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 12,
    mediaImage: cardImage12,
    userImage: userImage4,
    postedBy: "Peter Williamson",
    uploadedOn: new Date(),
    taggedUsers: ["@peter567", "@will1452", "@chopdawg65"],
    experience: "Intermediate",
    userName: "@paterwill",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 13,
    mediaImage: cardImage13,
    userImage: userImage5,
    postedBy: "kaitlyn Eaton",
    uploadedOn: new Date(),
    taggedUsers: ["@eaton567", "@kaitlyn452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@keaton",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 14,
    mediaImage: cardImage14,
    userImage: userImage5,
    postedBy: "Madaline Obe",
    uploadedOn: new Date(),
    taggedUsers: ["@obe567", "@madely1452", "@chopdawg645"],
    experience: "Intermediate",
    userName: "@madaline12",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
  {
    id: 15,
    mediaImage: cardImage15,
    userImage: userImage5,
    postedBy: "Brandon Jack",
    uploadedOn: new Date(),
    taggedUsers: ["@jack7", "@bandon1452", "@chopdawg6"],
    experience: "Intermediate",
    userName: "@brandon",
    link: "@alinktotheexperienceinapp",
    mediaCaption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
  },
];

export const reportsColumns = [
  { id: "requester", label: "REQUESTER", minWidth: 170 },
  { id: "reason", label: "SUPPORT REASON", minWidth: 170 },
  { id: "requestedDate", label: "REQUESTED DATE", minWidth: 170 },
  { id: "reportedUser", label: "REPORTED USERNAME", minWidth: 170 },
];

export const ReportsHeader = [
  { label: "Requester", key: "requester" },
  { label: "Support Reason", key: "reason" },
  { label: "Requested Date", key: "requestedDate" },
  { label: "Reported Username", key: "reportedUsername" },
  // { label: "Reported #", key: "reportedNumbers" },
  { label: "Report Status", key: "reportStatus" },
];

export const reportsData = [
  {
    id: 1,
    requester: "Katie Greene",
    reason: "Harrasment",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 2,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 2,
    requester: "Katie Greene",
    reason: "Bullying",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 4,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 3,
    requester: "Katie Greene",
    reason: "Inappropriate Content",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 3,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 4,
    requester: "Katie Greene",
    reason: "Minor Account",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 1,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 5,
    requester: "Katie Greene",
    reason: "Other...",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 1,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
      },
    ],
    replys: [],
  },
  {
    id: 6,
    requester: "Katie Greene",
    reason: "Harrasment",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 3,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 7,
    requester: "Katie Greene",
    reason: "Bullying",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 4,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris",
      },
    ],
    replys: [],
  },
  {
    id: 8,
    requester: "Katie Greene",
    reason: "Other...",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 1,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 9,
    requester: "Katie Greene",
    reason: "Harrasment",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 2,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 10,
    requester: "Katie Greene",
    reason: "Inappropriate Content",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 3,
    reportStatus: "unresolved",
    messages: [
      {
        date: new Date(),
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
];

export const resolvedReportsData = [
  {
    id: 1,
    requester: "Anthony Davis",
    reason: "Harrasment",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@tipsydude",
    name: "thespydude",
    reportedNumbers: 2,
    reportStatus: "resolved",
    messages: [
      {
        date: new Date(),
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 2,
    requester: "Katie Greene",
    reason: "Bullying",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@tipsydude",
    name: "thespydude",
    reportedNumbers: 4,
    reportStatus: "resolved",
    messages: [
      {
        date: new Date(),
        message:
          "Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 3,
    requester: "Anthony Davis",
    reason: "Inappropriate Content",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 3,
    reportStatus: "resolved",
    messages: [
      {
        date: new Date(),
        message:
          "aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 4,
    requester: "Adam Greene",
    reason: "Minor Account",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@ADthedude",
    name: "ADthedude",
    reportedNumbers: 1,
    reportStatus: "resolved",
    messages: [
      {
        date: new Date(),
        message:
          "Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
      },
    ],
    replys: [],
  },
  {
    id: 5,
    requester: "Katie Davis",
    reason: "Other...",
    requestedDate: moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    reportedUsername: "@tipsydude",
    name: "thespydude",
    reportedNumbers: 1,
    reportStatus: "resolved",
    messages: [
      {
        date: new Date(),
        message:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque.",
      },
    ],
    replys: [],
  },
];

const createCertificationData = (
  id,
  name,
  email,
  username,
  date,
  status,
  statement,
  links
) => {
  return { id, name, email, username, date, status, statement, links };
};

export const certificationsHeader = [
  // { label: "First Name", key: "firstName" },
  // { label: "Last Name", key: "lastName" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Username", key: "username" },
  // { label: "Date", key: "date" },
  { label: "Status", key: "status" },
  { label: "Statement", key: "statement" },
];

export const certificationData = [
  createCertificationData(
    1,
    "Elliot Smith",
    "elliot@hello.com",
    "@elliesmith98",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Not Started",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    2,
    "Smith Elliot",
    "smith@hello.com",
    "@elliot346",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Not Started",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    3,
    "Katie Greene",
    "katie@hello.com",
    "@katie234",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Verified",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    4,
    "Smith Elliot",
    "smith@hello.com",
    "@elliot346",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Verified",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    5,
    "Smith Elliot",
    "smith@hello.com",
    "@elliot346",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Not Started",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    6,
    "Smith Elliot",
    "smith@hello.com",
    "@elliot346",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Denied",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    7,
    "Smith Elliot",
    "smith@hello.com",
    "@elliot346",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Denied",

    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    8,
    "Smith Elliot",
    "smith@hello.com",
    "@elliot346",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Not Started",

    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    9,
    "Smith Elliot",
    "smith@hello.com",
    "@elliot346",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Verified",

    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    10,
    "Adam Smith",
    "adam@hello.com",
    "@adams456",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Verified",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    11,
    "Will Smith",
    "will@hello.com",
    "@smith765",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Not Started",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
  createCertificationData(
    12,
    "Jack Adam",
    "adam@hello.com",
    "@jack564",
    moment(new Date("July 28, 2021")).format("MMMM DD, YYYY"),
    "Denied",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci in eu tortor aliquam id augue at scelerisque. Arcu justo, id sed mauris, fusce nunc. Duis in vel pellentesque vitae arcu.",
    ["emmag.instagram.com", "emmag.twitter.com", "freshfloraldesign.com"]
  ),
];

export const certificationsColumns = [
  { id: "name", label: "NAME", minWidth: 170 },
  { id: "date", label: "DATE", minWidth: 170 },
  { id: "email", label: "EMAIL", minWidth: 170 },
  { id: "username", label: "USERNAME", minWidth: 170 },
  { id: "certificate_doc", label: "CERTIFICATION DOCUMENT", minWidth: 170 },
  { id: "status", label: "STATUS", minWidth: 170 },
];
