import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAllUsers,
  getBusinessUsers,
  getPersonalUsers,
  getSuspendUsers,
  getUserCertificates,
} from "../apis/users";
import { useAuth } from "./AuthContext";

export const UsersContext = createContext();
export const useUsersContext = () => useContext(UsersContext);

export const UsersProvider = ({ children }) => {
  const { userToken } = useAuth();
  const [users, setUsers] = useState([]);
  const [personalUsers, setPersonalUsers] = useState([]);
  const [businessUsers, setBusinessUsers] = useState([]);
  const [deactivatedUsers, setDeactivatedUsers] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const [totalUsersCurrentPage, setTotalUsersCurrentPage] = useState(1);
  const [personalUsersCurrentPage, setPersonalUsersCurrentPage] = useState(1);
  const [businessUsersCurrentPage, setBusinessUsersCurrentPage] = useState(1);

  const [totalUsersRowsPage, setTotlalUsersRowPage] = useState(10);
  const [personalUsersRowsPage, setPersonalUsersRowPage] = useState(10);
  const [businessUsersRowsPage, setBusinessUsersRowPage] = useState(10);

  const [loading, setLoading] = useState(false);
  const [totalUsersTableDetails, setTotalUsersTableDetails] = useState({
    current_page: 1,
    total: 0,
  });
  const [businessUsersTableDetails, setBusinessUsersTableDetails] = useState({
    current_page: 1,
    total: 0,
  });
  const [personalUsersTableDetails, setPersonalUsersTableDetails] = useState({
    current_page: 1,
    total: 0,
  });

  useEffect(() => {
    handleGetSuspendedUsers();
    handleGetCertificates();
  }, [userToken]);
  useEffect(() => {
    setLoading(true);
    handleGetAllUsers(totalUsersRowsPage);
  }, [userToken, totalUsersCurrentPage]);
  useEffect(() => {
    setLoading(true);
    handleGetPersonalUsers(personalUsersRowsPage);
  }, [userToken, personalUsersCurrentPage]);
  useEffect(() => {
    setLoading(true);
    handleGetBusinessUsers(businessUsersRowsPage);
  }, [userToken, businessUsersCurrentPage]);

  const handleGetAllUsers = async (limit) => {
    try {
      let docsTemp = await getAllUsers(totalUsersCurrentPage, limit ? limit : 10);
      setUsers(docsTemp.data);
      setTotalUsersTableDetails(docsTemp);

      // let personalUsersTemp = [];
      // let businessUsersTemp = [];
      // let allUsersTemp = [];
      // setUsers(docsTemp);

      // docsTemp.forEach((el) => {
      //   allUsersTemp.push(el);
      //   if (el.account_type == "Personal") {
      //     personalUsersTemp.push(el);
      //   } else if (el.account_type == "Business") {
      //     businessUsersTemp.push(el);
      //   }
      // });
      // setPersonalUsers(personalUsersTemp);
      // setBusinessUsers(businessUsersTemp);

      // setUsers(allUsersTemp);
      // console.log("personal users:", personalUsersTemp);
      // console.log("Business Users:", businessUsersTemp);
    } catch (error) {
      console.log("error getting Users:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetPersonalUsers = async (limit) => {
    try {
      let docsTemp = await getPersonalUsers(personalUsersCurrentPage, limit ? limit : 10);
      console.log({ docsTemp });
      setPersonalUsers(docsTemp.data);
      setPersonalUsersTableDetails(docsTemp);
      console.log("personal Users:", docsTemp);
    } catch (error) {
      console.log("error getting personal Users:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleGetBusinessUsers = async (limit) => {
    try {
      let docsTemp = await getBusinessUsers(businessUsersCurrentPage, limit ? limit : 10);
      setBusinessUsers(docsTemp.data);
      setBusinessUsersTableDetails(docsTemp);
    } catch (error) {
      console.log("error getting personal Users:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleGetSuspendedUsers = async () => {
    try {
      let docsTemp = await getSuspendUsers();
      setDeactivatedUsers(docsTemp);
      // console.log("deactivated/suspended Users:", docsTemp);
    } catch (error) {
      console.log("error getting suspended Users:", error.message);
    }
  };
  const handleGetCertificates = async () => {
    try {
      let docsTemp = await getUserCertificates();
      setCertificates(docsTemp);
      // console.log("Users Certificates:", docsTemp);
    } catch (error) {
      console.log("error getting suspended Users:", error.message);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers,
        personalUsers,
        setPersonalUsers,
        handleGetAllUsers,
        handleGetPersonalUsers,
        handleGetBusinessUsers,
        businessUsers,
        setBusinessUsers,
        deactivatedUsers,
        setDeactivatedUsers,
        handleGetSuspendedUsers,
        certificates,
        setCertificates,
        handleGetCertificates,
        //paginations params
        totalUsersCurrentPage,
        setTotalUsersCurrentPage,
        personalUsersCurrentPage,
        setPersonalUsersCurrentPage,
        businessUsersCurrentPage,
        setBusinessUsersCurrentPage,
        totalUsersRowsPage,
        setTotlalUsersRowPage,
        personalUsersRowsPage,
        setPersonalUsersRowPage,
        businessUsersRowsPage,
        setBusinessUsersRowPage,
        loading,
        setLoading,
        totalUsersTableDetails,
        setTotalUsersTableDetails,
        businessUsersTableDetails,
        setBusinessUsersTableDetails,
        personalUsersTableDetails,
        setPersonalUsersTableDetails,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
