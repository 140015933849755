import React from "react";
import { Button, Grid, Toolbar } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const MenuItemsHeader = ({ handleModalOpen, goBack, menuData }) => {
  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid item xs>
          <ArrowBackIosIcon style={{ marginTop: 10 }} onClick={goBack} />
        </Grid>
        <Grid item xs>
          <span
            style={{
              color: "#313137",
              fontWeight: 700,
              fontSize: 36,
              marginTop: -5,
            }}
          >
            {menuData && menuData.name && menuData.name}
          </span>
        </Grid>
        <Grid item xs style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            onClick={handleModalOpen}
            style={addButtonStyles}
          >
            Add Menu Item
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default MenuItemsHeader;

const addButtonStyles = {
  color: "#fff",
  background: "#C84B96",
  borderRadius: 12,
  fontWeight: 600,
  textTransform: "capitalize",
  fontSize: 15,
  height: 50,
};
