import React, { useState } from "react";
import { Button, Grid, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { modalStyle } from "../../constants";
import ButtonLoader from "../../ButtonLoader";
import { updateQuiz } from "../../../apis/quizzes";
import { Toast } from "../../alert";
import { useNavigate } from "react-router-dom";
import { useQuizContext } from "../../../context/QuizContext";

const UpdateQuizTitleModal = ({
  adminModal,
  handleClose,
  title,
  adminData,
  setAdminData,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { handleGetAllQuizzes } = useQuizContext();

  const handleUpdateQuiz = async () => {
    const updatedQuiz = {...adminData};
    updatedQuiz["_method"] ='PUT';
    try {
      setLoading(true);

      let adminTemp = await updateQuiz(updatedQuiz, adminData.id);
      if (adminTemp) {
        await Toast("success", "Quiz Updated Successfully.");
        await handleGetAllQuizzes(1);
        await handleClose();
        navigate(-1);
      }
    } catch (error) {
      console.log("error updating Quiz:", error.message);
      Toast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log("Admin Data>>>", adminData);
  return (
    <Modal open={adminModal} onClose={handleClose}>
      <Box sx={modalStyle}>
        <span
          style={{
            color: "#313137",
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 30,
          }}
        >
          {title}
        </span>
        <TextField
          fullWidth
          label="Quiz Title"
          placeholder="Quiz Title"
          variant="outlined"
          value={adminData?.name}
          sx={{ marginTop: "20px" }}
          onChange={(e) => setAdminData({ ...adminData, name: e.target.value })}
          inputProps={{
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <TextField
          fullWidth
          label="Quiz Order"
          placeholder="Quiz Order"
          variant="outlined"
          value={adminData?.order}
          type="number"
          sx={{ marginTop: "20px" }}
          onChange={(e) =>
            setAdminData({ ...adminData, order: e.target.value })
          }
          inputProps={{
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <Grid container style={{ marginTop: "30px" }}>
          {loading ? (
            <Grid
              container
              style={{ width: 200 }}
              alignItems="center"
              justifyContent="center"
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleUpdateQuiz}
              style={{
                ...confirmButton,
                background: "#C84B96",
                color: "#FFFFFF",
              }}
            >
              {title}
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={{
              width: "130px",
              marginLeft: "auto",
              height: 55,
              fontSize: 15,
              fontWeight: 600,
              color: "#313137",
              borderColor: "#313137",
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UpdateQuizTitleModal;

const confirmButton = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
};
