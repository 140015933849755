import { API } from "../constants";
import { request } from "../constants/request";

export const getFoodRecipes = (menu_id) => {
  return request({
    url: API.baseUrl + API.recipesMenu + `/${menu_id}`,
    method: "GET",
  });
};
export const getByIdFoodRecipes = (recipesId) => {
    return request({
      url: API.baseUrl + API.saveRecipes + `/${recipesId}`,
      method: "GET",
    });
  };
export const addFoodRecipes = (data) => {
  return request({
    url: API.baseUrl + API.saveRecipes,
    method: "POST",
    data: data,
  });
};
export const editFoodRecipes = (data, recipesId) => {
  return request({
    url: API.baseUrl + API.saveRecipes + `/${recipesId}`,
    method: "POST",
    data: data,
  });
};
export const deleteFoodRecipes = (recipesId) => {
  return request({
    url: API.baseUrl + API.saveRecipes + `/${recipesId}`,
    method: "DELETE",
  });
};