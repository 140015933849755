import { API } from "../constants";
import { request } from "../constants/request";

import moment from "moment";

export const getAllAds = () => {
  return request({
    url: API.baseUrl + API.getCurrentAds,
    method: "GET",
  });
};
// export const getAllAds = () => {
//   return request({
//     url:
//       API.baseUrl +
//       API.getAds +
//       `?filter[end_date][min]=${moment(new Date()).format("YYYY-MM-DD")}`,
//     method: "GET",
//   });
// };

export const getTotalAds = () => {
  return request({
    url: API.baseUrl + API.getAds,
    method: "GET",
  });
};
export const deleteAd = (adId, data) => {
  return request({
    url: API.baseUrl + API.getAds + `/${adId}`,
    method: "POST",
    data: data,
  });
};
export const deleteWineReview = (id) => {
  return request({
    url: `${API.baseUrl}${API.getWineReview}/${id}`,
    method: "POST",
    data: { _method: "DELETE" },
  });
};

export const getExpiredAds = () => {
  return request({
    url:
      API.baseUrl +
      API.getAds +
      `?filter[end_date][max]=${moment(new Date()).format("YYYY-MM-DD")}`,
    method: "GET",
  });
};

export const addInterest = (inetrestData) => {
  return request({
    url: API.baseUrl + API.getInterests,
    method: "POST",
    data: inetrestData,
  });
};

export const getInterest = () => {
  return request({
    url: API.baseUrl + API.getInterests,
    method: "GET",
  });
};

export const addNewAd = (adData) => {
  return request({
    url: API.baseUrl + API.getAds,
    method: "POST",
    data: adData,
  });
};
