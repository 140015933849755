import React, { useState } from "react";
import {
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  lastMonthFirstDay,
  lastMonthLastDay,
  lastWeekFirstDay,
  lastWeekLastDay,
  today,
} from "../../constants";
import moment from "moment";

const SortQuizMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  classes,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setDropdownButtonText,
  handleFilter,
  handleDateRangeData,
}) => {
  const [openStartDateModal, setOpenStartDateModal] = useState(false);
  const [openEndDateModal, setOpenEndDateModal] = useState(false);
  const [customRange, setCustomRange] = useState(false);

  const handleOpenStartDateModal = () => setOpenStartDateModal(true);
  const handleCloseStartDateModal = () => setOpenStartDateModal(false);
  const handleOpenEndDateModal = () => setOpenEndDateModal(true);
  const handleCloseEndDateModal = () => setOpenEndDateModal(false);
  const handleOpenCustomRange = () => setCustomRange(true);
  const handleCloseCustomRange = () => setCustomRange(false);

  return (
    <Menu
      id="sort-menu"
      MenuListProps={{
        "aria-labelledby": "sort-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      TransitionComponent={Fade}
      className={classes.customWidth}
    >
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <span className={classes.sortTitle}>Sort By</span>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <CloseIcon
            onClick={handleCloseMenu}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Divider />
      <MenuItem
        onClick={() => {
          setDropdownButtonText("All");
          setStartDate(null);
          setEndDate(null);
          handleCloseCustomRange();
          handleCloseMenu();
          handleFilter("All");
        }}
      >
        <span className={classes.sortItems}>All</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setDropdownButtonText("Today");
          setStartDate(null);
          setEndDate(null);
          handleCloseCustomRange();
          handleCloseMenu();
          handleFilter("Today");
        }}
      >
        <span className={classes.sortItems}>
          Today <a style={{ color: "#90909A", marginLeft: 8 }}>{today}</a>
        </span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setDropdownButtonText(lastWeekFirstDay + "-" + lastWeekLastDay);
          setStartDate(null);
          setEndDate(null);
          handleCloseCustomRange();
          handleCloseMenu();
          handleFilter("Last Week");
        }}
      >
        <span className={classes.sortItems}>
          Last Week{" "}
          <a style={{ color: "#90909A", marginLeft: 8 }}>
            {lastWeekFirstDay + "-" + lastWeekLastDay}
          </a>
        </span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setDropdownButtonText(lastMonthFirstDay + "-" + lastMonthLastDay);
          setStartDate(null);
          setEndDate(null);
          handleCloseCustomRange();
          handleCloseMenu();
          handleFilter("Last Month");
        }}
      >
        <span className={classes.sortItems}>
          Last Month{" "}
          <a style={{ color: "#90909A", marginLeft: 8 }}>
            {lastMonthFirstDay + "-" + lastMonthLastDay}
          </a>
        </span>
      </MenuItem>
      <MenuItem
        onClick={() => setCustomRange(!customRange)}
        style={{ marginBottom: 10 }}
      >
        <span className={classes.sortItems}>Custome Range</span>
      </MenuItem>

      {/*
       */}
      {customRange && (
        <div style={{ marginBottom: 20, marginTop: 10 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <span className={classes.sortItems} style={{ marginLeft: 10 }}>
              Start Date
            </span>
            <MobileDatePicker
              inputFormat="MM/dd/yyyy"
              open={openStartDateModal}
              onOpen={handleOpenStartDateModal}
              onClose={handleCloseStartDateModal}
              InputProps={{
                style: { width: 255, borderRadius: 10, marginBottom: 15 },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ justifyContent: " end" }}
                  >
                    <IconButton edge="end" onClick={handleOpenStartDateModal}>
                      <ArrowForwardIosIcon
                        style={{ width: 16, marginRight: 5 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={startDate}
              onChange={(newValue) => {
                // console.log("Start Date:", newValue);
                setStartDate(newValue);
                if (startDate || endDate) {
                  handleDateRangeData(newValue, endDate);
                  setDropdownButtonText(
                    moment(newValue).format("MMMM D") +
                      "-" +
                      moment(endDate).format("D, YYYY")
                  );
                  // handleCloseStartDateModal();
                  // handleCloseMenu();
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <span
              className={classes.sortItems}
              style={{ marginLeft: 10, marginTop: 10 }}
            >
              End Date
            </span>
            <MobileDatePicker
              inputFormat="MM/dd/yyyy"
              open={openEndDateModal}
              onOpen={handleOpenEndDateModal}
              onClose={handleCloseEndDateModal}
              InputProps={{
                style: { width: 255, borderRadius: 10 },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ justifyContent: " end" }}
                  >
                    <IconButton edge="end" onClick={handleOpenEndDateModal}>
                      <ArrowForwardIosIcon
                        style={{ width: 16, marginRight: 5 }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={endDate}
              onChange={(newValue) => {
                // console.log("End Date:", newValue);
                setEndDate(newValue);
                if (startDate || endDate) {
                  handleDateRangeData(startDate, newValue);
                  setDropdownButtonText(
                    moment(startDate).format("MMMM D") +
                      "-" +
                      moment(newValue).format("MMMM D, YYYY")
                  );
                  // handleCloseEndDateModal();
                  // handleCloseMenu();
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      )}
    </Menu>
  );
};

export default SortQuizMenu;
