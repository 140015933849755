import { Grid } from "@mui/material";
import React, { useState } from "react";
import QuizCard from "./QuizCard";
import { Toast } from "../../components/alert";
import { getAllQuizzes, updateQuiz } from "../../apis/quizzes";

const PublishedQuizzesSection = ({ allQuizzes, setAllQuizzes }) => {
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [feartureLoading, setFeartureLoading] = useState(false);
  const handleFeatureQuizzes = async () => {
    try {
      setFeartureLoading(true);
      const Data = new FormData();
      Data.append("name", selectedQuiz.name);
      Data.append("is_featured", 1);
      Data.append("_method", "PUT");
      let doc = await updateQuiz(Data, selectedQuiz.id);
      console.log("doc:", doc);
      let allQuizzesFormApi = await getAllQuizzes(1);
      setAllQuizzes(allQuizzesFormApi?.data);
      setSelectedQuiz(null);
      Toast("success", "Quizz featured successfully.");
    } catch (error) {
      console.log("error featuring quizz:", error.message);
    } finally {
      setFeartureLoading(false);
    }
  };
  const handleUnFeatureQuizzes = async () => {
    try {
      setFeartureLoading(true);
      const Data = new FormData();
      Data.append("name", selectedQuiz.name);
      Data.append("is_featured", 0);
      Data.append("_method", "PUT");
      let doc = await updateQuiz(Data, selectedQuiz.id);
      console.log("doc:", doc);
      let allQuizzesFormApi = await getAllQuizzes(1);
      setAllQuizzes(allQuizzesFormApi?.data);
      setSelectedQuiz(null);
      Toast("success", "Menu un-featured successfully.");
    } catch (error) {
      console.log("error un-featuring menu:", error.message);
    } finally {
      setFeartureLoading(false);
    }
  };
  console.log("allQuizzes",allQuizzes);
  return (
    <Grid container direction={"column"} style={{ marginTop: 20 }}>
      {/*  Header/Top section */}
      <Grid container item>
        <Grid>
          <span style={{ fontSize: 20, fontWeight: 600, color: "#000" }}>
            Published Quizzes
          </span>
        </Grid>
        {/* <Grid style={{ marginLeft: "auto" }}>
          <span
            style={{
              marginRight: 35,
              textDecorationLine: "underline",
              fontSize: 16,
              fontWeight: 500,
              color: "#000",
            }}
          >
            View All
          </span>
        </Grid> */}
      </Grid>
      {/* Quizzes Cards section */}
      <Grid container>
        { allQuizzes && allQuizzes?.filter((quiz)=> quiz.status === 'Publish').map((quiz, index) => (
            <QuizCard
              quiz={quiz}
              key={index}
              allQuizzes={allQuizzes}
              status="Publish"
              feartureLoading={feartureLoading}
              handleFeatureQuizzes={handleFeatureQuizzes}
              handleUnFeatureQuizzes={handleUnFeatureQuizzes}
              setSelectedQuiz={setSelectedQuiz}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default PublishedQuizzesSection;
