import React, { Fragment, useState } from "react";
import AdsHeader from "../../components/ads/AdsHeader";
import AdTabs from "../../components/ads/AdTabs";
import { useNavigate } from "react-router-dom";

import { useAdsContext } from "../../context/AdsContext";
import { useEffect } from "react";
import DeleteAdModal from "../../components/ads/DeleteAdModal";
import { deleteAd } from "../../apis/ads";
import { Toast } from "../../components/alert";
import { useDashboardContext } from "../../context/DashboardContext";
import VisitsModal from "../../components/ads/ViewsModal";
import PricesModal from "../../components/ads/PricesModal";

const Ads = () => {
  const {
    ads,
    expiredAds,
    draftAds,
    handleGetAllAds,
    handleGetExpiredAds,
    handleGetTotalAds,
  } = useAdsContext();
  const { handleGetDashboardData } = useDashboardContext();
  const navigate = useNavigate();

  const [allAds, setAllAds] = useState(ads);
  const [allExpiredAds, setAllExpiredAds] = useState(expiredAds);

  const [allDraftAds, setAllDraftAds] = useState(draftAds);
  const [loading, setLoading] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [deleteAdModal, setDeleteAdModal] = useState(false);
  const [pricesModal, setPricesModal] = useState(false);
  const [viewsModal, setViewsModal] = useState(false);

  useEffect(() => {
    setAllAds(ads);
  }, [ads]);

  useEffect(() => {
    setAllDraftAds(draftAds);
  }, [draftAds]);

  useEffect(() => {
    setAllExpiredAds(expiredAds);
  }, [expiredAds]);

  const handleClickCreateAd = () => {
    navigate("/ads/createAd", { state: { allAds, allDraftAds } });
  };

  const handleDeleteAdModalOpen = () => {
    setDeleteAdModal(true);
  };
  const handleDeleteAdModalClose = () => {
    setDeleteAdModal(false);
  };
  const handlePricesModalOpen = () => {
    setPricesModal(true);
  };
  const handlePricesModalClose = () => {
    setPricesModal(false);
  };
  const handleViewsModalOpen = () => {
    setViewsModal(true);
  };
  const handleViewsModalClose = () => {
    setViewsModal(false);
  };

  const handleUpdateAdsPrices = async () => {
    try {
      setLoading(true);
    } catch (error) {
      console.log("error.message");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAd = async () => {
    try {
      setLoading(true);
      let temp = { _method: "DELETE" };
      await deleteAd(selectedAd && selectedAd.id, temp);
      await handleGetAllAds();
      await handleGetExpiredAds();
      await handleGetTotalAds();
      handleDeleteAdModalClose();
      Toast("success", "Ad deleted successfully.");
      await handleGetDashboardData();
    } catch (error) {
      console.log("error deleting ad:", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      {/* Header Section */}
      <AdsHeader handleClickCreateAd={handleClickCreateAd} />
      {/* Ads Tabs section */}
      <AdTabs
        allAds={allAds}
        setAllAds={setAllAds}
        allExpiredAds={allExpiredAds}
        setAllExpiredAds={setAllExpiredAds}
        allDraftAds={allDraftAds}
        setAllDraftAds={setAllDraftAds}
        setSelectedAd={setSelectedAd}
        selectedAd={selectedAd}
        handleDeleteAdModalOpen={handleDeleteAdModalOpen}
        handleViewsModalOpen={handleViewsModalOpen}
        handlePricesModalOpen={handlePricesModalOpen}
      />
      <DeleteAdModal
        modalOpen={deleteAdModal}
        handleClose={handleDeleteAdModalClose}
        handleConfirm={handleDeleteAd}
        loading={loading}
      />
      <VisitsModal modalOpen={viewsModal} handleClose={handleViewsModalClose} />
      <PricesModal
        modalOpen={pricesModal}
        handleClose={handlePricesModalClose}
        handleConfirm={handleUpdateAdsPrices}
        loading={loading}
      />
    </Fragment>
  );
};

export default Ads;
