import React, { Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";

import { reportsColumns, ReportsHeader } from "../../constants";
import { Button, Grid, Menu, MenuItem, TablePagination } from "@mui/material";
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";

import MessageModal from "./MessageModal";
import SuspendUserModal from "../../users/userDetails/SuspendUserModal";
import UserSuspendedModal from "../../users/userDetails/UserSuspendedModal";
import DeleteSuspendMenu from "./DeleteSuspendMenu";
import ConfirmSuspendUserModal from "./ConfirmSuspendUserModal";
import ResolveReportModal from "./ResolveReportModal";

import { Toast } from "../../alert";
import moment from "moment";
import { resolveReport } from "../../../apis/reports";
import { useReportsContext } from "../../../context/ReportsContext";
import { suspendUser } from "../../../apis/users";
import { useUsersContext } from "../../../context/UsersContext";
import { useDashboardContext } from "../../../context/DashboardContext";

export default function ReportsTable({ reports, setReports }) {
  const { handleGetResolvedReports, resolvedReports, setResolvedReports } =
    useReportsContext();
  const { handleGetSuspendedUsers, handleGetAllUsers } = useUsersContext();
  const { handleGetDashboardData } = useDashboardContext();

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [anchorEl, setAnchorEl] = useState(null);
  const [messageModal, setMessageModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [suspendUserModal, setSuspendUserModal] = useState(false);
  const [confirmSuspendUserModal, setConfirmSuspendUserModal] = useState(false);
  const [userSuspendedModal, setUserSuspendedModal] = useState(false);
  const [resolveReportModal, setResolveReportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suspendDays, setSuspendDays] = useState("");

  const open = Boolean(anchorEl);

  // console.log("reports:", reports);

  const handleOpenMessageModal = () => {
    setMessageModal(true);
  };
  const handleCloseMessageModal = () => {
    setMessageModal(false);
  };

  const handleOpenSuspendUserModal = () => {
    setSuspendUserModal(true);
  };
  const handleCloseSuspendUserModal = () => {
    setSuspendUserModal(false);
  };

  const handleOpenConfirmSuspendUserModal = () => {
    setConfirmSuspendUserModal(true);
  };

  const handleCloseConfirmSuspendUserModal = () => {
    handleCloseConfirmSuspendUserModal();
    handleOpenUserSuspendedModal();
  };

  const handleOpenUserSuspendedModal = () => {
    setUserSuspendedModal(true);
  };
  const handleCloseUserSuspendedModal = () => {
    setUserSuspendedModal(false);
    Toast("success", "User Suspended successfully.");
  };

  const handleOpenResolveReportModal = () => {
    setResolveReportModal(true);
  };
  const handleCloseResolveReportModal = () => {
    setResolveReportModal(false);
  };
  const handleResolveReport = async () => {
    try {
      setLoading(true);
      let data = {
        title: selectedReport.title,
        reason: selectedReport.reason,
        status: "Resolved",
        _method: "PUT",
      };
      // console.log("selected report:", selectedReport);
      await resolveReport(selectedReport.id, data);

      let docsTemp = [...reports];
      let resolvedDocsTemp = [...resolvedReports];

      let index = docsTemp.indexOf(selectedReport);
      if (index !== -1) {
        docsTemp.splice(index, 1);
        resolvedDocsTemp.push({ ...selectedReport, status: "Resolved" });
      }
      setReports(docsTemp);
      // setResolvedReports(resolvedDocsTemp);
      await handleGetResolvedReports();
      setResolveReportModal(false);
    } catch (error) {
      console.log("error resolving report:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMessageReply = () => {
    try {
      handleCloseMessageModal();
    } catch (error) {
      console.log("error replying message:", error.message);
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSuspendUser = async (suspensionDays, suspensionReason) => {
    try {
      // console.log("selected report:", selectedReport);
      setSuspendDays(suspensionDays);
      setLoading(true);
      await suspendUser({
        days: suspensionDays,
        reason: suspensionReason,
        user_id: selectedReport.post.user_id,
      });
      await handleGetAllUsers();
      await handleGetSuspendedUsers();
      await handleGetDashboardData();
      Toast("success", "The Reported User has been suspended");
      handleCloseSuspendUserModal();
      handleOpenUserSuspendedModal();
    } catch (error) {
      console.log("error suspending user:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Table style={{ border: "none" }}>
        <TableHead style={{ border: "none" }}>
          <TableRow>
            {reportsColumns &&
              reportsColumns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    border: "none",
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#8086A2",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.length > 0 &&
            reports
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <Fragment>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      style={{ borderRadius: 20 }}
                    >
                      {reportsColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            style={{
                              border: "none",
                              background: "#F6F6F6",
                              color: "#C84B96",
                              fontSize: 14,
                              fontWeight: column.id == "requester" ? 600 : 500,
                              borderRadius:
                                column.id == "requester"
                                  ? "8px 0px 0px 8px"
                                  : undefined,
                            }}
                          >
                            {/*
                             */}
                            {column.id == "requestedDate" ? (
                              moment(row.created_at).format("MMMM DD, YYYY")
                            ) : column.id == "requester" ? (
                              <Grid container direction={"column"}>
                                <Grid>
                                  {row?.user?.account_type === "Business"
                                    ? row?.user?.name
                                    : row?.user?.first_name +
                                      " " +
                                      row?.user?.last_name}{" "}
                                </Grid>
                                <Grid>{row?.user?.email}</Grid>
                              </Grid>
                            ) : column.id == "reportedUser" ? (
                              <Grid container direction={"column"}>
                                <Grid>
                                  {row?.post?.user?.account_type === "Business"
                                    ? row?.post?.user?.name
                                    : row?.post?.user?.first_name +
                                      " " +
                                      row?.post?.user?.last_name}
                                </Grid>
                                <Grid>{row?.user?.email}</Grid>
                              </Grid>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        key="forward"
                        align="right"
                        style={tableLastCellStyle}
                      >
                        <Grid container>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedReport(row);
                              handleOpenMessageModal();
                            }}
                          >
                            view message
                          </span>
                          {row.status === "Resolved" ? undefined : (
                            <MoreHorizRoundedIcon
                              style={{ marginLeft: "auto", color: "#000" }}
                              id="action-button"
                              aria-controls={open ? "action-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(e) => {
                                setSelectedReport(row);
                                handleOpenMenu(e);
                              }}
                            />
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                    <div style={{ margin: 8 }} />
                  </Fragment>
                );
              })}
        </TableBody>
      </Table>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: 50 }}
      >
        <TablePagination
          rowsPerPageOptions={[8, 15, 30]}
          component="div"
          count={reports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button variant="outlined" style={exportButton}>
          <CSVLink
            data={reports.length ? reports : []}
            headers={ReportsHeader}
            filename="Reports-Record.csv"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export CSV
          </CSVLink>
        </Button>
      </Grid>

      <DeleteSuspendMenu
        anchorEl={anchorEl}
        open={open}
        handleCloseMenu={handleCloseMenu}
        classes={classes}
        handleOpenSuspendUserModal={handleOpenSuspendUserModal}
        handleOpenResolveReportModal={handleOpenResolveReportModal}
      />
      <MessageModal
        modalOpen={messageModal}
        handleClose={handleCloseMessageModal}
        modalStyle={modalStyle}
        reportData={selectedReport}
        setReportData={setSelectedReport}
        handleReply={handleMessageReply}
        reports={reports}
        setReports={setReports}
      />
      <SuspendUserModal
        modalOpen={suspendUserModal}
        modalStyle={modalStyle}
        handleClose={handleCloseSuspendUserModal}
        handleConfirm={handleSuspendUser}
        // userData={userDetails}
        loading={loading}
      />
      <ConfirmSuspendUserModal
        modalOpen={confirmSuspendUserModal}
        modalStyle={modalStyle}
        handleClose={handleCloseConfirmSuspendUserModal}
        handleConfirm={handleCloseConfirmSuspendUserModal}
        userData={selectedReport}
        suspendDays={suspendDays}
      />
      <UserSuspendedModal
        modalOpen={userSuspendedModal}
        modalStyle={modalStyle}
        handleClose={handleCloseUserSuspendedModal}
        userData={selectedReport}
        suspendDays={suspendDays}
      />
      <ResolveReportModal
        modalOpen={resolveReportModal}
        modalStyle={modalStyle}
        handleClose={handleCloseResolveReportModal}
        handleConfirm={handleResolveReport}
        loading={loading}
      />
    </Fragment>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 476,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};

const tableLastCellStyle = {
  border: "none",
  background: "#F6F6F6",
  color: "#C84B96",
  fontSize: 14,
  fontWeight: 500,
  borderRadius: "0px 8px 8px 0px",
};

const exportButton = {
  marginLeft: "auto",
  background: "#C84B96",
  borderRadius: 12,
  width: 130,
  height: 50,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
};

const useStyles = makeStyles(() => {
  return {
    menuDropdown: {
      //   width: 240,
      "& .MuiPaper-root": {
        backgroundColor: "#C84B96",
      },
      "& div": {
        width: "192px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
  };
});
