import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { modalStyle } from "../constants";

const AdminDeletedModal = ({
  modalOpen,
  handleClose,
  title,
  adminData,
}) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          {title}
        </span>
        <Grid container>
          <span
            style={{
              color: "#000000",
              fontSize: 15,
              fontWeight: 500,
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            "{adminData.name}” has been removed from the system successfully.
          </span>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          onClick={handleClose}
          style={{
            width: "200px",
            textTransform: "none",
            height: 55,
            fontSize: 15,
            fontWeight: 600,
            background: "#C84B96",
            color: "#FFFFFF",
          }}
        >
          {title}
        </Button>
      </Box>
    </Modal>
  );
};

export default AdminDeletedModal;
