import { Box, Button, Grid, Typography } from "@mui/material";
import style from "./game.module.css"
import { useEffect, useState } from "react";
import { deleteDraftedWord, getAllGameWord, getCurrentActiveWordCall } from "../../apis/admins";
import gameLogo from "../../images/icons/gameLogo.jpg";
import AddGameFormModal from "./AddGameFormModal";
import UserWordCard from "./UserWordCard";
import ButtonLoader from "../../components/ButtonLoader";
import AddGameDefinationModal from "./AddGameDefinationModal";
import DeleteWordModal from "./DeleteWordModal";
import { Toast } from "../../components/alert";
import { useNavigate } from "react-router-dom";
import AddGameWebsiteModal from "./AddGameWebsiteModal";

const Games = () => {
    const navigate =useNavigate();
    const [addGameModalOpen, setAddGameModalOpen] =useState()
    // const [isUpdate, setIsUpdate] = useState(false)
    const [wordData, setWordData]=useState({});
    const [words, setWords] = useState("");
    const [wordDataComp, setWordDataComp] = useState({});
    const [userList, setUserList] =useState([]);
    const [userListLoading, setUserListLoading] =useState(false);
    const [currentActiveLoading, setCurrentActiveLoading] =useState(false);
    const [addGameDefinitionModal, setAddGameDefinitionModal] =useState(false);
    const [addGameWebsiteModal, setAddGameWebsiteModal] =useState(false);
    const [deleteGameWord, setDeleteGameWord] = useState(false); 
    const [isUpdateDefinition,setIsUpdateDefinition] =useState(false);
    const [deleteWordLoading, setDeleteWordLoading] =useState(false)

    const handleChangeWord = (value) => {
        setWords(value)
    }
    const handleChangeDefinition =(value)=>{
        setWordDataComp(value)
    }
    const handleChangeWebsite =(value)=>{
      setWordDataComp(value)
  }
    const handleModalOpen =() => {
        setAddGameModalOpen(true);
    }

    const getAllGameWordList = async () => {
        setUserListLoading(true);
        try {
            const resp = await getAllGameWord();
            setUserList(resp?.data)
        } catch (error) {
            console.log(error)
        }finally{
            setUserListLoading(false);
        }
    }

    const getCurrentActiveWord = async () =>{
        setCurrentActiveLoading(true)
        try{
            const res = await getCurrentActiveWordCall();
            setWordData(res);
        }catch(err){
            console.log("error", err)
        }finally{
            setCurrentActiveLoading(false);
        }
    }

    const handleDeleteWord =async() =>{
        setDeleteWordLoading(true)
        try {
          const res = await deleteDraftedWord(wordDataComp?.id);
          if(res){
            Toast("success", "Your Word Deleted Successfully!");
            getCurrentActiveWord()
            getAllGameWordList()
          }
        } catch (error) {
          
        }finally{
            setDeleteWordLoading(false);
            handleDeleteWordModalClose()
        }
      }

    useEffect(() => {
        getAllGameWordList()
        getCurrentActiveWord()
    }, [])

    const handleCloseAddModal =()=>{
        setAddGameModalOpen(false)
    }

    const handleAddDefinitionState =()=>{
        setAddGameDefinitionModal(true)
    }

    const handleCloseAddDefinitionModal =()=>{
        setAddGameDefinitionModal(false)
    }
    const handleAddWebsiteState =()=>{
      setAddGameWebsiteModal(true)
  }

  const handleCloseAddWebsiteModal =()=>{
      setAddGameWebsiteModal(false)
  }

    const handleDeleteWordModalOpen =() =>{
        setDeleteGameWord(true)
    }

    const handleDeleteWordModalClose = () =>{
        setDeleteGameWord(false)
    }

    return (
        <>
            <Box className={style.gameHeadingBox}>
                <img src={gameLogo} width={100} height={100} alt="logo-image"/>
                <Typography
                    component="h2"
                    sx={{ fontWeight: '700', fontSize: "2.25rem", color: "#313137" }}
                >
                    Games
                </Typography>
                <Button
                variant="contained"
                onClick={handleModalOpen}
                style={addButtonStyles}
            >
            Add Word
          </Button>
            </Box>
            <Box sx={{ marginTop:'2rem'}}>
            <Grid container>
            <Grid item>
            <span style={{ fontSize: 20, fontWeight: 600, color: "#000" }}>
            Sponsored Word:
          </span>
            </Grid>
            <Grid item sx={{backgroundColor:"#C84B96", color:'white', padding:'1rem', borderRadius:'1rem', marginLeft:'1rem'}}>
            <span style={{ fontSize: 20, fontWeight: 600 }}>
            Promoter: <span style={{ fontSize: 16, fontWeight: 500 }}>
            {currentActiveLoading ? "..." : wordData?.user?.username  ?? wordData?.user?.full_name }
            </span>
          </span> 
          <br/>
            <span style={{ fontSize: 20, fontWeight: 600 }}>
            Word: <span style={{ fontSize: 16, fontWeight: 500 }}>
            {currentActiveLoading ? "..." : wordData?.word}
            </span> 
          </span>
          <br/>
          <span style={{ fontSize: 20, fontWeight: 600 }}>
            Website: <span style={{ fontSize: 16, fontWeight: 500 }}>
            {currentActiveLoading ? "..." : wordData?.website}
            </span> 
          </span>
          <br/>
          <span style={{ fontSize: 20, fontWeight: 600 }}>
            Definition: <span style={{ fontSize: 16, fontWeight: 500 }}>
            {currentActiveLoading ? "..." : wordData?.definition}
            </span> 
          </span>
          <Grid item container>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/add-games/word-detail", {
              state: { WordData:wordData },
            });
          }}
          style={{
            marginTop: 15,
            color: "#4E1B5C",
            fontSize: 14,
            fontWeight: 600,
            textTransform: "none",
            background: "#FFF",
            borderRadius: 8,
          }}

        >
          View Details
        </Button>
      </Grid>
            </Grid>
            </Grid>
            </Box>
            <Box sx={{ marginTop:'2rem'}}>
            <Grid>
            <span style={{ fontSize: 20, fontWeight: 600, color: "#000" }}>
               Drafts
            </span>
            </Grid>
            <Box>
            <Grid container sx={userListLoading ? {height:'10rem', alignItems:'center', justifyContent:'center'}:{} }>
            {
                userListLoading ? <Grid item>
                <ButtonLoader/>
                </Grid>  :  userList?.filter((item)=> item.status === "draft").map((item)=> (
                    <UserWordCard userWordData={item} getCurrentActiveWord={getCurrentActiveWord} getAllGameWordList={getAllGameWordList} handleAddDefinitionState={handleAddDefinitionState} handleAddWebsiteState={handleAddWebsiteState} handleChange={handleChangeDefinition} setWordDataComp={setWordDataComp} handleDeleteModalOpen ={handleDeleteWordModalOpen}/>
                ))
            }
            </Grid>
            </Box>
            </Box>
            { addGameModalOpen &&
               <AddGameFormModal modalOpen={addGameModalOpen} words={words} handleChange={handleChangeWord} getAllGameWordList={getAllGameWordList} handleClose={handleCloseAddModal} />
            }
            {
                addGameDefinitionModal &&
               <AddGameDefinationModal modalOpen={addGameDefinitionModal} words={wordDataComp} handleChange={handleChangeDefinition} isUpdate={isUpdateDefinition} getAllGameWordList={getAllGameWordList} setIsUpdate={setIsUpdateDefinition} handleClose={handleCloseAddDefinitionModal} />
            }
            {
                addGameWebsiteModal &&
               <AddGameWebsiteModal modalOpen={addGameWebsiteModal} words={wordDataComp} handleChange={handleChangeWebsite} isUpdate={isUpdateDefinition} getAllGameWordList={getAllGameWordList} setIsUpdate={setIsUpdateDefinition} handleClose={handleCloseAddWebsiteModal} />
            }
            {
                deleteGameWord &&
                <DeleteWordModal modalOpen={deleteGameWord}
                handleClose={handleDeleteWordModalClose}
                handleConfirm={handleDeleteWord}
                loading ={deleteWordLoading}
    />
            }
        </>
    )
}



export default Games;

const addButtonStyles = {
    color: "#fff",
    background: "#C84B96",
    borderRadius: 12,
    fontWeight: 600,
    textTransform: "capitalize",
    fontSize: 15,
    height: 50,
};