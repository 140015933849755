import { Button, Grid } from "@mui/material";
import React, { useState } from "react";

const SelectOptionsComponent = ({
  title,
  data,
  handleSelectOption,
  handleSelectAll,
  selectedGender,
  interestsDropdown,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  
  return (
    <Grid container style={showUsersContainerStyles}>
      <Grid
        container
        style={{
          marginBottom: 22,
          cursor: "pointer",
        }}
      >
        <span style={userFullNameStyles}>{title}</span>
        <Grid>
          {data.map((doc, index) => (
            <Button
              variant={
                interestsDropdown
                  ? selectedGender.includes(doc.id)
                    ? "contained"
                    : "outlined"
                  : selectedGender.includes(doc)
                  ? "contained"
                  : "outlined"
              }
              key={index}
              style={
                interestsDropdown
                  ? selectedGender.includes(doc.id)
                    ? selectedButtonStyle
                    : nonSelectedButtonStyle
                  : selectedGender.includes(doc)
                  ? selectedButtonStyle
                  : nonSelectedButtonStyle
              }
              onClick={() => {
                if (interestsDropdown) {
                  handleSelectOption(doc.id);
                } else {
                  handleSelectOption(doc);
                }
              }}
            >
              {interestsDropdown ? doc.name : doc}
            </Button>
          ))}
          <Button
            variant={selectAll ? "contained" : "outlined"}
            style={selectAll ? selectedButtonStyle : nonSelectedButtonStyle}
            onClick={() => {
              setSelectAll(true);
              handleSelectAll();
            }}
          >
            All
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectOptionsComponent;

const showUsersContainerStyles = {
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
  padding: "18px 24px",
  borderRadius: 10,
  background: "#fff",
  marginLeft: -10,
  width: 375,
  //   marginRight: -20,
  //   marginTop: -15,
  //   position: "absolute",
  //   zIndex: 99,
};
const userFullNameStyles = { fontSize: 16, fontWeight: 700, color: "#000" };

const selectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  color: "#FFF",
  background: "#C84B96",
};

const nonSelectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  borderColor: "#000",
  color: "#000",
};
