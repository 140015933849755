import { Menu, MenuItem } from "@mui/material";
import React from "react";

const DeleteSuspendMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  handleOpenSuspendUserModal,
  handleOpenResolveReportModal,
}) => {
  return (
    <Menu
      id="action-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "action-button",
      }}
      // className={classes.menuDropdown}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "#C84B96",
        },
      }}
    >
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          handleOpenSuspendUserModal();
        }}
        style={{ color: "#fff" }}
      >
        Suspend User
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          handleOpenResolveReportModal();
        }}
        style={{ color: "#fff" }}
      >
        Resolve Report
      </MenuItem>
    </Menu>
  );
};

export default DeleteSuspendMenu;
