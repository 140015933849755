import React, { createContext, useContext, useEffect, useState } from "react";
import { getAllQuizzes, getQuizDetails } from "../apis/quizzes";
import { useAuth } from "./AuthContext";

export const QuizContext = createContext();
export const useQuizContext = () => useContext(QuizContext);

export const QuizProvider = ({ children }) => {
  const { userToken } = useAuth();
  const [quizzes, setQuizzes] = useState([]);
  const [draftQuizzes, setDraftQuizzes] = useState([]);
  const [pendingQuizzes, setPendingQuizzes] = useState([]);
  const [quizPage, setQuizePage] = useState(1);
  const [totalQuize, setTotalQuize] = useState(0);

  // console.log("token:", userToken);

  useEffect(() => {
    handleGetAllQuizzes(quizPage);
  }, [userToken, quizPage]);

  const handleGetAllQuizzes = async (page) => {
    try {
      let allDocsTemp = [];
      let draftDocsTemp = [];
      let pendingDocsTemp = [];
      let docsTemp = await getAllQuizzes(page);
      setTotalQuize(docsTemp?.total);
      docsTemp?.data?.forEach(async (el) => {
        // let temp = await getQuizDetails(el.id);
        if (el.status === "Draft") {
          draftDocsTemp.push(el);
          // draftDocsTemp.push(temp);
        } else if (el.status === "Pending") {
          pendingDocsTemp.push(el);
        } else {
          allDocsTemp.push(el);
          // allDocsTemp.push(temp);
        }
      });
      setQuizzes(allDocsTemp);
      setDraftQuizzes(draftDocsTemp);
      setPendingQuizzes(pendingDocsTemp);
    } catch (error) {
      console.log("error getting Quizzes:", error.message);
    }
  };

  return (
    <QuizContext.Provider
      value={{
        quizzes,
        setQuizzes,
        draftQuizzes,
        setDraftQuizzes,
        pendingQuizzes,
        setPendingQuizzes,
        handleGetAllQuizzes,
        setQuizePage,
        totalQuize
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};
