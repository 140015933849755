import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles({
  cardTitle: {
    color: "#C84B96",
    fontSize: 20,
    fontWeight: 700,
  },
  countText: {
    color: "#000",
    fontSize: 16,
    fontWeight: 500,
  },
});

const AnalyticsNonPercentCard = ({ card, hashtags }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        width: 280,
        height: 160,
        borderRadius: 20,
        background: "#F9F9FA",
        margin: 10,
      }}
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid container alignItems="center" justifyContent="center">
        <span className={classes.cardTitle}>
          {card && card.name && card.name}{" "}
        </span>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <span className={classes.countText}>
          {hashtags
            ? `${card && card.total_posts && card.total_posts} Posts`
            : `${card && card.total_reviews && card.total_reviews} Reviews`}
        </span>
      </Grid>
    </Grid>
  );
};

export default AnalyticsNonPercentCard;
