import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WordDetailsHeader from "./wordDetail/WordDetailHeader";
import WordAnalytics from "./wordDetail/WordAnalytics";
import UsersPointsTable from "./wordDetail/UserPointTable";
import { getWordDetail } from "../../apis/games";


const WordDetail = () => {
  const location = useLocation();
  const navigate =useNavigate();

  const [wordData, setWordData] = useState({});
  const [wordDetails, setWordDetails] = useState(null);
  
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setWordData(location.state.WordData);
    handleGetWordDetails(); 
  }, [location.state.WordData]);

  const handleGetWordDetails = async () => {
    try {
      setDataLoading(true);
      let temp = await getWordDetail(location.state.WordData.id);
      setWordDetails(temp);
    } catch (error) {
      console.log("error getting quiz details:", error.message);
    } finally {
      setDataLoading(false);
    }
  };

  const goBack= () => {
      navigate("/add-games");
    };


  return (
    <Fragment>
      {/* Header Section */}
      <WordDetailsHeader
        // wordData={wordData}
        wordDetails={wordDetails}
        goBack={goBack}
      />
      {/* total participants, Average, Highest and Lowset score section */}
      <WordAnalytics wordData={wordData} wordDetails={wordDetails} />
      {/* Scores (users score table) section */}
      <UsersPointsTable
        wordData={wordData}
        scores={wordDetails && wordDetails.word_game_attempt}
        wordDetails={wordDetails}
      />
    </Fragment>
  );
};

export default WordDetail;
