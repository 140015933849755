import React, { useState } from "react";
import { Button, Grid, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortMenu from "../SortMenu";
import AdsSortMenu from "./AdsSortMenu";
import AdsFilterMenu from "./AdsFilterMenu";

const AdsHeader = ({
  open,
  handleOpenMenu,
  handleCloseMenu,
  dropdownButtonText,
  anchorEl,
  setDropdownButtonText,
  handleSortAds,
  handleSortOptionChange,
  sortOption,
  openFilter,
  filterAnchorEl,
  handleCloseFilterMenu,
  handleFilter,
  filterData,
  setFilterData,
  handleOpenFilterMenu,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs justifyContent="center" alignItems={"center"}>
          <ArrowBackIosIcon
            className={classes.backIcon}
            onClick={() => navigate("/dashboard")}
          />
          <span className={classes.title}>Ads</span>
        </Grid>

        <Grid item xs style={{ textAlign: "end" }} justifyContent="center">
          <Button
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
            id="sort-button"
            aria-controls={open ? "sort-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleOpenMenu}
            style={buttonStyle}
          >
            {dropdownButtonText}
          </Button>
          <Button
            variant="outlined"
            endIcon={<FilterListIcon />}
            id="filter-button"
            aria-controls={openFilter ? "filter-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openFilter ? "true" : undefined}
            onClick={handleOpenFilterMenu}
            style={buttonStyle}
          >
            Filter
          </Button>

          <AdsSortMenu
            anchorEl={anchorEl}
            open={open}
            handleCloseMenu={handleCloseMenu}
            classes={classes}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setDropdownButtonText={setDropdownButtonText}
            handleSortAds={handleSortAds}
            handleSortOptionChange={handleSortOptionChange}
            sortOption={sortOption}
          />
          <AdsFilterMenu
            anchorEl={filterAnchorEl}
            open={openFilter}
            handleCloseMenu={handleCloseFilterMenu}
            classes={classes}
            sortOption={sortOption}
            handleSortOptionChange={handleSortOptionChange}
            filterData={filterData}
            setFilterData={setFilterData}
            handleFilter={handleFilter}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default AdsHeader;

const useStyles = makeStyles(() => {
  return {
    title: {
      color: "#000",
      fontSize: 25,
      fontWeight: 600,
      marginLeft: 10,
    },
    backIcon: {
      // marginTop: 15,
      marginBottom: -3,
      cursor: "pointer",
    },
    filterCustomWidth: {
      "& div": {
        width: "270px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    customWidth: {
      "& div": {
        width: "305px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
  };
});

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
