import React, { createContext, useContext, useEffect, useState } from "react";
import { getAllAds, getExpiredAds, getTotalAds, getInterest } from "../apis/ads";
import { useAuth } from "./AuthContext";

export const AdsContext = createContext();
export const useAdsContext = () => useContext(AdsContext);

export const AdsProvider = ({ children }) => {
  const { userToken } = useAuth();
  const [totalAds, setTotalAds] = useState([]);
  const [ads, setAds] = useState([]);
  const [expiredAds, setExpiredAds] = useState([]);
  const [draftAds, setDraftAds] = useState([]);
  const [interests, setInterests] = useState([]);

  //   console.log("token:", userToken);

  useEffect(() => {
    handleGetAllAds();
    handleGetExpiredAds();
    handleGetTotalAds();
    handleGetInterests();
  }, [userToken]);

  const handleGetTotalAds = async () => {
    try {
      let docsTemp = await getTotalAds();
      setTotalAds(docsTemp);
      // console.log("Total ads:", docsTemp);
    } catch (error) {
      console.log("error getting total ads:", error.message);
    }
  };
  const handleGetInterests = async () => {
    try {
      let docsTemp = await getInterest();
      setInterests(docsTemp);
      // console.log("Total ads:", docsTemp);
    } catch (error) {
      console.log("error getting total ads:", error.message);
    }
  };
  const handleGetAllAds = async () => {
    try {
      let temp = [];
      let draftTemp = [];
      let docsTemp = await getAllAds();
      docsTemp.forEach((el) => {
        if (el.status == "Draft") {
          draftTemp.push(el);
        } else {
          temp.push(el);
        }
      });
      setAds(temp);
      setDraftAds(draftTemp);
      // console.log("ads:", docsTemp);
    } catch (error) {
      console.log("error getting ads:", error.message);
    }
  };
  const handleGetExpiredAds = async () => {
    try {
      let docsTemp = await getExpiredAds();
      setExpiredAds(docsTemp);
      // console.log("expired ads:", docsTemp);
    } catch (error) {
      console.log("error getting expired ads:", error.message);
    }
  };

  return (
    <AdsContext.Provider
      value={{
        ads,
        setAds,
        draftAds,
        setDraftAds,
        handleGetAllAds,
        expiredAds,
        setExpiredAds,
        handleGetExpiredAds,
        totalAds,
        setTotalAds,
        handleGetTotalAds,
        interests,
      }}
    >
      {children}
    </AdsContext.Provider>
  );
};
