import React, { Fragment, useState } from "react";
import {
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";

const RegisteredUsersHeader = ({
  oncChange,
  headerTitle,
  mainTitle,
  tabValue,
  handleTabValueChange,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs justifyContent="center" alignItems={"center"}>
          {headerTitle && (
            <Fragment>
              <ArrowBackIosIcon
                className={classes.backIcon}
                onClick={() => navigate("/dashboard")}
              />
              <span className={classes.title}>{headerTitle}</span>
            </Fragment>
          )}
          {mainTitle === "Manage Users" ? (
            <Tabs
              value={tabValue}
              onChange={handleTabValueChange}
              TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
              centered
              style={tabsStyle}
            >
              <Tab
                label="Personal"
                {...a11yProps(0)}
                style={tabValue == 0 ? selectedTab : nonSelectedTab}
              />
              <Tab
                label="Business"
                {...a11yProps(1)}
                style={tabValue == 1 ? selectedTab : nonSelectedTab}
              />
            </Tabs>
          ) : undefined}
        </Grid>
        <Grid item xs>
          {mainTitle && <span className={classes.mainTitle}>{mainTitle}</span>}
        </Grid>

        <Grid item xs style={{ textAlign: "end" }}>
          <TextField
            id="search"
            onKeyDown={oncChange}
            // onChange={oncChange}
            placeholder="Search Users"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                background: "#F6F6F6",
                borderRadius: 33,
                width: 323,
                height: 48,
              },
            }}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default RegisteredUsersHeader;

const useStyles = makeStyles({
  mainTitle: {
    color: "#313137",
    fontSize: 36,
    fontWeight: 700,
    marginLeft: 10,
  },
  title: {
    color: "#000",
    fontSize: 25,
    fontWeight: 600,
    marginLeft: 10,
  },
  backIcon: {
    marginTop: 15,
    marginBottom: -3,
    cursor: "pointer",
  },
});

const selectedTab = {
  background: "#C84B96",
  borderRadius: "53px",
  textTransform: "none",
  color: "#FFF",
  height: 50,
  padding: "0px 43px",
  fontSize: 16,
  fontWeight: 500,
  width: 127,
};

const nonSelectedTab = {
  background: "#F6F6F6",
  textTransform: "none",
  color: "#000000",
  height: 50,
  fontSize: 16,
  fontWeight: 500,
  padding: "0px 43px",
};

const tabsStyle = {
  background: "#F6F6F6",
  width: 256,
  borderRadius: 53,
  // padding: 5,
};

function a11yProps(index) {
  return {
    id: `user-tab-${index}`,
    "aria-controls": `user-tabpanel-${index}`,
  };
}
