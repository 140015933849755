import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import AnalyticsCard from "./AnalyticsCard";
// import { usersAnalyticsArray } from "../constants";

const useStyles = makeStyles({
  title: {
    color: "#000",
    fontSize: 20,
    fontWeight: 600,
    // fontFamily: "Poppins",
  },
});

const UserSection = ({
  users,
  personalUsers,
  businessUsers,
  suspendedUsers,
}) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.title}>Users</span>
      <Grid container direction="row" style={{ padding: 20 }}>
        {/*
    
      {usersAnalyticsArray &&
        usersAnalyticsArray.map((card, index) => (
          <AnalyticsCard key={index} card={card} />
          ))}
        */}
        <AnalyticsCard
          card={{
            title: "Registered Users",
            count: users,
            percent: 2.5,
            expandable: true,
            link: "/dashboard/registered-users",
          }}
        />
        <AnalyticsCard
          card={{
            title: "Personal Accounts",
            count:
              personalUsers,
            percent: 4.5,
            expandable: true,
            link: "/dashboard/users/personal-accounts",
          }}
        />
        <AnalyticsCard
          card={{
            title: "Business Accounts",
            count:
              businessUsers,
            percent: -1.8,
            expandable: true,
            link: "/dashboard/users/business-accounts",
          }}
        />
        <AnalyticsCard
          card={{
            title: "Deactivated Users",
            count:
              suspendedUsers,
            percent: -1.5,
            expandable: true,
            link: "/dashboard/deactivated-users",
          }}
        />
      </Grid>
    </div>
  );
};

export default UserSection;
