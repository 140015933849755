import React from "react";
import { Button, Grid, Toolbar } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const Header = ({ handleModalOpen }) => {
  const theme = useTheme();
  const classes = useStyles();

  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  // const navigate = useNavigate();
  // const location = useLocation();

  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid
          item
          xs
          style={{
            textAlign: "end",
            width: matchesXL
              ? "65em"
              : matchesLG
              ? "50em"
              : matchesMD
              ? "35em"
              : matchesSM
              ? "28em"
              : "23em",
          }}
        >
          <span style={{ color: "#313137", fontWeight: 700, fontSize: 36 }}>
            Manage Quizzes
          </span>
        </Grid>
        <Grid item xs style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            onClick={handleModalOpen}
            endIcon={<AddOutlinedIcon style={{ fontSize: 24 }} />}
            style={{
              color: "#fff",
              background: "#C84B96",
              borderRadius: 38,
              marginTop: 25,
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 15,
              height: 50,
            }}
          >
            Add A Quiz
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default Header;

const useStyles = makeStyles(() => {
  return {
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
    customWidth: {
      "& div": {
        width: "305px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
  };
});
