import React from "react";
import { Box, Grid, Pagination } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import AddRecipesModal from "../../components/recipes/AddRecipesModal";
import { Toast } from "../../components/alert";
import { makeStyles } from "@mui/styles";
import { addFoodRecipes, deleteFoodRecipes, editFoodRecipes } from "../../apis/recipes";
import RecipesCard from "../../components/recipes/RecipesCard";
import { useRecipesContext } from "../../context/RecipesContext";
import RecipeHeader from "../../components/recipes/RecipeHeader";
import { useLocation } from "react-router-dom";
import ButtonLoader from "../../components/ButtonLoader";
import DeleteRecipeModal from "../../components/recipes/DeleteRecipesModal";

const RecipesItem = () => {
  const location =useLocation();
  const [menuData, setMenuData] = useState(location.state.menuData);
  const { recipes, setRecipes, fetchRecipes, loadingRecipes } = useRecipesContext();

  const [allRecipes, setAllRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [newRecipes, setNewRecipes] = useState({
    title: "",
    image: { preview: "", raw: "" },  
    duration:'',
    portion:'',
    instructions:'',
    ingredients:[],
    is_featured:0
  });
  const [addRecipeModal, setAddRecipeModal] = useState(false);
  const [editRecipeModal, setEditRecipeModal] = useState(false);
  const [deleteRecipeModal, setDeleteRecipeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quizPage, setQuizePage] = useState();
  const [totalRecipes, setTotalRecipes] =useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [feartureLoading, setFeartureLoading] = useState(false);

  useEffect(() => {
    setMenuData(location.state.menuData);
  }, [location.state]);

  useEffect(() => {
    setAllRecipes(JSON.parse(JSON.stringify(recipes.data)));
    setTotalRecipes(recipes.total);
  }, [recipes]);

  useEffect(()=>{
    fetchRecipes(menuData.id);
  },[quizPage])

  const handleAddRecipeModalOpen = () => {
    setAddRecipeModal(true);
  };
  const handleAddRecipeModalClose = () => {
    setAddRecipeModal(false);
  };
  const handleEditRecipeModalOpen = () => {
    setEditRecipeModal(true);
  };
  const handleEditRecipeModalClose = () => {
    setEditRecipeModal(false);
  };
  const handleDeleteRecipeModalOpen = () => {
    setDeleteRecipeModal(true);
  };
  const handleDeleteRecipeModalClose = () => {
    setDeleteRecipeModal(false);
  };


  const handleAddMenu = async () => {
    try {
      setLoading(true);
      const Data = new FormData();
      Data.append("image", newRecipes.image.raw);
      Data.append("title", newRecipes.title);
      Data.append("duration", newRecipes.duration);
      Data.append("portion", newRecipes.portion);
      Data.append("menu_id", menuData.id);
      Data.append("instructions", newRecipes.instructions);
      for (var i = 0; i < newRecipes?.ingredients?.length; i++) {
        Data.append('ingredients[]', newRecipes.ingredients[i].value);
    }
      Data.append("is_featured", 0);
      await addFoodRecipes(Data);
      await fetchRecipes(menuData.id);
      setNewRecipes({
        name: "",
        image: { preview: "", raw: "" },
      });
      Toast("success", "New Recipe added successfully.");
      handleAddRecipeModalClose();
    } catch (error) {
      console.log("error adding menu:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditMenu = async() => {

    try {
      setLoading(true);
      const Data = new FormData();
   //   // if (selectedRecipe.image) {
    //   //   Data.append(
    //   //     "image",
    //   //     selectedRecipe.image
    //   //   );
    //   // }
      Data.append("title", selectedRecipe.title); 
      Data.append("menu_id", menuData.id);
      Data.append("duration", selectedRecipe.duration);
      Data.append("portion", selectedRecipe.portion);
      Data.append("instructions", selectedRecipe.instructions);
      for (var i = 0; i < selectedRecipe?.ingredients.length; i++) {
        Data.append('ingredients[]', selectedRecipe.ingredients[i].value);
      }
      Data.append("is_featured",selectedRecipe.is_featured );
      Data.append("_method", "PUT");

      await editFoodRecipes(Data, selectedRecipe.id);
      await fetchRecipes(menuData.id);
      setSelectedRecipe(null);
      Toast("success", "Recipe updated successfully.");
      handleEditRecipeModalClose();
    } catch (error) {
      console.log("error editing Recipe:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteMenu = async () => {
    try {
      setLoading(true);
      await deleteFoodRecipes(selectedRecipe.id);
      await fetchRecipes(menuData.id);
      Toast("success", "Recipe Deleted successfully.");
      handleDeleteRecipeModalClose();
    } catch (error) {
      console.log("error deleting Recipe:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFeatureRecipe = async () => {
    try {
      setFeartureLoading(true);
      const Data = new FormData();
      Data.append("title", selectedRecipe.title);
      Data.append("menu_id", menuData.id);
      Data.append("duration", selectedRecipe.duration);
      Data.append("portion", selectedRecipe.portion);
      Data.append("instructions", selectedRecipe.instructions);
      Data.append("is_featured", 1);
      Data.append("_method", "PUT");
      let doc = await editFoodRecipes(Data, selectedRecipe.id);
      // console.log("doc:", doc);
      await fetchRecipes(menuData.id);
      setSelectedRecipe(null);
      Toast("success", "Recipe featured successfully.");
    } catch (error) {
      console.log("error featuring menu:", error.message);
    } finally {
      setFeartureLoading(false);
    }
  };
  const handleUnFeatureRecipe = async () => {
    try {
      setFeartureLoading(true);
      const Data = new FormData();
      Data.append("title", selectedRecipe.title);
      Data.append("menu_id", menuData.id);
      Data.append("duration", selectedRecipe.duration);
      Data.append("portion", selectedRecipe.portion);
      Data.append("instructions", selectedRecipe.instructions);
      Data.append("is_featured", 0);
      Data.append("_method", "PUT");
      let doc = await editFoodRecipes(Data, selectedRecipe.id);
      // console.log("doc:", doc);
      await fetchRecipes(menuData.id);
      setSelectedRecipe(null);
      Toast("success", "Recipe un-featured successfully.");
    } catch (error) {
      console.log("error un-featuring recipe:", error.message);
    } finally {
      setFeartureLoading(false);
    }
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <RecipeHeader handleModalOpen={handleAddRecipeModalOpen} />
      <Box container gap={"1rem"} style={{ paddingTop: 60,gridTemplateColumns:'1fr 1fr 1fr', display:'grid' }}>
      {loadingRecipes && (
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          style={{ height: 400 }}
        >
          <ButtonLoader />
        </Grid>
      )}
        {allRecipes?.length > 0 && !loadingRecipes ? (
          allRecipes?.map((recipes, index) => (
            <RecipesCard
              key={index}
              recipesData={recipes}
              setSelectedRecipe={setSelectedRecipe}
              handleEditRecipesModalOpen={handleEditRecipeModalOpen}
              handleDeleteRecipesModalOpen={handleDeleteRecipeModalOpen}
              feartureLoading={feartureLoading}
              handleFeatureRecipe={handleFeatureRecipe}
              handleUnFeatureRecipe={handleUnFeatureRecipe}
            />
          ))
        ) : (
          <Grid
            container
            justifyContent={"center"}
            alignItems="center"
            style={{ height: 300 }}
          >
            {!loadingRecipes && <h4>No Recipe items avaiable.</h4>}
          </Grid>
        )
         }
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Pagination
          count={Math.ceil(totalRecipes / 10)}
          page={quizPage}
          onChange={(_, value) => setQuizePage(value)}
          showFirstButton
          showLastButton
        />
      </div>
      {
        addRecipeModal && 
      <AddRecipesModal
        modalOpen={addRecipeModal}
        handleClose={handleAddRecipeModalClose}
        handleConfirm={handleAddMenu}
        recipesData={newRecipes}
        setRecipesData={setNewRecipes}
        loading={loading}
      />
      }
      {
        editRecipeModal && 
      <AddRecipesModal
        modalOpen={editRecipeModal}
        handleClose={handleEditRecipeModalClose}
        handleConfirm={handleEditMenu}
        recipesData={selectedRecipe}
        setRecipesData={setSelectedRecipe}
        editMenu={true}
        loading={loading}
      />
      }
      {
        deleteRecipeModal && 
      <DeleteRecipeModal
        modalOpen={deleteRecipeModal}
        handleClose={handleDeleteRecipeModalClose}
        handleConfirm={handleDeleteMenu}
        loading={loading}
      />
      }
    </Fragment>
  );
};

export default RecipesItem;

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 30,
  paddingRight: 30,
  marginRight: 35,
};

const useStyles = makeStyles(() => {
  return {
    customWidth: {
      "& div": {
        width: "290px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
  };
});
