import React, { Fragment, useEffect, useState } from "react";
import { Button, Grid, TextField, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AddQuizHeader = ({
  quiz,
  setQuiz,
  handleReviewQuiz,
  reviewQuiz,
  editQuestion,
  closeEditQuestion,
  handlePublishQuizModalOpen,
  handleSaveDraftModalOpen,
  goback,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs justifyContent="center" alignItems={"center"}>
          {editQuestion ? (
            <ArrowBackIosIcon
              className={classes.backIcon}
              onClick={() => goback()}
            />
          ) : (
            <CloseIcon className={classes.backIcon} onClick={() => goback()} />
          )}
        </Grid>

        <Grid item xs style={{ background: "white", marginTop: -15 }}>
          <TextField
            variant="outlined"
            placeholder="Type Quiz title..."
            value={quiz.title}
            onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
            // InputLabelProps={{ style: { fontSize: 36, fontWeight: 700,  } }}
            InputProps={{
              style: {
                fontSize: 36,
                fontWeight: 700,
                // background: "red",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </Grid>

        <Grid item xs style={{ textAlign: "end" }} justifyContent="center">
          {!editQuestion && (
            <Fragment>
              {reviewQuiz ? (
                <Button
                  variant="contained"
                  style={reviewButtonStyle}
                  onClick={handlePublishQuizModalOpen}
                >
                  Publish Quiz
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={reviewButtonStyle}
                  onClick={handleReviewQuiz}
                >
                  Review Quiz
                </Button>
              )}

              <Button
                variant="outlined"
                style={draftButtonStyle}
                onClick={handleSaveDraftModalOpen}
              >
                Save Draft
              </Button>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default AddQuizHeader;

const useStyles = makeStyles(() => {
  return {
    title: {
      color: "#C84B96",
      fontSize: 25,
      fontWeight: 600,
      marginLeft: 10,
    },
    backIcon: {
      //   marginTop: 15,
      marginBottom: -3,
      cursor: "pointer",
      color: "#C84B96",
    },
    customWidth: {
      "& div": {
        width: "305px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
  };
});

const reviewButtonStyle = {
  color: "#FFFFFF",
  background: "#C84B96",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const draftButtonStyle = {
  color: "#C84B96",
  borderColor: "#C84B96",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
