import { makeStyles } from "@mui/styles";
import { Button, Grid, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../components/quizzes/Header";
import { useQuizContext } from "../../context/QuizContext";
import React, { Fragment, useEffect, useState } from "react";
import SortQuizzesMenu from "../../components/quizzes/SortQuizzesMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DraftsQuizzesSection from "../../components/quizzes/DraftsQuizzesSection";
import PendingQuizzesSection from "../../components/quizzes/PendingQuizzesSection";
import PublishedQuizzesSection from "../../components/quizzes/PublishedQuizzesSection";

const Quizzes = () => {
  const { quizzes, draftQuizzes, pendingQuizzes, quizPage, setQuizePage, totalQuize } =
    useQuizContext();

  const classes = useStyles();
  const navigate = useNavigate();

  const [allQuizzes, setAllQuizzes] = useState(quizzes);
  const [allDraftQuizzes, setAllDraftQuizzes] = useState(draftQuizzes);
  const [allPendingQuizzes, setAllPendingQuizzes] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownButtonText, setDropdownButtonText] = useState("Sort");
  const [sortOption, setSortOption] = useState("Ascending");
  const open = Boolean(anchorEl);

  useEffect(() => {
    setAllQuizzes(quizzes);
  }, [quizzes]);
  useEffect(() => {
    setAllDraftQuizzes(draftQuizzes);
  }, [draftQuizzes]);
  useEffect(() => {
    setAllPendingQuizzes(pendingQuizzes);
  }, [pendingQuizzes]);

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  //Handle Add Quiz
  const handleAddQuiz = () => {
    navigate("/quizzes/addQuiz", { state: { allQuizzes, allDraftQuizzes } });
    console.log({allQuizzes,allDraftQuizzes})
  };

  //Handle Sort Quiz
  const handleSortQuiz = (field) => {
    // console.log("Selected:", field);
    setDropdownButtonText(field);
    let sorted = [...quizzes];
    let draftSorted = [...draftQuizzes];
    if (field === "All") {
      setAllQuizzes(quizzes);
      setAllDraftQuizzes(draftQuizzes);
    }
    sorted.sort((a, b) =>
      field === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Date Joined"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "No. of Questions"
        ? a.questions_count > b.questions_count
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    draftSorted.sort((a, b) =>
      field === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Date Joined"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "No. of Questions"
        ? a.questions_count > b.questions_count
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    setAllQuizzes(sorted);
    setAllDraftQuizzes(draftSorted);
    handleCloseSortMenu();
  };

  //Handle Sort Options
  const handleSortOptionChange = (e) => {
    // console.log(e.target.value);
    setSortOption(e.target.value);
    let sorted = [...allQuizzes];
    let draftSorted = [...allDraftQuizzes];
    sorted.sort((a, b) =>
      dropdownButtonText === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Joined"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "No. of Questions"
        ? a.questions_count > b.questions_count
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    draftSorted.sort((a, b) =>
      dropdownButtonText === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Date Joined"
        ? a.created_at > b.created_at
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "No. of Questions"
        ? a.questions_count > b.questions_count
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    setAllQuizzes(sorted);
    setAllDraftQuizzes(draftSorted);
    handleCloseSortMenu();
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <Header handleModalOpen={handleAddQuiz} />
      {/* Sort and Filter Section*/}
      <Grid container style={{ padding: 20 }} alignItems="flex-end" justifyContent="flex-end">
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
          style={buttonStyle}
        >
          {dropdownButtonText}
        </Button>
        <SortQuizzesMenu
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          classes={classes}
          setDropdownButtonText={setDropdownButtonText}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          handleSortQuiz={handleSortQuiz}
        />
      </Grid>

      {/* Published Quizzes section */}
      <PublishedQuizzesSection allQuizzes={allQuizzes} setAllQuizzes={setAllQuizzes} />

      {/* Published Quizzes section */}
      <DraftsQuizzesSection
        allDraftQuizzes={allDraftQuizzes}
        setAllDraftQuizzes={setAllDraftQuizzes}
      />
      {/* Published Quizzes section */}
      <PendingQuizzesSection
        allPendingQuizzes={allPendingQuizzes}
        setAllPendingQuizzes={setAllPendingQuizzes}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Pagination
          count={Math.ceil(totalQuize / 10)}
          page={quizPage}
          onChange={(_, value) => setQuizePage(value)}
          showFirstButton
          showLastButton
        />
      </div>
    </Fragment>
  );
};

export default Quizzes;

const useStyles = makeStyles(() => {
  return {
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
    customWidth: {
      "& div": {
        width: "290px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },

    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      color: "#000",
    },
  };
});

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
