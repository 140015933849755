import React from "react";

import UsersTable from "../dashboard/registeredUsers/UsersTable";

const SuspendedUsersTab = ({ suspendedUsers }) => {
  return (
    <UsersTable
      allUsers={suspendedUsers && suspendedUsers}
      headerTitle="Deactivated Accounts"
    />
  );
};

export default SuspendedUsersTab;
