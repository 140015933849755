import React, { useEffect, useState } from "react";
// import { registeredUsersData } from "../../components/constants";
import { useUsersContext } from "../../context/UsersContext";
import UsersMainPage from "./UsersMainPage";
import { useAuth } from "../../context/AuthContext";

const Users = () => {
  const {
    personalUsers,
    setPersonalUsers,
    handleGetAllUsers,
    businessUsers,
    setBusinessUsers,
    personalUsersRowsPage,
    setPersonalUsersRowPage,
    businessUsersRowsPage,
    setBusinessUsersRowPage,
  } = useUsersContext();

  // const { userToken } = useAuth();

  // console.log("token:", userToken);

  const [allPersonalUsers, setAllPersonalUsers] = useState([]);
  const [allBusinessUsers, setAllBusinessUsers] = useState([]);

  // console.log("users:", personalUsers)

  useEffect(() => {
    setAllPersonalUsers(personalUsers);
  }, [personalUsers]);
  useEffect(() => {
    setAllBusinessUsers(businessUsers);
  }, [businessUsers]);

  return (
    <UsersMainPage
      allPersonalUsers={allPersonalUsers}
      setAllPersonalUsers={setAllPersonalUsers}
      allBusinessUsers={allBusinessUsers}
      setAllBusinessUsers={setAllBusinessUsers}
      mainTitle="Manage Users"
    />
  );
};

export default Users;
