import { API } from "../constants";
import { request } from "../constants/request";

export const getDashboardCounts = () => {
  return request({
    url: API.baseUrl + API.getDashboard,
    method: "POST",
  });
};

export const getDashboardCountsByDates = (dates) => {
  return request({
    url: API.baseUrl + API.getDashboard,
    method: "POST",
    data: dates,
  });
};
