import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import ReviewCard from "./ReviewCard";
import SortReviews from "./SortReviews";

const ReviewsTab = ({
  wineData,
  setWineData,
  wineReviews,
  setWineReviews,
  handleGetWineReviews,
}) => {
  const [allReviews, setAllReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState({});
  const [dropdownButtonText, setDropdownButtonText] = useState("Sort");
  const [sortOption, setSortOption] = React.useState("");
  const classes = useStyles();

  useEffect(() => {
    setAllReviews(wineReviews.length ? wineReviews : []);
  }, [wineReviews]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  // console.log("reviews:", wineReviews)

  const handleSortOptionChange = (e) => {
    if (e.target.value.toLowerCase() === "ascending") {
      setSortOption(e.target.value);
      let sorted = [...allReviews];
      sorted.sort((a, b) =>
        [a?.user.first_name] > [b?.user.first_name] ? 1 : -1
      );
      setAllReviews(sorted);
      handleCloseSortMenu();
    } else {
      setSortOption(e.target.value);
      let sorted = [...allReviews];
      sorted.sort((a, b) =>
        [a?.user.first_name] > [b?.user.first_name] ? -1 : 1
      );
      setAllReviews(sorted);
      handleCloseSortMenu();
    }
    // console.log(e.target.value);
    setSortOption(e.target.value);
    let sorted = [...allReviews];
    sorted.sort((a, b) => (a > b ? 1 : -1));
    setWineReviews(sorted);
    handleCloseSortMenu();
  };
  return (
    <Grid container>
      <Grid container display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
          style={buttonStyle}
        >
          Sort
        </Button>
        <SortReviews
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          classes={classes}
          setDropdownButtonText={setDropdownButtonText}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          // handleSortWines={handleSortWines}
        />
      </Grid>
      <Grid container direction={"column"} style={{ marginTop: 35 }}>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={3}>
            <span style={tableTitleStyles}>USER</span>
          </Grid>
          <Grid item xs={5}>
            <span style={tableTitleStyles}>REVIEW</span>
          </Grid>
          <Grid item xs={2}>
            <span style={tableTitleStyles}>DATE</span>
          </Grid>
          <Grid item xs={1}>
            <span style={tableTitleStyles}>RATING</span>
          </Grid>
          <Grid item xs={1}>
            <span style={tableTitleStyles}></span>
          </Grid>
        </Grid>
        {wineReviews && wineReviews.length ? (
          wineReviews.map((review, index) => (
            <ReviewCard
              review={review}
              key={index}
              handleGetWineReviews={handleGetWineReviews}
              handleSelectReview={() => {
                setSelectedReview(review);
              }}
            />
          ))
        ) : (
          <Grid
            container
            alignItems={"center"}
            justifyContent="center"
            style={{ marginTop: 30 }}
          >
            <span>No Reviews available</span>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ReviewsTab;

const tableTitleStyles = {
  color: "#8086A2",
  fontSize: 13,
  fontWeight: 700,
  paddingLeft: 20,
};

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const useStyles = makeStyles(() => {
  return {
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
    customWidth: {
      "& div": {
        width: "290px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    filterCustomWidth: {
      "& div": {
        width: "330px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
    selectedFilterButton: {
      color: "#FFFFFF",
      background: "#C84B96",
      borderRadius: 21,
      marginRight: 20,
    },
    nonSelectedFilterButton: {
      color: "#000000",
      borderRadius: 21,
      marginRight: 20,
      borderColor: "#000000",
    },
  };
});
