import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles({
  cardItemTitle: {
    color: "#000000",
    fontSize: 16,
    fontWeight: 600,
    // fontFamily: "Poppins",
  },
  cardCount: {
    color: "#C84B96",
    fontSize: 32,
    fontWeight: 700,
  },
  percentText: {
    color: "#000",
    fontSize: 16,
    fontWeight: 500,
  },
});

const AnalyticsCard = ({ card }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <Grid
      container
      style={{
        width: 280,
        height: 160,
        borderRadius: 20,
        background: "#F9F9FA",
        margin: 10,
      }}
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid container alignItems="center" justifyContent="center">
        <span className={classes.cardItemTitle}>
          {card && card.title && card.title}{" "}
        </span>
        {card && card.expandable && (
          <ArrowForwardIosIcon
            style={{ width: 16, marginLeft: 10, cursor: "pointer" }}
            onClick={() => {
              card && card.link && navigate(card.link);
            }}
          />
        )}
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <span className={classes.cardCount}>
          {card && card.count && card.count}
        </span>
      </Grid>
      {/* <Grid container alignItems="center" justifyContent="center">
        <span className={classes.percentText}>
          <a
            style={{
              color: card.percent && card.percent > 0 ? "#1FCBAC" : "#FD355A",
            }}
          >
            {card && card.percent && card.percent > 0
              ? "+" + card.percent
              : card.percent}
            %{" "}
          </a>
          vs last week
        </span>
      </Grid> */}
    </Grid>
  );
};

export default AnalyticsCard;
