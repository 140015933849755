import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAllWines,
  getSingleWine,
  getSearchWine,
  getWines,
} from "../apis/wines";
import { useAuth } from "./AuthContext";

export const WinesContext = createContext();
export const useWinesContext = () => useContext(WinesContext);

export const WinesProvider = ({ children }) => {
  const { userToken } = useAuth();
  const [wines, setWines] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPage, setRowPage] = useState(10);

  const [loading, setLoading] = useState(false);
  const [winesTableDetail, setWinesTableDetail] = useState({
    current_page: 1,
    total: 0,
  });

  useEffect(() => {
    setLoading(true);
    handleGetAllWines(10);
  }, [userToken, currentPage]);

  // useEffect(() => {
  //   handleGetWines();
  // }, [userToken]);

  const handleGetAllWines = async (limit) => {
    try {
      let docsTemp = await getAllWines(currentPage, limit ? limit : 10);
      // console.log("Test Current Docs>>", docsTemp);
      setWines(docsTemp.data);
      setWinesTableDetail(docsTemp);
      setLoading(false);
    } catch (error) {
      console.log("error getting wines:", error.message);
    }
  };

  // const handleGetWines = async () => {
  //   try {
  //     let docsTemp = await getWines();
  //     // console.log("Test Wines>>", docsTemp);
  //   } catch (error) {
  //     console.log("error getting wines:", error.message);
  //   }
  // };

  const handleGetSingleWine = async (wineId) => {
    try {
      let docsTemp = await getSingleWine(wineId);
      // console.log("single Wine>>>", docsTemp);
      return docsTemp;
    } catch (error) {
      console.log("error getting single wine:", error.message);
    }
  };

  const handleSearchWine = async (name) => {
    try {
      let docsTemp = await getSearchWine(name);
      // console.log(docsTemp);
      setWines(docsTemp);
      // return docsTemp;
    } catch (error) {
      console.log("error getting single wine:", error.message);
    }
  };

  return (
    <WinesContext.Provider
      value={{
        wines,
        setWines,
        handleGetAllWines,
        handleGetSingleWine,
        handleSearchWine,
        winesTableDetail,
        setWinesTableDetail,
        setCurrentPage,
        currentPage,
        loading,
        setLoading,
        rowsPage,
        setRowPage,
      }}
    >
      {children}
    </WinesContext.Provider>
  );
};
