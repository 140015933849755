import React, { useCallback, useRef, useState } from "react";
import { Button, Grid, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";

// import Cropper from "react-easy-crop";

// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { modalStyle } from "../constants";
import ButtonLoader from "../ButtonLoader";

const AddMenuModal = ({
  modalOpen,
  handleClose,
  handleConfirm,
  menuData,
  setMenuData,
  loading,
  editMenu,
  menuItems,
}) => {
  const imageRef = useRef();
  const [editImageModal, setEditImageModal] = useState(false);

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const handlePhotoChange = (e) => {
    if (e.target.files.length) {
      setMenuData({
        ...menuData,
        image: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        },
      });
      setEditImageModal(true);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        handleClose();
        setEditImageModal(false);
      }}
    >
      <Box sx={modalStyle}>
        <Grid container justifyContent={"center"}>
          <span style={titleStyles}>
            {menuItems
              ? editMenu
                ? "Edit Menu Item"
                : "Add Menu Item"
              : editMenu
              ? "Edit Menu"
              : "Add Menu"}
          </span>
        </Grid>
        <Grid container alignItems={"center"} justifyContent="center">
          {editImageModal ? (
            <Grid
              container
              alignItems={"center"}
              justifyContent="center"
              style={{
                width: 335,
                height: 262,
                marginBottom: 10,
                borderRadius: 12,
              }}
            >
              <img
                src={
                  menuData &&
                  menuData.image &&
                  menuData.image.preview &&
                  menuData.image.preview
                }
                style={{
                  width: 252,
                  height: 250,
                  borderRadius: 12,
                  margin: "10px",
                }}
                alt=""
              />
            </Grid>
          ) : (
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              direction="column"
              style={{
                width: 335,
                height: 262,
                borderRadius: 12,
                margin: "30px 0px 20px 0px",
                border: "1px dashed #85848B",
                cursor: "pointer",
              }}
              onClick={showOpenFileDialog}
            >
              <span
                style={{
                  color: "#C84B96",
                  fontSize: 18,
                  fontWeight: 700,
                  paddingBottom: 32,
                }}
              >
                Upload Image Here{" "}
              </span>
              <span
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontWeight: 500,
                  width: 210,
                  textAlign: "center",
                }}
              >
                * We accept images (jpg.)
              </span>
              <span
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontWeight: 500,
                  width: 210,
                  textAlign: "center",
                }}
              >
                * Image size: min. 500x500 px
              </span>
            </Grid>
          )}
          <input
            ref={imageRef}
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => handlePhotoChange(e)}
          />
        </Grid>
        <TextField
          variant="outlined"
          fullWidth
          value={menuData && menuData.name && menuData.name}
          onChange={(e) => setMenuData({ ...menuData, name: e.target.value })}
          placeholder="Type Name"
          style={{ marginBottom: 20, width: 335 }}
        />
        <Grid container>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              handleClose();
              setEditImageModal(false);
            }}
            style={cancelButtonStyles}
          >
            Cancel
          </Button>
          {loading ? (
            <Grid
              container
              style={{ width: "55%" }}
              alignItems="center"
              justifyContent={"center"}
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirm}
              style={confirmButtonStyles}
            >
              Okay
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddMenuModal;

const titleStyles = { color: "#151E24", fontSize: 20, fontWeight: 700 };

const confirmButtonStyles = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
  borderRadius: 8,
  marginLeft: "auto",
};

const cancelButtonStyles = {
  width: "130px",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  borderRadius: 8,
  textTransform: "none",
};
