import React from "react";
import CertificationsTable from "./certificationsTab/CertificationsTable";

const CertificationsTab = ({ certifications, setCertifications }) => {
  return (
    <CertificationsTable
      certifications={certifications}
      setCertifications={setCertifications}
    />
  );
};

export default CertificationsTab;
