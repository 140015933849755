import React, { useState, useEffect } from "react";
// import { deactivatedUsersData } from "../../components/constants";
import { useUsersContext } from "../../context/UsersContext";

import UsersMainPage from "../users/UsersMainPage";

const DeactivatedUsers = () => {
  const { deactivatedUsers, personalUsers, businessUsers, users } =
    useUsersContext();

  const [allBusinessUsers, setAllBusinessUsers] = useState(businessUsers);
  const [allPersonalUsers, setAllPersonalUsers] = useState(personalUsers);
  const [allUsers, setAllUsers] = useState(users);
  const [allDeactivatedUsers, setAllDeactivatedUsers] =
    useState(deactivatedUsers);

  useEffect(() => {
    setAllBusinessUsers(businessUsers);
  }, [businessUsers]);
  useEffect(() => {
    setAllPersonalUsers(personalUsers);
  }, [personalUsers]);
  useEffect(() => {
    setAllUsers(users);
  }, [users]);
  useEffect(() => {
    setAllDeactivatedUsers(deactivatedUsers);
  }, [deactivatedUsers]);

  return (
    <UsersMainPage
      allPersonalUsers={allPersonalUsers}
      allUsers={allUsers}
      setAllUsers={setAllUsers}
      setAllPersonalUsers={setAllPersonalUsers}
      allBusinessUsers={allBusinessUsers}
      setAllBusinessUsers={setAllBusinessUsers}
      allDeactivatedUsers={allDeactivatedUsers && allDeactivatedUsers}
      setAllDeactivatedUsers={setAllDeactivatedUsers}
      headerTitle="Deactivated Accounts"
    />
  );
};

export default DeactivatedUsers;
