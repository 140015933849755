import { API } from "../constants";
import { request } from "../constants/request";

export const getFoodMenus = () => {
  return request({
    url: API.baseUrl + API.getFoodMenus,
    method: "GET",
  });
};
export const addFoodMenu = (data) => {
  return request({
    url: API.baseUrl + API.getFoodMenus,
    method: "POST",
    data: data,
  });
};
export const editFoodMenu = (data, menuId) => {
  return request({
    url: API.baseUrl + API.getFoodMenus + `/${menuId}`,
    method: "POST",
    data: data,
  });
};
export const deleteFoodMenu = (data, menuId) => {
  return request({
    url: API.baseUrl + API.getFoodMenus + `/${menuId}`,
    method: "POST",
    data: data,
  });
};

export const getMenuItems = (menuId) => {
  return request({
    url: API.baseUrl + API.getMenuItems + `?filter[menu_id][is]=${menuId}`,
    method: "GET",
  });
};
export const addFoodMenuItem = (data, menuId) => {
  return request({
    url: API.baseUrl + API.getMenuItems,
    method: "POST",
    data: data,
  });
};
export const editFoodMenuItem = (data, menuItemId) => {
  return request({
    url: API.baseUrl + API.getMenuItems + `/${menuItemId}`,
    method: "POST",
    data: data,
  });
};
export const deleteFoodMenuItem = (data, menuItemId) => {
  return request({
    url: API.baseUrl + API.getMenuItems + `/${menuItemId}`,
    method: "POST",
    data: data,
  });
};
