import React, { useState } from "react";
import { Avatar, Grid, Menu, MenuItem } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { baseUrl } from "../../constants";

const MenuItemCard = ({
  menuItemData,
  setSelectedMenuItem,
  handleEditMenuItemModalOpen,
  handleDeleteMenuItemModalOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const EditDeleteMenu = () => (
    <Menu
      id="edit-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "editMenuButton",
      }}
    >
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          // console.log(menuItemData);
          setSelectedMenuItem(menuItemData);
          handleEditMenuItemModalOpen();
          handleClose();
        }}
      >
        Edit
      </MenuItem>
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          // console.log(menuItemData);
          setSelectedMenuItem(menuItemData);
          handleDeleteMenuItemModalOpen();
          handleClose();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <Grid item style={cardContainerStyles}>
      <Grid
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
        style={{ padding: 20 }}
      >
        <MoreVertIcon
          id="editMenuButton"
          aria-controls={open ? "edit-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        />
        <EditDeleteMenu />
        {menuItemData.image !== null ? (
          <Avatar
            src={`${baseUrl + menuItemData.image}`}
            style={{ width: 100, height: 100 }}
          />
        ) : (
          <Avatar
            src={menuItemData && menuItemData.image && menuItemData.image}
            style={{ width: 100, height: 100 }}
          >
            {menuItemData &&
              menuItemData.name &&
              menuItemData.name.substring(0, 2)}
          </Avatar>
        )}
        <span style={cardTilteStyles}>
          {menuItemData && menuItemData.name && menuItemData.name}
        </span>
      </Grid>
    </Grid>
  );
};

export default MenuItemCard;

const cardContainerStyles = {
  width: 215,
  height: 320,
  borderRadius: 12,
  border: "1px solid #E2EBEF",
  marginRight: 25,
  marginBottom: 25,
};

const cardTilteStyles = { fontSize: 18, fontWeight: 700, paddingTop: 14 };

const menuItemStyles = { width: 150, fontSize: 14, fontWeight: 600 };
