import React from "react";
import { Button, Grid, Toolbar } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const RecipeHeader = ({ handleModalOpen }) => {
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  // const navigate = useNavigate();
  // const location = useLocation();

  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid
          item
          xs
          style={{
            textAlign: "end",
            width: matchesXL
              ? "65em"
              : matchesLG
              ? "50em"
              : matchesMD
              ? "35em"
              : matchesSM
              ? "28em"
              : "23em",
          }}
        >
          <span style={{ color: "#313137", fontWeight: 700, fontSize: 36 }}>
            Manage Recipes
          </span>
        </Grid>
        <Grid item xs style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            onClick={handleModalOpen}
            style={addButtonStyles}
          >
            Add New Recipe
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default RecipeHeader;

const addButtonStyles = {
  color: "#fff",
  background: "#C84B96",
  borderRadius: 12,
  fontWeight: 600,
  textTransform: "capitalize",
  fontSize: 15,
  height: 50,
};
