import React, { useCallback, useRef, useState } from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import Cropper from "react-easy-crop";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { modalStyle } from "../../constants";

const UploadImageModal = ({
  modalOpen,
  handleClose,
  handleConfirm,
  wineData,
  setWineData,
}) => {
  const imageRef = useRef();
  const [editImageModal, setEditImageModal] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
  }, []);

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const handlePhotoChange = (e) => {
    if (e.target.files.length) {
      setWineData({
        ...wineData,
        wineImage: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        },
      });
      setEditImageModal(true);
    }
  };

  {
    /*
  <img
  src={wineData.wineImage.preview}
  style={{
    width: 322,
    height: 262,
    borderRadius: 12,
    margin: "30px 0px 40px 0px",
  }}
  alt=""
  />
*/
  }

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        handleClose();
        setEditImageModal(false);
      }}
    >
      <Box sx={modalStyle}>
        <Grid container justifyContent={"felx-start"}>
          <ArrowBackIosNewOutlinedIcon
            style={{ marginBottom: -5, marginRight: 20 }}
          />
          <span style={titleStyles}>
            {editImageModal ? "Edit Image" : "Upload Image"}
          </span>
        </Grid>
        <Grid container alignItems={"center"} justifyContent="center">
          {editImageModal ? (
            <Grid
              container
              alignItems={"center"}
              justifyContent="center"
              style={{
                width: 322,
                height: 262,
                marginBottom: 44,
                borderRadius: 12,
              }}
            >
              <Cropper
                image={wineData.wineImage.preview}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape={"round"}
                cropSize={{ width: 245, height: 245 }}
                showGrid={false}
                style={{
                  containerStyle: {
                    width: 322,
                    height: 262,
                    margin: "100px 70px",
                    borderRadius: 12,
                  },
                  mediaStyle: { objectFit: "cover", width: 322, height: 270 },
                }}
              />
            </Grid>
          ) : (
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              direction="column"
              style={{
                width: 322,
                height: 262,
                borderRadius: 12,
                margin: "30px 0px 40px 0px",
                border: "1px dashed #85848B",
                cursor: "pointer",
              }}
              onClick={showOpenFileDialog}
            >
              <span
                style={{
                  color: "#C84B96",
                  fontSize: 18,
                  fontWeight: 700,
                  paddingBottom: 32,
                }}
              >
                Upload Image Here{" "}
              </span>
              <span
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontWeight: 500,
                  width: 210,
                  textAlign: "center",
                }}
              >
                * We accept images (jpg.)
              </span>
              <span
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontWeight: 500,
                  width: 210,
                  textAlign: "center",
                }}
              >
                * Image size: min. 500x500 px
              </span>
            </Grid>
          )}
          <input
            ref={imageRef}
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => handlePhotoChange(e)}
          />
        </Grid>
        <Grid container>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              handleClose();
              setEditImageModal(false);
            }}
            style={cancelButtonStyles}
          >
            Cancel
          </Button>
          {editImageModal ? (
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              style={confirmButtonStyles}
            >
              Crop & Upload
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={showOpenFileDialog}
              style={confirmButtonStyles}
            >
              Upload
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default UploadImageModal;

const titleStyles = { color: "#151E24", fontSize: 20, fontWeight: 700 };

const confirmButtonStyles = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
  borderRadius: 8,
  marginLeft: "auto",
};

const cancelButtonStyles = {
  width: "130px",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  borderRadius: 8,
  textTransform: "none",
};
