import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { modalStyle } from "../../constants";

const ShowImageModal = ({ modalOpen, handleClose, image }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container>
          <img
            src={image}
            alt=""
            style={{
              width: 356,
              height: 356,
              borderRadius: 10,
              marginBottom: 15,
            }}
          />
        </Grid>
        <Button
          fullWidth
          variant="contained"
          onClick={handleClose}
          style={{
            textTransform: "none",
            height: 55,
            fontSize: 15,
            fontWeight: 600,
            background: "#C84B96",
            color: "#FFFFFF",
            marginTop: 20,
            margin: 5,
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ShowImageModal;
