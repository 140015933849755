import { Button, Grid, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ButtonLoader from "../ButtonLoader";

import quizDummyImage from "../../images/quizImages/img1.png";

const QuizCard = ({ quiz, allQuizzes, status, handleFeatureQuizzes, handleUnFeatureQuizzes, setSelectedQuiz, feartureLoading  }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const FeaturedMenu = () => (
    <Menu
      id="edit-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "editMenuButton",
      }}
    >{status === "Publish" ? (
        <MenuItem
          // style={menuItemStyles}
          onClick={() => {
            setSelectedQuiz(quiz);
            if(quiz?.is_featured === 1){
            handleUnFeatureQuizzes();
            } else{
              handleFeatureQuizzes();
            }
            handleClose();
          }}
        >
          {!feartureLoading ? <>{quiz?.is_featured === 1 ? "Un-feature" : "Feature" } </>: <ButtonLoader /> }
        </MenuItem> )
       : null
    }
    </Menu>
  );
  return (
    <Grid
      item
      container
      direction={"column"}
      style={{
        width: 220,
        height: "auto",
        padding: "40px 30px 30px 30px",
        borderRadius: 20,
        background: "#F9F9FA",
        marginRight: 15,
        marginBottom: 15,
        border:`4px solid ${quiz.is_featured ? "#D675AC" : "#fff"}`,
      }}
      alignItems="center"
      justifyContent={"space-between"}
    >
    {
      status === "Publish" ? 
      <>
      <MoreVertIcon
          id="editMenuButton"
          aria-controls={open ? "edit-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            setSelectedQuiz(quiz);
            handleClick(e);
          }}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        />
        <FeaturedMenu/> 
      </>
        : null
    }
    
      <img
        // src={quiz.image && quiz.image}
        src={quizDummyImage}
        alt="quiz"
        style={{ width: 98, height: 67 }}
      />
      <Grid item container style={{ height: 40, marginTop: 30 }}>
        <span
          style={{
            fontWeight: 700,
            fontSize: 16,
            color: "#000",
            // paddingTop: 30,
          }}
        >
          {quiz.name && quiz.name}
        </span>
      </Grid>
      <Grid item container style={{ height: 40 }}>
        <span
          style={{
            fontWeight: 600,
            fontSize: 16,
            color: "#000",
            // paddingTop: 30,
          }}
        >
          Level:
        </span>
        <span style={{ textTransform: "capitalize" }}>&nbsp;{quiz.level && quiz.level}</span>
      </Grid>
      <Grid item container style={{ height: 40, marginTop: 10 }}>
        <span
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: "rgba(128, 134, 162, 0.7)",
          }}
        >
          {quiz.questions_count && quiz.questions_count} questions
        </span>
      </Grid>
      {quiz.is_featured ?  <Grid item container style={{ height: 40, marginTop: 10, display:'flex', flexDirection:'row', alignItems:'center' }}>
        <p
          style={{
            fontWeight: 700,
            fontSize: 20,
            color: "#D675AC",
          }}
        >
          Featured
        </p>
      </Grid> : null
      }
      <Grid item container>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            navigate("/quizzes/quizDetails", {
              state: { quizData: quiz, allQuizzes, status },
            });
          }}
          style={{
            marginTop: 15,
            color: "#fff",
            fontSize: 14,
            fontWeight: 600,
            textTransform: "none",
            background: "#4E1B5C",
            borderRadius: 8,
          }}
        >
          View Quiz
        </Button>
      </Grid>
    </Grid>
  );
};

export default QuizCard;
