import React from "react";
import { Grid, InputAdornment, TextField, Toolbar } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";

const Header = ({ onChange }) => {
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  // const navigate = useNavigate();
  // const location = useLocation();

  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid
          item
          xs
          style={{
            textAlign: "end",
            width: matchesXL
              ? "65em"
              : matchesLG
              ? "50em"
              : matchesMD
              ? "35em"
              : matchesSM
              ? "28em"
              : "23em",
          }}
        >
          <span style={{ color: "#313137", fontWeight: 700, fontSize: 36 }}>
            Wines
          </span>
        </Grid>
        <Grid item xs style={{ textAlign: "end" }}>
          <TextField
            id="search"
            onKeyDown={onChange}
            // onChange={(e) => onChange(e)}
            placeholder="Search Wines"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                background: "#F6F6F6",
                borderRadius: 33,
                width: 323,
                height: 48,
              },
            }}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default Header;
