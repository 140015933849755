import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Avatar, Button, Grid } from "@mui/material";

import AdDetailsHeader from "../../components/dashboard/ads/AdDetailsHeader";
import AdDetailsTextField from "../../components/dashboard/ads/AdDetailsTextField";
import moment from "moment";
import { baseUrl } from "../../components/constants";

import userImage from "../../images/users/User_New1.png";

const AdDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(location.state.card);

  const [adData, setAdData] = useState(location.state.card);

  return (
    <Fragment>
      {/* Header Section */}
      <AdDetailsHeader navigate={navigate} adData={adData} />

      {/* Media Details Section */}
      <Grid container spacing={2} style={{ padding: "60px 55px" }}>
        <Grid item container direction="column" xs={5}>
          <Avatar
            src={userImage}
            style={{ width: 50, height: 50, marginBottom: 20 }}
          />
          <img
            // src={`url(${baseUrl + adData?.post?.media[0]?.media})`}
            src={`${
              adData.post &&
              adData.post.media &&
              adData.post.media[0] &&
              baseUrl + adData.post.media[0].media
            }`}
            // src={adData.image}
            alt="ads image"
            style={{
              width: 400,
              height: 400,
              marginBottom: 20,
              borderRadius: 10,
            }}
          />
          {Math.ceil(
            new Date(adData.end_date).getTime() - new Date().getTime()
          ) /
            (1000 * 3600 * 24) <
          0 ? (
            <Button
              variant="contained"
              style={{
                width: 260,
                background: "#FFEEEE",
                color: "#FF5655",
                fontSize: 16,
                fontWeight: 600,
                textTransform: "none",
              }}
            >
              Expired
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{
                width: 260,
                background: "#EEFCF8",
                color: "#17DDA2",
                fontSize: 16,
                fontWeight: 600,
                textTransform: "none",
              }}
            >
              {`Active: Day ${Math.ceil(
                (new Date(adData.end_date).getTime() - new Date().getTime()) /
                  (1000 * 3600 * 24)
              )} of ${
                (new Date(adData.end_date).getTime() -
                  new Date(adData.start_date).getTime()) /
                (1000 * 3600 * 24)
              }`}
            </Button>
          )}
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={7}
          style={{ marginTop: 70 }}
        >
          <AdDetailsTextField
            title={"Budget"}
            value={adData && adData.budget ? adData.budget : "$00"}
          />
          <AdDetailsTextField
            title={"Start Date"}
            value={
              adData &&
              adData.start_date &&
              moment(adData.start_date).format("MMMM DD, YYYY")
            }
          />
          <AdDetailsTextField
            title={"End Date"}
            value={
              adData &&
              adData.end_date &&
              moment(adData.end_date).format("MMMM DD, YYYY")
            }
          />
          <AdDetailsTextField
            title={"Description"}
            value={adData && adData.post && adData.post.caption}
            multiline={true}
            rows={4}
          />
          <AdDetailsTextField
            title={"Website"}
            value={adData && adData.website && adData.website}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AdDetails;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};
