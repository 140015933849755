import React, { useState } from "react";
import { Avatar, Grid, Menu, MenuItem } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../constants";
import ButtonLoader from "../ButtonLoader";

const MenuCard = ({
  menuData,
  setSelectedMenu,
  handleEditMenuModalOpen,
  handleDeleteMenuModalOpen,
  feartureLoading,
  handleFeatureMenu,
  handleUnFeatureMenu,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const EditDeleteMenu = () => (
    <Menu
      id="edit-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "editMenuButton",
      }}
    >
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          // console.log(menuData);
          setSelectedMenu(menuData);
          handleEditMenuModalOpen();
          handleClose();
        }}
      >
        Edit
      </MenuItem>
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          // console.log(menuData);
          setSelectedMenu(menuData);
          handleDeleteMenuModalOpen();
          handleClose();
        }}
      >
        Delete
      </MenuItem>
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          // console.log(menuData);
          navigate("/menus/menu-items", {
            state: { menuData: menuData },
          });
          handleClose();
        }}
      >
        Menu Items
      </MenuItem>
      <MenuItem
        style={menuItemStyles}
        onClick={() => {
          // console.log(menuData);
          navigate("/menus/recipes", {
            state: { menuData: menuData },
          });
          handleClose();
        }}
      >
        Recipes
      </MenuItem>
      {menuData.is_featured && menuData.is_featured === 1 ? (
        <MenuItem
          style={menuItemStyles}
          onClick={() => {
            // console.log(menuData);
            setSelectedMenu(menuData);
            handleUnFeatureMenu();
            handleClose();
          }}
        >
          {feartureLoading ? <ButtonLoader /> : "Un-feature"}
        </MenuItem>
      ) : (
        <MenuItem
          style={menuItemStyles}
          onClick={() => {
            // console.log(menuData);
            setSelectedMenu(menuData);
            handleFeatureMenu();
            handleClose();
          }}
        >
          {feartureLoading ? <ButtonLoader /> : "Feature"}
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Grid
      item
      style={
        menuData.is_featured === 1
          ? cardFeaturedContainerStyles
          : cardContainerStyles
      }
    >
      <Grid
        container
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
        style={{ padding: 20 }}
      >
        <MoreVertIcon
          id="editMenuButton"
          aria-controls={open ? "edit-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            setSelectedMenu(menuData);
            handleClick(e);
          }}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        />
        <EditDeleteMenu />
        {menuData.icon !== null ? (
          <Avatar
            src={`${baseUrl + menuData.icon}`}
            style={{ width: 100, height: 100 }}
          />
        ) : (
          <Avatar
            src={menuData && menuData.icon && menuData.icon}
            style={{ width: 100, height: 100 }}
          >
            {menuData && menuData.name && menuData.name.substring(0, 2)}
          </Avatar>
        )}
        <div style={cardTilteStyles}>
          {menuData && menuData.name && menuData.name}
        </div>
        <div style={{ color: "#D675AC", fontWeight: 700 }}>
          {menuData.is_featured && menuData.is_featured === 1 ? "Featured" : ""}
        </div>
      </Grid>
    </Grid>
  );
};

export default MenuCard;

const cardContainerStyles = {
  width: 215,
  height: 235,
  borderRadius: 12,
  border: "1px solid #E2EBEF",
  marginRight: 25,
  marginBottom: 25,
};
const cardFeaturedContainerStyles = {
  width: 215,
  height: 215,
  borderRadius: 12,
  border: "4px solid #d675ac",
  marginRight: 25,
  marginBottom: 25,
};

const cardTilteStyles = { fontSize: 18, fontWeight: 700, paddingTop: 10 };

const menuItemStyles = { width: 150, fontSize: 14, fontWeight: 600 };
