import React, { Fragment, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Header from "../../components/wines/Header";
import SortWinesMenu from "../../components/wines/SortWinesMenu";
import FilterWinesMenu from "../../components/wines/FilterWinesMenu";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import { winesData } from "../../components/constants";
import { Toast } from "../../components/alert";
import WinesTable from "../../components/wines/WinesTable";
import ManageWineModal from "../../components/wines/ManageWineModal";
import { useLocation } from "react-router-dom";
import { useWinesContext } from "../../context/WinesContext";
import { addWine } from "../../apis/wines";
import { winesSearch } from "../../common";

const Wines = () => {
  const {
    wines,
    setWines,
    handleGetAllWines,
    handleSearchWine,
    winesTableDetail,
    rowsPage,
    setRowPage,
  } = useWinesContext();

  const classes = useStyles();
  const location = useLocation();

  const [allWines, setAllWines] = useState(
    location.state && location.state.allWines ? location.state.allWines : wines
  );
  const [selectedWine, setSelectedWine] = useState({});

  const [newWine, setNewWine] = useState({
    name: "",
    price: "",
    varietal: "",
    region: "",
    vintage: "",
  });

  const [addWineModal, setAddWineModal] = useState(false);
  const [editWineModal, setEditWineModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [dropdownButtonText, setDropdownButtonText] = useState("Sort");
  const [sortOption, setSortOption] = React.useState("Ascending");
  const [filterData, setFilterData] = useState({
    varietal: "All",
    region: "All",
    vintage: "All",
  });

  useEffect(() => {
    setAllWines(wines);
  }, [wines]);

  const open = Boolean(anchorEl);
  const openFilter = Boolean(filterAnchorEl);

  const handleAddWineModalOpen = () => {
    setAddWineModal(true);
  };
  const handleAddWineModalClose = () => {
    setAddWineModal(false);
  };

  const handleAddNewWine = async () => {
    try {
      setLoading(true);
      // console.log(newWine);

      let temp = {
        name: newWine.name,
        price: newWine.price,
        varietal: newWine.varietal,
        vintage: [newWine.vintage.toString()],
        region: newWine.region,
        plan: "Cooking at home",
      };

      let addDocTemp = await addWine(temp);
      await handleGetAllWines(rowsPage);

      // let id = new Date().getUTCMilliseconds();
      // let docsTemp = [...allWines];
      // docsTemp.push({ ...temp, id: addDocTemp.id });
      // setAllWines(docsTemp);

      console.log("response:", addDocTemp);
      // console.log("all admins:", docsTemp);
      setNewWine({
        name: "",
        price: "",
        varietal: "",
        region: "",
        vintage: "",
      });

      handleAddWineModalClose();
      Toast("success", "New Wine Added Successfully.");
    } catch (error) {
      console.log("error adding new wine:", error.message);
      handleAddWineModalClose();
      Toast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditWineModalOpen = () => {
    setEditWineModal(true);
  };
  const handleEditWineModalClose = () => {
    setEditWineModal(false);
  };

  const handleUpdateWine = async () => {
    try {
      setLoading(true);
      // console.log(newWine);

      let docsTemp = [...allWines];
      let index;
      docsTemp.forEach((doc) => {
        if (doc.id == selectedWine.id) {
          index = docsTemp.indexOf(doc);
        }
      });
      if (index) {
        docsTemp[index] = selectedWine;
      }

      setAllWines(docsTemp);

      // console.log("wine updated:", selectedWine);
      setSelectedWine({});
      handleEditWineModalClose();
      Toast("success", "Wine Updated Successfully.");
    } catch (error) {
      console.log("error updating wine:", error.message);
      handleAddWineModalClose();
      Toast("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = async (e) => {
    let value = e.target.value;

    if (e.keyCode === 13) {
      if (value.length >= 1) {
        // let search = await winesSearch(wines, value);
        // setAllWines(search);
        await handleSearchWine(value);
      } else {
        await handleGetAllWines(rowsPage);
        // setAllWines(wines);
      }
    }
  };

  const handleSortWines = (field) => {
    setDropdownButtonText(field);
    let sorted = [...wines];
    if (field === "All") {
      setAllWines(wines);
    }
    sorted.sort((a, b) =>
      field === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Varietal"
        ? a.varietal > b.varietal
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Location"
        ? a.region > b.region
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : field === "Vintage"
        ? a.vintage > b.vintage
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    setAllWines(sorted);
    handleCloseSortMenu();
  };

  const handleSortOptionChange = (e) => {
    // console.log(e.target.value);
    setSortOption(e.target.value);
    let sorted = [...allWines];
    sorted.sort((a, b) =>
      dropdownButtonText === "Name"
        ? a.name > b.name
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Varietal"
        ? a.varietal > b.varietal
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Location"
        ? a.region > b.region
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : dropdownButtonText === "Vintage"
        ? a.vintage > b.vintage
          ? sortOption === "Ascending"
            ? 1
            : -1
          : sortOption === "Ascending"
          ? -1
          : 1
        : a.name > b.name
        ? sortOption === "Ascending"
          ? 1
          : -1
        : sortOption === "Ascending"
        ? -1
        : 1
    );
    setAllWines(sorted);
    handleCloseSortMenu();
  };

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  const handleFilter = async (filterOption) => {
    setFilterData(filterOption);
    // console.log("option :", filterOption);
    let itemsTemp = [];
    for (let i = 0; i < wines.length; i++) {
      let el = wines[i];
      if (
        (filterOption.varietal === "All" ||
          filterOption.varietal === el.varietal) &&
        (filterOption.region === "All" || filterOption.region === el.region) &&
        (filterOption.vintage === "All" || filterOption.vintage === el.vintage)
      ) {
        itemsTemp.push(el);
      }
    }
    if (
      filterOption.varietal === "All" &&
      filterOption.region === "All" &&
      filterOption.vintage === "All"
    ) {
      setAllWines(wines);
    } else {
      setAllWines(itemsTemp);
    }
    // let itemsTemp = [];
    // let temp = [...wines];
    // temp.forEach((el) => {
    //   if (
    //     filterOption.varietal === el.varietal &&
    //     filterOption.region === el.region &&
    //     filterOption.vintage === el.vintage
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.varietal === el.varietal &&
    //     filterOption.region === el.region &&
    //     filterOption.vintage === "All"
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.varietal === el.varietal &&
    //     filterOption.region === "All" &&
    //     filterOption.vintage === el.vintage
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.varietal === "All" &&
    //     filterOption.region === el.region &&
    //     filterOption.vintage === el.vintage
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.varietal === el.varietal &&
    //     filterOption.region === "All" &&
    //     filterOption.vintage === "All"
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.varietal === "All" &&
    //     filterOption.region === el.region &&
    //     filterOption.vintage === "All"
    //   ) {
    //     itemsTemp.push(el);
    //   } else if (
    //     filterOption.varietal === "All" &&
    //     filterOption.region === "All" &&
    //     filterOption.vintage === el.vintage
    //   ) {
    //     itemsTemp.push(el);
    //   }
    // });
    // console.log(itemsTemp);
    // setAllWines(itemsTemp);
    // if (
    //   filterOption.varietal === "All" &&
    //   filterOption.region === "All" &&
    //   filterOption.vintage === "All"
    // ) {
    //   setAllWines(wines);
    // }
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <Header onChange={handleSearchChange} />
      {/* Sort and Filter Section*/}
      <Grid
        container
        style={{ padding: 20 }}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
          style={buttonStyle}
        >
          {dropdownButtonText}
        </Button>
        <Button
          variant="outlined"
          endIcon={<FilterListIcon />}
          id="filter-button"
          aria-controls={openFilter ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilter ? "true" : undefined}
          onClick={handleOpenFilterMenu}
          style={buttonStyle}
        >
          Filter
        </Button>
        <SortWinesMenu
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          classes={classes}
          setDropdownButtonText={setDropdownButtonText}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          handleSortWines={handleSortWines}
        />
        <FilterWinesMenu
          anchorEl={filterAnchorEl}
          open={openFilter}
          handleCloseMenu={handleCloseFilterMenu}
          classes={classes}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          filterData={filterData}
          setFilterData={setFilterData}
          handleFilter={handleFilter}
        />
      </Grid>
      {/*Wines Table section */}
      <WinesTable
        allWines={allWines ? allWines : []}
        setSelectedWine={setSelectedWine}
        handleClickAdd={handleAddWineModalOpen}
      />

      {/* Add new Wine Modal */}
      <ManageWineModal
        wineModal={addWineModal}
        handleClose={handleAddWineModalClose}
        modalStyle={modalStyle}
        title={"Add New Wine"}
        wineData={newWine}
        setWineData={setNewWine}
        handleConfirm={handleAddNewWine}
        loading={loading}
      />
      {/* Edit Wine Modal */}
      <ManageWineModal
        wineModal={editWineModal}
        handleClose={handleEditWineModalClose}
        modalStyle={modalStyle}
        title={"Update Wine"}
        wineData={selectedWine}
        setWineData={setSelectedWine}
        handleConfirm={handleUpdateWine}
        loading={loading}
      />
    </Fragment>
  );
};

export default Wines;

const useStyles = makeStyles(() => {
  return {
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
    customWidth: {
      "& div": {
        width: "290px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    filterCustomWidth: {
      "& div": {
        width: "330px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
    selectedFilterButton: {
      color: "#FFFFFF",
      background: "#C84B96",
      borderRadius: 21,
      marginRight: 20,
    },
    nonSelectedFilterButton: {
      color: "#000000",
      borderRadius: 21,
      marginRight: 20,
      borderColor: "#000000",
    },
  };
});

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};
