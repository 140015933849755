import React, {useState } from "react";
import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";

import style from "./game.module.css";
import { addGameWord } from "../../apis/admins";
import { Toast } from "../../components/alert";

import { modalStyle } from "../../components/constants";

const AddGameFormModal = (props) => {
  const {modalOpen, handleClose, handleChange, words, getAllGameWordList } = props
  const [isSubmit, setIsSubmit] = useState(false);

  const handleGetWord = (e) => {
        const wordVal = e?.target?.value;
        const newVal = wordVal?.toUpperCase();
        handleChange(newVal);
    };

  const handleSubmitForm = async (e) => {
        e.preventDefault();
        setIsSubmit(true);
        if (words.length > 8) {
            return Toast("error", "You can enter 7 character maximum!");
        }
        try {
            const response = await addGameWord({ word: words });
            if (response) {
                Toast("success", "your Word Added Successfully!");
                handleChange("");
                setIsSubmit(false);
                getAllGameWordList();
            }
        } catch (error) {
            console.log(error)
        } finally {
          handleClose()
        }
    };

    console.log("words",words);
  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={modalStyle}>
      <form className={style.formBox} onSubmit={handleSubmitForm}>
            <h2>Add Your Game Word</h2>
            <TextField
                type="text"
                placeholder="Add Here..."
                size="small"
                fullWidth
                value={words}
                onChange={handleGetWord}
                maxLength={8}
                helperText={words.length > 7 ? "You can enter 7 character maximum" : null}
            />
            <Box className={style.btnBox}>
                {<Button
                    variant="outlined" 
                    color="secondary"
                    fullWidth
                    onClick={handleClose}
                    style={{ border: "1px solid #c84b96", color: "#c84b96" }}
                >
                    Cancel
                </Button>}
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ background: "#c84b96" }}
                    type="submit"
                    fullWidth
                    disabled={words.length < 5 || words.length > 7 || isSubmit ? true : false}
                >
                    Add
                </Button>
            </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddGameFormModal;

const titleStyles = { color: "#151E24", fontSize: 20, fontWeight: 700 };

const confirmButtonStyles = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
  borderRadius: 8,
  marginLeft: "auto",
};

const cancelButtonStyles = {
  width: "130px",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  borderRadius: 8,
  textTransform: "none",
};
