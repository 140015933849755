import React, { Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { quizScoresColumns } from "../../constants";
import { Grid, TablePagination } from "@mui/material";

export default function UsersScoresTable({
  scores,
  quizDetails,
  quizStatus,
  quizData,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid
      container
      direction={"column"}
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: 70 }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "1000px",
        }}
      >
        <span style={{ fontSize: 20, fontWeight: 600, color: "#404746" }}>
          Duration{quizData?.duration && `: ${quizData.duration}`}
        </span>

        <span style={{ fontSize: 20, fontWeight: 600, color: "#404746" }}>
          Scores
        </span>

        <span style={{ fontSize: 20, fontWeight: 600, color: "#404746" }}>
          Created By<span>: {quizData?.user && quizData.user.email}</span>
        </span>
      </div>
      <Grid
        style={{
          border: "1px solid #40474640",
          borderRadius: 12,
          width: "75%",
          marginTop: 24,
        }}
      >
        <Table style={{ border: "none" }}>
          <TableHead style={{ border: "none" }}>
            <TableRow>
              {quizScoresColumns &&
                quizScoresColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      border: "none",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#8086A2",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {scores &&
              scores
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <Fragment>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        style={{ borderRadius: 20 }}
                      >
                        {quizScoresColumns.map((column) => {
                          const value = row.user[column.id];
                          // console.log(value);
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                border: "none",
                                background: "#F6F6F6",
                                color: "#C84B96",
                                fontSize: 14,
                                fontWeight: column.id == "name" ? 600 : 500,
                                borderRadius:
                                  column.id == "name"
                                    ? "8px 0px 0px 8px"
                                    : column.id == "score"
                                    ? "0px 8px 8px 0px"
                                    : undefined,
                              }}
                            >
                              {column.id == "name"
                                ? `${
                                    row.user?.account_type === "Business"
                                      ? row.user?.name
                                      : row.user.first_name +
                                        " " +
                                        row.user.last_name
                                  }`
                                : column.id === "score"
                                ? row.user_result + "%"
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      <div style={{ margin: 8 }} />
                    </Fragment>
                  );
                })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[3, 5, 8]}
          component="div"
          count={scores && scores.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}
