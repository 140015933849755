import React, { useState } from "react";
import { Button, Grid, Toolbar } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import "./style.css";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import SortMenu from "../../components/dashboard/SortMenu";

const Header = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dropdownButtonText,
  setDropdownButtonText,
  handleFilter,
  handleDateRangeData,
}) => {
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const classes = useStyles();
  // const navigate = useNavigate();
  // const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [dropdownButtonText, setDropdownButtonText] = useState("Today");

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid
          item
          style={{
            textAlign: "center",
            width: matchesXL
              ? "65em"
              : matchesLG
              ? "50em"
              : matchesMD
              ? "35em"
              : matchesSM
              ? "28em"
              : "23em",
          }}
        >
          <span style={{ color: "#313137", fontWeight: 700, fontSize: 36 }}>
            Perfomance Analytics
          </span>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            style={{
              color: "#000000",
              borderColor: "#000000",
              borderRadius: 20,
              // marginTop: 30,
              fontWeight: 600,
              textTransform: "capitalize",
            }}
            endIcon={<KeyboardArrowDownIcon />}
            id="sort-button"
            aria-controls={open ? "sort-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleOpenMenu}
          >
            {dropdownButtonText}
          </Button>
          <SortMenu
            anchorEl={anchorEl}
            open={open}
            handleCloseMenu={handleCloseMenu}
            classes={classes}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setDropdownButtonText={setDropdownButtonText}
            handleFilter={handleFilter}
            handleDateRangeData={handleDateRangeData}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default Header;

const useStyles = makeStyles(() => {
  return {
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
    customWidth: {
      "& div": {
        width: "305px",
        borderRadius: 20,
        padding: "0px 7px",
      },
      // "& .MuiPaper-root": {
      //   backgroundColor: "lightblue",
      // },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
    // menuPaper: {
    //   backgroundColor: "lightblue",
    // },
  };
});
