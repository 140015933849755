import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Admins from "./pages/admins";
import Ads from "./pages/ads";
import CreateAd from "./pages/ads/CreateAd";
import Dashboard from "./pages/dashboard";
import DeactivatedUsers from "./pages/dashboard/DeactivatedUsers";
import AdDetails from "./pages/dashboard/AdDetails";
import RegisteredUsers from "./pages/dashboard/RegisteredUsers";
import DashboardAds from "./pages/dashboard/DashboardAds";
import Login from "./pages/login";
import Quizzes from "./pages/quizzes";
import AddQuiz from "./pages/quizzes/AddQuiz";
import QuizDetails from "./pages/quizzes/QuizDetails";
import Settings from "./pages/settings";
import CutomerSupport from "./pages/support";
import CertificationDetails from "./pages/support/CertificationDetails";
import Users from "./pages/users";
import UserDetails from "./pages/users/UserDetails";
import Wines from "./pages/wines";
import WineDetails from "./pages/wines/WineDetails";
import PersonalUsers from "./pages/dashboard/PersonalUsers";
import BusinessUsers from "./pages/dashboard/BusinessUsers";
import Menus from "./pages/menus";
import MenuItems from "./pages/menus/MenuItems";
import { useAuth } from "./context/AuthContext";
import NavigateToHome from "./NavigateToHome";
import EditQuiz from "./pages/quizzes/EditQuiz";
import Games from "./pages/games";
import RecipesItem from "./pages/menus/Recipes";
import WordDetail from "./pages/games/WordDetail";

function App() {
  const { currentUser, setCurrentUser } = useAuth();
  const isAuth = () => {
    if (currentUser == null) return false;
    else return true;
  };

  useEffect(() => {
    if (localStorage.getItem("userData"))
      setCurrentUser(JSON.parse(localStorage.getItem("userData")));
  }, []);

  return (
    <BrowserRouter>
      {!isAuth() ? (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/*" element={<NavigateToHome />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard/registered-users"
            element={
              <Layout>
                <RegisteredUsers />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard/users/personal-accounts"
            element={
              <Layout>
                <PersonalUsers />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard/users/business-accounts"
            element={
              <Layout>
                <BusinessUsers />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard/deactivated-users"
            element={
              <Layout>
                <DeactivatedUsers />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard/users/details"
            element={
              <Layout>
                <UserDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard/ads"
            element={
              <Layout>
                <DashboardAds />
              </Layout>
            }
          />
          <Route
            exact
            path="/dashboard/ads/details"
            element={
              <Layout>
                <AdDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/admins"
            element={
              <Layout>
                <Admins />
              </Layout>
            }
          />
          <Route
            exact
            path="/menus"
            element={
              <Layout>
                <Menus />
              </Layout>
            }
          />
          <Route
            exact
            path="/menus/menu-items"
            element={
              <Layout>
                <MenuItems />
              </Layout>
            }
          />
          <Route
            exact
            path="/menus/recipes"
            element={
              <Layout>
                <RecipesItem />
              </Layout>
            }
          />
          <Route
            exact
            path="/users"
            element={
              <Layout>
                <Users />
              </Layout>
            }
          />
          <Route
            exact
            path="/users/details"
            element={
              <Layout>
                <UserDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/quizzes"
            element={
              <Layout>
                <Quizzes />
              </Layout>
            }
          />
          <Route
            exact
            path="/quizzes/addQuiz"
            element={
              <Layout>
                <AddQuiz />
              </Layout>
            }
          />
          <Route
            exact
            path="/quizzes/quizDetails"
            element={
              <Layout>
                <QuizDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/quizzes/editQuiz"
            element={
              <Layout>
                <EditQuiz />
              </Layout>
            }
          />
          <Route
            exact
            path="/wines"
            element={
              <Layout>
                <Wines />
              </Layout>
            }
          />
          <Route
            exact
            path="/wines/wineDetails"
            element={
              <Layout>
                <WineDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/support"
            element={
              <Layout>
                <CutomerSupport />
              </Layout>
            }
          />
          <Route
            exact
            path="/support/certifications/details"
            element={
              <Layout>
                <CertificationDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/ads"
            element={
              <Layout>
                <Ads />
              </Layout>
            }
          />
          <Route
            exact
            path="/ads/details"
            element={
              <Layout>
                <AdDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/ads/createAd"
            element={
              <Layout>
                <CreateAd />
              </Layout>
            }
          />
          {/* <Route
            exact
            path="/recipes"
            element={
              <Layout>
                <Recipes />
              </Layout>
            }
          /> */}
          <Route
            exact
            path="/settings"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />

          <Route
            exact
            path="/add-games"
            element={
              <Layout>
                <Games />
              </Layout>
            }
          />
          <Route
            exact
            path="/add-games/word-detail"
            element={
              <Layout>
                <WordDetail />
              </Layout>
            }
          />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;

{
  /*
  <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          exact
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard/registered-users"
          element={
            <Layout>
              <RegisteredUsers />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard/users/personal-accounts"
          element={
            <Layout>
              <PersonalUsers />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard/users/business-accounts"
          element={
            <Layout>
              <BusinessUsers />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard/deactivated-users"
          element={
            <Layout>
              <DeactivatedUsers />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard/users/details"
          element={
            <Layout>
              <UserDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard/ads"
          element={
            <Layout>
              <DashboardAds />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard/ads/details"
          element={
            <Layout>
              <AdDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/admins"
          element={
            <Layout>
              <Admins />
            </Layout>
          }
        />
        <Route
          exact
          path="/menus"
          element={
            <Layout>
              <Menus />
            </Layout>
          }
        />
        <Route
          exact
          path="/menus/menu-items"
          element={
            <Layout>
              <MenuItems />
            </Layout>
          }
        />
        <Route
          exact
          path="/users"
          element={
            <Layout>
              <Users />
            </Layout>
          }
        />
        <Route
          exact
          path="/users/details"
          element={
            <Layout>
              <UserDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/quizzes"
          element={
            <Layout>
              <Quizzes />
            </Layout>
          }
        />
        <Route
          exact
          path="/quizzes/addQuiz"
          element={
            <Layout>
              <AddQuiz />
            </Layout>
          }
        />
        <Route
          exact
          path="/quizzes/quizDetails"
          element={
            <Layout>
              <QuizDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/wines"
          element={
            <Layout>
              <Wines />
            </Layout>
          }
        />
        <Route
          exact
          path="/wines/wineDetails"
          element={
            <Layout>
              <WineDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/support"
          element={
            <Layout>
              <CutomerSupport />
            </Layout>
          }
        />
        <Route
          exact
          path="/support/certifications/details"
          element={
            <Layout>
              <CertificationDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/ads"
          element={
            <Layout>
              <Ads />
            </Layout>
          }
        />
        <Route
          exact
          path="/ads/createAd"
          element={
            <Layout>
              <CreateAd />
            </Layout>
          }
        />
        <Route
          exact
          path="/settings"
          element={
            <Layout>
              <Settings />
            </Layout>
          }
        />
      </Routes>
*/
}
