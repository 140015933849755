import React, { createContext, useContext, useEffect, useState } from "react";
import { getDashboardCounts } from "../apis/dashboard";
import { useAuth } from "./AuthContext";

export const DashboardContext = createContext();
export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const { userToken } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);

//   console.log("token:", userToken);

  useEffect(() => {
    handleGetDashboardData();
  }, [userToken]);

  const handleGetDashboardData = async () => {
    try {
      let docsTemp = await getDashboardCounts();
      // console.log("dashboard data:", docsTemp);
      setDashboardData(docsTemp);
    } catch (error) {
      console.log("error getting dashboard Data:", error.message);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardData,
        setDashboardData,
        handleGetDashboardData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
