import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { adsData } from "../../components/constants";
import AdsMediaCard from "../../components/dashboard/ads/AdsMediaCard";
import AdsHeader from "../../components/dashboard/ads/AdsHeader";
import { useAdsContext } from "../../context/AdsContext";

const DashboardAds = () => {
  const { totalAds, setTotalAds, handleGetTotalAds } = useAdsContext();

  const [allAds, setAllAds] = useState(totalAds);

  const [sortOption, setSortOption] = useState("Ascending");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [dropdownButtonText, setDropdownButtonText] = useState("Sort");
  const [filterData, setFilterData] = useState("All");

  const open = Boolean(anchorEl);
  const openFilter = Boolean(filterAnchorEl);

  useEffect(() => {
    setAllAds(totalAds);
  }, [totalAds]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  const handleSortAds = (field) => {
    setDropdownButtonText(field);
    let sorted = [...adsData];
    handleCloseMenu();
  };

  const handleSortOptionChange = (e) => {
    // console.log(e.target.value);
    setSortOption(e.target.value);
    let sorted = [...allAds];
  };

  const handleFilter = async (filterOption) => {
    setFilterData(filterOption);
    // console.log("option :", filterOption);
    let itemsTemp = [];
    adsData.forEach((el) => {
      if (filterOption === el.type) {
        itemsTemp.push(el);
      }
      if (filterOption === "All") {
        itemsTemp.push(el);
      }
    });
    setAllAds(itemsTemp);
  };

  return (
    <Fragment>
      <AdsHeader
        open={open}
        handleOpenMenu={handleOpenMenu}
        handleCloseMenu={handleCloseMenu}
        dropdownButtonText={dropdownButtonText}
        setDropdownButtonText={setDropdownButtonText}
        anchorEl={anchorEl}
        handleSortAds={handleSortAds}
        handleSortOptionChange={handleSortOptionChange}
        sortOption={sortOption}
        openFilter={openFilter}
        filterAnchorEl={filterAnchorEl}
        handleCloseFilterMenu={handleCloseFilterMenu}
        handleFilter={handleFilter}
        filterData={filterData}
        setFilterData={setFilterData}
        handleOpenFilterMenu={handleOpenFilterMenu}
      />
      <Grid container style={{ margin: 25, width: "98%" }}>
        {allAds &&
          allAds.map((card) => <AdsMediaCard key={card.id} card={card} />)}
      </Grid>
    </Fragment>
  );
};

export default DashboardAds;
