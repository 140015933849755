import React, { useState } from "react";
import {
  Button,
  Tabs,
  Box,
  Tab,
  Typography,
  Grid,
  Modal,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { modalStyle } from "../../constants";
import ButtonLoader from "../../ButtonLoader";

import { addMenuItem, addWineFoodPairing } from "../../../apis/wines";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddPairingModal = ({
  modalOpen,
  handleClose,
  handleConfirm,
  wineData,
  foodTypes,
  allFoods,
  handleGetWinePairings,
  handleGetWineReviews,
}) => {
  const [food, setFood] = useState("");
  const [newFood, setNewFood] = useState("");
  const [foodMenuId, setFoodMenuId] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddExistingFood = async () => {
    try {
      setSearchLoading(true);
      let pairingData = {
        plan: "Cooking at home",
        menu_item_id: food.id,
        menu_id: foodMenuId,
        wine_id: wineData.id,
        image: null,
        // review: {
        //   wine_id: wineData.id,
        //   varietal: wineData.varietal,
        //   vintage: wineData.vintage,
        //   region: wineData.region,
        //   alcohol_content: wineData.name,
        //   price: wineData.price ? wineData.price : 0,
        //   food_pairing: food.id,
        //   rating: 0,
        //   title: wineData.name,
        //   notes: wineData.notes ? wineData.notes : "No notes available",
        // },
      };
      // console.log("pairind dataa:", pairingData);
      await addWineFoodPairing(pairingData);
      await handleGetWinePairings();
      await handleGetWineReviews();
      handleConfirm();
    } catch (error) {
      console.log("error creating new Food:", error.response.data.message);
    } finally {
      setSearchLoading(false);
    }
  };
  const handleCreateNewFood = async () => {
    try {
      setSearchLoading(true);
      let data = {
        name: newFood,
        menu_id: foodMenuId,
        image: null,
      };

      let temp = await addMenuItem(data);
      if (temp) {
        let pairingData = {
          plan: "Cooking at home",
          menu_item_id: temp.id,
          menu_id: foodMenuId,
          wine_id: wineData.id,
          image: null,
          // review: {
          //   wine_id: wineData.id,
          //   varietal: wineData.varietal,
          //   vintage: wineData.vintage,
          //   region: wineData.region,
          //   alcohol_content: wineData.name,
          //   price: wineData.price ? wineData.price : 0,
          //   food_pairing: temp.id,
          //   rating: 0,
          //   title: wineData.name,
          //   notes: wineData.notes ? wineData.notes : "No notes available",
          // },
        };
        // console.log("data::", pairingData);
        await addWineFoodPairing(pairingData);
        await handleGetWinePairings();
        await handleGetWineReviews();
        handleConfirm();
      }
    } catch (error) {
      console.log("error creating new Food:", error.response.data.message);
    } finally {
      setSearchLoading(false);
    }
  };

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Create New Food
        </span>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Add Existing Food" {...a11yProps(0)} />
          <Tab label="Create New Food" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container direction="column">
            <Autocomplete
              // value={food.name ? food.name : ""}
              // inputValue={food ? food : ""}
              // onInputChange={(event, newInputValue) => {
              //   setFood(newInputValue);
              // }}
              onChange={(event, newValue) => {
                setFood(newValue);
                // console.log(newValue);
              }}
              fullWidth
              options={allFoods}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Food" variant="outlined" />
              )}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TextField
            variant="outlined"
            value={newFood}
            onChange={(e) => setNewFood(e.target.value)}
            fullWidth
            label="New Food"
          />
        </TabPanel>
        <Grid container justifyContent={"center"}>
          <Autocomplete
            // value={food.name ? food.name : ""}
            // inputValue={food ? food : ""}
            // onInputChange={(event, newInputValue) => {
            //   setFood(newInputValue);
            // }}
            onChange={(event, newValue) => {
              setFoodMenuId(newValue.id);
              // console.log(newValue);
            }}
            options={foodTypes}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Type" variant="outlined" />
            )}
            style={{ textAlign: "start", marginTop: 10, width: "86%" }}
          />
        </Grid>
        <Grid container style={{ marginBottom: 25 }}></Grid>
        <Grid container>
          <Button
            fullWidth
            variant="container"
            onClick={handleClose}
            style={cancelButtonStyles}
          >
            Cancel
          </Button>
          {searchLoading ? (
            <Grid
              container
              style={{ width: "48%" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <ButtonLoader />
            </Grid>
          ) : value === 0 ? (
            <Button
              fullWidth
              variant="contained"
              disabled={foodMenuId && food ? false : true}
              onClick={handleAddExistingFood}
              style={{
                ...confirmButton,
                background: foodMenuId && food ? "#C84B96" : "gray",
                color: "#FFFFFF",
              }}
            >
              Add Food
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              disabled={foodMenuId && newFood ? false : true}
              onClick={handleCreateNewFood}
              style={{
                ...confirmButton,
                background: foodMenuId && newFood ? "#C84B96" : "gray",
                color: "#FFFFFF",
              }}
            >
              Create New Food
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddPairingModal;

const confirmButton = {
  width: 200,
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  marginLeft: "auto",
};

const cancelButtonStyles = {
  width: "130px",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#9D9DAA",
  background: "#F6F6F6",
  borderRadius: 8,
  textTransform: "none",
};

{
  /*


const handleCheckFood = async (e) => {
  try {
    let value = e.target.value;
    let temp = [...allFoodPairings];
    let docsTemp = await getFoodItem(food);
    console.log("food items:", docsTemp);
    if (docsTemp.length) {
      let data = {
        plan: "Cooking at home",
        menu_item_id: docsTemp[0].menu_item_id,
        image: null,
        review: {
          wine_id: wineData.id,
          varietal: wineData.varietal,
          vintage: wineData.vintage,
          region: wineData.region,
          alcohol_content: wineData.name,
          price: wineData.price,
          food_pairing: null,
          rating: null,
          title: wineData.name,
          notes: "",
        },
      };
      console.log("pairind dataa:", data);
      let temp = await addWineFoodPairing(data);
      console.log("response:", temp);
      setFoodNotExist(false);
    } else setFoodNotExist(true);

    temp.forEach((item, index) => {
      if (item.food == value) {
        console.log("test value>>>", value);
        setFood(value);
        temp[index] = { ...item, pairings: item.pairings + 1 };
        setFoodNotExist(false);
      } else {
        setFoodNotExist(true);
      }
    });
    if (!foodNotExist) {
      setAllFoodPairings(temp);
    }
  } catch (error) {
    console.log("error adding foor pairing:", error.response.data.message);
  }
};

// console.log("food item found", foodItemFound);

const handleAddPairing = async () => {
  try {
    setSearchLoading(true);
    let docsTemp = await getFoodItem(food);
    console.log("food items:", docsTemp);
    if (docsTemp.length) {
      let data = {
        plan: "Cooking at home",
        menu_item_id: docsTemp[0].id,
        // image: docsTemp[0].image,
        review: {
          wine_id: wineData.id,
          varietal: wineData.varietal,
          vintage: Number(wineData.vintage),
          region: wineData.region,
          alcohol_content: wineData.name,
          price: wineData.price ? wineData.price : 0,
          food_pairing: docsTemp[0].id,
          rating: 0,
          title: wineData.name,
          notes: wineData.notes ? wineData.notes : "No notes available",
        },
      };
      const Data = new FormData();
      Data.append("plan", "Cooking at home");
      Data.append("menu_item_id", docsTemp[0].id);

      console.log("pairind dataa:", data);
      let temp = await addWineFoodPairing(data);
      await handleGetWinePairings();
      console.log("response:", temp);
      setFoodNotExist(false);
      handleConfirm();
    } else setFoodNotExist(true);

    let temp = [...allFoodPairings];
    temp.forEach((item, index) => {
      if (item.food == food) {
        temp[index] = { ...item, pairings: item.pairings + 1 };
        setFoodNotExist(false);
        handleConfirm();
      } else {
        setFoodNotExist(true);
      }
    });
    // if (!foodNotExist) {
    //   setAllFoodPairings(temp);
    // }
  } catch (error) {
    setSearchLoading(false);
    console.log("error adding pairing:", error.message);
  } finally {
    setSearchLoading(false);
  }
};
*/
}
