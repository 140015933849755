import { Grid } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

import ReviewTextCard from "./ReviewTextCard";
import DeleteReviewMenu from "./DeleteReviewModal";
import { deleteWineReview } from "../../../apis/ads";
import { Toast } from "../../alert";

const ReviewCard = ({ review, handleSelectReview, handleGetWineReviews }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDeleteReview = async () => {
    try {
      setLoading(true);
      await deleteWineReview(review.id);
      await handleGetWineReviews();
      Toast("success", "Review Deleted.");
      handleCloseMenu();
    } catch (error) {
      console.log("error deleting message:", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid
      container
      style={{
        // height: 260,
        // border: "1px solid #E2EBEF",
        padding: 20,
        borderRadius: 12,
        marginTop: 16,
        background: "#F6F6F6",
      }}
    >
      <Grid item xs={3} container direction={"column"} style={{}}>
        <span style={{ fontSize: 14, fontWeight: 600, color: "#C84B96" }}>
          {/*
          Name
          {`${
            review.user && review.user.account_type === "Business"
              ? review.user.name
              : review.user &&
                review.user.first_name + " " + review.user &&
                review.user.last_name
          }`}
        */}
          {review.user && review.user.email && review.user.email}
        </span>
        <span style={{ fontSize: 14, fontWeight: 400, color: "#151E24" }}>
          {/*
          @username
        */}
          {`${review.user && review.user.username}`}
        </span>
      </Grid>
      <Grid item xs={5} style={{}}>
        <ReviewTextCard review={review} />
      </Grid>
      <Grid item xs={2} container style={{ paddingLeft: 20 }}>
        <span
          style={{
            color: "#151E24",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {/*
        {review.date && moment(review.date).format("MM/DD/YYYY")}
      */}
          {review.created_at && moment(review.created_at).format("MM/DD/YYYY")}
        </span>
      </Grid>
      <Grid
        item
        xs={1}
        container
        justifyContent={"center"}
        alignItems={"flex-start"}
        style={{ paddingLeft: 40 }}
      >
        <StarOutlinedIcon style={{ color: "#D675AC", marginRight: 6 }} />
        <span
          style={{
            color: "#151E24",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {`${review.rating && review.rating} / 5`}
        </span>
      </Grid>
      <Grid item xs={1} container justifyContent="flex-end">
        <MoreVertOutlinedIcon
          onClick={(e) => {
            handleSelectReview();
            handleOpenMenu(e);
          }}
          style={{ cursor: "pointer" }}
          id="action-button"
          aria-controls={open ? "action-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          // onClick={(e) => {

          // }}
        />
        <DeleteReviewMenu
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseMenu}
          loading={loading}
          handleDeleteReview={handleDeleteReview}
        />
      </Grid>
    </Grid>
  );
};

export default ReviewCard;
