import { Toast } from "../../components/alert";
import { useLocation, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { useWinesContext } from "../../context/WinesContext";
import WineTabs from "../../components/wines/wineDetails/WineTabs";
import {
  deleteWine,
  getSingleWine,
  getWinePairings,
  getWineReviews,
} from "../../apis/wines";
import DeleteWineModal from "../../components/wines/wineDetails/DeleteWineModal";
import WineDetailsHeader from "../../components/wines/wineDetails/WineDetailsHeader";

const WineDetails = () => {
  const { setWines } = useWinesContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [wineData, setWineData] = useState(location.state.wineData);
  const [wineReviews, setWineReviews] = useState([]);
  const [winePairings, setWinePairings] = useState([]);
  const [allWines, setAllWines] = useState(location.state.allWines);
  // const [wineDetails, setWineDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const [deleteWineModal, setDeleteWineModal] = useState(false);

  const goback = () => {
    navigate("/wines", { state: { allWines } });
  };

  useEffect(() => {
    handleGetWineReviews();
    handleGetWinePairings();
  }, []);

  //Handle Reviews
  const handleGetWineReviews = async () => {
    try {
      let temp = await getWineReviews(location.state.wineData.id);
      setWineReviews(temp);
      // console.log("reviews:", temp);
      // let temp = await getSingleWine(location.state.wineData.id);
      // setWineReviews(temp.reviews);
    } catch (error) {
      console.log("error getting wine details:", error.message);
    }
  };

  // Handle Pairing
  const handleGetWinePairings = async () => {
    try {
      let temp = await getWinePairings(location.state.wineData.id);
      setWinePairings(temp);
    } catch (error) {
      console.log("error getting wine pairings:", error.response.data.message);
    }
  };

  console.log(wineData);

  // Delete Modal
  const handleDeleteWineModalOpen = () => {
    setDeleteWineModal(true);
  };
  const handleDeleteWineModalClose = () => {
    setDeleteWineModal(false);
  };

  //Handle Delete Wine
  const handleDeleteWine = async () => {
    try {
      setLoading(true);
      let deleteDocTemp = await deleteWine(wineData.id);
      if (deleteDocTemp) {
        let docsTemp = [...allWines];
        let index = docsTemp.indexOf(location.state.wineData);
        if (index > -1) {
          docsTemp.splice(index, 1);
        }
        setAllWines(docsTemp);
        setWines(docsTemp);
        handleDeleteWineModalClose();
        Toast("success", "This Wine deleted successfully.");
        setTimeout(() => {
          goback();
        }, 500);
      }
    } catch (error) {
      console.log("error deleting wine:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {/* Header section */}
      <WineDetailsHeader
        wineData={wineData}
        goback={goback}
        handleDeleteWineModalOpen={handleDeleteWineModalOpen}
      />
      {/* Wine Tabs section */}
      <WineTabs
        wineData={wineData}
        setWineData={setWineData}
        allWines={allWines}
        setAllWines={setAllWines}
        wineReviews={wineReviews}
        setWineReviews={setWineReviews}
        winePairings={winePairings}
        setWinePairings={setWinePairings}
        handleGetWinePairings={handleGetWinePairings}
        handleGetWineReviews={handleGetWineReviews}
      />
      <DeleteWineModal
        modalOpen={deleteWineModal}
        handleClose={handleDeleteWineModalClose}
        handleConfirm={handleDeleteWine}
        loading={loading}
      />
    </Fragment>
  );
};

export default WineDetails;
