import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteDraftedWord, updateCurrentActiveWordCall } from "../../apis/admins";
import { Toast } from "../../components/alert";
import { useNavigate } from "react-router-dom";

const UserWordCard = ( {userWordData, getCurrentActiveWord,getAllGameWordList, handleAddDefinitionState, handleChange, setWordDataComp, handleDeleteModalOpen}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePromoteWord = async()=>{
    try {
      const apiBody = {"status" : "active",
    "_method" : "PUT"}
      const res = await updateCurrentActiveWordCall(userWordData?.id, apiBody);
      if(res){
        Toast("success", "Your Word Sponsored Successfully!");
        getCurrentActiveWord()
        getAllGameWordList()
      }
    } catch (error) {
      
    }
  }



  const FeaturedMenu = () => (
    <Menu
      id="edit-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "editMenuButton",
      }}
    >{
      <>
      <MenuItem
          disabled={userWordData?.definition === "" ? true : false}
          onClick={() => {
            handlePromoteWord();
            handleClose();
          }}
        >
        Sponsor
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChange(userWordData);
            handleAddDefinitionState(true);
            setWordDataComp(userWordData);
            handleClose();
          }}
        >
       {userWordData?.definition === "" ? "Add" : "Edit"} definition
        </MenuItem>
        <MenuItem
          onClick={() => {
            setWordDataComp(userWordData);
            handleDeleteModalOpen()
            handleClose();
          }}
        >
       Delete
        </MenuItem>
      </>
    }
    </Menu>
  );

  return (
    <Grid
      item
      container
      direction={"column"}
      style={{
        width: 220,
        height: "auto",
        padding: "40px 30px 30px 30px",
        borderRadius: 20,
        background: "#F9F9FA",
        marginRight: 15,
        marginBottom: 15,
        border:'4px solid #fff',
      }}
      alignItems="center"
      justifyContent={"space-between"}
    >
    {
      <>
      <MoreVertIcon
          id="editMenuButton"
          aria-controls={open ? "edit-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            // setSelectedQuiz(quiz);
            handleClick(e);
          }}
          style={{ marginLeft: "auto", cursor: "pointer" }}
        />
        <FeaturedMenu/> 
      </>
    }
      <Grid item container style={{ height: 40, marginTop: 30 }}>
        <span
          style={{
            fontWeight: 700,
            fontSize: 16,
            color: "#000",
            // paddingTop: 30,
          }}
        >
          {userWordData.user?.username}
        </span>
      </Grid>
      <Grid item container style={{ height: 40 }}>
        <span
          style={{
            fontWeight: 600,
            fontSize: 16,
            color: "#000",
            // paddingTop: 30,
          }}
        >
          Word :
        </span>
        <span style={{ textTransform: "capitalize" }}>&nbsp;{userWordData.word}</span>
      </Grid>
      <Grid item container style={{ height: 40, marginTop: 10 }}>
      <Typography>Website: </Typography>
        <span
          style={{
            fontWeight: 400,
            fontSize: 16,
            color: "rgba(128, 134, 162, 0.7)",
            marginLeft:'1rem',
          }}
        >
          {userWordData?.website === "" ? "N/A" : userWordData?.website } 
        </span>
      </Grid>
      <Grid item container>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            navigate("/add-games/word-detail", {
              state: { WordData:userWordData },
            });
          }}
          style={{
            marginTop: 15,
            color: "#fff",
            fontSize: 14,
            fontWeight: 600,
            textTransform: "none",
            background: "#4E1B5C",
            borderRadius: 8,
          }}
        >
          View Details
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserWordCard;
