import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import AnalyticsCard from "./AnalyticsCard";
import { activityAnalyticsArray } from "../constants";

const useStyles = makeStyles({
  title: {
    color: "#000",
    fontSize: 20,
    fontWeight: 600,
    // fontFamily: "Poppins",
  },
});

const ActivitySection = ({
  uploadedImages,
  uploadedVideos,
  quizzesTaken,
  ads,
}) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.title}>Activity</span>
      <Grid container direction="row" style={{ padding: 20 }}>
        {/*
        {activityAnalyticsArray &&
          activityAnalyticsArray.map((card, index) => (
            <AnalyticsCard key={index} card={card} />
            ))}
          */}
        {/*
         */}
        <AnalyticsCard
          card={{
            title: "Uploaded Images",
            count: uploadedImages,
            percent: 2.5,
          }}
        />
        <AnalyticsCard
          card={{
            title: "Uploaded Videos",
            count: uploadedVideos,
            percent: -1.4,
          }}
        />
        <AnalyticsCard
          card={{
            title: "Quizzes Taken",
            count: quizzesTaken,
            percent: 3.5,
          }}
        />
        <AnalyticsCard
          card={{
            title: "Ads",
            count: ads,
            percent: 2.5,
            expandable: true,
            link: "/ads",
          }}
        />
      </Grid>
    </div>
  );
};

export default ActivitySection;
