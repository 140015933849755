import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import {
  addFoodMenuItem,
  deleteFoodMenuItem,
  editFoodMenuItem,
  getMenuItems,
} from "../../apis/menus";
import MenuItemsHeader from "../../components/menus/menuItems/MenuItemsHeader";
import ButtonLoader from "../../components/ButtonLoader";
import MenuItemCard from "../../components/menus/menuItems/MenuItemCard";
import AddMenuModal from "../../components/menus/AddMenuModal";
import { Toast } from "../../components/alert";
import DeleteMenuModal from "../../components/menus/DeleteMenuModal";
import { makeStyles } from "@mui/styles";
import SortMenusMenu from "../../components/menus/SortMenusMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MenuItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const [menuData, setMenuData] = useState(location.state.menuData);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [newMenuItem, setNewMenuItem] = useState({
    name: "",
    image: { preview: "", raw: "" },  
  });

  const [addMenuItemModal, setAddMenuItemModal] = useState(false);
  const [editMenuItemModal, setEditMenuItemModal] = useState(false);
  const [deleteMenuItemModal, setDeleteMenuItemModal] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortOption, setSortOption] = useState("Ascending");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  console.log(selectedMenuItem)

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setMenuData(location.state.menuData);
  }, [location.state]);

  useEffect(() => {
    handleGetMenuItems();
  }, []);

  const goBack = () => {
    navigate("/menus");
  };

  const handleGetMenuItems = async () => {
    try {
      setItemsLoading(true);
      let temp = await getMenuItems(location.state.menuData.id);
      setMenuItems(temp.sort((a, b) => ([a?.name] > [b?.name] ? 1 : -1)));
      // console.log("menu items:", temp);
    } catch (error) {
      console.log("error getting menu items:", error.message);
    } finally {
      setItemsLoading(false);
    }
  };

  const handleAddMenuItemModalOpen = () => {
    setAddMenuItemModal(true);
  };
  const handleAddMenuItemModalClose = () => {
    setAddMenuItemModal(false);
  };
  const handleEditMenuItemModalOpen = () => {
    setEditMenuItemModal(true);
  };
  const handleEditMenuItemModalClose = () => {
    setEditMenuItemModal(false);
  };
  const handleDeleteMenuItemModalOpen = () => {
    setDeleteMenuItemModal(true);
  };
  const handleDeleteMenuItemModalClose = () => {
    setDeleteMenuItemModal(false);
  };

  const handleAddMenuItem = async () => {
    try {
      setLoading(true);
      //   let Data =
      const Data = new FormData();
      Data.append("image", newMenuItem.image.raw);
      Data.append("name", newMenuItem.name);
      Data.append("menu_id", menuData.id);
      //   console.log(menuData.id);
      await addFoodMenuItem(Data, menuData.id);
      //   axios
      //     .post(
      //       `https://winelikes.alphaprecision.co/api/menu_items/${menuData.id}`,
      //       { ...Data },
      //       {
      //         headers: {
      //           Accept: "application/json",
      //           Authorization: localStorage.getItem("token"),
      //           // "Content-Type": "multipart/form-data",
      //         },
      //       }
      //     )
      //     .then(async () => {
      //       await handleGetMenuItems();

      //       Toast("success", "New Menu Item added successfully.");
      //       handleAddMenuItemModalClose();
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      await handleGetMenuItems();

      Toast("success", "New Menu Item added successfully.");
      handleAddMenuItemModalClose();
    } catch (error) {
      console.log("error adding menu item:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleEditMenuItem = async () => {
    try {
      setLoading(true);
      const Data = new FormData();
      if (selectedMenuItem.image && selectedMenuItem.image.raw) {
        Data.append(
          "image",
          selectedMenuItem.image && selectedMenuItem.image.raw
            ? selectedMenuItem.image.raw
            : selectedMenuItem.image
        );
      }
      Data.append("name", selectedMenuItem.name);
      Data.append("menu_id", menuData.id);
      Data.append("_method", "PUT");
      await editFoodMenuItem(Data, selectedMenuItem.id);
      await handleGetMenuItems();
      setSelectedMenuItem(null);

      Toast("success", "Menu Item updated successfully.");
      handleEditMenuItemModalClose();
    } catch (error) {
      console.log("error editing menu item:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteMenuItem = async () => {
    try {
      setLoading(true);
      //   console.log("selected:", selectedMenuItem);
      let temp = { _method: "DELETE" };
      await deleteFoodMenuItem(temp, selectedMenuItem.id);
      await handleGetMenuItems();
      Toast("success", "Menu Item Deleted successfully.");
      handleDeleteMenuItemModalClose();
    } catch (error) {
      console.log("error deleting menu item:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleSortMenus = (e) => {
    if (e.target.value.toLowerCase() === "ascending") {
      setSortOption(e.target.value);
      let sorted = [...menuItems];
      sorted.sort((a, b) => ([a?.name] > [b?.name] ? 1 : -1));
      setMenuItems(sorted);
      handleCloseSortMenu();
    } else {
      setSortOption(e.target.value);
      let sorted = [...menuItems];
      sorted.sort((a, b) => ([a?.name] > [b?.name] ? -1 : 1));
      setMenuItems(sorted);
      handleCloseSortMenu();
    }
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <MenuItemsHeader
        goBack={goBack}
        handleModalOpen={handleAddMenuItemModalOpen}
        menuData={menuData}
      />
      <Grid container display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
          style={buttonStyle}
        >
          Sort
        </Button>
        <SortMenusMenu
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          sortOption={sortOption}
          handleSortOptionChange={handleSortMenus}
          classes={classes}
          // setDropdownButtonText={setDropdownButtonText}
          // handleSortWines={handleSortWines}
        />
      </Grid>
      {itemsLoading && (
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          style={{ height: 400 }}
        >
          <ButtonLoader />
        </Grid>
      )}
      <Grid container style={{ paddingTop: 60 }}>
        {menuItems.length > 0 ? (
          menuItems.map((menuItem, index) => (
            <MenuItemCard
              keyy={index}
              menuItemData={menuItem}
              setSelectedMenuItem={setSelectedMenuItem}
              handleEditMenuItemModalOpen={handleEditMenuItemModalOpen}
              handleDeleteMenuItemModalOpen={handleDeleteMenuItemModalOpen}
            />
          ))
        ) : (
          <Grid
            container
            justifyContent={"center"}
            alignItems="center"
            style={{ height: 300 }}
          >
            {!itemsLoading && <h4>No Menu items avaiable.</h4>}
          </Grid>
        )}
      </Grid>
      <AddMenuModal
        modalOpen={addMenuItemModal}
        handleClose={handleAddMenuItemModalClose}
        handleConfirm={handleAddMenuItem}
        menuData={newMenuItem}
        setMenuData={setNewMenuItem}
        loading={loading}
        menuItems={true}
      />
      <AddMenuModal
        modalOpen={editMenuItemModal}
        handleClose={handleEditMenuItemModalClose}
        handleConfirm={handleEditMenuItem}
        menuData={selectedMenuItem}
        setMenuData={setSelectedMenuItem}
        loading={loading}
        editMenu={true}
        menuItems={true}
      />
      <DeleteMenuModal
        modalOpen={deleteMenuItemModal}
        handleClose={handleDeleteMenuItemModalClose}
        handleConfirm={handleDeleteMenuItem}
        loading={loading}
        menuItems={true}
      />
    </Fragment>
  );
};

export default MenuItems;

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 30,
  paddingRight: 30,
  marginRight: 35,
};

const useStyles = makeStyles(() => {
  return {
    customWidth: {
      "& div": {
        width: "290px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
  };
});
