import { Grid } from "@mui/material";
import React from "react";
import AdCard from "./AdCard";

const ExpiredAdsTab = ({
  anchorEl,
  open,
  handleCloseMenu,
  handleOpenMenu,
  allExpiredAds,
  handleDeleteAdModalOpen,
  setSelectedAd,
  selectedAd,
  handleViewsModalOpen,
  handlePricesModalOpen,
}) => {
  return (
    <Grid container>
      {allExpiredAds.length > 0 ? (
        allExpiredAds.map((ad, index) => (
          <AdCard
            anchorEl={anchorEl}
            open={open}
            handleCloseMenu={handleCloseMenu}
            handleOpenMenu={handleOpenMenu}
            ad={ad}
            key={index}
            handleDeleteAdModalOpen={handleDeleteAdModalOpen}
            setSelectedAd={setSelectedAd}
            selectedAd={selectedAd}
            handleViewsModalOpen={handleViewsModalOpen}
            handlePricesModalOpen={handlePricesModalOpen}
          />
        ))
      ) : (
        <Grid container alignItems={"center"} justifyContent="center">
          <span>No Expired Ads available.</span>
        </Grid>
      )}
    </Grid>
  );
};

export default ExpiredAdsTab;
