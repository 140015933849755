export const API = {
  baseUrl: "https://dev-winelikes.qkkalabs.com",
  // baseUrl: "https://staging.api.winelikes.com",
  // baseUrl: "https://winelikes.alphaprecision.co",
  // getAdminLogin: "/api/login",
  getAdminLogin: "/api/admin/login",
  logoutAdmin: "/api/logout",
  getWines: "/api/wines",
  getWineReview: "/api/wine_reviews",
  getMenuItem: "/api/menu_items",
  addWinePairing: "/api/wine_pairings",
  getWinePairings: "/api/wine",
  getFoodMenus: "/api/menus",
  getMenuItems: "/api/menu_items",
  searchFoods: "/api/search_foods",
  searchMenuItem: "/api/search_menu_items",
  updateCertificate: "/api/certificate",
  getUsers: "/api/users",
  getAdmins: "/api/admins",
  resetPassword: "/api/reset/passwords",
  getSuspendedUsers: "/api/admin/suspend_users",
  getQuizzes: "/api/quizzes",
  getReports: "/api/post_reports",
  passwordResetEmail: "/api/forgot/passwords",
  getUserAds: "/api/user_promotions",
  getDashboard: "/api/dashboards",
  addQuizQuestion: "/api/quiz_questions",
  getAds: "/api/admin/ads",
  getCurrentAds: "/api/current_promotions",
  getInterests: "/api/hashtags",
  gameWords:"/api/word-game",
  getAllWord:'/api/words',
  sendReplyReport:"/api/post_reports_message",
  saveRecipes:'/api/recipes',
  recipesMenu:'/api/menu-recipes',
  getGameUser:'/api/',
  getWordAnalytics : '/api/word-game'
};
