import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import AccountInfoTab from "./AccountInfoTab";
import ProfileInfoTab from "./ProfileInfoTab";
import UploadedMediaTab from "./UploadedMediaTab";
import AdsTab from "./AdsTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-tabpanel-${index}`}
      aria-labelledby={`user-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `user-tab-${index}`,
    "aria-controls": `user-tabpanel-${index}`,
  };
}

export default function UserTabs({
  userDetails,
  setUserDetails,
  userInfo,
  userAds,
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(userDetails);

  return (
    <Box sx={{ width: "100%", marginTop: 3 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
          centered
          style={{
            ...tabsStyle,
            width: userDetails.account_type === "Business" ? 690 : 580,
          }}
        >
          <Tab
            label="Account Info"
            {...a11yProps(0)}
            style={value == 0 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Profile Info"
            {...a11yProps(1)}
            style={value == 1 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Uploaded Media "
            {...a11yProps(2)}
            style={value == 2 ? selectedTab : nonSelectedTab}
          />
          {userDetails.account_type === "Business" && (
            <Tab
              label="Ads "
              {...a11yProps(3)}
              style={value == 3 ? selectedTab : nonSelectedTab}
            />
          )}
        </Tabs>
      </Grid>

      <TabPanel value={value} index={0}>
        <AccountInfoTab
          userDetails={userDetails}
          setUserDetails={setUserDetails}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProfileInfoTab userDetails={userDetails} userInfo={userInfo} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UploadedMediaTab
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          userInfo={userInfo}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AdsTab
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          userInfo={userInfo}
          userAds={userAds}
        />
      </TabPanel>
    </Box>
  );
}

const selectedTab = {
  background: "#C84B96",
  borderRadius: "53px",
  textTransform: "none",
  color: "#FFF",
  height: 50,
  padding: "0px 43px",
  fontSize: 16,
  fontWeight: 500,
};

const nonSelectedTab = {
  background: "#F6F6F6",
  textTransform: "none",
  color: "#000000",
  height: 50,
  fontSize: 16,
  fontWeight: 500,
  padding: "0px 43px",
};

const tabsStyle = {
  background: "#F6F6F6",
  // width: "580px",
  borderRadius: 53,
  padding: 5,
};
