import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { CSVLink } from "react-csv";

import { baseUrl, businessUsersAdsData, usersHeader } from "../../constants";
import AdsDetails from "./adsTab/AdsDetails";

const AdsTab = ({ userDetails, userAds }) => {
  const [selectedAd, setSelectedAd] = useState(null);
  const [selected, setSelected] = useState(false);

  const handleCloseDetails = () => {
    setSelected(false);
    setSelectedAd(null);
  };

  // console.log("user ads.....:", userAds);

  return (
    <Grid container style={{ margin: 25 }}>
      <Grid
        container
        style={{
          height: userAds && userAds.length < 10 ? "30em" : undefined,
          paddingBottom: 50,
        }}
      >
        {selected ? (
          <AdsDetails
            adData={selectedAd && selectedAd}
            handleCloseDetails={handleCloseDetails}
          />
        ) : userAds && userAds.length > 0 ? (
          userAds.map((media, index) => (
            <Grid item style={{ margin: 5 }} key={index}>
              <img
                // src={media.mediaImage}
                src={`${
                  media.post &&
                  media.post.media &&
                  media.post.media[0] &&
                  baseUrl + media.post.media[0].media
                }`}
                alt="media"
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 10,
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedAd(media);
                  setSelected(true);
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid container alignItems={"center"} justifyContent="center">
            <sapn>No ads available</sapn>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        style={{
          paddingRight: 30,
          marginTop: businessUsersAdsData.length < 10 ? "10em" : 50,
        }}
        justifyContent="flex-end"
      >
        <Button variant="contained" style={exportButtonStyle}>
          <CSVLink
            data={userDetails ? [userDetails] : []}
            headers={usersHeader}
            filename={`${userDetails.name}-Record.csv`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export CSV
          </CSVLink>
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdsTab;

const exportButtonStyle = {
  background: "#C84B96",
  borderRadius: 12,
  height: 48,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
};
