import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Alert,
  InputAdornment,
  Typography,
  TextField,
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import loginLogo from "../../images/LoginLogo.png";
import ButtonLoader from "../../components/ButtonLoader";
import { adminLogin } from "../../apis/auth";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const { currentUser, setCurrentUser, userToken, setUserToken } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  const [values, setValues] = useState({
    password: "",
    email: "",
  });

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeEmail = (e) => {
    setValues({ ...values, email: e.target.value });
    let valid =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      );

    if (!valid) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (e.target.value.length < 1) {
      setEmailError(false);
    }
  };

  const handleChangePassword = (e) => {
    setValues({ ...values, password: e.target.value });
    if (e.target.value.length > 6) {
      setPasswordError(false);
    } else if (e.target.value.length == 0) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const userDoc = await adminLogin(values.email, values.password);

      //go to the main page after logging in\
      if (userDoc) {
        setCurrentUser(userDoc.data.message);
        setUserToken("Bearer " + userDoc.data.message.token);
        localStorage.setItem("token", "Bearer " + userDoc.data.message.token);
        localStorage.setItem("userId", userDoc.data.message.id);
        localStorage.setItem("userData", JSON.stringify(userDoc.data.message));

        navigate("/dashboard");
      }
    } catch (error) {
      console.log("error logging in admin:", error.response.data.message);

      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      {errorMessage && (
        <Grid
          container
          alignItems={"center"}
          justifyContent="center"
          style={{ background: "#FD355A" }}
        >
          <Alert
            variant="filled"
            severity="error"
            style={{
              height: 80,
              background: "#FD355A",
              marginTop: 10,
            }}
          >
            {errorMessage}
          </Alert>
        </Grid>
      )}
      <Grid
        item
        container
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: 60, marginTop: 60 }}
      >
        <img src={loginLogo} alt="Brand logo" className={classes.logo} />
        {/*
         */}
      </Grid>
      <Grid container style={{ width: 600 }}>
        <Typography title="Email Address" className={classes.label}>
          EMAIL ADDRESS
        </Typography>
      </Grid>

      <TextField
        className={emailError ? classes.errorTextField : classes.textField}
        variant="outlined"
        value={values.email}
        onChange={handleChangeEmail}
        type="email"
        helperText={emailError && "Invalid Email"}
        error={emailError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {values.email.length > 1 ? (
                !emailError ? (
                  <DoneOutlinedIcon
                    style={{
                      color: "#C84B96",
                      border: "3px solid #C84B96",
                      borderRadius: 100,
                      width: 23,
                      height: 23,
                    }}
                  />
                ) : (
                  <HighlightOffIcon style={{ color: "#C84B96" }} />
                )
              ) : undefined}
            </InputAdornment>
          ),
          style: {
            color: "#C84B96",
            fontWeight: 600,
            fontSize: 24,
            background: "#F7F7F7",
            borderRadius: 20,
            "& fieldset": {
              border: emailError ? "red" : "none",
            },
          },
        }}
        // inputProps={{}}
      />

      <Grid container style={{ width: 600, marginTop: 50 }}>
        <Typography title="Password" className={classes.label}>
          PASSWORD
        </Typography>
      </Grid>

      <TextField
        className={passwordError ? classes.errorTextField : classes.textField}
        variant="outlined"
        value={values.password}
        type="password"
        error={passwordError}
        onChange={handleChangePassword}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {values.password.length > 6 ? (
                <DoneOutlinedIcon
                  style={{
                    color: "#C84B96",
                    border: "3px solid #C84B96",
                    borderRadius: 100,
                    width: 23,
                    height: 23,
                  }}
                />
              ) : values.password.length > 1 ? (
                <HighlightOffIcon style={{ color: "#C84B96" }} />
              ) : undefined}
            </InputAdornment>
          ),
          style: {
            color: "#C84B96",
            fontWeight: 600,
            fontSize: 24,
            background: "#F7F7F7",
            borderRadius: 20,
            "& fieldset": {
              border: passwordError ? "red" : "none",
            },
          },
        }}
        // inputProps={{}}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        style={{ width: "30em" }}
      >
        <Typography title="Forget Password?" className={classes.forgetPass}>
          <a href="#"> Forget Password?</a>
        </Typography>
      </Grid>
      <Grid style={{ marginTop: 40, marginBottom: 50 }}>
        {loading ? (
          <ButtonLoader />
        ) : values.email && values.password && !emailError && !passwordError ? (
          <ArrowForwardOutlinedIcon
            className={classes.continueButton}
            onClick={() => handleLogin()}
          />
        ) : (
          <ArrowForwardIcon
            className={classes.continueDisabledButton}
            onClick={() => handleLogin()}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Login;

const useStyles = makeStyles({
  mainContainer: {
    textAlign: "center",
    // paddingTop: "5em",
  },
  logo: {
    width: 354,
    height: 90,
    marginTop: 40,
  },

  textFieldsContainer: {
    marginTop: 30,
  },
  label: {
    color: "#808080",
    fontSize: 12,
    fontWeight: 700,
    paddingBottom: 10,
  },
  forgetPass: {
    color: "#808080",
    fontSize: 15,
    fontWeight: 600,
    paddingTop: 20,
  },
  textField: {
    width: 600,
    height: 70,
    borderRadius: 20,
    background: "#F7F7F7",
    "& fieldset": {
      border: "none",
    },
  },
  errorTextField: {
    width: 600,
    height: 70,
    borderRadius: 20,
    background: "#F7F7F7",
  },
  continueDisabledButton: {
    padding: 25,
    background: "#F7F7F7",
    borderRadius: 60,
  },
  continueButton: {
    padding: 25,
    background: "linear-gradient(145.87deg, #C866A0 9.52%, #982269 93.57%)",
    borderRadius: 60,
    cursor: "pointer",
    color: "#fff",
  },
});

// <img
// src={ContinueButton}
// alt="ContinueButton"
// className={classes.continueButton}
// onClick={() => handleLogin()}
// style={{ width: 60, height: 60, padding: 0 }}
// />
