import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import logo from "../images/Top Icon.png";
import analyticsIcon from "../images/icons/Statistics.png";
import selectedAnalyticsIcon from "../images/icons/StatisticsWhite.png";
import adminIcon from "../images/icons/Admin.png";
import selectedAdminIcon from "../images/icons/AdminWhite.png";
import usersIcon from "../images/icons/GroupUser.png";
import selectedUsersIcon from "../images/icons/GroupUserWhite.png";
import adsIcon from "../images/icons/ads.png";
import selectedAdsIcon from "../images/icons/adsWhite.png";
import quizzesIcon from "../images/icons/Quizzes.png";
import selectedQuizzesIcon from "../images/icons/QuizzesWhite.png";
import winesIcon from "../images/icons/Wines.png";
import selectedWinesIcon from "../images/icons/WinesWhite.png";
import supportIcon from "../images/icons/support.png";
import selectedSupportIcon from "../images/icons/supportWhite.png";
import settingsIcon from "../images/icons/name.png";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import gameIcon from "../images/icons/game-white.png"
import gameIconColor from "../images/icons/game-color.png";
import RecipeWhite from "../images/icons/recipeWhiteChange.png";
import RecipeBlack from "../images/icons/recipeBlackChange.png";
import { useNavigate } from "react-router-dom";

const drawerWidth = 140;

const useStyles = makeStyles({
  listButton: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    background: "red",
  },
  iconImg: {
    width: 25,
    height: 25,
    color: "#fff",
  },
});

const SideDrawer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [drawerItems, setDrawerItems] = useState([
    {
      id: 0,
      name: "Performance Analytics",
      icon: analyticsIcon,
      selectedIcon: selectedAnalyticsIcon,
      selected: true,
      path: "/dashboard",
    },
    {
      id: 1,
      name: "Manage Admins",
      icon: adminIcon,
      selectedIcon: selectedAdminIcon,
      selected: false,
      path: "/admins",
    },
    {
      id: 2,
      name: "Manage Users",
      icon: usersIcon,
      selectedIcon: selectedUsersIcon,
      selected: false,
      path: "/users",
    },
    {
      id: 3,
      name: "Manage Quizzes",
      icon: quizzesIcon,
      selectedIcon: selectedQuizzesIcon,
      selected: false,
      path: "/quizzes",
      width: 24,
      height: 42,
    },
    {
      id: 4,
      name: "Manage Wines",
      icon: winesIcon,
      selectedIcon: selectedWinesIcon,
      selected: false,
      path: "/wines",
      width: 17,
      height: 24,
    },
    {
      id: 5,
      name: "Manage Ads",
      icon: adsIcon,
      selectedIcon: selectedAdsIcon,
      selected: false,
      path: "/ads",
    },
    {
      id: 6,
      name: "Customer Support",
      icon: supportIcon,
      selectedIcon: selectedSupportIcon,
      selected: false,
      path: "/support",
    },
    {
      id: 7,
      name: "Manage Menus",
      icon: adsIcon,
      selectedIcon: selectedAdsIcon,
      selected: false,
      path: "/menus",
    },
    {
      id: 8,
      name: "Add Games",
      icon: gameIcon,
      selectedIcon: gameIconColor,
      selected: false,
      path: "/add-games",
    },
    // {
    //   id: 10,
    //   name: "Recipes",
    //   icon: RecipeWhite,
    //   selectedIcon: RecipeBlack,
    //   selected: false,
    //   path: "/recipes",
    // },
    {
      id: 9,
      name: "Personal Settings",
      icon: settingsIcon,
      selectedIcon: settingsIcon,
      selected: false,
      path: "/settings",
    }
  ]);

  //   console.log(window.location.href);
  //   console.log(window.location.pathname);
  useEffect(() => {
    let temp = [...drawerItems];
    let paths = window.location.pathname.split("/");
    let newPathname = "/";
    newPathname += paths[1];

    temp.forEach((item) => {
      let index = temp.indexOf(item);
      item.selected = false;
      if (item.path == window.location.pathname) {
        temp[index].selected = true;
      }
      if (item.path == newPathname) {
        temp[index].selected = true;
      }
    });
    setDrawerItems(temp);
  }, [window.location.pathname]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          border: "none",
        },
      }}
      variant="permanent"
      anchor="left"
      //   classes={{
      //     paper: classes.drawerPapper,
      //   }}
      className="customScrollBar"
    >
      <Toolbar>
        <img
          src={logo}
          alt="logo"
          style={{ width: 34, height: 60, margin: "35px 0px 20px 30px" }}
        />
      </Toolbar>

      <List>
        {drawerItems.map((item) => (
          <ListItem
            key={item.id}
            disablePadding
            style={{ marginTop: item.id == 9 ? "5em" : undefined }}
          >
            <ListItemButton
              // className={classes.listButton}
              style={{
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                let temp = [...drawerItems];
                temp.forEach((item) => {
                  item.selected = false;
                });
                let index = temp.indexOf(item);
                temp[index].selected = true;
                setDrawerItems(temp);
                navigate(item.path);
              }}
            >
              <ListItemIcon
                style={{
                  width: 49,
                  height: 47,
                  justifyContent: "center",
                  alignItems: "center",
                  background: item.selected ? "#C84B96" : undefined,
                  borderRadius: 12,
                }}
              >
                {item.id === 7 ? (
                  <RestaurantMenuIcon
                    style={{ color: item.selected ? "#fff" : undefined }}
                  />
                ) : (
                  <img
                    src={item.selected ? item.selectedIcon : item.icon}
                    alt="icon"
                    className={classes.iconImg}
                    style={{
                      width:
                        item.id == 9 ? 36 : item.width ? item.width : undefined,
                      height:
                        item.id == 9
                          ? 36
                          : item.height
                          ? item.height
                          : undefined,
                    }}
                  />
                )}
              </ListItemIcon>
              {/*
            <ListItemText primary={item.name} />
        */}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideDrawer;
