import React, { useState } from "react";
import { Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import ButtonLoader from "../../ButtonLoader";
import { sendReplyReport } from "../../../apis/support";

const MessageModal = ({
  modalOpen,
  handleClose,
  modalStyle,
  reportData,
  setReportData,
  handleReply,
  reports,
  setReports,
}) => {
  const [replyEnabled, setReplyEnabled] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async () => {
    console.log("reportData",reportData);
    try {
      setLoading(true);
      let temp = [reportData.reason];
      let docsTemp = [...reports];
      temp.push({
        date: new Date(),
        message: replyMessage,
        username: "WineLikes",
      });
      setReportData({ ...reportData, messages: temp });
      // let messageTemp = { ...reportData, messages: temp };
      let messageTemp = {message :replyMessage};
      delete messageTemp.id;
      let sendreplyRes = await sendReplyReport(messageTemp, reportData.id);
    

      let index = docsTemp.indexOf(reportData);
      if (index !== -1) {
        // setReports([...reports, { ...reportData, messages: temp }]);
        reports[index] = { ...reportData, messages: temp };
      }

      setReplyEnabled(false);
      setReplyMessage("");

      // console.log(temp);
    } catch (error) {
      console.log("error updating report:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <EmailSharpIcon style={{ color: "#8E2DE2", width: 50, height: 50 }} />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Support Message
        </span>
        <Grid container>
          <span style={resonStyle}>
            Reason: {reportData && reportData.reason}
          </span>
        </Grid>
        <Grid
          style={
            reportData && reportData.messages && reportData.messages.length > 3
              ? modalContentStyle
              : undefined
          }
        >
          {reportData &&
            reportData.messages &&
            reportData.messages.map((message) => (
              <Grid container style={{ textAlign: "start" }}>
                <Typography style={messageTextStyle}>
                  {message.username
                    ? `${message.username} :[${
                        message.date.seconds
                          ? moment
                              .unix(message.date.seconds)
                              .format("MMMM DD, YYYY")
                          : moment(message.date).format("MMMM DD, YYYY")
                      }] `
                    : "Message:"}
                  <Typography>{message.message}</Typography>
                </Typography>
              </Grid>
            ))}
        </Grid>
        {replyEnabled && (
          <TextField
            multiline
            rows={5}
            fullWidth
            placeholder="Type Message..."
            value={replyMessage}
            onChange={(e) => setReplyMessage(e.target.value)}
            style={{ margin: "30px 0px", borderRadius: 8 }}
          />
        )}
        <Grid container>
          {replyEnabled ? (
            loading ? (
              <Grid
                container
                style={{ width: "50%" }}
                alignItems="center"
                justifyContent="center"
              >
                <ButtonLoader />
              </Grid>
            ) : (
              <Button
                fullWidth
                variant="contained"
                onClick={handleSendMessage}
                style={replyButtonStyle}
              >
                Send
              </Button>
            )
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={() => setReplyEnabled(true)}
              style={replyButtonStyle}
            >
              Reply
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={closeButtonStyle}
          >
            Close
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MessageModal;

const messageTextStyle = {
  color: "#313137",
  fontSize: 15,
  fontWeight: 500,
  marginTop: 10,
  marginBottom: 20,
};

const resonStyle = {
  color: "#C84B96",
  fontSize: 15,
  fontWeight: 500,
  marginTop: 10,
  marginBottom: 10,
};

const replyButtonStyle = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
};

const closeButtonStyle = {
  width: "130px",
  marginLeft: "auto",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
};

const modalContentStyle = {
  //   padding: 12,
  overflow: "scroll",
};
