import { Button, Grid } from "@mui/material";
import React from "react";
import { CSVLink } from "react-csv";

import { baseUrl, uploadedMediaData, usersHeader } from "../../constants";

const UploadedMediaTab = ({ userDetails, setUserDetails, userInfo }) => {
  return (
    <Grid container style={{ margin: 25 }}>
      <Grid
        container
        style={{
          height:
            userInfo && userInfo.gallery && userInfo.gallery.length < 10
              ? "30em"
              : undefined,
          paddingBottom: 50,
        }}
      >
        {userInfo && userInfo.gallery && userInfo.gallery.length > 0 ? (
          userInfo.gallery.map((media, index) => (
            <Grid item style={{ margin: 5 }} key={index}>
              <img
                src={`${baseUrl + media.image}`}
                alt="media"
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 10,
                  objectFit: "cover",
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid container alignItems={"center"} justifyContent="center">
            <span>No Media available</span>
          </Grid>
        )}
      </Grid>
      <Grid container style={{ paddingRight: 30 }} justifyContent="flex-end">
        <Button variant="contained" style={exportButtonStyle}>
          <CSVLink
            data={userDetails ? [userDetails] : []}
            headers={usersHeader}
            filename={`${userDetails.name}-Record.csv`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export CSV
          </CSVLink>
        </Button>
      </Grid>
    </Grid>
  );
};

export default UploadedMediaTab;

const exportButtonStyle = {
  background: "#C84B96",
  borderRadius: 12,
  height: 48,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
};
