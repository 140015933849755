import React from "react";
import {
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const SortMenu = ({
  anchorEl,
  open,
  handleCloseMenu,
  classes,
  sortOption,
  handleSortOptionChange,
  handleSortUser,
  dropdownButtonText,
}) => {
  return (
    <Menu
      id="sort-menu"
      MenuListProps={{
        "aria-labelledby": "sort-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      TransitionComponent={Fade}
      className={classes.customWidth}
    >
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <span className={classes.sortTitle}>Sort By</span>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "end" }}>
          <CloseIcon
            onClick={handleCloseMenu}
            style={{ marginRight: 10, cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container direction="column">
        <MenuItem
          onClick={() => handleSortUser("Requester Name")}
          style={{
            background:
              dropdownButtonText === "Requester Name" ? "#F6F6F6" : undefined,
          }}
        >
          <span className={classes.sortItems}>Requester Name</span>
        </MenuItem>
        <MenuItem
          onClick={() => handleSortUser("Date Requested")}
          style={{
            background:
              dropdownButtonText === "Date Requested" ? "#F6F6F6" : undefined,
          }}
        >
          <span className={classes.sortItems}>Date Requested</span>
        </MenuItem>
        <MenuItem
          onClick={() => handleSortUser("All")}
          style={{
            background:
              dropdownButtonText === "All" ? "#F6F6F6" : undefined,
            marginBottom: 10,
          }}
        >
          <span className={classes.sortItems}>All</span>
        </MenuItem>
      </Grid>
      <Divider />
      <RadioGroup
        value={sortOption}
        onChange={handleSortOptionChange}
        style={{ paddingLeft: 17 }}
      >
        <FormControlLabel
          value="Ascending"
          control={<Radio />}
          label="Ascending"
        />
        <FormControlLabel
          value="Descending"
          control={<Radio />}
          label="Descending"
        />
      </RadioGroup>
    </Menu>
  );
};

export default SortMenu;
