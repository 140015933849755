import { Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import ButtonLoader from "../../ButtonLoader";

const CertificationDetermination = ({
  verifyStatus,
  setVerifyStatus,
  handleUpdateSupport,
  loading,
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      style={{ width: "100%", height: "100%",  }}
    >
      <Typography style={{ color: "#000000", fontSize: 21, fontWeight: 600 }}>
        Verification
      </Typography>
      <Select
        style={{
          width: 350,
          marginTop: 18,
          fontSize: 16,
          fontWeight: 500,
          color:
            verifyStatus == "Verified"
              ? "#1FCBAC"
              : verifyStatus == "Denied"
              ? "red"
              : "#000",
        }}
        value={verifyStatus}
        onChange={(e) => setVerifyStatus(e.target.value)}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#313137",
              color: "#fff",
              // "& .MuiMenuItem-root": {
              //   padding: 2,
              // },
            },
          },
        }}
      >
        <MenuItem value="Verified">Verified</MenuItem>
        <MenuItem value="Denied">Denied</MenuItem>
      </Select>
      {loading ? (
        <Grid style={{ paddingTop: 25, paddingLeft: 150 }}>
          <ButtonLoader />
        </Grid>
      ) : (
        <Button
          variant="contained"
          disabled={verifyStatus == null}
          style={{
            ...saveButtonStyle,
            color: verifyStatus == null ? "#B8BBC9" : "#FFFFFF",
            background: verifyStatus == null ? "#F0F0F0" : "#C84B96",
          }}
          onClick={handleUpdateSupport}
        >
          Save
        </Button>
      )}
    </Grid>
  );
};

export default CertificationDetermination;

const saveButtonStyle = {
  borderRadius: 8,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 60,
  width: 350,
  marginTop: 15,
  marginBottom: 20,
};
