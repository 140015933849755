import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { modalStyle } from "../../constants";

const WineUpdatedModal = ({ modalOpen, handleClose }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container justifyContent={"center"}>
          <CheckCircleIcon fontSize="large" style={iconStyles} />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Photo Updated
        </span>
        <Grid container justifyContent="center">
          <span
            style={{
              color: "#000000",
              fontSize: 15,
              fontWeight: 500,
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            Your upload was successful, the photo for this wine has been
            updated.
          </span>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          onClick={handleClose}
          style={{
            width: "200px",
            textTransform: "none",
            height: 55,
            fontSize: 15,
            fontWeight: 600,
            background: "#C84B96",
            color: "#FFFFFF",
            borderRadius: 8,
          }}
        >
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default WineUpdatedModal;

const iconStyles = {
  color: "#C866A0",
};
