export const usersSearch = (array, keyword) => {
  const searchTerm = keyword.toLowerCase();
  return array.filter((value) => {
    return (
      (value.first_name &&
        value.first_name.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.last_name &&
        value.last_name.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.name &&
        value.name.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.email &&
        value.email.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.state &&
        value.state.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.zipcode &&
        value.zipcode.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.city &&
        value.city.toLowerCase().match(new RegExp(searchTerm, "g")))
    );
  });
};
export const suspendedUsersSearch = (array, keyword) => {
  const searchTerm = keyword.toLowerCase();
  return array.filter((rec) => {
    let value = rec.user && rec.user;
    return (
      (value.first_name &&
        value.first_name.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.last_name &&
        value.last_name.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.name &&
        value.name.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.email &&
        value.email.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.state &&
        value.state.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.zipcode &&
        value.zipcode.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.city &&
        value.city.toLowerCase().match(new RegExp(searchTerm, "g")))
    );
  });
};

export const winesSearch = (array, keyword) => {
  const searchTerm = keyword.toLowerCase();
  return array.filter((value) => {
    return (
      (value.name &&
        value.name.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.region &&
        value.region.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.vintage &&
        value.vintage.toLowerCase().match(new RegExp(searchTerm, "g"))) ||
      (value.varietal &&
        value.varietal.toLowerCase().match(new RegExp(searchTerm, "g")))
    );
  });
};
