import axios from "axios";

export const request = async (config) => {
  const token = localStorage.getItem("token");
  // console.log(token);
  config["headers"] = {
    ...config.headers,
    Accept: "application/json",
    // "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
    Authorization: token,
  };
  return await axios({
    ...config,
  })
    .then((res) => {
      //   console.log("request return", res.data);
      return res.data;
    })
    .catch((err) => {
      // console.log({err});
      return { err };
    });
};
