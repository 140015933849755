import {Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteQuiz, getQuizDetails, publishDraftQuiz, updateQuiz } from "../../apis/quizzes";
import { Toast } from "../../components/alert";
import DeleteQuizModal from "../../components/quizzes/quizDetails/DeleteQuizModal";
import QuizAnalytics from "../../components/quizzes/quizDetails/QuizAnalytics";

import QuizDetailsHeader from "../../components/quizzes/quizDetails/QuizDetailsHeader";
import QuizQuestionsSection from "../../components/quizzes/quizDetails/QuizQuestionsSection";
import UsersScoresTable from "../../components/quizzes/quizDetails/UsersScoresTable";
import { useQuizContext } from "../../context/QuizContext";


const QuizDetails = () => {
  const { handleGetAllQuizzes } = useQuizContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [quizData, setQuizData] = useState({});
  const [quizDetails, setQuizDetails] = useState(null);
  const [questions, setQuestions] = useState([]);

  const [allQuizzes, setAllQuizzes] = useState([]);
 

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dropdownButtonText, setDropdownButtonText] = useState("Sort");
  const [deleteQuizModal, setDeleteQuizModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  
  const [dataLoading, setDataLoading] = useState(false);
  const [level, setLevel] = useState("easy");
  // const [quizStatus, setQuizStatus] = useState(location.state.status);

  // console.log("quiz data:", quizData);
  // console.log("quiz details:", quizDetails);

  const handleLevel = (e) => setLevel(e.target.value);

  useEffect(() => {
    setQuizData(location.state.quizData);
    setAllQuizzes(location.state.allQuizzes);
    setLevel(location.state.quizData?.level)
  }, [location.state]);

  useEffect(() => {
    setLevel(location.state.quizData?.level)
    setQuizData(location.state.quizData);
    handleGetQuizDetails();
  }, [location.state]);

  const handleGetQuizDetails = async () => {
    try {
      setDataLoading(true);
      let temp = await getQuizDetails(location.state.quizData.id);
      setQuestions(temp.questions);
      setQuizDetails(temp);
      // console.log("quiz Details:", temp);
    } catch (error) {
      console.log("error getting quiz details:", error.message);
    } finally {
      setDataLoading(false);
    }
  };

  const goback = () => {
    navigate("/quizzes", { state: { allQuizzes: allQuizzes } });
  };

  const handleFilter = async (filterOption) => {
    setDropdownButtonText(filterOption);
    // console.log("filter option:", filterOption);
  };

  const handleDateRangeData = async (sDate, eDate) => {
    if (sDate || eDate) {
      // console.log("start date", sDate);
      // console.log("end date", eDate);
    }
  };

  const handleDelteQuizModalOpen = () => {
    setDeleteQuizModal(true);
  };
  const handleDelteQuizModalClose = () => {
    setDeleteQuizModal(false);
  };

  const handleDeleteQuiz = async () => {
    try {
      setLoading(true);

      let deleteDocTemp = await deleteQuiz(quizData.id);
      if (deleteDocTemp) {
        // let docsTemp = [...allQuizzes];
        // let index = docsTemp.indexOf(quizData);
        // if (index > -1) {
        //   docsTemp.splice(index, 1); // 2nd parameter means remove one item only
        // }
        // setAllQuizzes(docsTemp);
        await handleGetAllQuizzes(1);
        handleDelteQuizModalClose();
        Toast("success", "Quiz deleted successfully.");
        setTimeout(() => {
          goback();
        }, 100);
      }
    } catch (error) {
      console.log("error deleting quiz:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePublishQuiz = async () => {
    try {
      console.log({ quizData });
      setPublishLoading(true);
      let form = new FormData();
      form.append("name", quizData.name);
      if (quizData.order) {
        form.append("order", quizData.order);
      }
      form.append("_method", "PUT");
      form.append("status", "Publish");
      form.append("level", level);
      // let quizDataTemp = {
      //   name: quizData.name,
      //   status: "Publish",
      //   _method: "PUT",
      // };

      await publishDraftQuiz(form, quizData.id);
      await handleGetAllQuizzes(1);
      Toast("success", "Your Quiz is published now.");
    } catch (error) {
      console.log("error publishing quiz:", error.message);
    } finally {
      setPublishLoading(false);
    }
  };


  return (
    <Fragment>
      {/* Header Section */}
      <QuizDetailsHeader
        quizData={quizData}
        setQuizData={setQuizData}
        goback={goback}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        dropdownButtonText={dropdownButtonText}
        setDropdownButtonText={setDropdownButtonText}
        handleFilter={handleFilter}
        handleDateRangeData={handleDateRangeData}
        handleDelteQuizModalOpen={handleDelteQuizModalOpen}
        quizStatus={location.state && location.state.status && location.state.status}
        publishLoading={publishLoading}
        handlePublishQuiz={handlePublishQuiz}
        handleLevel={handleLevel}
        level={level}
      />
      {/* total participants, Average, Highest and Lowset score section */}
      <QuizAnalytics quizData={quizData} quizDetails={quizDetails} />

      {/* Scores (users score table) section */}
      <UsersScoresTable
        quizData={quizData}
        scores={quizDetails && quizDetails.attempts && quizDetails.attempts}
        quizDetails={quizDetails}
        quizStatus={location.state && location.state.status && location.state.status}
      />

      {/* Quiz Questions section */}
      <QuizQuestionsSection
        questions={questions && questions.length && questions.length > 0 && questions}
        quizData={quizData}
        quizDetails={quizDetails}
        // questions={quizData.questions && quizData.questions}
      />

      {/* Delete Quiz Modal section */}
      <DeleteQuizModal
        modalOpen={deleteQuizModal}
        handleClose={handleDelteQuizModalClose}
        handleConfirm={handleDeleteQuiz}
        loading={loading}
      />
    </Fragment>
  );
};

export default QuizDetails;
