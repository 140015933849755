import { Toast } from "../../alert";
import React, { Fragment, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { foodPairingsColumns } from "../../constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteWinePairing } from "../../../apis/wines";
import DeleteFoodPairingMenu from "./DeleteFoodPairingMenu";

const FoodPairingsTable = ({ foodPairingsData, handleGetWinePairings }) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [selectedPairing, setSelectedPairing] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //Handle Delete Food Pairing
  const handleDeleteFoodPairing = async () => {
    try {
      setLoading(true);
      await deleteWinePairing(selectedPairing.id);
      await handleGetWinePairings();
      Toast("success", "Food Pairing Deleted.");
      handleCloseMenu();
    } catch (error) {
      console.log("error deleting food pairing:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //Handle Page Change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      <Table style={{ border: "none" }}>
        <TableHead style={{ border: "none" }}>
          <TableRow>
            {foodPairingsColumns &&
              foodPairingsColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    border: "none",
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#8086A2",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {foodPairingsData.length > 0 &&
            foodPairingsData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <Fragment>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      style={{ borderRadius: 20 }}
                    >
                      {foodPairingsColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              border: "none",
                              background: "#F6F6F6",
                              color: "#C84B96",
                              fontSize: 14,
                              fontWeight: column.id === "food" ? 600 : 500,
                              borderRadius:
                                column.id === "food"
                                  ? "8px 0px 0px 8px"
                                  : undefined,
                            }}
                          >
                            {column.id === "food"
                              ? row?.menu_item?.name
                              : column.id === "type"
                              ? row?.menu?.name
                              : column.id === "pairings"
                              ? row?.menu_item?.total_pairings
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        key="forward"
                        align="right"
                        style={tableCellForward}
                      >
                        <MoreVertIcon
                          style={moreIconStyles}
                          id="action-button"
                          aria-controls={open ? "action-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={(e) => {
                            setSelectedPairing(row);
                            handleOpenMenu(e);
                          }}
                        />
                        <DeleteFoodPairingMenu
                          anchorEl={anchorEl}
                          open={open}
                          handleCloseMenu={handleCloseMenu}
                          loading={loading}
                          handleDeleteFoodPairing={handleDeleteFoodPairing}
                        />
                      </TableCell>
                    </TableRow>
                    <div style={{ margin: 8 }} />
                  </Fragment>
                );
              })}
        </TableBody>
      </Table>
      <Grid container alignItems={"flex-end"} justifyContent="flex-end">
        <TablePagination
          rowsPerPageOptions={[7, 10, 15]}
          component="div"
          count={foodPairingsData && foodPairingsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Fragment>
  );
};

export default FoodPairingsTable;

const moreIconStyles = {
  color: "#0E3C66",
  marginRight: 10,
  cursor: "pointer",
};

const tableCellForward = {
  border: "none",
  background: "#F6F6F6",
  color: "#000",
  fontSize: 14,
  fontWeight: 500,
  borderRadius: "0px 8px 8px 0px",
};
