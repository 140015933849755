import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { CSVLink } from "react-csv";

import UserUpdatedModal from "./UserUpdatedModal";
import VerifyUserModal from "./VerifyUserModal";
import { Toast } from "../../alert";
import ButtonLoader from "../../ButtonLoader";

import { usersHeader } from "../../constants";
import { sendPasswordResetEmail } from "../../../apis/users";

const AccountInfoTab = ({ userDetails, setUserDetails }) => {
  // const { fetchUsers } = useUsersContext();
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  const [verifyUserModal, setVerifyUserModal] = useState(false);
  const [userUpdatedModal, setUserUpdatedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleOpenVerifyUserModal = () => {
    setVerifyUserModal(true);
  };
  const handleCloseVerifyUserModal = () => {
    setVerifyUserModal(false);
  };

  const handleOpenUserUpdatedModal = () => {
    setUserUpdatedModal(true);
  };
  const handleCloseUserUpdatedModal = () => {
    setUserUpdatedModal(false);
  };

  const handleVerifyUser = () => {
    try {
      setEditEnabled(true);
      handleOpenUserUpdatedModal();
      handleCloseVerifyUserModal();
    } catch (error) {
      console.log("error verifying user:", error.message);
    }
  };

  const handleSendPasswordResetEmail = async () => {
    try {
      if (userDetails.email) {
        setLoading(true);
        let docTemp = {
          email: "dawood@chopdawg.com",
        };
        let temp = await sendPasswordResetEmail(docTemp);
        // console.log(temp);

        Toast("success", "Password Reset Email Sent");
        setPasswordResetEmailSent(true);
        setEditEnabled(true);
      }
    } catch (error) {
      console.log("error sending password reset email:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmVerification = async () => {
    try {
      // setLoading(true);
      let temp = {
        ...userDetails,
        verified: "Yes",
        verifiedOn: new Date(),
      };
      delete temp.verificationDeniedMessage;
      delete temp.verificationDeniedReason;
      delete temp.verificationDeniedOn;

      setUserDetails(temp);

      setEditEnabled(true);
      handleCloseVerifyUserModal();
      // let temp = await updateFirestoreUser(userDetails.id, {
      //   verified: "Yes",
      //   verifiedOn: new Date(),
      // });
      // Toast("success", "User Verified successfully.");
    } catch (error) {
      console.log("error confirming User Verification:", error.message);
      // Toast("error", error.message);
    }
  };

  const handleDenyVerification = async (denyMessage, denyReason) => {
    try {
      // setLoading(true);
      let temp = {
        ...userDetails,
        verified: "No",
        verificationDeniedMessage: denyMessage,
        verificationDeniedReason: denyReason,
        verificationDeniedOn: new Date(),
      };
      delete temp.verifiedOn;
      setUserDetails(temp);
      setEditEnabled(true);
      handleCloseVerifyUserModal();
    } catch (error) {
      console.log("error rejecting user Verification: ", error.message);
      // Toast("error", error.message);
    }
  };

  const handleUpdateUserProfile = async () => {
    try {
      // console.log("updated user:", userDetails);
      setUpdateLoading(true);
      let temp = { ...userDetails };
      delete temp.id;
      // await updateFirestoreUser(userDetails.id, temp);
      // await fetchUsers();

      Toast("success", "User Verified successfully.");
      handleOpenUserUpdatedModal();
    } catch (error) {
      console.log("error updating User:", error.message);
      Toast("error", error.message);
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid style={{ width: "30%" }}>
        <Typography
          style={{ fontSize: 16, fontWeight: 600, padding: "20px 0px" }}
        >
          Email Address
        </Typography>

        <TextField
          variant="outlined"
          placeholder="Email Address"
          value={userDetails && userDetails.email && userDetails.email}
          style={{ width: 366, borderRadius: 8 }}
          InputProps={{
            style: {
              fontSize: 16,
              fontWeight: 600,
              color: "#000",
            },
          }}
        />

        <Grid container alignItems="center" justifyContent="center">
          {loading ? (
            <Grid style={{ margin: 20 }}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              variant="contained"
              disabled={passwordResetEmailSent}
              style={
                passwordResetEmailSent
                  ? resetPasswordButtonDisabled
                  : resetPasswordButtonActive
              }
              onClick={() => {
                handleSendPasswordResetEmail();
              }}
            >
              Send Password Reset Email
            </Button>
          )}
        </Grid>
      </Grid>

      <Typography
        style={{
          fontSize: 16,
          fontWeight: 600,
          color: "#1FCBAC",
          // padding: "50px 0px 20px 0px",
        }}
      >
        {passwordResetEmailSent &&
          "* You have successfully sent the password reset email to this user"}
      </Typography>

      {/*
    <Grid style={{ width: "30%" }}>
        <Typography style={verifiedUserStyle}>Verified User</Typography>

        <Typography
          style={{ fontSize: 16, fontWeight: 400, padding: "20px 0px" }}
        >
          User Name:{" "}
          <span style={userNameTextStyle}>
            {userDetails && userDetails.username && userDetails.username}
          </span>
        </Typography>

        <Typography
          style={{ fontSize: 16, fontWeight: 400, padding: "20px 0px" }}
        >
          Verification Status:{" "}
          <span
            style={{
              ...statusTextStyle,
              color:
                userDetails.verified && userDetails.verified == "Yes"
                  ? "#fff"
                  : userDetails.verified == "No"
                  ? "#FD355AF"
                  : "#1FCBAC",
              fontWeight:
                userDetails.verified && userDetails.verified == "Yes"
                  ? 500
                  : 600,
              background:
                userDetails.verified && userDetails.verified == "Yes"
                  ? "#C84B96"
                  : undefined,
              borderRadius:
                userDetails.verified && userDetails.verified == "Yes"
                  ? 21
                  : undefined,
              padding:
                userDetails.verified && userDetails.verified == "Yes"
                  ? "10px 18px"
                  : undefined,
            }}
          >
            {userDetails && userDetails.verified ? userDetails.verified : "Nil"}
          </span>
        </Typography>

        <Typography
          style={changeStatusTextTyle}
          onClick={handleOpenVerifyUserModal}
        >
          Change Verification Status
        </Typography>
      </Grid>
    */}
      <Grid
        container
        alignItems={"center"}
        justifyContent="center"
        style={{ marginTop: 80 }}
      >
        {updateLoading ? (
          <Grid container alignItems="center" justifyContent="center">
            <ButtonLoader />
          </Grid>
        ) : (
          <Button
            variant="contained"
            disabled={!editEnabled}
            onClick={handleUpdateUserProfile}
            style={{
              background: editEnabled ? "#C84B96" : "#F0F0F0",
              borderRadius: 38,
              height: 48,
              fontSize: 16,
              fontWeight: 600,
              textTransform: "none",
              color: editEnabled ? "#FFFFFF" : "#B8BBC9",
            }}
          >
            Update Info
          </Button>
        )}
      </Grid>
      <Grid
        container
        alignItems={"flex-end"}
        justifyContent="flex-end"
        style={{ textAlign: "end", paddingRight: 30, marginTop: 150 }}
      >
        <Button variant="contained" style={exportButtonStyle}>
          <CSVLink
            data={userDetails ? [userDetails] : []}
            headers={usersHeader}
            filename={`${userDetails.name}-Record.csv`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export
          </CSVLink>
        </Button>
      </Grid>
      <VerifyUserModal
        modalOpen={verifyUserModal}
        modalStyle={modalStyle}
        handleClose={handleCloseVerifyUserModal}
        handleConfirm={handleVerifyUser}
        handleConfirmVerification={handleConfirmVerification}
        handleDenyVerification={handleDenyVerification}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
      />
      <UserUpdatedModal
        modalOpen={userUpdatedModal}
        modalStyle={modalStyle}
        handleClose={handleCloseUserUpdatedModal}
        handleConfirm={handleCloseUserUpdatedModal}
        userData={userDetails}
      />
    </Grid>
  );
};

export default AccountInfoTab;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};

const verifiedUserStyle = {
  fontSize: 16,
  fontWeight: 600,
  padding: "50px 0px 20px 0px",
};

const statusTextStyle = {
  fontSize: 16,
  fontWeight: 600,
  color: "#1FCBAC",
  marginLeft: 30,
};

const userNameTextStyle = {
  fontSize: 16,
  fontWeight: 600,
  color: "#C84B96",
  marginLeft: 30,
};

const changeStatusTextTyle = {
  fontSize: 16,
  fontWeight: 600,
  padding: "20px 0px",
  cursor: "pointer",
  color: "#FD355A",
};

const exportButtonStyle = {
  background: "#C84B96",
  borderRadius: 12,
  height: 48,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
};

const resetPasswordButtonActive = {
  margin: 20,
  background: "#C84B96",
  borderRadius: 38,
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
};
const resetPasswordButtonDisabled = {
  margin: 20,
  background: "#F0F0F0",
  borderRadius: 38,
  color: "rgba(128, 134, 162, 0.5)",
  fontSize: 16,
  fontWeight: 600,
  textTransform: "none",
};
