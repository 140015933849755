import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const SupportUpdatedModal = ({ modalOpen, handleClose, modalStyle }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <CheckCircleRoundedIcon
            style={{ color: "#1FCBAC", width: 50, height: 50 }}
          />
        </Grid>
        <Grid style={{ marginTop: 10, marginBottom: 40 }}>
          <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
            Successfully Updated
          </span>
        </Grid>

        <Button
          fullWidth
          variant="contained"
          onClick={handleClose}
          style={{
            width: "200px",
            textTransform: "none",
            height: 55,
            fontSize: 15,
            fontWeight: 600,
            background: "#C84B96",
            color: "#FFFFFF",
          }}
        >
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default SupportUpdatedModal;
