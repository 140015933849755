import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField, Typography } from "@mui/material";

import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { adminEmailTemp, adminPasswordTemp } from "../constants";

import { Toast } from "../alert";
import ButtonLoader from "../ButtonLoader";
import { updateAdminPassword } from "../../apis/auth";

const PasswordTab = () => {
  const classes = useStyles();
  const [enableEdit, setEnableEdit] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPasswords, setShowNewPasswords] = useState(false);

  const handlePasswordChange = async () => {
    try {
      setLoading(true);

      if (currentPassword) {
        if (newPassword === confirmPassword) {
          let data = {
            current_password: currentPassword,
            new_password: newPassword,
          };
          await updateAdminPassword(data);

          Toast("success", "your password updated successfully");

          setPasswordErrorMessage(null);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          setPasswordErrorMessage(
            "new password and confirm password does not match"
          );
          return;
        }
      } else {
        setPasswordErrorMessage("please Enter your current password");
      }

      // if (currentPassword == adminPasswordTemp) {
      //   if (newPassword == confirmPassword) {
      //     setPasswordErrorMessage(null);
      //     Toast("success", "your password updated successfully");
      //   } else {
      //     setPasswordErrorMessage("The passwords you entered do not match.");
      //   }
      // } else {
      //   setPasswordErrorMessage(
      //     "The password you entered is incorrect. Please try again."
      //   );
      // }
    } catch (error) {
      console.log("error changing password:", error.response.data.message);
      // if (error.code == "auth/wrong-password") {
      //   setPasswordErrorMessage(
      //     "Your Password did not match to your email, please provide your correct password."
      //   );
      // } else {
      setPasswordErrorMessage(error.response.data.message);
      // }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography style={messageTextStyle}>
        * If you require a new login to this Admin panel, please update the
        email address below.
      </Typography>
      <Grid style={{ width: 400, padding: 20 }}>
        <span style={emailLabelStyle}>
          Current Password{" "}
          {showCurrentPassword ? (
            <VisibilityOffRoundedIcon
              style={{ margin: -7, marginLeft: 20, color: "#808080" }}
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
            />
          ) : (
            <VisibilityRoundedIcon
              style={{ margin: -7, marginLeft: 20, color: "#808080" }}
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
            />
          )}
        </span>
        <TextField
          fullWidth
          placeholder="Current Password"
          className={classes.textfieldStyle}
          type={showCurrentPassword ? "text" : "password"}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          InputProps={{
            style: {
              ...textFieldPropsStyle,
              color: enableEdit ? "#000" : "#808080",
            },
          }}
        />
      </Grid>
      <Grid style={{ width: 400, padding: 20 }}>
        <span style={emailLabelStyle}>
          New Password
          {showNewPasswords ? (
            <VisibilityOffRoundedIcon
              style={{ margin: -7, marginLeft: 20, color: "#808080" }}
              onClick={() => setShowNewPasswords(!showNewPasswords)}
            />
          ) : (
            <VisibilityRoundedIcon
              style={{ margin: -7, marginLeft: 20, color: "#808080" }}
              onClick={() => setShowNewPasswords(!showNewPasswords)}
            />
          )}
        </span>
        <TextField
          fullWidth
          placeholder="New Password"
          className={classes.textfieldStyle}
          type={showNewPasswords ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            style: {
              ...textFieldPropsStyle,
              color: enableEdit ? "#000" : "#808080",
              marginBottom: 20,
            },
          }}
        />
        <TextField
          fullWidth
          placeholder="Confirm Password"
          className={classes.textfieldStyle}
          type={showNewPasswords ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            style: {
              ...textFieldPropsStyle,
              color: enableEdit ? "#000" : "#808080",
            },
          }}
        />
      </Grid>
      {passwordErrorMessage && (
        <Typography style={emailErrorTextStyle}>
          {passwordErrorMessage}
        </Typography>
      )}
      {loading ? (
        <Grid>
          <ButtonLoader />
        </Grid>
      ) : (
        <Button
          variant="contained"
          onClick={handlePasswordChange}
          disabled={
            currentPassword.length &&
            newPassword.length &&
            confirmPassword.length
              ? false
              : true
          }
          style={{
            ...updateButtonStyle,
            background:
              currentPassword.length &&
              newPassword.length &&
              confirmPassword.length
                ? "#C84B96"
                : "#F0F0F0",
            color:
              currentPassword.length &&
              newPassword.length &&
              confirmPassword.length
                ? "#fff"
                : "#808080",
          }}
        >
          Update
        </Button>
      )}
    </Grid>
  );
};

export default PasswordTab;

const useStyles = makeStyles({
  textfieldStyle: {
    marginTop: 20,
    "& fieldset": {
      border: "none",
    },
  },
});

const messageTextStyle = {
  width: 380,
  color: "#85848B",
  fontWeight: 500,
  fontSize: 16,
  marginTop: 40,
  marginBottom: 20,
};

const emailErrorTextStyle = {
  color: "#FF5655",
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 20,
};

const updateButtonStyle = {
  borderRadius: 38,
  textTransform: "none",
  width: 110,
  height: 50,
  fontSize: 16,
  fontWeight: 600,
};

const emailLabelStyle = {
  color: "#000",
  fontWeight: 600,
  fontSize: 16,
  marginBottom: 20,
};

const textFieldPropsStyle = {
  background: "#F6F6F6",
  fontSize: 16,
  fontWeight: 600,
  borderRadius: 8,
};
