import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Button, Chip, Grid, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";

// import Cropper from "react-easy-crop";

// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { modalStyle } from "../constants";
import ButtonLoader from "../ButtonLoader";

const AddRecipesModal = ({
  modalOpen,
  handleClose,
  handleConfirm,
  recipesData,
  setRecipesData,
  loading,
  editMenu,
  menuItems,
}) => {
  const imageRef = useRef();
  const [editImageModal, setEditImageModal] = useState(false);
  const [ingredient, setIngredient] = useState([]);
  
  const showOpenFileDialog = () => {
    imageRef.current.click();
  };
  const handlePhotoChange = (e) => {
    if (e.target.files.length) {
        setRecipesData({
        ...recipesData,
        image: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        },
      });
      setEditImageModal(true);
    }
  };

  useEffect(()=>{
    if(ingredient.length !== 0){
      setRecipesData({...recipesData,ingredients: ingredient});
    }
  },[ingredient])

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        handleClose();
        setEditImageModal(false);
      }}
    >
      <Box sx={modalStyle}>
        <Grid container justifyContent={"center"}>
          <span style={titleStyles}>
            {menuItems
              ? editMenu
                ? "Edit Recipe Item"
                : "Add Recipe Item"
              : editMenu
              ? "Edit Recipe"
              : "Add Recipe"}
          </span>
        </Grid>
        <Grid container alignItems={"center"} justifyContent="center">
          {editImageModal ? (
            <Grid
              container
              alignItems={"center"}
              justifyContent="center"
              style={{
                width: 335,
                height: 262,
                marginBottom: 10,
                borderRadius: 12,
              }}
            >
              <img
                src={
                  recipesData &&
                  recipesData.image &&
                  recipesData.image.preview &&
                  recipesData.image.preview
                }
                style={{
                  width: 252,
                  height: 250,
                  borderRadius: 12,
                  margin: "10px",
                }}
                alt=""
              />
            </Grid>
          ) : (
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              direction="column"
              style={{
                width: 335,
                height: 200,
                borderRadius: 12,
                margin: "30px 0px 20px 0px",
                border: "1px dashed #85848B",
                cursor: "pointer",
              }}
              onClick={showOpenFileDialog}
            >
              <span
                style={{
                  color: "#C84B96",
                  fontSize: 18,
                  fontWeight: 700,
                  paddingBottom: 32,
                }}
              >
                Upload Image Here{" "}
              </span>
              <span
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontWeight: 500,
                  width: 210,
                  textAlign: "center",
                }}
              >
                * We accept images (jpg.)
              </span>
              <span
                style={{
                  color: "#808080",
                  fontSize: 14,
                  fontWeight: 500,
                  width: 210,
                  textAlign: "center",
                }}
              >
                * Image size: min. 500x500 px
              </span>
            </Grid>
          )}
          <input
            ref={imageRef}
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => handlePhotoChange(e)}
          />  
        </Grid>
        <TextField
          variant="outlined"
          fullWidth
          value={recipesData && recipesData.title && recipesData.title}
          onChange={(e) => setRecipesData({ ...recipesData, title: e.target.value })}
          placeholder="Type Title"
          style={{ marginBottom: 20 }}
        />
        <Grid container style={{gap:'1rem', justifyContent:'space-between'}}>
        <Grid item xs={5}>
        <TextField
          variant="outlined"
          fullWidth
          value={recipesData && recipesData.duration && recipesData.duration}
          onChange={(e) => setRecipesData({ ...recipesData, duration: e.target.value })}
          placeholder="Type Duration(in mins)"
          style={{ marginBottom: 20 }}
        />
        </Grid>
        <Grid item xs={5}>
        <TextField
          variant="outlined"
          fullWidth
          value={recipesData && recipesData.portion && recipesData.portion}
          onChange={(e) => setRecipesData({ ...recipesData, portion: e.target.value })}
          placeholder="Type Portion"
          style={{ marginBottom: 20 }}
        />
        </Grid>
        </Grid>
        <TextField
          variant="outlined"
          fullWidth
          value={recipesData && recipesData.instructions && recipesData.instructions}
          onChange={(e) => setRecipesData({ ...recipesData, instructions: e.target.value })}
          placeholder="Instructions"
          style={{ marginBottom: 20 }}
        />
        {/* ingridients */}
        <Grid container style={{marginBottom:'1rem'}}>
        <Autocomplete
        sx={{width:'100%'}}
        multiple
        id="tags-filled"
        options={[]}
        freeSolo
        onChange={(event,value)=>{
        return setIngredient((prev)=> [...prev,{label:value.slice(-1)[0] ,value:value.slice(-1)[0]}]);
        }}
        renderTags={(value, getTagProps) =>
          value?.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
            <Chip variant="outlined" label={option} key={key} {...tagProps} />
            );
          })
        }
        renderInput={(params) => (
        <TextField
            {...params}
            variant="outlined"
            label="Ingredients"
            placeholder="Press Enter to add"
          />
        )}
      />
        </Grid>
        <Grid container>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              handleClose();
              setEditImageModal(false);
            }}
            style={cancelButtonStyles}
          >
            Cancel
          </Button>
          {loading ? (
            <Grid
              container
              style={{ width: "55%" }}
              alignItems="center"
              justifyContent={"center"}
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirm}
              style={confirmButtonStyles}
            >
              Okay
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddRecipesModal;

const titleStyles = { color: "#151E24", fontSize: 20, fontWeight: 700 };

const confirmButtonStyles = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
  borderRadius: 8,
  marginLeft: "auto",
};

const cancelButtonStyles = {
  width: "130px",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  borderRadius: 8,
  textTransform: "none",
};
