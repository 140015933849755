import {Button, Grid } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import React from "react";
import QuizQuestionCard from "./QuizQuestionCard";
import { useNavigate } from "react-router-dom";

const QuizQuestionsSection = ({ questions, quizData, quizDetails }) => {
  const navigate = useNavigate();
    console.log("questions:", questions);
    console.log("questionsData:", quizData);
  return (
    <Grid
      container
      direction={"column"}
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: 70, padding: "0px 30px" }}
    >
      <span
        style={{
          fontSize: 20,
          fontWeight: 600,
          color: "#000",
          marginBottom: 40,
        }}
      >
        Questions
      </span>
      <Grid container>
        {questions && questions.length > 0 ? (
          questions.sort((a, b)=> a.order - b.order).map((question, index) => (
            <QuizQuestionCard
              question={question}
              allQuestions={questions}
              key={index}
              quizData={quizData}
              quizDetails={quizDetails}
            />
          ))
        ) : (
          <Grid container alignItems="center" justifyContent="center">
            <span style={{ paddingTop: 50 }}>No Questions available</span>
          </Grid>
        )}
        <Button
            variant="contained"
            onClick={()=> navigate("/quizzes/addQuiz", { state: { quizData, questions, isAddNew: true } })}
            endIcon={<AddOutlinedIcon style={{ fontSize: 24 }} />}
            style={{
              color: "#fff",
              background: "#C84B96",
              borderRadius: 38,
              marginTop: 25,
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: 15,
              height: 50,
            }}
          >
            Add New Question
          </Button>
      </Grid>
    </Grid>
  );
};

export default QuizQuestionsSection;
