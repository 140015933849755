import React, { createContext, useContext, useEffect, useState } from "react";
import { getFoodMenus } from "../apis/menus";
// import { getFoodMenus } from "../apis/wines";

export const MenusContext = createContext();
export const useMenusContext = () => useContext(MenusContext);

export const MenusProvider = ({ children }) => {
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    fetchMenus();
  }, []);

  const fetchMenus = async () => {
    try {
    //   let temp = [];
      const menusSnap = await getFoodMenus();
      //   menusSnap.forEach((doc) => {
      //     console.log("menu doc:", doc)
      //     temp.push({ ...doc.data(), id: doc.id });
      //   });
      // console.log("menus:", menusSnap);
      setMenus(menusSnap);
    } catch (error) {
      console.log("Error getting menus", error.message);
    }
  };

  return (
    <MenusContext.Provider
      value={{
        fetchMenus,
        menus,
        setMenus,
      }}
    >
      {children}
    </MenusContext.Provider>
  );
};
