import { Grid } from "@mui/material";
import React from "react";

const ReviewTextCard = ({ review }) => {
  return (
    <Grid container direction={"column"}>
      <Grid style={{ width: "80%", marginBottom: 5 }}>
        <span
          style={{
            color: "#C84B96",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {review.title && review.title}
        </span>
      </Grid>
      <Grid style={{ width: "80%", marginBottom: 15 }}>
        <span
          style={{
            color: "#151E24",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {review.notes
            ? review.notes
            : "Not Available"}
        </span>
      </Grid>
    </Grid>
  );
};

export default ReviewTextCard;
