import React from "react";
import { useNavigate } from "react-router-dom";

import userImage1 from "../../../images/users/User_New1.png";
import { baseUrl } from "../../constants";

const AdsMediaCard = ({ card }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/dashboard/ads/details", { state: { card } })}
      style={{
        width: 200,
        height: 200,
        borderRadius: 10,
        margin: 7,
        backgroundImage: `url(${baseUrl + card?.post?.media[0]?.media})`,
        // backgroundImage: `url(${card.image && card.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        cursor: "pointer",
        border: "1px solid gray",
      }}
    >
      <div
        style={{
          width: 50,
          height: 50,
          borderRadius: 50,
          marginTop: "-10px",
          marginLeft: "-7px",
          border: "4px solid white",
          backgroundImage: `url(${userImage1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
    </div>
  );
};

export default AdsMediaCard;
