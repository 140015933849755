import React, { createContext, useContext, useEffect, useState } from "react";
import { getFoodRecipes } from "../apis/recipes";
// import { getFoodMenus } from "../apis/wines";
export const RecipesContext = createContext();
export const useRecipesContext = () => useContext(RecipesContext);

export const RecipesProvider = ({ children }) => {
  const [recipes, setRecipes] = useState([]);
  const [loadingRecipes, setLoadingRecipes] =useState(false);

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async (menu_id) => {
    setLoadingRecipes(true);
    try {
    //   let temp = [];
      const recipesSnap = await getFoodRecipes(menu_id ? menu_id : 1);
      //   menusSnap.forEach((doc) => {
      //     console.log("menu doc:", doc)
      //     temp.push({ ...doc.data(), id: doc.id });
      //   });
      // console.log("menus:", menusSnap);
      setRecipes(recipesSnap);
    } catch (error) {
      console.log("Error getting menus", error.message);
    }finally{
      setLoadingRecipes(false);
    }
  };

  return (
    <RecipesContext.Provider
      value={{
        recipes,
        fetchRecipes,
        setRecipes,
        loadingRecipes
      }}
    >
      {children}
    </RecipesContext.Provider>
  );
};
