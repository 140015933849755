import { Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "../../components/alert";
import SupportUpdatedModal from "../../components/support/certificationsTab/SupportUpdatedModal";
import CertificationDetailsHeader from "../../components/support/certificationsTab/CertificationDetailsHeader";
import CertificationDetailsForm from "../../components/support/certificationsTab/CertificationDetailsForm";
import CertificationDetermination from "../../components/support/certificationsTab/CertificationDetermination";
import { updateCertificate } from "../../apis/support";
import { useUsersContext } from "../../context/UsersContext";

const CertificationDetails = () => {
  const { handleGetCertificates } = useUsersContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [supportDetails, setSupportDetails] = useState(location.state.support);
  const [certifications, setCertifications] = useState(
    location.state.certifications
  );
  const [verifyStatus, setVerifyStatus] = useState(
    location.state.support.certificate_status
  );
  const [supportUpdatedModal, setSupportUpdatedModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenSupportUpdatedModal = () => {
    setSupportUpdatedModal(true);
  };
  const handleCloseSupportUpdatedModal = () => {
    setSupportUpdatedModal(false);
  };

  const handleUpdateSupport = async () => {
    try {
      setLoading(true);
      let temp = [...certifications];

      let docTemp = {
        status: verifyStatus,
        _method: "PUT",
      };
      await updateCertificate(docTemp, supportDetails.id);
      await handleGetCertificates();

      let index = temp.indexOf(supportDetails);
      if (index !== -1) {
        temp[index] = {
          ...supportDetails,
          certificate_status: verifyStatus,
        };
      }
      Toast("success", "Certification Request processed successfully.");
      handleOpenSupportUpdatedModal();
      setCertifications(temp);
    } catch (error) {
      console.log("error updating support");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {/* header section*/}
      <CertificationDetailsHeader
        navigate={navigate}
        supportDetails={supportDetails}
      />

      <Grid container spacing={2} style={{ padding: "200px 55px" }}>
        {/*support details form section*/}
        <CertificationDetailsForm supportDetails={supportDetails} />
        {/*take acition section*/}
        <Grid item xs style={{}}>
          <CertificationDetermination
            verifyStatus={verifyStatus}
            setVerifyStatus={setVerifyStatus}
            handleUpdateSupport={handleUpdateSupport}
            loading={loading}
          />
        </Grid>
      </Grid>
      <SupportUpdatedModal
        modalOpen={supportUpdatedModal}
        modalStyle={modalStyle}
        handleClose={handleCloseSupportUpdatedModal}
      />
    </Fragment>
  );
};

export default CertificationDetails;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};
