import { useEffect } from "react";
import { Toast } from "../../alert";
import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import AddPairingModal from "./AddPairingModal";
import { getFoodMenus, getFoods } from "../../../apis/wines";
import FoodPairingsTable from "./FoodPairingsTable";
import { makeStyles } from "@mui/styles";
import SortReviews from "./SortReviews";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const FoodPairingsTab = ({
  wineData,
  winePairings,
  handleGetWinePairings,
  handleGetWineReviews,
}) => {
  const [allFoodPairings, setAllFoodPairings] = useState([]);
  const [addPairingModal, setAddPairingModal] = useState(false);
  const [foodTypes, setFoodTypes] = useState([]);
  const [allFoods, setAllFoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOption, setSortOption] = useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAllFoodPairings(winePairings);
  }, [winePairings]);

  useEffect(() => {
    handleGetFoodTypes();
    handleGetAllFoods();
  }, []);

  //Handle Get Food Type
  const handleGetFoodTypes = async () => {
    try {
      let temp = await getFoodMenus();
      setFoodTypes(temp);
    } catch (error) {
      console.log("error getting food menus:", error.response.data.message);
    }
  };
  //Handle Get Foods
  const handleGetAllFoods = async () => {
    try {
      let temp = await getFoods();
      // console.log("foods", temp);
      setAllFoods(temp);
    } catch (error) {
      console.log("error getting food menus:", error.response.data.message);
    }
  };

  //Add Pairing Modals
  const handleAddPairingModalOpen = () => {
    setAddPairingModal(true);
  };
  const handleAddPairingModalClose = () => {
    setAddPairingModal(false);
  };

  //Handle Add New Food Pairing
  const handleAddFoodPairing = async () => {
    try {
      setLoading(true);
      handleGetWinePairings();
      handleAddPairingModalClose();
      Toast("success", "food item found and added to pairings");
    } catch (error) {
      console.log("error adding pairing:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSortOptionChange = (e) => {
    if (e.target.value.toLowerCase() === "ascending") {
      setSortOption(e.target.value);
      let sorted = [...allFoodPairings];
      sorted.sort((a, b) =>
        [a?.menu_item.name] > [b?.menu_item.name] ? 1 : -1
      );
      setAllFoodPairings(sorted);
      handleCloseSortMenu();
    } else {
      setSortOption(e.target.value);
      let sorted = [...allFoodPairings];
      sorted.sort((a, b) =>
        [a?.menu_item.name] > [b?.menu_item.name] ? -1 : 1
      );
      setAllFoodPairings(sorted);
      handleCloseSortMenu();
    }
  };

  return (
    <Grid container style={mainContainerStyles}>
      <Grid
        container
        alignItems={"flex-end"}
        justifyContent="flex-end"
        style={{ marginBottom: "20px" }}
      >
        <Button
          variant="outlined"
          style={addButtonStyles}
          onClick={handleAddPairingModalOpen}
        >
          Add Food Pairings
        </Button>
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
          style={buttonStyle}
        >
          Sort
        </Button>
        <Button variant="contained" style={exportButtonStyles}>
          Export
        </Button>
      </Grid>
      <Grid container display={"flex"} justifyContent={"flex-end"}>
        <SortReviews
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          classes={classes}
          sortOption={sortOption}
          handleSortOptionChange={handleSortOptionChange}
          // handleSortWines={handleSortWines}
        />
      </Grid>
      <Grid
        container
        style={{ border: "1px solid #8086A233", padding: "25px 14px" }}
      >
        <FoodPairingsTable
          foodPairingsData={allFoodPairings}
          handleGetWinePairings={handleGetWinePairings}
        />
      </Grid>

      <AddPairingModal
        modalOpen={addPairingModal}
        handleClose={handleAddPairingModalClose}
        title="Search Food Pairing"
        handleConfirm={handleAddFoodPairing}
        loading={loading}
        allFoodPairings={allFoodPairings}
        setAllFoodPairings={setAllFoodPairings}
        wineData={wineData}
        foodTypes={foodTypes}
        allFoods={allFoods}
        handleGetWinePairings={handleGetWinePairings}
        handleGetWineReviews={handleGetWineReviews}
      />
    </Grid>
  );
};

export default FoodPairingsTab;

const mainContainerStyles = {
  borderRadius: 12,
  background: "#fff",
  padding: "0px 10px 25px 10px",
};

const exportButtonStyles = {
  color: "#FFFFFF",
  background: "#C84B96",
  borderRadius: 12,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const addButtonStyles = {
  color: "#C84B96",
  borderColor: "#C84B96",
  borderRadius: 12,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const useStyles = makeStyles(() => {
  return {
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
    customWidth: {
      "& div": {
        width: "190px",
        borderRadius: 20,
        padding: "0px 7px",
        marginTop: "7px",
      },
    },

    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
    selectedFilterButton: {
      color: "#FFFFFF",
      background: "#C84B96",
      borderRadius: 21,
      marginRight: 20,
    },
    nonSelectedFilterButton: {
      color: "#000000",
      borderRadius: 21,
      marginRight: 20,
      borderColor: "#000000",
    },
  };
});
