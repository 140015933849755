import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";

const LogoutModal = ({ modalOpen, handleClose, modalStyle, handleConfirm }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Log Out
        </span>
        <Grid container>
          <span style={messageTextStyle}>
            Are you sure you want to logout of WineLikes Admin Panel? You will need
            to re-login to enter again.
          </span>
        </Grid>
        <Grid container>
          <Button
            fullWidth
            variant="contained"
            onClick={handleConfirm}
            style={confirmButtonStyle}
          >
            Yes, I confirm
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={noButtonStyle}
          >
            No
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default LogoutModal;

const noButtonStyle = {
  width: "130px",
  marginLeft: "auto",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
};

const confirmButtonStyle = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
};

const messageTextStyle = {
  color: "#000000",
  fontSize: 15,
  fontWeight: 500,
  marginTop: 30,
  marginBottom: 30,
};
