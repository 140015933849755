import React, { useEffect, useState } from "react";
import { Button, Grid, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const WineDetailsHeader = ({ wineData, goback, handleDeleteWineModalOpen }) => {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs justifyContent="center" alignItems={"center"}>
          <ArrowBackIosIcon className={classes.backIcon} onClick={goback} />
          <span className={classes.title}>
            {wineData && wineData.name && wineData.name}
          </span>
        </Grid>

        <Grid item xs style={{ textAlign: "end" }} justifyContent="center">
          <Button
            variant="outlined"
            style={deleteButtonStyle}
            onClick={handleDeleteWineModalOpen}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default WineDetailsHeader;

const useStyles = makeStyles(() => {
  return {
    title: {
      color: "#000",
      fontSize: 25,
      fontWeight: 600,
      marginLeft: 10,
    },
    backIcon: {
      //   marginTop: 15,
      marginBottom: -3,
      cursor: "pointer",
      color: "#C84B96",
    },
    customWidth: {
      "& div": {
        width: "305px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
  };
});

const sortButtonStyle = {
  color: "#000",
  borderColor: "#000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const deleteButtonStyle = {
  color: "#FD355A",
  borderColor: "#FD355A",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
