import React, { useState } from "react";

// import { verifiedUsersData } from "../../components/constants";
import { useUsersContext } from "../../context/UsersContext";
import UsersMainPage from "../users/UsersMainPage";

const BusinessUsers = () => {
  const { deactivatedUsers, personalUsers, businessUsers, users } =
    useUsersContext();

  const [allBusinessUsers, setAllBusinessUsers] = useState(businessUsers);
  const [allPersonalUsers, setAllPersonalUsers] = useState(personalUsers);
  const [allUsers, setAllUsers] = useState(users);
  const [allDeactivatedUsers, setAllDeactivatedUsers] =
    useState(deactivatedUsers);

  return (
    <UsersMainPage
      allPersonalUsers={allPersonalUsers}
      allUsers={allUsers}
      setAllUsers={setAllUsers}
      setAllPersonalUsers={setAllPersonalUsers}
      allBusinessUsers={allBusinessUsers}
      setAllBusinessUsers={setAllBusinessUsers}
      allDeactivatedUsers={allDeactivatedUsers}
      setAllDeactivatedUsers={setAllDeactivatedUsers}
      headerTitle="Business Accounts"
    />
  );
};

export default BusinessUsers;
