import React, { Fragment } from "react";
import ReportsTable from "./reportsTab/ReportsTable";

const ReportsTab = ({ reports, setReports }) => {
  return (
    <Fragment>
      {/* reports table section*/}
      <ReportsTable reports={reports && reports} setReports={setReports} />
    </Fragment>
  );
};

export default ReportsTab;
