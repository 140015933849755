import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { mostPopularBrandsArray } from "../constants";
import AnalyticsNonPercentCard from "./AnalyticsNonPercentCard";

const useStyles = makeStyles({
  title: {
    color: "#000",
    fontSize: 20,
    fontWeight: 600,
    // fontFamily: "Poppins",
  },
});

const MostPopularHashtagsSection = ({ popularHashtags }) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.title}>Most Popular Hashtags</span>
      <Grid container direction="row" style={{ padding: 20 }}>
        {popularHashtags &&
          popularHashtags.map((card, index) => (
            <AnalyticsNonPercentCard key={index} card={card} hashtags={true} />
          ))}
      </Grid>
    </div>
  );
};

export default MostPopularHashtagsSection;
