import { API } from "../constants";
import { request } from "../constants/request";

export const getAllQuizzes = (page) => {
  return request({
    url: API.baseUrl + API.getQuizzes + `?page=${page}&all=1`,
    method: "GET",
  });
};

export const getQuizDetails = (quizId) => {
  return request({
    url: API.baseUrl + API.getQuizzes + `/${quizId}`,
    method: "GET",
  });
};

export const addQuiz = (quizData) => {
  return request({
    url: API.baseUrl + API.getQuizzes,
    method: "POST",
    data: quizData,
  });
};

export const addQuizQuestion = (questionData) => {
  return request({
    url: API.baseUrl + API.addQuizQuestion,
    method: "POST",
    data: questionData,
  });
};

export const updateQuiz = (quizData, quizId) => {
  return request({
    url: API.baseUrl + API.getQuizzes + `/${quizId}`,
    method: "POST",
    data: quizData,
  });
};

export const updateQuestion = (questionData, questionId) => {
  return request({
    url: API.baseUrl + API.addQuizQuestion + `/${questionId}`,
    method: "POST",
    data: questionData,
  });
};

export const publishDraftQuiz = (quizData, quizId) => {
  return request({
    url: API.baseUrl + API.getQuizzes + `/${quizId}`,
    method: "POST",
    data: quizData,
  });
};

export const deleteQuiz = (quizId) => {
  return request({
    url: API.baseUrl + API.getQuizzes + `/${quizId}`,
    method: "DELETE",
  });
};

export const deleteQuizQuestion = (questionId) => {
  let temp = { _method: "DELETE" };
  return request({
    url: API.baseUrl + API.addQuizQuestion + `/${questionId}`,
    method: "POST",
    data: temp,
  });
};
