import React from "react";
import { Button, Grid, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { modalStyle } from "../constants";
import ButtonLoader from "../ButtonLoader";

const PricesModal = ({ modalOpen, handleClose, handleConfirm, loading }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Manage Ad's Prices
        </span>
        <TextField
          variant="outlined"
          fullWidth
          label="Quiz Price"
          placeholder={"100"}
          value={100}
          onChange={(e) => {}}
          style={{ marginTop: 40 }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          //   inputProps={{ sx: { color: "#808080" } }}
          inputProps={{
            sx: { color: "#808080" },
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <TextField
          variant="outlined"
          fullWidth
          label="DM Promotion Price"
          placeholder={"100"}
          value={100}
          onChange={(e) => {}}
          style={{ marginTop: 20, marginBottom: 40 }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          inputProps={{
            sx: { color: "#808080" },
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <Grid container justifyContent="center">
          {loading ? (
            <Grid style={{ paddingLeft: 70 }}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirm}
              style={confirmButton}
            >
              Update
            </Button>
          )}

          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={cancelButton}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PricesModal;

const cancelButton = {
  width: "130px",
  marginLeft: "auto",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  textTransform: "none",
};

const confirmButton = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
};
