import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CurrentAdsTab from "./CurrentAdsTab";
import ExpiredAdsTab from "./ExpiredAdsTab";
import DraftAdsTab from "./DraftAdsTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ad-tabpanel-${index}`}
      aria-labelledby={`ad-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `ad-tab-${index}`,
    "aria-controls": `ad-tabpanel-${index}`,
  };
}

export default function AdTabs({
  allAds,
  setAllAds,
  allExpiredAds,
  setAllExpiredAds,
  allDraftAds,
  setAllDraftAds,
  setSelectedAd,
  selectedAd,
  handleDeleteAdModalOpen,
  handleViewsModalOpen,
  handlePricesModalOpen,
}) {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
          centered
          style={tabsStyle}
        >
          <Tab
            label="Current Ads"
            {...a11yProps(0)}
            style={value == 0 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Expired Ads"
            {...a11yProps(1)}
            style={value == 1 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Drafts "
            {...a11yProps(2)}
            style={value == 2 ? selectedTab : nonSelectedTab}
          />
        </Tabs>
      </Grid>

      <TabPanel value={value} index={0}>
        <CurrentAdsTab
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseMenu}
          handleOpenMenu={handleOpenMenu}
          allAds={allAds}
          setAllAds={setAllAds}
          selectedAd={selectedAd}
          setSelectedAd={setSelectedAd}
          handleDeleteAdModalOpen={handleDeleteAdModalOpen}
          allDraftAds={allDraftAds}
          handleViewsModalOpen={handleViewsModalOpen}
          handlePricesModalOpen={handlePricesModalOpen}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ExpiredAdsTab
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseMenu}
          handleOpenMenu={handleOpenMenu}
          allExpiredAds={allExpiredAds}
          setAllExpiredAds={setAllExpiredAds}
          selectedAd={selectedAd}
          setSelectedAd={setSelectedAd}
          handleDeleteAdModalOpen={handleDeleteAdModalOpen}
          handleViewsModalOpen={handleViewsModalOpen}
          handlePricesModalOpen={handlePricesModalOpen}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DraftAdsTab
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseMenu}
          handleOpenMenu={handleOpenMenu}
          allDraftAds={allDraftAds}
          setAllDraftAds={setAllDraftAds}
          selectedAd={selectedAd}
          setSelectedAd={setSelectedAd}
          handleDeleteAdModalOpen={handleDeleteAdModalOpen}
          handleViewsModalOpen={handleViewsModalOpen}
          handlePricesModalOpen={handlePricesModalOpen}
        />
      </TabPanel>
    </Box>
  );
}

const selectedTab = {
  background: "#C84B96",
  borderRadius: "53px",
  textTransform: "none",
  color: "#FFF",
  height: 50,
  padding: "0px 15px",
  fontSize: 16,
  fontWeight: 500,
  width: 128,
};

const nonSelectedTab = {
  background: "#F6F6F6",
  textTransform: "none",
  color: "#000000",
  height: 50,
  fontSize: 16,
  fontWeight: 500,
  padding: "0px 15px",
};

const tabsStyle = {
  background: "#F6F6F6",
  width: 380,
  borderRadius: 57,
  padding: 5,
  marginRight: 180,
};
