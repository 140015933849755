import { API } from "../constants";
import { request } from "../constants/request";

export const getAllReports = () => {
  return request({
    url: API.baseUrl + API.getReports + `?filter[status][isnt]=Resolved`,
    method: "GET",
  });
};

export const getResolvedReports = () => {
  return request({
    url: API.baseUrl + API.getReports + `?filter[status][is]=Resolved`,
    method: "GET",
  });
};

export const resolveReport = (reportId, data) => {
  return request({
    url: API.baseUrl + API.getReports + `/${reportId}`,
    method: "POST",
    data: data,
  });
};
