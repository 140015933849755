import React from "react";
import { Grid, Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import MenuCard from "../../components/menus/MenuCard";
import MenusHeader from "../../components/menus/MenusHeader";
import { useMenusContext } from "../../context/MenusContext";
import AddMenuModal from "../../components/menus/AddMenuModal";
import { addFoodMenu, deleteFoodMenu, editFoodMenu } from "../../apis/menus";
import { Toast } from "../../components/alert";
import DeleteMenuModal from "../../components/menus/DeleteMenuModal";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SortMenusMenu from "../../components/menus/SortMenusMenu";
import { makeStyles } from "@mui/styles";

const Menus = () => {
  const { menus, setMenus, fetchMenus } = useMenusContext();
  const classes = useStyles();

  const [allMenus, setAllMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [newMenu, setNewMenu] = useState({
    name: "",
    image: { preview: "", raw: "" },
  });
  const [addMenuModal, setAddMenuModal] = useState(false);
  const [editMenuModal, setEditMenuModal] = useState(false);
  const [deleteMenuModal, setDeleteMenuModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortOption, setSortOption] = useState("Ascending");
  const [feartureLoading, setFeartureLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  // console.log("menus:", menus);
  console.log("selected menu:", selectedMenu);

  useEffect(() => {
    // setAllMenus(menus.sort((a, b) => ([a?.name] > [b?.name] ? 1 : -1)));
    setAllMenus(menus);
  }, [menus]);

  const handleAddMenuModalOpen = () => {
    setAddMenuModal(true);
  };
  const handleAddMenuModalClose = () => {
    setAddMenuModal(false);
  };
  const handleEditMenuModalOpen = () => {
    setEditMenuModal(true);
  };
  const handleEditMenuModalClose = () => {
    setEditMenuModal(false);
  };
  const handleDeleteMenuModalOpen = () => {
    setDeleteMenuModal(true);
  };
  const handleDeleteMenuModalClose = () => {
    setDeleteMenuModal(false);
  };

  const handleAddMenu = async () => {
    try {
      setLoading(true);
      //   let Data =
      const Data = new FormData();
      Data.append("icon", newMenu.image.raw);
      Data.append("name", newMenu.name);
      //   let temp = { name: newMenu.name, icon: newMenu.image.raw };
      let doc = await addFoodMenu(Data);
      // console.log("doc:", doc);

      await fetchMenus();
      setNewMenu({
        name: "",
        image: { preview: "", raw: "" },
      });

      Toast("success", "New Menu added successfully.");
      handleAddMenuModalClose();
    } catch (error) {
      console.log("error adding menu:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditMenu = async () => {
    try {
      setLoading(true);
      const Data = new FormData();
      if (selectedMenu.image) {
        Data.append(
          "icon",
          selectedMenu.image ? selectedMenu.image.raw : selectedMenu.icon
        );
      }
      Data.append("name", selectedMenu.name);
      Data.append("_method", "PUT");
      let doc = await editFoodMenu(Data, selectedMenu.id);
      console.log("doc:", doc);
      await fetchMenus();
      setSelectedMenu(null);
      Toast("success", "Menu updated successfully.");
      handleEditMenuModalClose();
    } catch (error) {
      console.log("error editing menu:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteMenu = async () => {
    try {
      setLoading(true);
      // console.log("selected:", selectedMenu);
      let temp = { _method: "DELETE" };
      await deleteFoodMenu(temp, selectedMenu.id);
      await fetchMenus();
      Toast("success", "Menu Deleted successfully.");
      handleDeleteMenuModalClose();
      //   axios
      //     .post(
      //       `https://winelikes.alphaprecision.co/api/menus/${selectedMenu.id}`,
      //       { _method: "PUT" },
      //       {
      //         headers: {
      //           Accept: "application/json",
      //           Authorization: localStorage.getItem("token"),
      //           // "Content-Type": "multipart/form-data",
      //         },
      //       }
      //     )
      //     .then(async () => {
      //       await fetchMenus();
      //       Toast("success", "Menu Deleted successfully.");
      //       handleDeleteMenuModalClose();
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
    } catch (error) {
      console.log("error deleting menu:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSortMenus = (e) => {
    if (e.target.value.toLowerCase() === "ascending") {
      setSortOption(e.target.value);
      let sorted = [...allMenus];
      sorted.sort((a, b) => ([a?.name] > [b?.name] ? 1 : -1));
      setAllMenus(sorted);
      handleCloseSortMenu();
    } else {
      setSortOption(e.target.value);
      let sorted = [...allMenus];
      sorted.sort((a, b) => ([a?.name] > [b?.name] ? -1 : 1));
      setAllMenus(sorted);
      handleCloseSortMenu();
    }
  };

  const handleFeatureMenu = async () => {
    try {
      setFeartureLoading(true);
      const Data = new FormData();
      Data.append("name", selectedMenu.name);
      Data.append("is_featured", 1);
      Data.append("_method", "PUT");
      let doc = await editFoodMenu(Data, selectedMenu.id);
      console.log("doc:", doc);
      await fetchMenus();
      setSelectedMenu(null);

      Toast("success", "Menu featured successfully.");
    } catch (error) {
      console.log("error featuring menu:", error.message);
    } finally {
      setFeartureLoading(false);
    }
  };
  const handleUnFeatureMenu = async () => {
    try {
      setFeartureLoading(true);
      const Data = new FormData();
      Data.append("name", selectedMenu.name);
      Data.append("is_featured", 0);
      Data.append("_method", "PUT");
      let doc = await editFoodMenu(Data, selectedMenu.id);
      console.log("doc:", doc);
      await fetchMenus();
      setSelectedMenu(null);

      Toast("success", "Menu un-featured successfully.");
    } catch (error) {
      console.log("error un-featuring menu:", error.message);
    } finally {
      setFeartureLoading(false);
    }
  };

  return (
    <Fragment>
      {/*Header Section*/}
      <MenusHeader handleModalOpen={handleAddMenuModalOpen} />
      <Grid container display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          endIcon={<KeyboardArrowDownIcon />}
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenSortMenu}
          style={buttonStyle}
        >
          Sort
        </Button>
        <SortMenusMenu
          anchorEl={anchorEl}
          open={open}
          handleCloseMenu={handleCloseSortMenu}
          sortOption={sortOption}
          handleSortOptionChange={handleSortMenus}
          classes={classes}
          // setDropdownButtonText={setDropdownButtonText}
          // handleSortWines={handleSortWines}
        />
      </Grid>
      <Grid container style={{ paddingTop: 60 }}>
        {allMenus.length > 0 &&
          allMenus.map((menu, index) => (
            <MenuCard
              key={index}
              menuData={menu}
              setSelectedMenu={setSelectedMenu}
              handleEditMenuModalOpen={handleEditMenuModalOpen}
              handleDeleteMenuModalOpen={handleDeleteMenuModalOpen}
              feartureLoading={feartureLoading}
              handleFeatureMenu={handleFeatureMenu}
              handleUnFeatureMenu={handleUnFeatureMenu}
            />
          ))}
      </Grid>
      <AddMenuModal
        modalOpen={addMenuModal}
        handleClose={handleAddMenuModalClose}
        handleConfirm={handleAddMenu}
        menuData={newMenu}
        setMenuData={setNewMenu}
        loading={loading}
      />
      <AddMenuModal
        modalOpen={editMenuModal}
        handleClose={handleEditMenuModalClose}
        handleConfirm={handleEditMenu}
        menuData={selectedMenu}
        setMenuData={setSelectedMenu}
        editMenu={true}
        loading={loading}
      />
      <DeleteMenuModal
        modalOpen={deleteMenuModal}
        handleClose={handleDeleteMenuModalClose}
        handleConfirm={handleDeleteMenu}
        loading={loading}
      />
    </Fragment>
  );
};

export default Menus;

const buttonStyle = {
  color: "#000000",
  borderColor: "#000000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 46,
  paddingLeft: 30,
  paddingRight: 30,
  marginRight: 35,
};

const useStyles = makeStyles(() => {
  return {
    customWidth: {
      "& div": {
        width: "290px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      //   fontFamily: "Poppins",
      color: "#000",
    },
  };
});
