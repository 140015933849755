import { Button, Grid } from "@mui/material";
import React from "react";

const SelectedOptions = ({ data, interestsDropdown, interests }) => {
  return (
    <Grid style={{ marginBottom: 10 }}>
      {data.map((doc, index) => (
        <Button variant={"contained"} style={selectedButtonStyle} key={index}>
          {interestsDropdown
            ? interests.find((item) => item.id === doc).name
            : doc}
        </Button>
      ))}
    </Grid>
  );
};

export default SelectedOptions;

const selectedButtonStyle = {
  borderRadius: 21,
  textTransform: "none",
  marginRight: 5,
  marginTop: 10,
  color: "#FFF",
  background: "#C84B96",
};
