import React, { useEffect, useState } from "react";
import { Button, Grid, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const UserDetailsHeader = ({
  userDetails,
  handleOpenDeleteUserModal,
  handleOpenSuspendUserModal,
  handleOpenUnsuspendUserModal,
  userInfo,
  suspension,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs justifyContent="center" alignItems={"center"}>
          <ArrowBackIosIcon
            className={classes.backIcon}
            onClick={() => navigate("/users")}
          />
          <span className={classes.title}>
            {userDetails && userDetails.first_name && userDetails.first_name}{" "}
            {userDetails && userDetails.last_name && userDetails.last_name}
            {userDetails.first_name == null && userDetails.last_name == null
              ? userDetails.name
              : undefined}
          </span>
        </Grid>

        <Grid item xs>
          {suspension && (
            <Typography style={suspendedText}>
              User Suspended for{" "}
              {suspension && suspension.days && suspension.days} Days
            </Typography>
          )}
        </Grid>

        <Grid item xs style={{ textAlign: "end" }} justifyContent="center">
          {suspension ? (
            <Button
              variant="contained"
              style={suspendButtonStyle}
              onClick={handleOpenUnsuspendUserModal}
            >
              Unsuspend
            </Button>
          ) : (
            <Button
              variant="contained"
              style={suspendButtonStyle}
              onClick={handleOpenSuspendUserModal}
            >
              Suspend
            </Button>
          )}

          <Button
            variant="outlined"
            style={deleteButtonStyle}
            onClick={handleOpenDeleteUserModal}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default UserDetailsHeader;

const useStyles = makeStyles(() => {
  return {
    title: {
      color: "#C84B96",
      fontSize: 25,
      fontWeight: 600,
      marginLeft: 10,
    },
    backIcon: {
      //   marginTop: 15,
      marginBottom: -3,
      cursor: "pointer",
      color: "#C84B96",
    },
    customWidth: {
      "& div": {
        width: "305px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
  };
});

const suspendedText = {
  fontSize: 14,
  fontWeight: 600,
  color: "#FD355A",
  padding: "7px 15px",
  borderRadius: 7,
  background: "#FFEEEE",
  width: 261,
  textAlign: "center",
};

const suspendButtonStyle = {
  color: "#FFFFFF",
  background: "#FD355A",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const deleteButtonStyle = {
  color: "#FD355A",
  borderColor: "#FD355A",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
