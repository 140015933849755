import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteUser,
  getUserAds,
  getUserInfo,
  suspendUser,
  unsuspendUser,
} from "../../apis/users";

import { Toast } from "../../components/alert";
import DeleteUserModal from "../../components/users/userDetails/DeleteUserModal";
import SuspendUserModal from "../../components/users/userDetails/SuspendUserModal";
import UnsuspendUserModal from "../../components/users/userDetails/UnsuspendUserModal";
import UserDeletedModal from "../../components/users/userDetails/UserDeletedModal";
import UserDetailsHeader from "../../components/users/userDetails/UserDetailsHeader";
import UserSuspendedModal from "../../components/users/userDetails/UserSuspendedModal";
import UserTabs from "../../components/users/userDetails/UserTabs";

import { useAuth } from "../../context/AuthContext";
import { useDashboardContext } from "../../context/DashboardContext";
import { useUsersContext } from "../../context/UsersContext";

const UserDetails = () => {
  const { handleGetAllUsers, handleGetSuspendedUsers } = useUsersContext();
  const { handleGetDashboardData } = useDashboardContext();
  const { userToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // console.log("tok:", userToken);

  // location.state.suspended
  //     ? location.state.user &&
  //         location.state.user.user &&
  //         location.state.user.user
  //     :location.state.user

  const [userDetails, setUserDetails] = useState(
    location.state.user && location.state.user.user
      ? location.state.user.user
      : location.state.user
  );
  const [userInfo, setUserInfo] = useState(null);
  const [suspension, setSuspension] = useState(
    location.state.user && location.state.user.user
      ? location.state.user
      : location.state.user.suspension
  );
  const [userAds, setUserAds] = useState([]);
  const [users, setUsers] = useState(location.state.allUsers);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [userDeletedModal, setUserDeletedModal] = useState(false);
  const [suspendUserModal, setSuspendUserModal] = useState(false);
  const [userSuspendedModal, setUserSuspendedModal] = useState(false);
  const [unSuspendUserModal, setUnSuspendUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [userSuspensionDays, setUserSuspensionDays] = useState(null);



  const handleOpenDeleteUserModal = () => {
    setDeleteUserModal(true);
  };
  const handleCloseDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const handleOpenUserDeletedModal = () => {
    setUserDeletedModal(true);
  };
  const handleCloseUserDeletedModal = () => {
    setUserDeletedModal(false);
    Toast("success", "User Deleted successfully.");
    // setTimeout(() => {
      navigate("/users");
    // }, 3000);
  };
  const handleOpenSuspendUserModal = () => {
    setSuspendUserModal(true);
  };
  const handleCloseSuspendUserModal = () => {
    setSuspendUserModal(false);
  };
  const handleOpenUserSuspendedModal = () => {
    setUserSuspendedModal(true);
  };
  const handleCloseUserSuspendedModal = () => {
    setUserSuspendedModal(false);
  };

  const handleOpenUnsuspendUserModal = () => {
    setUnSuspendUserModal(true);
  };
  const handleCloseUnsuspendUserModal = () => {
    setUnSuspendUserModal(false);
  };

  useEffect(() => {
    handleGetUserInfo();
    if (location.state.user.account_type === "Business") {
      handleGetUserAds();
    }
  }, [location.state]);

  const handleGetUserInfo = async () => {
    try {
      let temp = await getUserInfo(
        location.state.suspended
          ? location.state.user.user_id
          : location.state.user.id
      );
      setUserInfo(temp);
      // console.log("user info:", temp);
    } catch (error) {
      console.log("error getting user info:", error.message);
    }
  };

  const handleGetUserAds = async () => {
    try {
      // let temp = await getUserAds(location.state.user.id);
      let temp = await getUserAds(
        location.state.suspended
          ? location.state.user.user_id
          : location.state.user.id
      );
      setUserAds(temp);
      // console.log("user's ads:", temp);
    } catch (error) {
      console.log("error getting user's ads:", error.message);
    }
  };

  const handleDeleteUser = async () => {
    try {
      setDeleteLoading(true);

      await deleteUser(
        location.state.suspended ? location.state.user.user_id : userDetails.id
      );
      await handleGetAllUsers();
      await handleGetSuspendedUsers();
      await handleGetDashboardData();

      handleOpenUserDeletedModal();
      handleCloseDeleteUserModal();
      // navigate("/users")
    } catch (error) {
      console.log("error deleting User:", error.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleSuspendUser = async (suspensionDays, suspensionReason) => {
    try {
      setLoading(true);
      setUserSuspensionDays(suspensionDays);
      let suspensionTemp = await suspendUser({
        days: suspensionDays,
        reason: suspensionReason,
        user_id: location.state.suspended
          ? location.state.user.user_id
          : userDetails.id,
      });
      let temp = {
        days: suspensionDays,
        reason: suspensionReason,
        created_at: new Date(),
        user_id: location.state.suspended
          ? location.state.user.user_id
          : userDetails.id,
        id: suspensionTemp.id,
      };
      setSuspension(temp);

      let docsTemp = [...users];
      let index = docsTemp.indexOf(userDetails);
      if (index > -1) {
        docsTemp[index] = { ...userDetails, suspension: temp };
      }
      setUsers(docsTemp);
      // console.log("users :", docsTemp);

      setUserDetails({ ...userDetails, suspension: temp });
      await handleGetAllUsers();
      await handleGetSuspendedUsers();
      await handleGetDashboardData();

      handleOpenUserSuspendedModal();
      handleCloseSuspendUserModal();
      Toast("success", "User Suspended successfully.");
      // navigate("/users/details", {
      //   state: { user: { ...temp, id: userDetails.id } },
      // });
      // navigate(-1);
    } catch (error) {
      console.log("error suspending user:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log(location.state.suspended)
  // console.log(location.state.user.id)
  // console.log(userDetails?.suspension?.id)

  const handleUnsuspendUser = async () => {
    try {
      setLoading(true);
      let temp = { ...userDetails };
      delete temp.suspension;

      // console.log("suspension id:", userDetails.suspension.id);
      await unsuspendUser(
        location.state.suspended
          ? location.state.user.id
          : userDetails?.suspension?.id
      );
      setSuspension(null);
      // await fetchUsers();
      let docsTemp = [...users];
      let index = docsTemp.indexOf(userDetails);
      if (index > -1) {
        docsTemp[index] = { ...temp };
      }
      setUsers(docsTemp);

      setUserDetails({ ...temp });
      await handleGetAllUsers();
      await handleGetSuspendedUsers();
      await handleGetDashboardData();

      Toast("success", "User Unsuspended successfully.");
      handleCloseUnsuspendUserModal();
      // navigate("/users/details", {
      //   state: { user: { ...temp } },
      // });
    } catch (error) {
      console.log("error unsuspending user:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log("user:", location.state.user);

  return (
    <Fragment>
      {/* Header Section */}
      <UserDetailsHeader
        userDetails={userDetails}
        userInfo={userInfo}
        suspension={suspension}
        handleOpenDeleteUserModal={handleOpenDeleteUserModal}
        handleOpenSuspendUserModal={handleOpenSuspendUserModal}
        handleOpenUnsuspendUserModal={handleOpenUnsuspendUserModal}
      />

      {/* User Tabs section */}
      <UserTabs
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        userInfo={userInfo}
        userAds={userAds}
      />

      <DeleteUserModal
        modalOpen={deleteUserModal}
        handleClose={handleCloseDeleteUserModal}
        modalStyle={modalStyle}
        userData={userDetails}
        handleConfirm={handleDeleteUser}
        loading={deleteLoading}
      />
      <UserDeletedModal
        modalOpen={userDeletedModal}
        handleClose={handleCloseUserDeletedModal}
        modalStyle={modalStyle}
        userData={userDetails}
      />
      <SuspendUserModal
        modalOpen={suspendUserModal}
        handleClose={handleCloseSuspendUserModal}
        modalStyle={modalStyle}
        handleConfirm={handleSuspendUser}
        userData={userDetails}
        loading={loading}
      />
      <UserSuspendedModal
        modalOpen={userSuspendedModal}
        handleClose={handleCloseUserSuspendedModal}
        modalStyle={modalStyle}
        userData={userDetails}
        suspendDays={userSuspensionDays}
      />
      <UnsuspendUserModal
        modalOpen={unSuspendUserModal}
        handleClose={handleCloseUnsuspendUserModal}
        modalStyle={modalStyle}
        userData={userDetails}
        handleConfirm={handleUnsuspendUser}
        loading={loading}
      />
    </Fragment>
  );
};

export default UserDetails;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};
