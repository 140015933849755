import React from "react";
import { Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const TextFieldComponent = ({
  label,
  value,
  handleChange,
  placeholder,
  rows,
  multiline,
}) => {
  const classes = useStyles();

  return (
    <Grid item container alignItems="center" style={{ marginBottom: "20px" }}>
      <span
        style={{
          color: "#000",
          fontWeight: 600,
          fontSize: 16,
          width: 120,
        }}
      >
        {label}
      </span>
      <TextField
        className={classes.textField}
        variant="outlined"
        value={value}
        onChange={handleChange}
        multiline={multiline}
        rows={rows}
        InputProps={{
          style: {
            borderRadius: 8,
            background: "#F6F6F6",
            fontSize: 16,
            color: "#808080",
            fontWeight: 600,
          },
        }}
      />
    </Grid>
  );
};

export default TextFieldComponent;

const useStyles = makeStyles({
  textField: {
    width: 366,
    height: 50,
    borderRadius: 8,
    "& fieldset": {
      border: "none",
    },
  },
});
