import { Button, Grid, TextField } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import quizImage1 from '../../images/quizImages/img1.png'

import AddQuizHeader from '../../components/quizzes/addQuiz/AddQuizHeader'
import { Toast } from '../../components/alert'
import CreateQuestionCard from '../../components/quizzes/addQuiz/CreateQuestionCard'
import QuestionPreviewCard from '../../components/quizzes/addQuiz/QuestionPreviewCard'
import DeleteQuestionModal from '../../components/quizzes/addQuiz/DeleteQuestionModal'
import PublishQuizModal from '../../components/quizzes/addQuiz/PublishQuizModal'
import QuizPublishedModal from '../../components/quizzes/addQuiz/QuizPublishedModal'
import { useLocation, useNavigate } from 'react-router-dom'
import SaveDraftModal from '../../components/quizzes/addQuiz/SaveDraftModal'
import { addQuiz, addQuizQuestion } from '../../apis/quizzes'
import { useQuizContext } from '../../context/QuizContext'

const AddQuiz = () => {
    const { handleGetAllQuizzes } = useQuizContext()
    

    const location = useLocation()
    const navigate = useNavigate()

    // main quiz state 
    const [quiz, setQuiz] = useState({
        title: '',
        order: '',
        // set default image 
        image: quizImage1,
        participants: '',
        averageScore: '',
        highestScore: '',
        lowestScore: '',
        questions: [],
        scores: [],
    })



    const [allQuizzes, setAllQuizzes] = useState(location.state.allQuizzes)
    // const [allDraftQuizzes, setAllDraftQuizzes] = useState(
    //   location.state.allDraftQuizzes
    // );
    const [questionCompleted, setQuestionCompleted] = useState(false)
    const [reviewQuiz, setReviewQuiz] = useState(false)
    const [editQuestion, setEditQuestion] = useState(false)
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null)
    const [loading, setLoading] = useState(false)
    const [deleteQuestionModal, setDeleteQuestionModal] = useState(false)
    const [publishQuizModal, setPublishQuizModal] = useState(false)
    const [quizPublishedModal, setQuizPublishedModal] = useState(false)
    const [saveDraftModal, setSaveDraftModal] = useState(false)
    const [draftSavedModal, setDraftSavedModal] = useState(false)
    const [draftLoading, setDraftLoading] = useState(false)

      // single question state 
      const [singleQuestion, setSingleQuestion] = useState({
        question: '',
        answers: [
            { label: 'A', answer: '' },
            { label: 'B', answer: '' },
        ],
        image: null,
    })


    const goback = () => {
        navigate('/quizzes', {
            state: { allQuizzes },
        })
    }

// allQuizzes carry allDraftQuiz and allQuiz array 
    console.log({ allQuizzes })
    console.log({singleQuestion})

    // handle reset the quiz 
    const handleResetQuiz = () => {
        setQuiz({
            title: '',
            order: '',
            image: quizImage1,
            participants: '',
            averageScore: '',
            highestScore: '',
            lowestScore: '',
            questions: [],
            scores: [],
        })

         // handle set single question  in quiz 
        setSingleQuestion({
            question: '',
            answers: [
                { label: 'A', answer: '' },
                { label: 'B', answer: '' },
            ],
        })
        setQuestionCompleted(false)
        setReviewQuiz(false)
        setEditQuestion(false)
        setSelectedQuestionIndex(false)
    }
// all more answer option 
    const addAnswerToArray = () => {
        let temp = [...singleQuestion.answers]
        if (singleQuestion.answers.length === 2) {
            temp.push({
                label: 'C',
                answer: '',
            })
        }
        if (singleQuestion.answers.length === 3) {
            temp.push({
                label: 'D',
                answer: '',
            })
        }
        setSingleQuestion({
            ...singleQuestion,
            answers: temp,
        })
    }
    const removeAnswerFromArray = (index) => {
        let temp = [...singleQuestion.answers]
        temp.splice(index, 1)
        setSingleQuestion({
            ...singleQuestion,
            answers: temp,
        })
    }

    const handleChangeAnswer = (e, index) => {
        let value = e.target.value
        let temp = [...singleQuestion.answers]
        temp[index].answer = value
        setSingleQuestion({
            ...singleQuestion,
            answers: temp,
        })
    }

    const handleUploadFile = (e) => {
        setSingleQuestion({
            ...singleQuestion,
            image: e.target.files[0],
        })
    }

    const handleSelectCorrectAnswer = (index) => {
        try {
            let temp = [...singleQuestion.answers]
            temp.forEach((item, itemIndex) => delete temp[itemIndex].correct)
            temp[index] = {
                ...temp[index],
                correct: true,
            }
            // console.log(temp);
            setSingleQuestion({
                ...singleQuestion,
                answers: temp,
            })
            setQuestionCompleted(true)
        } catch (error) {
            console.log('error selecting answer:', error.message)
        }
    }

    const handleAddAnotherQuestion = async () => {
        try {
            setQuiz({
                ...quiz,
                questions: [...quiz.questions, singleQuestion],
            })
            setSingleQuestion({
                question: '',
                answers: [
                    { label: 'A', answer: '' },
                    { label: 'B', answer: '' },
                ],
                image: null,
            })
            // console.log(singleQuestion);
            setQuestionCompleted(false)
        } catch (error) {}
    }

    const handleReviewQuiz = () => {
        if (singleQuestion.question !== '') {
            Toast('error', 'Please complete your question first.')
        } else if (quiz.questions.length === 0) {
            Toast(
                'error',
                'Please add questions first, and then try to Review the Quiz.'
            )
        } else {
            setReviewQuiz(true)
        }
    }

    const handleUpdateQuestion = async () => {
        try {
            let temp = [...quiz.questions]
            temp[selectedQuestionIndex] = {
                ...singleQuestion,
            }
            setQuiz({
                ...quiz,
                questions: temp,
            })
            setSingleQuestion({
                question: '',
                answers: [
                    { label: 'A', answer: '' },
                    { label: 'B', answer: '' },
                ],
            })
            setQuestionCompleted(false)
            setEditQuestion(false)
        } catch (error) {}
    }

    const handleDeleteQuestionModalOpen = () => {
        setDeleteQuestionModal(true)
    }
    const handleDeleteQuestionModalClose = () => {
        setDeleteQuestionModal(false)
    }

    const handleDeleteQuestion = () => {
        try {
            setLoading(true)
            let temp = [...quiz.questions]
            temp[selectedQuestionIndex] = {
                ...singleQuestion,
            }
            if (selectedQuestionIndex > -1) {
                temp.splice(selectedQuestionIndex, 1) // 2nd parameter means remove one item only
            }
            setQuiz({
                ...quiz,
                questions: temp,
            })
            setSingleQuestion({
                question: '',
                answers: [
                    { label: 'A', answer: '' },
                    { label: 'B', answer: '' },
                ],
            })
            setQuestionCompleted(false)
            setEditQuestion(false)
            handleDeleteQuestionModalClose()
        } catch (error) {
            console.log('error deleting question:', error.message)
        } finally {
            setLoading(false)
        }
    }

    const handlePublishQuizModalOpen = () => {
        setPublishQuizModal(true)
    }
    const handlePublishQuizModalClose = () => {
        setPublishQuizModal(false)
    }

    const handlePublishQuiz = async () => {
        try {
            setLoading(true)
            let quizDataTemp = {
                name: quiz.title,
                order: quiz.order,
                icon: quiz.image,
                question_count: quiz.questions.length,
                status: 'Publish',
            }

            let quizTemp = await addQuiz(quizDataTemp)
            // console.log("created quiz: ", quizTemp);
            if (quizTemp) {
                let temp = [...quiz.questions]
                temp.forEach(async (doc, index) => {
                    await handleAddQuestion(doc, index, quizTemp)
                })

                // let docsTemp = [...allQuizzes];
                // docsTemp.push({ ...quiz, id: quizTemp.id });
                handleQuizPublishedModalOpen()
                handlePublishQuizModalClose()
                // setAllQuizzes(docsTemp);
                Toast('success', 'Quiz published successfully.')
                await handleResetQuiz()
                await handleGetAllQuizzes(1)
            }
        } catch (error) {
            console.log('error publishing quiz:', error.message)
        } finally {
            setLoading(false)
        }
    }

    const handleAddQuestion = async (doc, index, quizTemp) => {
        try {
            let answersTemp = []
            let correctAnswerIndex

            doc.answers.forEach((ans, index) => {
                answersTemp.push(ans.answer)
                if (ans.correct) {
                    correctAnswerIndex = index
                }
            })

            const Data = new FormData()
            Data.append('quiz_id', quizTemp.id)
            Data.append('question', doc.question)
            Data.append('order', index + 1)
            for (let i = 0; i < answersTemp.length; i++) {
                Data.append('option[]', answersTemp[i])
            }
            Data.append('correct_option_index', correctAnswerIndex)
            if (doc?.image) {
                Data.append('image', doc?.image)
            }

            let questionDataTemp = {
                quiz_id: quizTemp.id,
                question: doc.question,
                order: index + 1,
                option: answersTemp,
                correct_option_index: correctAnswerIndex,
                image: doc?.image,
            }

            // console.log("Question data", questionDataTemp);
            let questionTemp = await addQuizQuestion(Data)

            // console.log("created question: ", questionTemp);
        } catch (error) {
            console.log('error adding quiz question:', error.message)
        }
    }

    const handleQuizPublishedModalOpen = () => {
        setQuizPublishedModal(true)
    }
    const handleQuizPublishedModalClose = () => {
        setQuizPublishedModal(false)
        goback()
    }

    const handleSaveDraftModalOpen = () => {
        setSaveDraftModal(true)
    }
    const handleSaveDraftModalClose = () => {
        setSaveDraftModal(false)
    }
    const handleDraftSavedModalOpen = () => {
        setDraftSavedModal(true)
    }
    const handleDraftSavedModalClose = () => {
        setDraftSavedModal(false)
    }

    const handleSaveDraft = async () => {
        try {
            if (singleQuestion.question !== '') {
                Toast('error', 'Please complete your question first.')
            } else if (quiz.questions.length === 0) {
                Toast(
                    'error',
                    'Please add questions first, and then try to Review the Quiz.'
                )
            } else {
                setDraftLoading(true)
                if (location?.state?.isAddNew) {
                    let temp = [...quiz.questions]
                    temp.forEach(async (doc, index) => {
                        if (Object.keys(doc)?.length <= 3) {
                            await handleAddQuestion(
                                doc,
                                index,
                                location?.state?.quizData
                            )
                        }
                    })
                } else {
                    let quizDataTemp = {
                        name: quiz.title,
                        order: quiz.order,
                        icon: quiz.image,
                        question_count: quiz.questions.length,
                        status: 'Draft',
                    }

                    let quizTemp = await addQuiz(quizDataTemp)
                    console.log("quiz save as draft: ", quizTemp);
                    if (quizTemp) {
                        let temp = [...quiz.questions];
                        for (const [index, doc] of temp.entries()) {
                            console.log({ doc });
                            await new Promise(resolve => setTimeout(resolve, index + 1000));
                            await handleAddQuestion(doc, index, quizTemp);
                        }
                    }
                }

                await handleGetAllQuizzes(1)
                await handleDraftSavedModalOpen()
                await handleSaveDraftModalClose()
                await Toast('success', 'Quiz saved as Draft successfully.')
                await handleResetQuiz()
                await navigate(-1)
            setDraftLoading(false)

            }
        } catch (error) {
            console.log('error saving draft:', error.message)
            setDraftLoading(false)
        }
        // } finally {
        //     setDraftLoading(false)
        // }
    }

    console.log(location.state)

    useEffect(() => {
        if (location.state?.isAddNew) {
            setQuiz({
                ...location.state.quizData,
                title: location?.state?.quizData?.name,
                questions: location.state?.questions?.map((item) => ({
                    ...item,
                    answers: item?.options?.map((ele) => ({
                        ...ele,
                        correct: ele.is_correct,
                        answer: ele.option,
                    })),
                })),
                images: location.state?.questions?.map((item) => ({
                    ...item,
                    image: item?.image,
                })),
            })
        }
    }, [location.state])

    console.log({ quiz })

    return (
        <Fragment>
            {/* Header section */}
            <AddQuizHeader
                quiz={quiz}
                setQuiz={setQuiz}
                handleReviewQuiz={handleReviewQuiz}
                reviewQuiz={reviewQuiz}
                editQuestion={editQuestion}
                closeEditQuestion={() => setEditQuestion(false)}
                handlePublishQuizModalOpen={handlePublishQuizModalOpen}
                handleSaveDraftModalOpen={handleSaveDraftModalOpen}
                goback={goback}
            />
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <TextField
                    variant="outlined"
                    placeholder="Quiz Order"
                    value={quiz.order}
                    type="number"
                    onChange={(e) =>
                        setQuiz({
                            ...quiz,
                            order: e.target.value,
                        })
                    }
                    InputProps={{
                        style: {
                            fontSize: 20,
                            fontWeight: 500,
                            borderRadius: 50,
                        },
                    }}
                    sx={{
                        width: '35%',
                        // "& .MuiOutlinedInput-notchedOutline": {
                        //   border: "none",
                        // },
                    }}
                />
            </Grid>
            {!editQuestion ? (
                <Fragment>
                    {reviewQuiz ? (
                        <Grid container style={{ marginTop: 100 }}>
                            {quiz.questions.map((question, index) => (
                                <QuestionPreviewCard
                                    question={question}
                                    key={index}
                                    handleEditQuestionClick={() => {
                                        setSingleQuestion({ ...question })
                                        setSelectedQuestionIndex(index)
                                        setEditQuestion(true)
                                    }}
                                />
                            ))}
                        </Grid>
                    ) : (
                        <Grid
                            container
                            direction={'column'}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid
                                style={{
                                    width: '40%',
                                    marginTop: 40,
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 300,
                                    }}
                                >
                                    <i>
                                        Type your question then add answers.
                                        Once you’re done adding answers, select
                                        the correct option.{' '}
                                    </i>
                                </span>
                            </Grid>
                            {/* Add or preview question (s) section */}

                            <CreateQuestionCard
                                singleQuestion={singleQuestion}
                                setSingleQuestion={setSingleQuestion}
                                handleChangeAnswer={handleChangeAnswer}
                                handleSelectCorrectAnswer={
                                    handleSelectCorrectAnswer
                                }
                                questionCompleted={questionCompleted}
                                handleAddAnotherQuestion={
                                    handleAddAnotherQuestion
                                }
                                addAnswerToArray={addAnswerToArray}
                                editQuestion={editQuestion}
                                removeAnswerFromArray={removeAnswerFromArray}
                                handleUploadFile={handleUploadFile}
                            />
                        </Grid>
                    )}
                    {reviewQuiz && (
                        <Grid
                            container
                            alignItems={'center'}
                            justifyContent="center"
                        >
                            <Button
                                variant="contained"
                                style={addQuestionButtonStyles}
                                //   disabled={!questionCompleted}
                                onClick={() => setReviewQuiz(false)}
                            >
                                Add another question
                            </Button>
                        </Grid>
                    )}
                </Fragment>
            ) : (
                <Grid
                    container
                    direction={'column'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        style={{
                            width: '40%',
                            marginTop: 40,
                        }}
                    >
                        <span
                            style={{
                                fontSize: 16,
                                fontWeight: 300,
                            }}
                        >
                            <i>
                                Type your question then add answers. Once you’re
                                done adding answers, select the correct option.{' '}
                            </i>
                        </span>
                    </Grid>
                    {/* Add or preview question (s) section */}

                    <CreateQuestionCard
                        singleQuestion={singleQuestion}
                        setSingleQuestion={setSingleQuestion}
                        handleChangeAnswer={handleChangeAnswer}
                        handleSelectCorrectAnswer={handleSelectCorrectAnswer}
                        questionCompleted={questionCompleted}
                        handleAddAnotherQuestion={handleAddAnotherQuestion}
                        addAnswerToArray={addAnswerToArray}
                        editQuestion={editQuestion}
                        handleUpdateQuestion={handleUpdateQuestion}
                        handleDeleteQuestionModalOpen={
                            handleDeleteQuestionModalOpen
                        }
                        handleUploadFile={handleUploadFile}
                    />
                </Grid>
            )}
            {/* Delete Quiz Modal section */}
            <DeleteQuestionModal
                modalOpen={deleteQuestionModal}
                handleClose={handleDeleteQuestionModalClose}
                handleConfirm={handleDeleteQuestion}
                loading={loading}
            />
            {/* Publish Quiz Modal section */}
            <PublishQuizModal
                modalOpen={publishQuizModal}
                handleClose={handlePublishQuizModalClose}
                handleConfirm={handlePublishQuiz}
                loading={loading}
                title="Are you sure you want to publish the quiz"
            />
            {/* Quiz published Modal section */}
            <QuizPublishedModal
                modalOpen={quizPublishedModal}
                handleClose={handleQuizPublishedModalClose}
                title="Published Successfully"
            />
            {/* Save Draft Quiz Modal section */}
            <SaveDraftModal
                modalOpen={saveDraftModal}
                handleClose={handleSaveDraftModalClose}
                handleConfirm={handleSaveDraft}
                loading={draftLoading}
                title="You have unsaved changes. Would you like to save your quiz?"
            />
            {/* Draft Quiz saved Modal section */}
            <QuizPublishedModal
                modalOpen={draftSavedModal}
                handleClose={handleDraftSavedModalClose}
                title="Successfully saved draft"
            />
        </Fragment>
    )
}

export default AddQuiz

const addQuestionButtonStyles = {
    marginTop: 100,
    width: 350,
    height: 60,
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none',
    background: '#C84B96',
    color: '#fff',
    borderRadius: 8,
}
