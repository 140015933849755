import React from "react";
import { Button, Grid, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import { modalStyle } from "../constants";

const VisitsModal = ({ modalOpen, handleClose }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Ad's Visits
        </span>
        <TextField
          variant="outlined"
          fullWidth
          label="Most Profile Visits"
          placeholder={12}
          value={12}
          //   onChange={(e) => {}}
          style={{ marginTop: 40 }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          //   inputProps={{ sx: { color: "#808080" } }}
          inputProps={{
            sx: { color: "#808080" },
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Most Website Visits"
          placeholder={12}
          value={12}
          //   onChange={(e) => {}}
          style={{ marginTop: 20, marginBottom: 40 }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C84B96",
            },
          }}
          //   inputProps={{ sx: { color: "#808080" } }}
          inputProps={{
            sx: { color: "#808080" },
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <Grid container justifyContent="center">
          <Button
            fullWidth
            variant="contained"
            onClick={handleClose}
            style={closeButton}
          >
            Close
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default VisitsModal;

const closeButton = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
};
