import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { mostPopularWineArray } from "../constants";
import AnalyticsNonPercentCard from "./AnalyticsNonPercentCard";

const useStyles = makeStyles({
  title: {
    color: "#000",
    fontSize: 20,
    fontWeight: 600,
    // fontFamily: "Poppins",
  },
});

const MostPopularWineSection = ({ popularWines }) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.title}>Most Popular Wine (added to cellar)</span>
      <Grid container direction="row" style={{ padding: 20 }}>
        {popularWines &&
          popularWines.map((card, index) => (
            <AnalyticsNonPercentCard key={index} card={card} />
          ))}
      </Grid>
    </div>
  );
};

export default MostPopularWineSection;
