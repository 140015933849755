import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import ButtonLoader from "../ButtonLoader";
import { modalStyle } from "../constants";

const ManageAdminModal = ({
  adminModal,
  handleClose,
  title,
  adminData,
  setAdminData,
  handleConfirm,
  newAdmin,
  loading,
}) => {
  const [emailError, setEmailError] = useState(false);

  return (
    <Modal open={adminModal} onClose={handleClose}>
      <Box sx={modalStyle}>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          {title}
        </span>
        <FormControl
          fullWidth
          style={{ textAlign: "start", marginTop: 30, marginBottom: 15 }}
        >
          <InputLabel id="role-select">Role</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={adminData?.role}
            label="Role"
            onChange={(e) =>
              setAdminData({ ...adminData, role: e.target.value })
            }
          >
            <MenuItem value={"Admin"}>Admin</MenuItem>
            <MenuItem value={"Super Admin"}>Super Admin</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Admin Name"
          placeholder="Admin Name"
          variant="outlined"
          value={adminData?.name}
          onChange={(e) => setAdminData({ ...adminData, name: e.target.value })}
          inputProps={{
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        <TextField
          fullWidth
          label="Admin Email Address"
          placeholder="Admin Email Address"
          variant="outlined"
          value={adminData?.email}
          error={emailError}
          helperText={emailError && "Email address invalid, please type again"}
          onChange={(e) => {
            setAdminData({ ...adminData, email: e.target.value });
            let valid =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                e.target.value
              );
            if (!valid) {
              setEmailError(true);
            } else {
              setEmailError(false);
            }
          }}
          style={{ marginTop: 15, marginBottom: 30 }}
          inputProps={{
            style: {
              color: "#9D9DAA",
              fontSize: 16,
              fontWeight: 500,
            },
          }}
        />
        {newAdmin && (
          <TextField
            fullWidth
            label="Admin Password"
            placeholder="Admin Passsword"
            variant="outlined"
            type="password"
            style={{ marginBottom: 30 }}
            value={adminData?.password && adminData?.password}
            onChange={(e) =>
              setAdminData({ ...adminData, password: e.target.value })
            }
            inputProps={{
              style: {
                color: "#9D9DAA",
                fontSize: 16,
                fontWeight: 500,
              },
            }}
          />
        )}
        <Grid container>
          {loading ? (
            <Grid
              container
              style={{ width: 200 }}
              alignItems="center"
              justifyContent="center"
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              disabled={
                adminData?.role && adminData?.name && adminData?.email
                  ? false
                  : true
              }
              onClick={handleConfirm}
              style={{
                ...confirmButton,
                background:
                  adminData?.role && adminData?.name && adminData?.email
                    ? "#C84B96"
                    : "#F6F6F6",
                color:
                  adminData?.role && adminData?.name && adminData?.email
                    ? "#FFFFFF"
                    : "#9D9DAA",
              }}
            >
              {title}
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={{
              width: "130px",
              marginLeft: "auto",
              height: 55,
              fontSize: 15,
              fontWeight: 600,
              color: "#313137",
              borderColor: "#313137",
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ManageAdminModal;

const confirmButton = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
};
