import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

const QuizPublishedModal = ({ modalOpen, handleClose, title }) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container alignItems={"center"} justifyContent="center">
          <Grid
            container
            alignItems={"center"}
            justifyContent="center"
            style={{
              margin: 12,
              borderRadius: 50,
              width: 55,
              height: 55,
              background: "#17DDA2",
            }}
          >
            <DoneOutlinedIcon
              fontSize="large"
              style={{ padding: 2, color: "#fff" }}
            />
          </Grid>
        </Grid>
        <span
          style={{
            color: "#313137",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title}
        </span>
        <Button
          fullWidth
          variant="contained"
          onClick={handleClose}
          style={{
            width: "200px",
            textTransform: "none",
            height: 55,
            fontSize: 15,
            fontWeight: 600,
            background: "#C84B96",
            color: "#FFFFFF",
            marginTop: 40,
          }}
        >
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default QuizPublishedModal;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};
