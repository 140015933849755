import React, { createContext, useContext, useEffect, useState } from "react";
import { getAllAdmins } from "../apis/admins";
import { useAuth } from "./AuthContext";

export const AdminsContext = createContext();
export const useAdminsContext = () => useContext(AdminsContext);

export const AdminsProvider = ({ children }) => {
  const { userToken } = useAuth();
  const [admins, setAdmins] = useState([]);
  // console.log("token:", userToken);
  useEffect(() => {
    handleGetAllAdmins();
  }, [userToken]);

  const handleGetAllAdmins = async () => {
    try {
      let docsTemp = await getAllAdmins();
      setAdmins(docsTemp);
    } catch (error) {
      console.log("error getting Admins:", error.message);
    }
  };

  return (
    <AdminsContext.Provider
      value={{
        admins,
        setAdmins,
        handleGetAllAdmins,
      }}
    >
      {children}
    </AdminsContext.Provider>
  );
};
