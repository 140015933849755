import React, { Fragment, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";

import { certificationsColumns, certificationsHeader } from "../../constants";
import { Button, Grid, TablePagination } from "@mui/material";
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function CertificationsTable({
  certifications,
  setCertifications,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      <Table style={{ border: "none" }}>
        <TableHead style={{ border: "none" }}>
          <TableRow>
            {certificationsColumns &&
              certificationsColumns.map((column, index) => (
                <TableCell
                  key={index}
                  style={{
                    minWidth: column.minWidth,
                    border: "none",
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#8086A2",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {certifications &&
            certifications
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <Fragment>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      style={{ borderRadius: 20 }}
                    >
                      {certificationsColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            style={{
                              border: "none",
                              background: "#F6F6F6",
                              color:
                                row.certificate_status == "Verified"
                                  ? "#1FCBAC"
                                  : row.certificate_status == "Not Started"
                                  ? "#1B2124"
                                  : row.certificate_status == null
                                  ? "#1B2124"
                                  : row.certificate_status == "Denied"
                                  ? "#FD355A"
                                  : "#C84B96",
                              fontSize: 14,
                              fontWeight: column.id == "name" ? 600 : 500,
                              borderRadius:
                                column.id == "name"
                                  ? "8px 0px 0px 8px"
                                  : undefined,
                            }}
                          >
                            {column.id == "status" ? (
                              <Grid container alignItems="center">
                                <div
                                  style={{
                                    background:
                                      row.certificate_status == "Verified"
                                        ? "#E5F6F1"
                                        : row.certificate_status ==
                                          "Not Started"
                                        ? "#E1E1E1"
                                        : row.certificate_status == null
                                        ? "#E1E1E1"
                                        : row.certificate_status == "Denied"
                                        ? "rgba(255, 86, 85, 0.2)"
                                        : undefined,
                                    width: 120,
                                    height: 30,
                                    padding: "5px 15px",
                                    borderRadius: 5,
                                    textAlign: "center",
                                  }}
                                >
                                  {row.certificate_status === null
                                    ? "Not Started"
                                    : row.certificate_status}
                                </div>
                                {value == "Not Started" ||
                                  (row.certificate_status === null && (
                                    <FiberManualRecordSharpIcon
                                      style={{ color: "#FD355A", height: 15 }}
                                    />
                                  ))}
                              </Grid>
                            ) : column.id == "name" ? (
                              `${
                                row.account_type === "Business"
                                  ? row.name
                                  : row.first_name + " " + row.last_name
                              }`
                            ) : column.id == "date" ? (
                              moment(row.created_at).format("MM-DD-YYYY")
                            ) : column.id == "certificate_doc" ? <a target="_blank" href={`https://staging.api.winelikes.com/${row.certificate_doc}`}>Open Document</a> : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        key="forward"
                        align="right"
                        style={tableLastCellStyle}
                      >
                        <ArrowForwardIosSharpIcon
                          style={{ color: "#000" }}
                          onClick={() => {
                            setSelectedRequest(row);
                            navigate("/support/certifications/details", {
                              state: { support: row, certifications },
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <div style={{ margin: 8 }} />
                  </Fragment>
                );
              })}
        </TableBody>
      </Table>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: 50 }}
      >
        <TablePagination
          rowsPerPageOptions={[8, 15, 30]}
          component="div"
          count={certifications && certifications.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button variant="outlined" style={exportButton}>
          <CSVLink
            data={certifications.length ? certifications : []}
            headers={certificationsHeader}
            filename="Certification-Requests.csv"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export CSV
          </CSVLink>
        </Button>
      </Grid>
    </Fragment>
  );
}

const tableLastCellStyle = {
  border: "none",
  background: "#F6F6F6",
  color: "#C84B96",
  fontSize: 14,
  fontWeight: 500,
  borderRadius: "0px 8px 8px 0px",
};

const exportButton = {
  marginLeft: "auto",
  background: "#C84B96",
  borderRadius: 12,
  width: 130,
  height: 50,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
};

const useStyles = makeStyles(() => {
  return {
    menuDropdown: {
      //   width: 240,
      "& .MuiPaper-root": {
        backgroundColor: "#C84B96",
      },
      "& div": {
        width: "192px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
  };
});
