import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import ButtonLoader from "../ButtonLoader";
import { modalStyle } from "../constants";

const DeleteAdminModal = ({
  modalOpen,
  handleClose,
  title,
  adminData,
  handleConfirm,
  loading,
}) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          {title}
        </span>
        <Grid container>
          <span
            style={{
              color: "#000000",
              fontSize: 15,
              fontWeight: 500,
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            Are you sure you want to delete the admin “{adminData.name}”?
            Actions are not reversable.
          </span>
        </Grid>
        <Grid container>
          {loading ? (
            <Grid
              container
              style={{ width: 200 }}
              alignItems="center"
              justifyContent="center"
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirm}
              style={{
                width: "200px",
                textTransform: "none",
                height: 55,
                fontSize: 15,
                fontWeight: 600,
                background: "#C84B96",
                color: "#FFFFFF",
              }}
            >
              {title}
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={{
              width: "130px",
              marginLeft: "auto",
              height: 55,
              fontSize: 15,
              fontWeight: 600,
              color: "#313137",
              borderColor: "#313137",
            }}
          >
            No
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteAdminModal;
