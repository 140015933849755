import { Box, Button, Grid } from "@mui/material";
import React from "react";

import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useNavigate } from "react-router-dom";
import { API } from "../../../apis/constants";

const QuizQuestionCard = ({ question, allQuestions, quizData, quizDetails }) => {
  //   console.log("answers:", question.answers);
  const navigate = useNavigate();

  // console.log("edit  Questions here >>>", question);
  let status = quizDetails.status;
  // console.log("quizDetails", status);

  return (
    <Grid
      item
      container
      direction={"column"}
      style={{
        width: 300,
        minHeight: "480px",
        padding: "30px  30px 30px 30px",
        borderRadius: 20,
        background: "#F9F9FA",
        marginRight: 15,
        marginBottom: 15,
        position: "relative",
      }}
    >
      <span
        style={{
          fontWeight: 600,
          fontSize: 16,
          color: "#000",
          marginBottom: "1rem",
        }}
      >
        {question.question && question.question}
      </span>

      {question?.image ? (
        <img
          src={`${API.baseUrl}${question?.image}`}
          alt={question?.question}
          height={220}
          style={{ width: "100%" }}
        />
      ) : (
        <div
          style={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.2rem",
          }}
        >
          No Image to show
        </div>
      )}
      {question.options &&
        question.options.map((option, index) => (
          <Grid
            item
            container
            style={{
              height: 40,
              marginTop: 15,
              borderRadius: 8,
              background: "#fff",
            }}
            key={index}
            alignItems="center"
          >
            {option.is_correct === 1 ? (
              <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                style={{
                  margin: 12,
                  //   border: "2px solid #9D9DAA",
                  borderRadius: 50,
                  width: 24,
                  height: 24,
                  background: "#17DDA2",
                }}
              >
                <DoneOutlinedIcon style={{ padding: 2, color: "#fff" }} />
              </Grid>
            ) : (
              <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                style={{
                  margin: 12,
                  border: "2px solid #9D9DAA",
                  borderRadius: 50,
                  width: 24,
                  height: 24,
                }}
              >
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#9D9DAA",
                  }}
                >
                  {index === 0
                    ? "A"
                    : index === 1
                    ? "B"
                    : index === 2
                    ? "C"
                    : index === 3
                    ? "D"
                    : ""}
                </span>
              </Grid>
            )}
            <span
              style={{
                fontWeight: 600,
                fontSize: 14,
                color: "#313137",
                maxWidth: "80%",
                overflow: "hidden",
                maxHeight: 40,
              }}
              title={option.option}
            >
              {option.option}
            </span>
          </Grid>
        ))}

      <Grid item container>
        <Box
          style={{
            position: "absolute",
            bottom: 30,
            width: "80%",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              navigate("/quizzes/editQuiz", {
                state: {
                  question: question,
                  allQuestions: allQuestions,
                  quizData: quizData,
                  quizDetails: quizDetails,
                },
              });
            }}
            style={{
              color: "#fff",
              fontSize: 14,
              fontWeight: 600,
              textTransform: "none",
              background: "#4E1B5C",
              borderRadius: 8,
            }}
          >
            Edit Question
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default QuizQuestionCard;
