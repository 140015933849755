import React, { createContext, useContext, useEffect, useState } from "react";
import { getAllReports, getResolvedReports } from "../apis/reports";
import { useAuth } from "./AuthContext";

export const ReportsContext = createContext();
export const useReportsContext = () => useContext(ReportsContext);

export const ReportsProvider = ({ children }) => {
  const { userToken } = useAuth();
  const [reports, setReports] = useState([]);
  const [resolvedReports, setResolvedReports] = useState([]);

  // console.log("token:", userToken);

  useEffect(() => {
    handleGetAllReports();
    handleGetResolvedReports();
  }, [userToken]);

  const handleGetAllReports = async () => {
    try {
      let docsTemp = await getAllReports();
      setReports(docsTemp);
    } catch (error) {
      console.log("error getting Reports:", error.message);
    }
  };

  const handleGetResolvedReports = async () => {
    try {
      let docsTemp = await getResolvedReports();
      setResolvedReports(docsTemp);
      // console.log("resolved reports:", docsTemp);
    } catch (error) {
      console.log("error getting resolved Reports:", error.message);
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        reports,
        setReports,
        handleGetAllReports,
        resolvedReports,
        setResolvedReports,
        handleGetResolvedReports,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
