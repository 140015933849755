import React from "react";
import { Button, Grid, Toolbar } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const AdsHeader = ({ handleClickCreateAd }) => {
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid
          item
          xs
          style={{
            textAlign: "end",
            width: matchesXL
              ? "65em"
              : matchesLG
              ? "50em"
              : matchesMD
              ? "35em"
              : matchesSM
              ? "28em"
              : "23em",
          }}
        >
          <span style={{ color: "#313137", fontWeight: 700, fontSize: 36 }}>
            Manage Ads
          </span>
        </Grid>
        <Grid item xs style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            onClick={handleClickCreateAd}
            endIcon={<AddOutlinedIcon style={{ fontSize: 24 }} />}
            style={createButtonStyles}
          >
            Create Ad
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default AdsHeader;

const createButtonStyles = {
  color: "#fff",
  background: "#C84B96",
  borderRadius: 38,
  // marginTop: 25,
  fontWeight: 600,
  textTransform: "capitalize",
  fontSize: 15,
  height: 50,
};
