import { Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import ReviewTextField from "./ReviewTextField";
import { useAdsContext } from "../../../context/AdsContext";

const ReviewAdDetailsSection = ({ adData }) => {
  const { handleGetAllAds, interests } = useAdsContext();
  return (
    <Grid
      item
      container
      direction={"column"}
      style={{ width: "50%", padding: 60 }}
    >
      {/*Ad's Name text field */}
      <ReviewTextField value={adData.adName && adData.adName} title="Name" />

      {/*Ad's Website text field */}
      <ReviewTextField
        value={adData.website && adData.website}
        title="Website"
      />

      {/*Ad's Location text field */}
      <ReviewTextField
        value={adData.location && adData.location}
        title="Location"
      />

      {/*Ad's Age Range text field */}
      <ReviewTextField value={adData.age && adData.age} title="Age " />

      {/*Ad's Gender text field */}
      <ReviewTextField
        value={adData.gender.length && adData.gender.map((el) => ` ${el}`)}
        title="Gender"
      />

      {/*Ad's Interests text field */}
      <ReviewTextField
        value={
          adData.interests.length &&
          adData.interests.map(
            (el) => interests.find((item) => item.id === el).name
          )
        }
        title="Interests"
      />

      {/*Ad's Start Date text field */}
      <ReviewTextField
        value={
          adData.startDate && moment(adData.startDate).format("MMM DD, YYYY")
        }
        title="Start Date"
      />

      {/*Ad's End Date text field */}
      <ReviewTextField
        value={adData.endDate && moment(adData.endDate).format("MMM DD, YYYY")}
        title="End Date"
      />
    </Grid>
  );
};

export default ReviewAdDetailsSection;
