import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {  Grid } from "@mui/material";
import EmailTab from "./EmailTab";
import PasswordTab from "./PasswordTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`support-tabpanel-${index}`}
      aria-labelledby={`support-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `support-tab-${index}`,
    "aria-controls": `support-tabpanel-${index}`,
  };
}

export default function SettingsTabs({ user, setUser, handleUpdateEmail, loading }) {
  const [value, setValue] = useState(0);
  const [enableEdit, setEnableEdit] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(user);

  const handleChangeEmail = (e) => {
    setEnableEdit(true);
    setUser({ ...user, email: e.target.value });
    let valid =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      );

    if (!valid) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container alignItems="center" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
          centered
          style={tabsStyle}
        >
          <Tab
            label="Email"
            {...a11yProps(0)}
            style={value == 0 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Password"
            {...a11yProps(1)}
            style={value == 1 ? selectedTab : nonSelectedTab}
          />
        </Tabs>
      </Grid>

      <TabPanel value={value} index={0}>
        <EmailTab
          user={user}
          handleChangeEmail={handleChangeEmail}
          enableEdit={enableEdit}
          emailError={emailError}
          handleUpdateEmail={handleUpdateEmail}
          loading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PasswordTab />
      </TabPanel>
    </Box>
  );
}

const selectedTab = {
  background: "#C84B96",
  borderRadius: 100,
  textTransform: "none",
  color: "#FFF",
  height: 40,
  padding: "0px 43px",
  fontSize: 16,
  fontWeight: 600,
};

const nonSelectedTab = {
  background: "#F6F6F6",
  textTransform: "none",
  color: "#000000",
  height: 40,
  fontSize: 16,
  fontWeight: 600,
  padding: "0px 43px",
};

const tabsStyle = {
  background: "#F6F6F6",
  borderRadius: 53,
  padding: 5,
};
