import React, { useEffect, useState } from "react";
import { Button, Grid, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const CreateAdHeader = ({
  goback,
  reviewAd,
  handleReviewAd,
  handlePublishModalOpen,
  handleSaveDraftModalOpen,
}) => {
  const classes = useStyles();

  // console.log(typeof handleSaveDraftModalOpen)

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs justifyContent="center" alignItems={"center"}>
          <ArrowBackIosIcon className={classes.backIcon} onClick={goback} />
          <span className={classes.title}>Create Ad</span>
        </Grid>

        <Grid item xs style={{ textAlign: "end" }} justifyContent="center">
          {reviewAd ? (
            <Button
              variant="contained"
              style={reviewButtonStyle}
              onClick={handlePublishModalOpen}
            >
              Publish Ad
            </Button>
          ) : (
            <Button
              variant="contained"
              style={reviewButtonStyle}
              onClick={handleReviewAd}
            >
              Review Ad
            </Button>
          )}
          <Button
            variant="outlined"
            style={draftButtonStyle}
            onClick={handleSaveDraftModalOpen}
          >
            Save Draft
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default CreateAdHeader;

const useStyles = makeStyles(() => {
  return {
    title: {
      color: "#000",
      fontSize: 25,
      fontWeight: 600,
      marginLeft: 10,
    },
    backIcon: {
      //   marginTop: 15,
      marginBottom: -3,
      cursor: "pointer",
      color: "#C84B96",
    },
  };
});

const reviewButtonStyle = {
  color: "#FFFFFF",
  background: "#C84B96",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const draftButtonStyle = {
  color: "#C84B96",
  borderColor: "#C84B96",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
