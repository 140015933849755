import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import SortQuizMenu from "./SortQuizMenu";
import ButtonLoader from "../../ButtonLoader";
import ManageAdminModal from "../../admins/ManageAdminModal";
import AdminUpdatedModal from "../../admins/AdminUpdatedModal";
import UpdateQuizTitleModal from "./UpdateQuizTitleModal";
import UpdatedQuizTitleModal from "./UpdatedQuizTitleModal";

const QuizDetailsHeader = ({
  quizData,
  goback,
  setQuizData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dropdownButtonText,
  setDropdownButtonText,
  handleFilter,
  handleDateRangeData,
  handleDelteQuizModalOpen,
  quizStatus,
  publishLoading,
  handlePublishQuiz,
  handleLevel,
  level,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // console.log("Quiz Data>>>", quizData);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [adminUpdatedModal, setAdminUpdatedModal] = useState(false);

  const handleEditAdminModalOpen = () => {
    setEditAdminModal(true);
  };
  const handleEditAdminModalClose = () => {
    setEditAdminModal(false);
  };

  const handleAdminUpdatedModalOpen = () => {
    setAdminUpdatedModal(true);
  };
  const handleAdminUpdatedModalClose = () => {
    setAdminUpdatedModal(false);
  };

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs display={"flex"} alignItems={"center"}>
          <ArrowBackIosIcon className={classes.backIcon} onClick={goback} />
          <span className={classes.title}>
            {quizData && quizData.name
              ? quizData?.name
              : quizData?.title
              ? quizData?.title
              : undefined}
          </span>
          <span style={{ fontSize: 16, fontWeight: 500, marginRight: 10 }}>
            {`/ Quiz Order: ${quizData && quizData.order ? quizData?.order : "Not available"}`}
          </span>
          <EditIcon style={{ color: "#C84B96" }} onClick={() => handleEditAdminModalOpen()} />
        </Grid>

        <Grid item xs container style={{ textAlign: "end" }} justifyContent="end">
          {quizStatus === "Draft" || quizStatus === "Pending" ? (
            publishLoading ? (
              <Grid style={{ paddingRight: 50 }}>
                <ButtonLoader />
              </Grid>
            ) : (
              <>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row
                    value={level}
                    onChange={handleLevel}
                  >
                    {["easy", "hard"].map((item) => (
                      <FormControlLabel
                        value={item}
                        key={item}
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#c84b96",
                              },
                            }}
                          />
                        }
                        label={item}
                        style={{ textTransform: "capitalize" }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <Button variant="outlined" style={publishButtonStyle} onClick={handlePublishQuiz}>
                  Publish Quiz
                </Button>
              </>
            )
          ) : (
            ""
          )}
          <Button
            variant="outlined"
            style={sortButtonStyle}
            endIcon={<KeyboardArrowDownIcon />}
            id="sort-button"
            aria-controls={open ? "sort-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleOpenMenu}
          >
            {dropdownButtonText}
          </Button>

          <Button variant="outlined" style={deleteButtonStyle} onClick={handleDelteQuizModalOpen}>
            Delete
          </Button>
          <SortQuizMenu
            anchorEl={anchorEl}
            open={open}
            handleCloseMenu={handleCloseMenu}
            classes={classes}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setDropdownButtonText={setDropdownButtonText}
            handleFilter={handleFilter}
            handleDateRangeData={handleDateRangeData}
          />
        </Grid>
      </Grid>
      {/*Edit Admin Modal Section*/}
      <UpdateQuizTitleModal
        adminModal={editAdminModal}
        handleClose={handleEditAdminModalClose}
        title="Update Quiz"
        adminData={quizData}
        setAdminData={setQuizData}
        // loading={loading}
      />

      {/*Admin updated Modal Section*/}
      <UpdatedQuizTitleModal
        modalOpen={adminUpdatedModal}
        handleClose={handleAdminUpdatedModalOpen}
        adminData={quizData}
        title="Quiz Title & Order Updated"
      />
    </Toolbar>
  );
};

export default QuizDetailsHeader;

const useStyles = makeStyles(() => {
  return {
    title: {
      color: "#000",
      fontSize: 25,
      fontWeight: 600,
      marginLeft: 10,
      marginRight: 20,
    },
    backIcon: {
      //   marginTop: 15,
      marginBottom: -3,
      cursor: "pointer",
      color: "#C84B96",
    },
    customWidth: {
      "& div": {
        width: "305px",
        borderRadius: 20,
        padding: "0px 7px",
      },
    },
    sortTitle: {
      fontWeight: 600,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    sortItems: {
      fontWeight: 500,
      fontSize: 16,
      // fontFamily: "Poppins",
      color: "#000",
    },
    dateTextfield: {
      width: 253,
    },
    headerTitle: {
      color: "#313137",
      fontSize: 36,
      fontWeight: 700,
      textAlign: "center",
    },
  };
});

const sortButtonStyle = {
  color: "#000",
  borderColor: "#000",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
const deleteButtonStyle = {
  color: "#FD355A",
  borderColor: "#FD355A",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};

const publishButtonStyle = {
  color: "#FFFFFF",
  background: "#C84B96",
  borderRadius: 38,
  fontWeight: 600,
  textTransform: "capitalize",
  height: 43,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 10,
};
