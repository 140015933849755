import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import ButtonLoader from "../../ButtonLoader";

const UnsuspendUserModal = ({
  modalOpen,
  handleClose,
  modalStyle,
  userData,
  handleConfirm,
  loading,
}) => {
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Unsuspend User
        </span>
        <Grid container>
          <span style={messageText}>
            Are you sure you want to unsuspend the user “
            {userData &&
              `${
                userData.account_type === "Business"
                  ? userData.name && userData.name
                  : userData.first_name &&
                    userData.first_name + " " + userData.last_name &&
                    userData.last_name
              }`}
            ”?
          </span>
        </Grid>
        <Grid container>
          {loading ? (
            <Grid
              container
              style={{ width: "50%" }}
              alignItems="center"
              justifyContent="center"
            >
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirm}
              style={confirmButton}
            >
              Yes, I confirm
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={cancelButton}
          >
            No
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UnsuspendUserModal;

const cancelButton = {
  width: "130px",
  marginLeft: "auto",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  textTransform: "none",
};

const confirmButton = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
};

const messageText = {
  color: "#000000",
  fontSize: 15,
  fontWeight: 500,
  marginTop: 30,
  marginBottom: 30,
};
