import React from "react";
import { Button, Grid, Toolbar } from "@mui/material";

const SettingsHeader = ({ handleOpenLogoutModal }) => {
  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid item xs></Grid>
        <Grid
          xs
          item
          style={{
            textAlign: "center",
          }}
        >
          <h1>Personal Settings</h1>
        </Grid>
        <Grid xs item style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            style={logoutButtonStyle}
            onClick={handleOpenLogoutModal}
          >
            Log Out
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default SettingsHeader;

const logoutButtonStyle = {
  color: "#fff",
  background: "#C84B96",
  borderRadius: 38,
  marginTop: 25,
  fontWeight: 600,
  textTransform: "capitalize",
  fontSize: 15,
  height: 46,
};
