import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import ReviewsTab from "./ReviewsTab";
import DetailsTab from "./DetailsTab";
import React, { useState } from "react";
import FoodPairingsTab from "./FoodPairingsTab";
import Typography from "@mui/material/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ad-tabpanel-${index}`}
      aria-labelledby={`ad-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `ad-tab-${index}`,
    "aria-controls": `ad-tabpanel-${index}`,
  };
}

export default function WineTabs({
  wineData,
  setWineData,
  allWines,
  setAllWines,
  wineReviews,
  setWineReviews,
  winePairings,
  setWinePairings,
  handleGetWinePairings,
  handleGetWineReviews,
}) {
  const [value, setValue] = useState(0);
  //Handle Change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
          centered
          style={tabsStyle}
        >
          <Tab
            label="Details"
            {...a11yProps(0)}
            style={value === 0 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Reviews"
            {...a11yProps(1)}
            style={value === 1 ? selectedTab : nonSelectedTab}
          />
          <Tab
            label="Food Pairings"
            {...a11yProps(2)}
            style={value === 2 ? selectedTab : nonSelectedTab}
          />
        </Tabs>
      </Grid>

      <TabPanel value={value} index={0}>
        <DetailsTab
          wineData={wineData}
          setWineData={setWineData}
          allWines={allWines}
          setAllWines={setAllWines}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReviewsTab
          wineData={wineData}
          setWineData={setWineData}
          wineReviews={wineReviews}
          setWineReviews={setWineReviews}
          handleGetWineReviews={handleGetWineReviews}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FoodPairingsTab
          winePairings={winePairings}
          wineData={wineData}
          setWineData={setWineData}
          handleGetWinePairings={handleGetWinePairings}
          handleGetWineReviews={handleGetWineReviews}
        />
      </TabPanel>
    </Box>
  );
}

const selectedTab = {
  background: "#C84B96",
  borderRadius: "53px",
  textTransform: "none",
  color: "#FFF",
  height: 50,
  padding: "0px 30px",
  fontSize: 16,
  fontWeight: 500,
  width: 188,
};

const nonSelectedTab = {
  background: "#F6F6F6",
  textTransform: "none",
  color: "#000000",
  height: 50,
  fontSize: 16,
  fontWeight: 500,
  padding: "0px 30px",
  width: 188,
};

const tabsStyle = {
  background: "#F6F6F6",
  width: 575,
  borderRadius: 57,
  padding: 5,
  marginRight: 180,
};
